import React, { useEffect, useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";

const ManageNewAdress = () => {
  const [isChanged, setIsChanged] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [newWarehouseDetails, setNewWarehouseDetails] = useState({});

  const [inputData, setInputData] = useState({
    name: "",
    company: "",
    street1: "",
    city: "",
    zip: "",
    country: "",
  });

  useEffect(() => {
    const fetchwarehouse = async () => {
      try {
        var jsonString = localStorage.getItem("token");
        var jsonObject = JSON.parse(jsonString);
        var Token = jsonObject?.token;

        const response = await axios.get(
          "https://api.rawstuff.deals/api/v1/warehouses",
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${Token}`,
            },
          }
        );
        const warehouseData = response.data.data;
        setNewWarehouseDetails(warehouseData[0]);
        setInputData({
          name: warehouseData[0]?.name || "",
          company: warehouseData[0]?.company || "",
          street1: warehouseData[0]?.street1 || "",
          city: warehouseData[0]?.city || "",
          zip: warehouseData[0]?.zip || "",
          country: warehouseData[0]?.country || "",
        });
      } catch (error) {
        console.log(error);
      }
    };

    fetchwarehouse();
  }, []);

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const [errors, setErrors] = useState({
    name: "",
    company: "",
    address: "",
    street1: "",
    city: "",
    state: "",
    zip: "",
    country: "",
  });

  const createWarehouseInShippo = async () => {
    try {
      var jsonString = localStorage.getItem("token");
      var jsonObject = JSON.parse(jsonString);
      var Token = jsonObject?.token;

      let formIsValid = true;
      const newErrors = { ...errors };

      Object.keys(inputData).forEach((key) => {
        if (inputData[key] === "" || !inputData[key]) {
          formIsValid = false;
          newErrors[key] = `${
            key.charAt(0).toUpperCase() + key.slice(1)
          } is required`;
        } else {
          newErrors[key] = "";
        }
      });
      if (!formIsValid) {
        setErrors(newErrors);
        return;
      }

      // Make a request to create a warehouse
      const response = await fetch(
        "https://api.rawstuff.deals/api/v1/warehouses",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${Token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(inputData),
        }
      );

      // toast.success("Warehouse created successfully.");
      toast.success("Lager erfolgreich erstellt!");
      handleModalClose();
    } catch (error) {
      // toast.error("An error occurred. Please try again later.");
      toast.error(
        "Ein Fehler ist aufgetreten. Bitte versuchen Sie es später noch einmal."
      );
    }
  };

  const handleFormInputChange = (event) => {
    const { name, value } = event.target;
    setInputData((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));

    if (inputData[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }

    if (name === "zip") {
      const isValidZipCode = /^[a-zA-Z0-9]{4,7}$/.test(value);

      if (!isValidZipCode) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Invalid zip code",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
      }
    }

    setIsChanged(true);
  };

  return (
    <div className="address-container">
      <article className="address-pargraph">
        {isChanged ? (
          <>
            {inputData.name}, {inputData.company}, {inputData.country},{" "}
            {inputData.city},{inputData.zip}, {inputData.street1}
          </>
        ) : (
          <>
            {newWarehouseDetails.name}, {newWarehouseDetails.company},{" "}
            {newWarehouseDetails.country}, {newWarehouseDetails.city},{" "}
            {newWarehouseDetails.street1}, {newWarehouseDetails.zip}
          </>
        )}
      </article>
      <br />
      <div className="product-adress-seller" onClick={() => setModalOpen(true)}>
        <a
          href="#"
          class="btn btn-secondary"
          data-toggle="modal"
          data-target="#editwarehousemodal"
          data-whatever="@mdo"
        >
          <i class="fa fa-pencil">&nbsp;</i>Edit Details
        </a>
      </div>
      <Modal
        id="shopping-address-bg"
        show={modalOpen}
        onHide={handleModalClose}
      >
        <Modal.Body>
          <Modal.Header closeButton>
            <Modal.Title className="add-new-address">
              Add New Address
            </Modal.Title>
          </Modal.Header>
          <Form>
            <Row>
              <Form.Group controlId="formGridName">
                <Form.Label className="mt-3  mb-1">
                  Delivery Address Name
                </Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  required
                  value={inputData.name}
                  onChange={handleFormInputChange}
                />
                <span className="text-danger">{errors.name}</span>
              </Form.Group>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="formGridCompany">
                  <Form.Label className="mt-3 mb-1">Company</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    name="company"
                    value={inputData.company}
                    onChange={handleFormInputChange}
                  />
                  <span className="text-danger">{errors.company}</span>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Form.Group controlId="formGridCountry">
                <Form.Label className="mt-3 mb-1">Country</Form.Label>
                <Form.Control
                  as="select"
                  name="country"
                  required
                  value={inputData.country}
                  onChange={handleFormInputChange}
                >
                  <option value="">Select Country</option>
                  {europeanCountries.map((country) => (
                    <option key={country} value={country}>
                      {country}
                    </option>
                  ))}
                </Form.Control>
                <span className="text-danger">{errors.country}</span>
              </Form.Group>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group controlId="formGridCity">
                  <Form.Label className="mt-3  mb-1">City</Form.Label>
                  <Form.Control
                    type="text"
                    name="city"
                    required
                    value={inputData.city}
                    onChange={handleFormInputChange}
                    style={{ borderRadius: "8px" }}
                  />
                  <span className="text-danger">{errors.city}</span>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formGridStreet1">
                  <Form.Label className="mt-3 mb-1">Street 1</Form.Label>
                  <Form.Control
                    type="text"
                    name="street1"
                    required
                    value={inputData.street1}
                    onChange={handleFormInputChange}
                  />
                  <span className="text-danger">{errors.street1}</span>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group controlId="formGridZip">
                  <Form.Label className="mt-3  mb-1">Zip Code</Form.Label>
                  <Form.Control
                    type="text"
                    name="zip"
                    required
                    value={inputData.zip}
                    onChange={handleFormInputChange}
                  />
                  <span className="text-danger">{errors.zip}</span>
                </Form.Group>
              </Col>
            </Row>

            <Form.Group className="position-relative">
              <Form.Check
                className="Save-my-location"
                required
                name="terms"
                label="Save my location for future visit"
                // onChange={handleChange}
                feedbackType="invalid"
                id="validationFormik106"
                feedbackTooltip
                style={{
                  height: "48px",
                  width: "30rem",
                  // margin: "0.5rem",
                  paddingleft: "1.5rem",
                  border: "#E1E1E1",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "1rem",
                }}
              />
            </Form.Group>
            <Button
              className="Save-Delivery"
              style={{ margin: "0.3rem" }}
              variant="secondary"
              onClick={() => createWarehouseInShippo()}
            >
              Save Delivery Address
            </Button>
            <Button
              className="cancel-bt"
              style={{
                margin: "0.3rem",
                background: "#fff",
                color: "#2C2C2C",
                border: "1px solid #2C2C2C",
              }}
              onClick={handleModalClose}
            >
              Cancel
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ManageNewAdress;

export const europeanCountriesCode = {
  Germany: "DE",
  Switzerland: "CH",
  Austria: "AT",
  Albania: "AL",
  Belarus: "BY",
  Belgium: "BE",
  Bulgaria: "BG",
  Croatia: "HR",
  "Czech Republic": "CZ",
  Denmark: "DK",
  Estonia: "EE",
  Finland: "FI",
  France: "FR",
  Greece: "GR",
  Hungary: "HU",
  Iceland: "IC",
  Ireland: "IE",
  Italy: "IT",
  Latvia: "LV",
  Liechtenstein: "LI",
  Lithuania: "LT",
  Luxembourg: "LU",
  Netherlands: "NL",
  Norway: "NO",
  Poland: "PL",
  Portugal: "PT",
  Romania: "RO",
  Slovakia: "SK",
  Slovenia: "SI",
  Spain: "ES",
  Sweden: "SE",
  "United Kingdom": "UK",
};

export const europeanCountries = [
  "Germany",
  "Switzerland",
  "Austria",
  "Albania",
  "Belarus",
  "Belgium",
  "Bulgaria",
  "Croatia",
  "Czech Republic",
  "Denmark",
  "Estonia",
  "Finland",
  "France",
  "Greece",
  "Hungary",
  "Iceland",
  "Ireland",
  "Italy",
  "Latvia",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Netherlands",
  "Norway",
  "Poland",
  "Portugal",
  "Romania",
  "Slovakia",
  "Slovenia",
  "Spain",
  "Sweden",
  "United Kingdom",
];
