import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import GroupsIcon from "@mui/icons-material/Groups";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import HandshakeIcon from "@mui/icons-material/Handshake";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./PurchasedItemStyle.css";
import axios from "axios";

export const formatDate = (date) => {
  return new Date(date).toLocaleDateString();
};

const PartnerOverview = () => {
  const [isCopied, setIsCopied] = useState(false);
  const [isUrlCopied, setIsUrlCopied] = useState(false);
  const [partners, setPartners] = useState({});

  let token = localStorage.getItem("token");
  token = JSON.parse(token);

  const navigate = useNavigate();

  const bonus = partners?.dashboard?.bonus;
  const refferal = partners?.dashboard?.refferal_code;

  const promourl = `https://rawstuff.deals/register?ref=${refferal}`;

  useEffect(() => {
    if (token?.user?.partner_status === null) {
      navigate("/dashboard");
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsCopied(false);
      setIsUrlCopied(false);
    }, 2000);
  }, [isCopied, isUrlCopied]);

  useEffect(() => {
    const fetchPartnerOverview = async () => {
      try {
        const response = await axios.get(
          "https://api.rawstuff.deals/api/v1/partners",
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token.token}`,
            },
          }
        );
        const data = response.data;
        setPartners(data);
      } catch (error) {
        console.log("error");
      }
    };

    fetchPartnerOverview();
  }, []);

  const handleLinkCopy = () => {
    navigator.clipboard
      .writeText(promourl)
      .then(() => {
        setIsUrlCopied(true);
        setIsCopied(false);
      })
      .catch((error) => {
        console.error("Error copying text: ", error);
      });
  };

  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(refferal)
      .then(() => {
        setIsCopied(true);
        setIsUrlCopied(false);
      })
      .catch((error) => {
        console.error("Error copying text: ", error);
      });
  };

  const renderTooltip = (props) => (
    <Tooltip id="copied-tooltip" {...props}>
      Copied!
    </Tooltip>
  );

  if (!token) {
    navigate("/login");
    return;
  }

  return (
    <div className="dashboardd">
      <div
        className="row rounded-3 m-0 m-sm-3 main-card"
        style={{
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          border: "1px solid #ddd",
        }}
      >
        <div className="col-sm p-4 mx-3">
          <p className="my-3 fw-bold text-center">
            {/* Partner Overview */}
            Übersicht Partner
          </p>
          <div className="row partnerCardd">
            <p className="d-none d-xl-block col-xl col-xxl-1"></p>

            <div
              className="partner-info-card  col-sm-4 col-md col-lg d-flex justify-content-center align-items-center hvrcard p-4"
              style={{
                boxShadow: "4px 4px 4px 2px rgba(0, 0, 0, 0.3)",
                background: "#f7eee0",
                cursor: "default",
              }}
            >
              <p className="d-flex align-items-center  justify-content-center gap-1  m-0 p-0">
                <p className="m-0 p-0">
                  <GroupsIcon fontSize="large" />
                </p>
                <p className="m-0 p-0">
                  <p className="fw-bold m-0 p-0">{partners?.totalPartners}</p>
                  <p className="text-muted m-0 p-0">Empfohlene Partner</p>
                </p>
              </p>
            </div>

            <div
              className="partner-info-card p-4 col-sm-3 col-md col-lg d-flex justify-content-center align-items-center hvrcard mx-0 mx-sm-1 mx-md-3  my-3 my-sm-0"
              style={{
                boxShadow: "4px 4px 4px 2px rgba(0, 0, 0, 0.3)",
                background: "#ffffff",
                cursor: "default",
              }}
            >
              <p className="d-flex align-items-center  justify-content-center gap-1  m-0 p-0">
                <p className="m-0 p-0">
                  <HandshakeIcon fontSize="large" />
                </p>
                <p className="m-0 p-0">
                  <p className="fw-bold m-0 p-0">€{partners.totalPartners?partners.totalPartners*10:0} </p>
                  <p className="text-muted m-0 p-0">Bonus</p>
                </p>
              </p>
            </div>

            <div
              className="col-sm-4 col-md col-lg d-flex justify-content-center align-items-center hvrcard p-4"
              style={{
                boxShadow: "4px 4px 4px 2px rgba(0, 0, 0, 0.3)",
                background: "lightgray",
                color: "black",
              }}
            >
              <OverlayTrigger
                placement="top"
                overlay={renderTooltip}
                show={isCopied}
              >
                <div
                  className="partner-info-card col-sm d-flex justify-content-center align-items-center"
                  style={{ cursor: "pointer" }}
                >
                  <p
                    className="d-flex align-items-center justify-content-center gap-1 m-0 p-0"
                    onClick={handleCopyClick}
                  >
                    <span className="m-0 p-0">
                      <FileCopyIcon fontSize="large" />
                    </span>
                    <span className="m-0 p-0">
                      <span className="fw-bold m-0 p-0">{refferal}</span>
                      <br />
                      <span className="text-muted m-0 p-0">Referral code</span>
                    </span>
                  </p>
                </div>
              </OverlayTrigger>
            </div>
            <p className="d-none d-xl-block col-xl col-xxl-1"></p>
          </div>
        </div>
      </div>

      <div className="row mt-2 mb-3" style={{ cursor: "default" }}>
        <div className="text-center">
          <p className="text-center">
            {/* Partner refferal url */}
            URL für Empfehlungen
          </p>
          <div className="d-flex justify-content-center gap-3">
            <div className="border p-2 rounded-5 px-sm-5">{promourl}</div>
            <OverlayTrigger
              placement="top"
              show={isUrlCopied}
              overlay={renderTooltip}
            >
              <div
                className="border p-2 px-3 rounded-5  bg-secondary text-white"
                onClick={handleLinkCopy}
                style={{ cursor: "pointer" }}
              >
                kopieren
              </div>
            </OverlayTrigger>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm">
          <div className="table-responsive">
            <table
              className="table table-striped table-hover"
              style={{ textAlign: "left" }}
            >
              <thead>
                <tr>
                  <th className="w-10">S/N</th>
                  <th>Unternehmens-Name</th>
                  {/* <th>Status</th> */}
                  <th>Datum</th>
                  <th className="">Bonus</th>
                  <th className="" style={{ textAlign: "centerr" }}>
                    kommission
                  </th>
                </tr>
              </thead>
              <tbody>
                {partners?.partners?.map((refferal, index) => (
                  <tr key={index} style={{ cursor: "default" }}>
                    <td>{index + 1}</td>
                    <td>{refferal.name}</td>
                    {/* <td>{refferal.status}</td> */}
                    <td>{formatDate(refferal.created_at)}</td>
                    <td>{bonus ? bonus : 10}</td>
                    {/* <td>{refferal.commission ? refferal.commission : 0}</td> */}
                    <td>{'10%'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerOverview;
