import React, { useState, useEffect } from "react";
import "./ManageWarehouse.css";
import WareHouses from "./WareHouses";
import AddWarehouse from "./AddWarehouse";
import axios from "axios";

function ManageWarehouse() {
  const [modalOpen, setModalOpen] = useState(false);
  const [warehouse, setWarehouse] = useState([]);

  const fetchwarehouse = async () => {
    try {
      var jsonString = localStorage.getItem("token");
      var jsonObject = JSON.parse(jsonString);
      var Token = jsonObject?.token;

      const response = await axios.get(
        "https://api.rawstuff.deals/api/v1/warehouses",
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${Token}`,
          },
        }
      );
      const warehouseData = response.data.data;
      setWarehouse(warehouseData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchwarehouse();
  }, []);

  return (
    <div className="dashboardd">
      <div class="row">
        <div className="col-sm">
          <div className="col-md-12" style={{ margin: "1%" }}>
            <div class="card text-center">
              <div class="card-header">
                <ul class="nav nav-pills card-header-pills">
                  <li class="nav-item">
                    <a class="nav-link" style={{ color: "#000000" }} href="#">
                      {/* Manage Address */}
                      Adressen verwalten
                    </a>
                  </li>
                  <li>
                    <button
                      class="btn btn-secondary"
                      onClick={() => setModalOpen(true)}
                    >
                      +Addresse
                    </button>
                    <AddWarehouse
                      modalOpen={modalOpen}
                      handleModalClose={() => setModalOpen(false)}
                      fetchwarehouse={fetchwarehouse}
                    />
                  </li>
                </ul>
              </div>

              {/****body starts here**** */}
              <div class="container">
                <div class="row">
                  <div class="col-sm-12">
                    <div
                      class="alert alert-warning alert-dismissible fade show d-none"
                      role="alert"
                    >
                      <div
                        class="d-none"
                        style={{ margin: "auto", width: "20rem" }}
                      >
                        {/* <ManageNewAdress /> */}
                      </div>
                    </div>
                    <WareHouses
                      fetchwarehouse={fetchwarehouse}
                      warehouse={warehouse}
                    />
                  </div>
                </div>
              </div>
              {/****Body ends here*** */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageWarehouse;
