const termsandconditionsEN = {
  header: "Terms and Conditions of the Raw Material Trading Portal",
  sectiononetitle: "Scope of Application",
  sectiononebody: `The company Rawstuff Deals GmbH, Zum Heidchen 6, 56377 Nassau (hereinafter
    referred to as "RAWSTUFF DEALS®") operates an internet marketplace for raw materials
    (online platform) on the internet at www.rawstuff-deals.com/. These General Terms and
    Conditions (AGB) apply to the use of the raw material trading portal (hereinafter "portal")
    by users. Terms and conditions of the user that deviate from these conditions shall not
    apply unless confirmed in writing by RAWSTUFF DEALS®.`,
  sectiontwotitle: `Service Description`,
  sectiontwobody: [
    `RAWSTUFF DEALS® provides an online service through its services, enabling users
    as advertisers (hereinafter referred to as "providers") to publish offers consisting of texts
    and images (hereinafter referred to as "advertisements"). Interested parties have the
    opportunity to view advertisements published by other users. In addition, interested parties
    and providers have the opportunity to exchange electronic messages through the
    messaging function of RAWSTUFF DEALS® (hereinafter referred to as "messages").`,

    `The RAWSTUFF DEALS® online platform enables the publication of advertisements
    and the contact between providers and interested parties. RAWSTUFF DEALS® does not
    itself offer the goods in the advertisements. Rather, providers and interested parties of the
    listed goods on the online platform are exclusively brought together as immediate
    contractual parties for the conclusion of contracts.`,
  ],
  sectionthreetitle: "Registration and User Requirements",
  sectionthreebody: [
    `Registration is required for the full use of the services, especially for the use of the
    messaging function and profiles.`,
    `Full use is only permitted from the age of 18. Minor users require the consent of their
    legal guardians to use the services. In this case, RAWSTUFF DEALS® reserves the right
    to make the provision of services dependent on the submission of proof of consent from
    the legal guardians.`,
    `Users are obliged to provide the data collected during registration on the RAWSTUFF
    DEALS® online platform truthfully and completely. In case of changes to the data after
    registration, users are obliged to update the data promptly in the password-protected user
    area themselves.`,
    `Each user may only use one email address for the use of the RAWSTUFF DEALS®
    online platform. This may not be a so-called disposable email address.`,
    `By submitting the registration form, users make an offer to conclude a usage
    agreement with RAWSTUFF DEALS®, with which they acknowledge the applicability of
    the terms of use and declare their knowledge of the privacy policy. If RAWSTUFF DEALS® accepts the registration, users will receive a confirmation email. Upon receipt of the
    confirmation email, a contract for the use of the RAWSTUFF DEALS® services
    (hereinafter "usage contract") is concluded between RAWSTUFF DEALS® and the user.`,
    `There is no entitlement to conclude a usage contract.`,
    `Upon successful registration, a user account is created for users, which they can
    access using their email address and the password chosen during registration. Users can
    change the password at any time via the password-protected user area. It must be kept
    secret by users and kept protected against access by unauthorized third parties. The user
    is liable for all activities that take place under his account. If users discover or suspect that
    their access data is being used by a third party without authorization, they must
    immediately inform RAWSTUFF DEALS® and change their password immediately.`,
    `RAWSTUFF DEALS® may make the use of certain functions dependent on the
    fulfillment of additional requirements, such as providing additional data and verifying this
    data and/or previous payment behavior. Until clarification, users may be prohibited from
    using the website in full or in part.`,
  ],
  sectionfourtitle: "User Rights",
  sectionfourbody: [
    `The responsibility for the content of advertisements and posts lies solely with the users.
    RAWSTUFF DEALS® assumes no liability for advertisements published by users or third
    parties, especially in case of abusive use.`,
    `By submitting the advertisement, providers confirm that they have the right to use the
    text and image.`,
    `Any contractual relationships resulting from the communication of the user with other
    users arise exclusively between the participating users. The operator RAWSTUFF
    DEALS® is neither a representative nor a contracting party itself.`,
    `Accordingly, the user alone is responsible for communication with his customer. This
    means that the user will answer all questions about contract fulfillment, warranty, and
    reverse transactions with his customers himself and directly, and update them if necessary.`,
    `Expressly, the user as a seller is solely responsible for the delivery of the products to
    the buyer in accordance with applicable law.`,
    `The portal grants the user the right to access the functions offered in accordance with
    these terms and conditions.`,
    `The user undertakes not to use the portal for unlawful or fraudulent activities.`,
  ],
  sectionfivetitle: "Prices, Usage Fees, Charges",
  sectionfivebody: [
    `The use of the portal may be subject to a charge. These costs and their amount will be
    transparently communicated to the user before the conclusion of the contract.`,
    `The user is obliged to pay the due fees on time.`,
  ],
  sectionsixtitle: "Liability",
  sectionsixbody: [
    `RAWSTUFF DEALS® does not warrant uninterrupted availability of the online platform
    on the internet and the effects of force majeure.`,
    `The advertisements on the RAWSTUFF DEALS® online platform originate from users.`,
    `RAWSTUFF DEALS® assumes no liability for the content, accuracy, quality,
    completeness, security, or legality of the advertisements or user communication.`,
    `Contracts between providers and interested parties are concluded directly between the
    contracting parties. RAWSTUFF DEALS® has no influence on this and is not involved in
    this contractual relationship.`,
    `RAWSTUFF DEALS® is liable according to the legal provisions for intent and gross
    negligence of RAWSTUFF DEALS®, its legal representatives, senior employees, or other
    vicarious agents. The same applies to the assumption of guarantees or any other
    assumption of strict liability as well as claims under the Product Liability Act or in the event
    of culpable injury to life, limb, or health. RAWSTUFF DEALS® is also liable in principle for
    simple negligence by RAWSTUFF DEALS®, its representatives, senior employees, and
    other vicarious agents causing simple negligent breaches of material contractual
    obligations, i.e., those obligations on whose fulfillment the user may regularly rely and trust
    in the proper execution of the contract, but in this case limited to the typically occurring,
    foreseeable damage. Further liability of RAWSTUFF DEALS® is excluded.`,
    `The European Commission provides a platform for online dispute resolution (ODR
      platform) at http://ec.europa.eu/consumers/odr/. RAWSTUFF DEALS® is neither willing
      nor obliged to participate in a dispute resolution procedure before a consumer arbitration
      board.`,
  ],
  sectionseventitle: "User Liability",
  sectionsevenbody: [
    `Users indemnify RAWSTUFF DEALS® against all claims asserted by other users or
    other third parties due to an infringement of their rights by advertisements and other
    content posted by users on the RAWSTUFF DEALS® online platform or due to other use
    of the RAWSTUFF DEALS® services. Each user bears the costs of the necessary legal
    defense of RAWSTUFF DEALS®, including all court and attorney fees in statutory amount.
    The claim for indemnification does not exist if the users are not responsible for the
    infringement.`,
    `Users are obliged to provide RAWSTUFF DEALS® promptly upon request with all
    information truthfully and completely necessary for the examination of claims and defense
    against them in case of claims by third parties.`,
    `Liability of users beyond these regulations remains unaffected.`,
  ],
  sectioneighttitle: "Termination",
  sectioneightbody: `RAWSTUFF DEALS® reserves the right to terminate the user's use of its online platform at any time if the user violates these terms and conditions or applicable laws.`,
  sectionninetitle: "Changes to the Terms and Conditions",
  sectionninebody: `RAWSTUFF DEALS® reserves the right to change these terms and conditions at any time. Users will be informed about changes by email or through the portal. Continued use of the portal after receipt of the notification shall be deemed acceptance of the amended terms and conditions.`,
  sectiontentitle: "Final Provisions",
  sectiontenbody: [
    `These terms and conditions are governed by the law of the Federal Republic of
    Germany.`,
    `If any provision of these terms and conditions is held to be invalid or unenforceable, the
    validity of the remaining provisions shall not be affected.`,
  ],
};

Object.freeze(termsandconditionsEN);

export default termsandconditionsEN;
