import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { RefinementList, Stats } from "react-instantsearch-dom";
// import { CustomStateResults } from "./StateResult";
import "./style.css";

const Filter = () => {
  return (
    <div className="container-fluid">
      <div>
        <div className="mb-4">
          {/* <CustomStateResults /> */}
          <Stats />
        </div>
        <div className="row filters">
          <Dropdown
            className="d-inline certificate-card w-10 p-0 m-0 custom-togglee"
            autoClose="outside"
          >
            <Dropdown.Toggle
              id="dropdown-autoclose-outside"
              className="custom-toggle"
            >
              Kategorie
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <RefinementList attribute="categories" />
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown
            className="d-inline certificate-card w-10 p-0 m-0 custom-togglee"
            autoClose="outside"
          >
            <Dropdown.Toggle
              className="custom-toggle"
              id="dropdown-autoclose-outside"
            >
              Zertifikat
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <RefinementList attribute="certifications" />
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown
            className="d-inline certificate-card p-0 m-0 w-10 custom-togglee"
            autoClose="outside"
          >
            <Dropdown.Toggle
              className="custom-toggle"
              id="dropdown-autoclose-outside"
            >
              Cas nummer
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <RefinementList attribute="cas_number" />
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="filter-border"></div>
      </div>
    </div>
  );
};

export default Filter;
