import React from "react";
import { connectRefinementList } from "react-instantsearch-dom";


const CustomRefinementList = connectRefinementList(
  ({ items, refine }) => (
      <select style={{width:'158px'}} className="custom-select">
        <option className="d-none">Category</option>
        {items.map(item => (
          <option key={item.label} onChange={event => refine(item.value)}
          onClick={localStorage.setItem('categorySelected', item.value)}>
            <label>
              <input
                type="checkbox"
                value={item.label}
                checked={item.isRefined}
              />
                {item.label}
            </label>
          </option>
        ))}
      </select>
  )
);

export default CustomRefinementList;
