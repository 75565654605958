import React, { createContext, useState } from "react";

export const StateContext = createContext();

function Stateprovider(props) {
  const [result, setResult] = useState([]);
  const values = {
    result,
    setResult,
  };

  return (
    <StateContext.Provider value={values}>
      {props.children}
    </StateContext.Provider>
  );
}

export default Stateprovider;