import React from "react";
import { images } from "../../../Constants";
import "./AboutRawStuff.css";

const AboutRawStuff = () => {
  return (
    <div className="About-rawStuff-container">
      <div className="about-rawstuff-content">
        <h3 className="text-center">Über uns</h3>
        <div className="about-rawstuff-description">
          <p>
            Rawstuff Deals, Ihrem vertrauenswürdigen Partner im globalen
            Rohstoffhandel. Wir sind eine dynamische B2B-Plattform, die darauf
            spezialisiert ist, Verkäufer von hochwertigen Rohstoffen mit
            engagierten Käufern aus aller Welt zusammenzubringen. Unsere Mission
            ist es, den Rohstoffhandel effizienter, transparenter und
            zugänglicher zu machen.
          </p>
          <p>
            Gegründet von einem Team aus erfahrenen Fachleuten der
            Handelsbranche, verstehen wir die Herausforderungen und Chancen des
            globalen Rohstoffmarktes. Deshalb haben wir Rawstuff Deals ins Leben
            gerufen – eine Plattform, die durch innovative Technologie und
            Kundenorientierung den Handel revolutioniert.
          </p>
          <p>
            Wir bieten unseren Nutzern eine breite Palette an Dienstleistungen
            an, darunter eine fortschrittliche Suchmaschine, um genau die
            Rohstoffe zu finden, die sie benötigen, und umfassende
            Verifizierungsprozesse, um die Qualität und Zuverlässigkeit aller
            auf unserer Plattform angebotenen Produkte zu gewährleisten. Unsere
            Nutzer profitieren von der Möglichkeit, Angebote verschiedener
            Verkäufer mühelos zu vergleichen und fundierte Einkaufs- oder
            Verkaufsentscheidungen zu treffen.
          </p>
          <p>
            Bei Rawstuff Deals legen wir großen Wert auf Sicherheit und
            Vertrauen. Deshalb bieten wir einen sicheren Handelsbereich, in dem
            alle Transaktionen vertraulich und geschützt abgewickelt werden
            können. Wir sind bestrebt, eine Gemeinschaft aufzubauen, in der
            Verkäufer und Käufer gleichermaßen von transparenten, fairen und
            effizienten Handelsbedingungen profitieren.
          </p>
          <p>
            Unser engagiertes Team arbeitet ständig daran, die Plattform zu
            verbessern und anzupassen, um den sich wandelnden Bedürfnissen
            unserer Nutzer gerecht zu werden. Wir sind stolz darauf, eine
            Schlüsselrolle in der Modernisierung des Rohstoffhandels zu spielen
            und freuen uns darauf, Sie auf Ihrem Handelsweg zu begleiten.
          </p>
          <p>
            Rawstuff Deals – Ihr Tor zu einem effizienteren und sichereren
            Rohstoffhandel.
          </p>
          <p className="text-center">bg image here</p>
        </div>
      </div>

      <div className="aboutus-download-btn">
        <a href="#scroll-down">
          <img src={images.aboutusdownload} alt="about-download-btn" />
        </a>
      </div>
    </div>
  );
};

export default AboutRawStuff;
