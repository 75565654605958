import React from 'react'
import "./Agbs.css"

const Agbs = () => {
  return (
    <div className="agb-container">
      <div className="container border px-4 pt-4 pb-5">
        <h3 className="mb-2">§ 1 Geltungsbereich</h3>
        <p className="mb-1 pb-2">
          Die Firma Rawstuff Deals GmbH, Zum Heidchen 6, 56377 Nassau (im
          Folgenden RAWSTUFF DEALS®) betreibt im Internet unter
          www.rawstuff.deals/ einen Internetmarktplatz für Rohstoffe
          (Online-Plattform). Diese Allgemeinen Geschäftsbedingungen (AGB)
          gelten für die Nutzung des Rohstoffhandelsportals (im Folgenden
          „Portal“) durch die Nutzer. Von diesen Bedingungen abweichende
          Geschäftsbedingungen des Nutzers finden keine Anwendung, wenn sie von
          RAWSTUFF DEALS® nicht schriftlich bestätigt worden sind.
        </p>
        <h3 className="mb-2">§ 2 Leistungsbeschreibung</h3>
        <p className="mb-1 ps-3 pb-2">
          (1). RAWSTUFF DEALS® stellt mit seinen Diensten einen Online-Service
          zur Verfügung, über den Nutzer als Inserenten (im Folgenden "Anbieter"
          genannt) aus Texten und Bildern bestehende Angebote (im Folgenden
          "Anzeigen" genannt) veröffentlichen können. Interessenten haben die
          Möglichkeit, veröffentlichte Anzeigen anderer Nutzer einzusehen.
          Darüber hinaus besteht für Interessent und Anbieter die Möglichkeit,
          elektronische Nachrichten über die Nachrichtenfunktion von RAWSTUFF
          DEALS® (im Folgenden "Nachrichten" genannt) auszutauschen.
        </p>
        <p className="mb-1 ps-3 pb-2">
          (2). Die RAWSTUFF DEALS® Online-Plattform ermöglicht das
          Veröffentlichen von Anzeigen und den Kontakt von Anbietern und
          Interessenten. RAWSTUFF DEALS® ist nicht selbst Anbieter der Waren in
          den Anzeigen. Vielmehr werden Anbieter und Interessenten der
          eingestellten Waren auf der Online-Plattform auschließlich als
          unmittelbare Vertragsparteien zum Vertragsabschluss zusammengeführt.
        </p>
        <h3 className="mb-2">§ 3 Registrierung und Nutzungsvoraussetzungen</h3>
        <p className="mb-1 ps-3 pb-2">
          (1). Für die vollständige Nutzung der Dienstleistungen, insbesondere
          für die Verwendung der Nachrichtenfunktion und der Profile, ist eine
          Registrierung erforderlich.
        </p>
        <p className="mb-1 ps-3 pb-2">
          (2). Die vollständige Nutzung ist erst ab 18 Jahren gestattet.
          Minderjährige Nutzer bedürfen zur Nutzung die Zustimmung ihrer
          Erziehungsberechtigten. RAWSTUFF DEALS® behält sich in diesem Fall
          vor, die Leistungserbringung von der Vorlage eines Nachweises der
          Zustimmung der Erziehungsberechtigten abhängig zu machen.
        </p>
        <p className="mb-1 ps-3 pb-2">
          (3). Nutzer sind verpflichtet, die bei der Registrierung auf der
          Online-Plattform von RAWSTUFF DEALS® erhobenen Daten wahrheitsgemäß
          und vollständig anzugeben. Bei einer Änderung der Daten nach erfolgter
          Registrierung sind Nutzer verpflichtet, die Daten unverzüglich im
          passwortgeschützten Nutzerbereich selbst zu aktualisieren.
        </p>
        <p className="mb-1 ps-3 pb-2">
          (4). Jeder Nutzer darf für die Nutzung der Online-Plattform von
          RAWSTUFF DEALS® lediglich eine E-Mail-Adresse verwenden. Dabei darf es
          sich nicht um eine sogenannte Wegwerf-E-Mail-Adresse handeln.
        </p>
        <p className="mb-1 ps-3 pb-2">
          (5). Mit dem Absenden des Registrierungsformulars geben Nutzer ein
          Angebot auf den Abschluss einer Nutzungsvereinbarung mit RAWSTUFF
          DEALS® ab, mit dem sie die Geltung der Nutzungsbedingungen anerkennen
          sowie die Kenntnisnahme der Datenschutzerklärung erklären. Akzeptiert
          RAWSTUFF DEALS® die Registrierung, erhalten Nutzer eine
          Bestätigungs-E-Mail. Mit Zugang der Bestätigungs-E-Mail kommt zwischen
          RAWSTUFF DEALS® und Nutzer ein Vertrag über die Nutzung der RAWSTUFF
          DEALS® -Dienste (im Folgenden „Nutzungsvertrag“) zustande.
        </p>
        <p className="mb-1 ps-3 pb-2">
          (6). Ein Anspruch auf Abschluss eines Nutzungsvertrages besteht nicht.
        </p>
        <p className="mb-1 ps-3 pb-2">
          (7). Mit erfolgreicher Registrierung wird für Nutzer ein Nutzerkonto
          angelegt, auf das sie mit Hilfe ihrer E-Mail-Adresse und ihres bei der
          Registrierung gewählten Passwortes zugreifen können. Das Passwort
          können Nutzer jederzeit über den passwortgeschützten Nutzerbereich
          ändern. Es ist von Nutzern geheim zu halten und vor dem Zugriff durch
          unbefugte Dritte geschützt aufzubewahren. Der Nutzer haftet für alle
          Aktivitäten, die unter seinem Konto stattfinden. Stellen Nutzer fest
          oder befürchten, dass ihre Zugangsdaten von einem Dritten unbefugt
          genutzt werden, haben sie dies RAWSTUFF DEALS® unverzüglich
          mitzuteilen und ihr Passwort unverzüglich zu ändern.
        </p>
        <p className="mb-1 ps-3 pb-2">
          (8). RAWSTUFF DEALS® kann die Nutzung bestimmter Funktionen von der
          Erfüllung zusätzlicher Voraussetzungen Nachweise zur Verifizierung von
          Angaben, z.B. Bereitstellung zusätzlicher Daten sowie der
          Verifizierung dieser Daten und/oder dem bisherigen Zahlungsverhalten,
          abhängig machen. Bis zur Klärung können Nutzern die vollständige oder
          teilweise Nutzung der Website untersagt werden.
        </p>
        <h3 className="mb-2">§ 4 Nutzungsrechte</h3>
        <p className="mb-1 ps-3 pb-2">
          (1). Die Verantwortung für Anzeigeninhalte und Beiträge liegt allein
          bei den Nutzern RAWSTUFF DEALS® übernimmt keinerlei Gewährleistung für
          die durch Nutzer oder Dritte veröffentlichte Anzeigen, insbesondere
          bei missbräuchlicher Nutzung.
        </p>
        <p className="mb-1 ps-3 pb-2">
          (2). Durch das Absenden der Anzeige bestätigen Anbieter, die
          Verwendungsrechte an Text und Bild zu besitzen
        </p>
        <p className="mb-1 ps-3 pb-2">
          (3). Bei jedweder Kommunikation des Nutzers mit anderen Nutzern
          entstehen etwaige Vertragsbeziehungen ausschließlich zwischen den
          beteiligten Nutzern. Der Betreiber RAWSTUFF DEALS® ist weder
          Stellvertreter noch wird er selbst Vertragspartner.
        </p>
        <p className="mb-1 ps-3 pb-2">
          (4). Dementsprechend ist der Nutzer allein für die Kommunikation mit
          seinem Kunden verantwortlich. D.h. der Nutzer wird alle Fragen zur
          Vertragserfüllung, Gewährleistung und Rückabwicklung mit seinen Kunden
          selbst und direkt beantworten und wenn erforderlich aktualisieren.
        </p>
        <p className="mb-1 ps-3 pb-2">
          (5). Ausdrücklich ist der Nutzer als Verkäufer allein verantwortlich
          für die Lieferung der Produkte an den Käufer in Übereinstimmung mit
          dem geltenden Recht.
        </p>
        <p className="mb-1 ps-3 pb-2">
          (6). Das Portal gewährt dem Nutzer das Recht, auf die angebotenen
          Funktionen gemäß diesen AGB zuzugreifen.
        </p>
        <p className="mb-1 ps-3 pb-2">
          (7). Der Nutzer verpflichtet sich, das Portal nicht für rechtswidrige
          oder betrügerische Aktivitäten zu nutzen.
        </p>
        <h3 className="mb-2">§ 5 Preise, Nutzungsentgelte, Gebühren</h3>
        <p className="mb-1 ps-3 pb-2">
          (1). Die Nutzung des Portals kann kostenpflichtig sein. Diese Kosten
          und deren Höhe werden dem Nutzer vor Vertragsschluss transparent
          mitgeteilt.
        </p>
        <p className="mb-1 ps-3 pb-2">
          (2). Der Nutzer ist verpflichtet, die fälligen Gebühren fristgerecht
          zu zahlen.
        </p>
        <h3 className="mb-2">§ 6 Haftung</h3>
        <p className="mb-1 ps-3 pb-2">
          (1). RAWSTUFF DEALS® übernimmt keine Gewährleistung für die
          ununterbrochene Verfügbarkeit der Online-Plattform im Internet und die
          Auswirkungen höherer Gewalt.
        </p>
        <p className="mb-1 ps-3 pb-2">
          (2). Die Anzeigen auf der Online-Plattform RAWSTUFF DEALS® stammen von
          Nutzern.
        </p>
        <p className="mb-1 ps-3 pb-2">
          (3). RAWSTUFF DEALS® übernimmt keine Gewährleistung für den Inhalt,
          die Richtigkeit, Qualität, Vollständigkeit, Sicherheit oder Legalität
          der Anzeigen oder der Nutzerkommunikation.
        </p>
        <p className="mb-1 ps-3 pb-2">
          (4). Verträge zwischen Anbieter und Interessenten kommen direkt
          zwischen den Vertragsparteien zusammen. RAWSTUFF DEALS® hat darauf
          keinen Einfluss und ist an diesem Vertragsverhältnis nicht beteiligt.
        </p>
        <p className="mb-1 ps-3 pb-2">
          (5). RAWSTUFF DEALS® haftet nach den gesetzlichen Vorschriften für
          Vorsatz und grobe Fahrlässigkeit von RAWSTUFF DEALS®, ihren
          gesetzlichen Vertretern, leitenden Angestellten oder sonstigen
          Erfüllungsgehilfen. Gleiches gilt bei der Übernahme von Garantien oder
          einer sonstigen Übernahme einer verschuldensunabhängigen Haftung sowie
          bei Ansprüchen nach dem Produkthaftungsgesetz oder bei einer
          schuldhaften Verletzung des Lebens, des Körpers oder der Gesundheit.
          RAWSTUFF DEALS® haftet zudem dem Grunde nach für durch RAWSTUFF
          DEALS®, ihre Vertreter, leitende Angestellten und sonstige
          Erfüllungsgehilfen verursachte einfach fahrlässige Verletzungen
          wesentlicher Vertragspflichten, also solcher Pflichten, auf deren
          Erfüllung der Nutzer zur ordnungsgemäßen Durchführung des Vertrages
          regelmäßig vertraut und vertrauen darf, in diesem Fall aber der Höhe
          nach begrenzt auf den typischerweise entstehenden, vorhersehbaren
          Schaden. Eine weitergehende Haftung von RAWSTUFF DEALS® ist
          ausgeschlossen.
        </p>
        <p className="mb-1 ps-3 pb-2">
          (6). Die Europäische Kommission stellt unter
          http://ec.europa.eu/consumers/odr/ eine Plattform zur
          außergerichtlichen Online-Streitbeilegung (sog. OS-Plattform) bereit.
          RAWSTUFF DEALS® ist zur Teilnahme an einem Streitbeilegungsverfahren
          vor einer Verbraucherschlichtungsstelle weder bereit noch verpflichtet
        </p>
        <h3 className="mb-2">§ 7 Haftung der Nutzer</h3>
        <p className="mb-1 ps-3 pb-2">
          (1). Nutzer stellen RAWSTUFF DEALS® von sämtlichen Ansprüchen frei,
          die andere Nutzer oder sonstige Dritte wegen einer Verletzung ihrer
          Rechte durch von Nutzern auf der Online-Plattform von RAWSTUFF DEALS®
          eingestellten Anzeigen und sonstigen Inhalte oder wegen sonstiger
          Nutzung der RAWSTUFF DEALS® -Dienste gegenüber RAWSTUFF DEALS® geltend
          machen. Jeder Nutzer übernimmt hierbei die Kosten der notwendigen
          Rechtsverteidigung von RAWSTUFF DEALS® einschließlich sämtlicher
          Gerichts- und Anwaltskosten in gesetzlicher Höhe. Der Anspruch auf
          Freistellung besteht nicht, wenn die Nutzer die Rechtsverletzung nicht
          zu vertreten haben.
        </p>
        <p className="mb-1 ps-3 pb-2">
          (2). Nutzer sind verpflichtet, RAWSTUFF DEALS® im Falle einer
          Inanspruchnahme durch Dritte auf Anforderung unverzüglich
          wahrheitsgemäß und vollständig alle Informationen zur Verteidigung zur
          Verfügung zu stellen, die für die Prüfung der Ansprüche und eine
          Verteidigung dagegen erforderlich sind.
        </p>
        <p className="mb-1 ps-3 pb-2">
          (3). Eine über diese Regelungen hinausgehende Haftung der Nutzer
          bleibt unberührt.
        </p>
        <h3 className="mb-2">§ 8 Kündigung</h3>
        <p className="mb-1 pb-2">
          Das RAWSTUFF DEALS® behält sich das Recht vor, die Nutzung seiner
          Online-Plattform durch den Nutzer jederzeit zu beenden, wenn dieser
          gegen diese AGB oder geltende Gesetze verstößt.
        </p>
        <h3 className="mb-2">§ 9 Änderungen der AGB</h3>
        <p className="mb-1 pb-2">
          RAWSTUFF DEALS® behält sich das Recht vor, diese AGB jederzeit zu
          ändern. Die Nutzer werden über Änderungen per E-Mail oder über das
          Portal informiert. Die fortgesetzte Nutzung des Portals nach Erhalt
          der Mitteilung gilt als Zustimmung zu den geänderten AGB.
        </p>
        <h3 className="mb-2">§ 10 Schlussbestimmungen</h3>
        <p className="mb-1 ps-3 pb-2">
          (1). Diese AGB unterliegen dem Recht der Bundesrepublik Deutschland.
        </p>
        <p className="mb-1 ps-3 pb-2">
          (2). Sollte eine Bestimmung dieser AGB für ungültig oder nicht
          durchsetzbar erklärt werden, bleibt die Gültigkeit der übrigen
          Bestimmungen unberührt.
        </p>
      </div>
    </div>
  );
}

export default Agbs