import React, { useState } from "react";
import "./ProfilePage.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocale, useTranslation } from "@psyycker/react-translation";

const ProfilePage = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [orderId, setOrderId] = useState("");
  const [errorPopUp, setErrorPopUp] = useState("");
  // const [confirmationBox, setConfirmationBox] = useState("")
  const [orderStatus, setOrderStatus] = useState("");
  const [showCancelRefund, setShowCancelRefund] = useState(false);
  const order = {
    status: orderStatus,
  };

  // let userName = localStorage.getItem("userName");
  let profileData = localStorage.getItem("token");
  let parseProfiledata = JSON.parse(profileData);
  let Token = useSelector((state) => state.userAuth.token);
  let newtoken = JSON.parse(Token);

  //Get Language Translation starts
  const { getTranslation } = useTranslation();
  const [locale, setLocale] = useLocale();
  const onButtonClick = () => {
    var lang = document.getElementById("lang").placeholder;
    var selectedx = "You have selected";
    if (lang == "English") {
      setLocale("en-US");
    }

    if (lang == "Germany") {
      setLocale("german");
    }

    if (lang == "Dutch") {
      setLocale("de");
    }

    if (lang == "French") {
      setLocale("fr-FR");
    }
  };

  //Get Language Translation ends
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("token");
    navigate("/");
    window.location.reload();
  };

  return (
    <div className="dashboard">
      <div class="row">
        <div class="col-sm">
          <div class="card bg-light mb-3">
            <div class="card-header">
              <h5>
              Käufer Profil &nbsp;
                <a href="edit-profile">
                  <i class="fa fa-pencil">&nbsp;&nbsp;</i>
                </a>
              </h5>{" "}
            </div>
            <h1 class="display-4">Käufer</h1>
            <center>
              {/**<img src={images.ProfileAvatar} className="ProfilePic" />**/}
              <p>
                <i class="fa fa-map">&nbsp;&nbsp;</i>
                {parseProfiledata.user.house_number},{" "}
                {parseProfiledata.user.street}, {parseProfiledata.user.city},{" "}
                {parseProfiledata.user.country}
              </p>
            </center>
            <hr class="my-4" />

            <p class="lead">
              <div class="container">
                <div class="row">
                  <div class="col-sm-4">
                    <span class="badge badge-pill badge-secondary" style={{background:"#eeeeee", color:"#000000"}}>
                      <i class="fa fa-user">&nbsp;&nbsp;</i>
                      Konto Typ: {newtoken.user.role}
                    </span>
                    <br />
                    <span class="badge badge-pill badge-warning" style={{background:"#eeeeee", color:"#000000"}}>
                      <i class="fa fa-phone">&nbsp;&nbsp;</i>
                      {newtoken.user.phone}
                    </span>
                    <br />
                    Umsatzsteuer ID: {newtoken.user.vat_number}
                    <br />
                    Email: {newtoken.user.email}
                    <br />
                    Ansprechpartner Phone: {newtoken.user.authorized_rep_telephone}
                  </div>

                  <div class="col-sm-3">
                    <span class="badge badge-pill badge-secondary" style={{background:"#eeeeee", color:"#000000"}}>
                      <i class="fa fa-certificate">&nbsp;&nbsp;</i>
                      {parseProfiledata.user.is_active === 1 && "Verifiziert"}
                    </span>
                    <br />
                    <b>Geschlecht:</b> {newtoken.user.authorized_rep_gender}
                    <br />
                    <b>Geburtsdatum :</b> {newtoken.user.authorized_rep_dob}
                    <br />
                    <b>Comapany Type:</b> {newtoken.user.compay_type}
                    <br />
                    <b>Land:</b> {newtoken.user.country}
                    <br />
                    
                  </div>

                  <div class="col-sm-4">
                    <i class="fa fa-users">&nbsp;&nbsp;</i>
                    <b>Ansprechpartner: </b>
                    <hr />
                    {newtoken.user.authorized_rep_firstName}&nbsp;{" "}
                    {newtoken.user.authorized_rep_lastName}
                    <br />
                    Position: {newtoken.user.authorized_rep_position}
                    <br />
                    <a
                      target="_blank"
                      href={
                        "https://api.rawstuff.deals/" +
                        newtoken.user.verification_document_path
                      }
                    >
                      Meine Dokumente anzeigen
                    </a>
                    <br />
                    <a
                      target="_blank"
                      href={
                        "https://api.rawstuff.deals/" +
                        newtoken.user.company_document_path
                      }
                    >
                      Meine Dokumente anzeigen
                    </a>
                  </div>
                </div>
              </div>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
