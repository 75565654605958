import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import "./style.css";
import { ProductCard } from "./ProductCard";
import { addToCarts } from "../../redux/Slice/ShoppingCartSlice";
import { useDispatch, useSelector } from "react-redux";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import FolderIcon from "@mui/icons-material/Folder";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import wl from "../../Constants/Contents/wishlist";

const WishList = () => {
  const [show, setShow] = useState(false);
  const [show7, setShow7] = useState(false);
  const [modalPosition7, setModalPosition7] = useState({ top: 0, left: 0 });

  const handleClick = (event) => {
    setShow(true);
  };

  const handleClick7 = (event) => {
    const { pageX, pageY } = event;
    setModalPosition7({ top: pageY, left: pageX });
    setShow7(true);
  };

  //retrive immidiately remainig product from db after delete
  const handleDelete = async (list_name) => {
    try {
      let jsonString = localStorage.getItem("token");
      let token = JSON.parse(jsonString).token;

      const url = "https://api.rawstuff.deals/api/v1/wish_list";

      const response = await axios.get(url, {
        params: { list_name: list_name },
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      setWishLists(response.data.Wish_lists);
      setLoading(false);
    } catch (error) {
      setLoading(true);
      console.error("Error fetching hot product:", error);
    }
  };

  //products in the List
  const [wishLists, setWishLists] = useState([]);

  //selected list
  const [selL, setSelL] = useState("default list");

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  let Token = useSelector((state) => state.userAuth?.token);
  const handleAddToCart = (trend) => {
    dispatch(addToCarts(trend.id));
    if (Token && Token) {
      toast.success("Produkt erfolgreich in den Warenkorb gelegt");
    }
  };
  const [lists, setLists] = useState([]);
  const [seemore, setSeemore] = useState(false);

  //Fetch available list from db
  const fetchLists = async () => {
    try {
      const url = "https://api.rawstuff.deals/api/v1/wishlist_collection";
      const response = await axios.get(url, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setLists(response.data.collections);
    } catch (error) {
      console.error("Error fetching:", error);
    }
  };

  //fetch default wishlist from API
  useEffect(() => {
    const fetchDefaultWishListProducts = async () => {
      setLoading(true);
      try {
        const url = "https://api.rawstuff.deals/api/v1/wish_list";

        const response = await axios.get(url, {
          params: {
            list_name: "default list",
          },
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        setWishLists(response.data.Wish_lists);
        setLoading(false);
      } catch (error) {
        setLoading(true);
        console.error("Error fetching product:", error);
      }
    };
    fetchDefaultWishListProducts();
    fetchLists();
  }, []);

  // fetch specific wishlist from API
  const fetchWishListProducts = async (list_name) => {
    setLoading(true);
    try {
      let token = localStorage.getItem("token");
      token = JSON.parse(token).token;

      var nlast = "";
      if (list_name) {
        nlast += list_name;
        setSelL(list_name);
      } else {
        nlast = "";
        setSelL("default list");
      }

      const url = `https://api.rawstuff.deals/api/v1/wish_list`;
      const queryParams = {
        list_name: nlast,
      };

      const response = await axios.get(url, {
        params: queryParams,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      setWishLists(response.data.Wish_lists);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  //Number of list visible on left side bar
  let listed;
  let def;
  let defid;
  if (lists && lists.length > 0) {
    listed = lists.slice();
    def = lists.find((el) => el.list_name === "default list");

    if (!seemore) {
      listed = lists.slice(0, 4);
    }
    listed = listed
      ?.map((obj) => {
        if (obj.list_name === def.list_name) {
          return null;
        }
        return obj;
      })
      .filter(Boolean);
    defid = listed.filter((obj) => obj.list_name === selL);
    if (defid.length >= 1) {
      defid = defid[0].id;
    }
  }

  //Delete any List
  const deleteList = async (list_name) => {
    try {
      let list_id = lists.find((list) => list.list_name === list_name).id;
      let token = localStorage.getItem("token");
      token = JSON.parse(token).token;

      const url = `https://api.rawstuff.deals/api/v1/wishlist_collection/${list_id}`;

      axios.delete(url, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      toast.success("Merkzettel erfolgreich gelöscht");
      fetchLists();
      fetchWishListProducts(list_name);
    } catch (error) {
      toast.error("Error deleting list");
    }
  };

  //Update list name
  const [showup, setshowUp] = useState(false);

  let jsonString = localStorage.getItem("token");
  let navigate = useNavigate();

  if (!jsonString || jsonString === "null") {
    navigate("/login");
    return null;
  }
  var token = JSON.parse(jsonString).token;

  return (
    <div className="containerr container-fluid">
      <div className="p-3">
        <div>
          <div className="wishlist row">
            <div className="left mr-3 mb-3 py-3 col-sm-3">
              <h2>{wl.wishde}</h2>
              <div className="d-flex gap-1 d-none">
                <BookmarkIcon />
                <h4>{wl.saveditemde}</h4>
              </div>
              <hr />
              <h4>{wl.mycolde}</h4>

              <p onClick={() => fetchWishListProducts("default list")}>
                <FolderIcon /> {def && def["list_name"]}
              </p>

              {listed &&
                listed.map((list, index) => (
                  <p
                    key={index}
                    onClick={() => fetchWishListProducts(list.list_name)}
                  >
                    <FolderIcon /> {list.list_name}
                  </p>
                ))}

              {listed && listed.length >= 3 ? (
                <p
                  onClick={() => {
                    setSeemore(!seemore);
                  }}
                >
                  {seemore ? (
                    <span>See Less ... </span>
                  ) : (
                    <span>See More ...</span>
                  )}
                </p>
              ) : (
                <span></span>
              )}

              <button
                className="btnclrwishlist w-100"
                onClick={(e) => handleClick(e)}
              >
                <h5>+{wl.newcolde}</h5>
              </button>
              <ModalCreateSavetoWishList
                show={show}
                setShow={setShow}
                // modalPosition={modalPosition}
                fetchLists={fetchLists}
              />
            </div>
            <div className="right py-3 col-sm-8">
              <div className="d-flex justify-content-between">
                <h2>Alle Merkzettel</h2>
                <button className="btn" onClick={handleClick7}>
                  Liste verwalten
                </button>
                <MyModal
                  show={show7}
                  setShow={setShow7}
                  modalPosition={modalPosition7}
                  list={selL}
                  deleteList={deleteList}
                  defid={defid}
                  showup={showup}
                  setShowUp={setshowUp}
                  fetchLists={fetchLists}
                  lists={lists}
                />
              </div>

              {loading ? (
                <p>Loading...</p>
              ) : (
                <ProductCard
                  currentPosts={wishLists}
                  handleAddToCart={handleAddToCart}
                  handleDelete={handleDelete}
                  list_name={selL}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WishList;

export function MyModal({
  show,
  setShow,
  list,
  deleteList,
  defid,
  lists,
  fetchLists,
}) {
  const [saveto, setSaveto] = useState("");
  const handleChange = (e) => {
    setSaveto(e.target.value);
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();

    try {
      let list_id = lists.find((list1) => list1.list_name === list);

      let token = localStorage.getItem("token");
      token = JSON.parse(token).token;
      await axios.put(
        `https://api.rawstuff.deals/api/v1/wishlist_collection/${list_id.id}`,
        {
          list_name: saveto,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Collection Updated successfully.");
      fetchLists();
    } catch (error) {
      toast.error("Creating Update Failed.");
    }
  };

  return (
    <Modal
      centered
      show={show}
      onHide={() => setShow(false)}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title>Liste verwalten</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div>
            {defid && defid.length > 0 ? (
              <form onSubmit={(e) => handleUpdateSubmit(e)}>
                <div className="">
                  <input
                    className="form-control"
                    type="text"
                    name="saveto"
                    id="saveto2"
                    placeholder={list}
                    onChange={handleChange}
                  />
                </div>

                <div className="mt-3">
                  <button className="btnclrwishlist w-100" type="submit">
                    <h5>aktualisieren</h5>
                  </button>
                </div>
              </form>
            ) : (
              <span>
                <form onSubmit={(e) => handleUpdateSubmit(e)}>
                  <div className="">
                    <input
                      className="form-control"
                      type="text"
                      name="saveto"
                      id="saveto2"
                      placeholder={list}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="mt-3">
                    <button className="btnclrwishlist w-100" type="submit">
                      <h5>aktualisieren</h5>
                    </button>
                  </div>
                </form>
              </span>
            )}
          </div>
          <div>
            <button className="btn btn-block" onClick={() => deleteList(list)}>
              Liste löschen
            </button>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          Abbrechen
        </Button>
        <Button variant="secondary" onClick={() => setShow(false)}>
          Speichern
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export function ModalEmptyWishList({
  show2,
  setShow2,
  modalPosition2,
  handleClick3,
}) {
  return (
    <Modal
      show={show2}
      onHide={() => setShow2(false)}
      aria-labelledby="contained-modal-title-vcenter"
      style={{
        position: "absolute",
        top: modalPosition2.top,
        left: modalPosition2.left,
        backgroundColor: "red",
        margin: "0",
        width: "auto",
        height: "auto",
        "border-radius": "8px",
        border: "1px solid #999",
        background: "#FAFAFA",
        padding: "0 10px 0 10px",
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Save To</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <button
          className="btnclrwishlist w-100 px-5"
          onClick={(e) => setShow2(false)}
        >
          <h5 onClick={(e) => handleClick3(e)}>+{wl.newcolde}</h5>
        </button>
      </Modal.Body>
    </Modal>
  );
}

export function ModalCreateSavetoWishList({ show, setShow, fetchLists }) {
  const [saveto, setSaveto] = useState("");
  const handleChange = (e) => {
    setSaveto(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let token = localStorage.getItem("token");
      token = JSON.parse(token).token;

      await axios.post(
        "https://api.rawstuff.deals/api/v1/wishlist_collection",
        { list_name: saveto },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      fetchLists();
      toast.success("Merkzettel erstellt.");
    } catch (error) {
      toast.error("Erstellen des Merkzettel fehlgeschlagen.");
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      aria-labelledby="contained-modal-title-vcenter"
      style={{
        position: "fixed",
        // top: modalPosition.top,
        // left: modalPosition.left,
        top: "40%",
        left: "40%",
        backgroundColor: "red",
        margin: "0",
        width: "auto",
        height: "auto",
        "border-radius": "8px",
        border: "1px solid #999",
        background: "#FAFAFA",
        padding: "0 10px 0 10px",
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Name Merkzettel</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form action="" onSubmit={handleSubmit}>
          <div className="">
            <input
              className="form-control"
              type="text"
              name="saveto"
              id="saveto2"
              placeholder="Eingabe Name"
              onChange={handleChange}
            />
          </div>

          <div className="mt-3">
            <button
              className="btnclrwishlist w-100"
              type="submit"
              onClick={() => setShow(false)}
            >
              <h5>Erstellen</h5>
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}
