import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import "./ProductCard.css";
import { addToCarts } from "../../../../redux/Slice/ShoppingCartSlice";
import { Link, useNavigate } from "react-router-dom";
import { fetchProductDetail } from "../../../../redux/Slice/productdetailSlice";
import { useDispatch, useSelector } from "react-redux";
import ModalLogIn from "../../../Regstration/ModalLogIn";
import { toast } from "react-toastify";
import { images } from "../../../../Constants";

const ProductCard = ({ trandingProductItem }) => {
  // check global cart state to check whether it is empty or not.
  const getCart = useSelector((state) => state.getCart);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  let Token = useSelector((state) => state.userAuth?.token);
  const [modalShow, setModalShow] = React.useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const handleCardClick = () => {
    setIsClicked(true);
    setModalShow(true);
  };
  const dispatch = useDispatch();

  const handleAddToCart = (trandingProductItem) => {
    dispatch(addToCarts(trandingProductItem.id));
    if (Token && Token){
      toast.success("Produkt erfolgreich in den Warenkorb gelegt");
    }
  };
  const navigate = useNavigate();

  const handleProductClick = (trandingProductItem) => {
    dispatch(fetchProductDetail(trandingProductItem.id));
    if (Token && Token) {
      navigate("/productDetailPage");
    } else {
      navigate("/login");
    }
  };
  useEffect(() => {
    // console.log("token", Token);
    //   var jsonString = localStorage.getItem("token");
    //   var jsonObject = JSON.parse(jsonString);
    //   var token = jsonObject?.token; // Replace 'token' with your token key

    if (Token && Token) {
      // User is logged in
      setIsLoggedIn(true);
    } else {
      // User is not logged in
      setIsLoggedIn(false);
    }
  }, []);

  return (
    <>
      {isLoggedIn ? (
        <div>
          <div className="trending_productCard" onClick={handleCardClick}>
            <Card>
              <Card.Body>
                <Card.Title>
                  <div className="product-trending-items d-flex flex-column flex-md-row justify-content-between">
                    <div
                      className="d-flex flex-column justify-content-between pe-4 pe-md-1 pe-lg-4"
                      onClick={() => handleProductClick(trandingProductItem)}
                    >
                      <div className="popular_productName-text">
                        {trandingProductItem.name}
                      </div>
                      <div className="d-flex">
                        <div className="popular_product-smtxtContent popular_productCas_number">
                          Cas -Nummer:
                          <span>{`  ( ${trandingProductItem.cas_number})`}</span>
                        </div>
                      </div>
                      {/* <div>certificate</div> */}
                      {isLoggedIn ? (
                        <div className="d-flex mb-2 mb-md-0">
                          <div className="popular_product-smtxtContent pe-4">
                            Min Menge:{" "}
                            <span>{`${trandingProductItem.min_quantity} ${trandingProductItem.unit_of_measurement}`}</span>
                          </div>
                          <div className="popular_product-smtxtContent">
                            Max Menge:{" "}
                            <span>{`${trandingProductItem.max_quantity} ${trandingProductItem.unit_of_measurement}`}</span>
                          </div>
                        </div>
                      ) : (
                        <div className="d-flex mb-2 mb-md-0">
                          <div className="popular_product-smtxtContent pe-4">
                            Min Menge: <span>****</span>
                          </div>
                          <div className="popular_product-smtxtContent">
                            Max Menge: <span>****</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div
                      onClick={() => handleProductClick(trandingProductItem)}
                      className={`${
                        trandingProductItem.seller?.user_certificate?.at(0) ===
                          "" || trandingProductItem.seller == null
                          ? "d-none"
                          : "d-flex"
                      } product_c-flexItems  justify-content-start justify-content-sm-end justify-content-md-start  flex-grow-1 align-items-center`}
                    >
                      <div className="product_c-flexItem">
                        <img
                          src={images.rsProductCertificate}
                          alt="product-certificate"
                        />
                      </div>

                      <div className="d-flex flex-column ps-3 ps-md-1 ps-lg-3">
                        <div className="product_c-data">
                          <h3>Zertifizierungen</h3>
                        </div>
                        {trandingProductItem.seller?.user_certificate?.map(
                          (userCertificate, i) => (
                            <div key={i} className="product_c-data">
                              <p>{userCertificate}</p>
                            </div>
                          )
                        )}
                      </div>
                    </div>

                    <div className="d-flex flex-column justify-content-between">
                      <div
                        onClick={() => handleProductClick(trandingProductItem)}
                        className="trendingProductPrice mt-sm-4 mt-md-0"
                      >
                        {isLoggedIn ? (
                          <div className="trendingProductPrice d-flex justify-content-md-between mb-3 mb-md-0  ">
                            Preis pro {trandingProductItem.unit_of_measurement}:
                            <span className="pe-3 ms-3 ms-md-0">{`€ ${trandingProductItem.unit_price_eur}`}</span>
                          </div>
                        ) : (
                          <div className="trendingProductPrice d-flex justify-content-md-between mb-3 mb-md-0  ">
                            Preis pro {trandingProductItem.unit_of_measurement}
                            :<span className="pe-3 ms-3 ms-md-0">€ *****</span>
                          </div>
                        )}
                      </div>
                      <div className="d-flex  justify-content-md-between mt-2 mt-md-0 ">
                        <div className="btn-addToBasket ">
                          <button
                            onClick={() => handleAddToCart(trandingProductItem)}
                          >
                            {/* <AddCircleIcon /> */}+ Warenkorb
                          </button>
                        </div>
                        <div className="btn-buyNow ms-0 ms-sm-5 ms-md-0">
                          <Link to="/shoppingcart">
                            {getCart.cartItemsget.total_distinct_quantity ===
                            0 ? (
                              <button
                                onClick={() =>
                                  handleAddToCart(trandingProductItem)
                                }
                              >
                                {/* <AddCircleIcon /> */}
                                Jetzt kaufen
                              </button>
                            ) : (
                              <button>
                                {/* <AddCircleIcon /> */}
                                Jetzt kaufen
                              </button>
                            )}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card.Title>
              </Card.Body>
            </Card>
          </div>
        </div>
      ) : (
        <div>
          <div
            className={`trending_productCard ${isClicked ? "clicked" : ""}`}
            onClick={handleCardClick}
          >
            <Card>
              <Card.Body>
                <Card.Title>
                  <div className="product-trending-items d-flex flex-column flex-md-row justify-content-between">
                    <div className="d-flex flex-column justify-content-between pe-4 pe-md-1 pe-lg-4">
                      <div className="popular_productName-text">
                        {trandingProductItem.name}
                      </div>
                      <div className="d-flex ">
                        <div className="popular_product-smtxtContent popular_productCas_number">
                          Cas -Nummer:
                          <span>{`  ( ${trandingProductItem.cas_number})`}</span>
                        </div>
                      </div>
                      {/* <div>certificate</div> */}
                      {isLoggedIn ? (
                        <div className="d-flex mb-2 mb-md-0">
                          <div className="popular_product-smtxtContent pe-4">
                            Min Menge:{" "}
                            <span>{trandingProductItem.min_quantity}</span>
                          </div>
                          <div className="popular_product-smtxtContent">
                            Min Menge:{" "}
                            <span>{trandingProductItem.max_quantity}</span>
                          </div>
                        </div>
                      ) : (
                        <div className="d-flex mb-2 mb-md-0">
                          <div className="popular_product-smtxtContent pe-4">
                            Min Menge: <span>****</span>
                          </div>
                          <div className="popular_product-smtxtContent">
                            Min Menge: <span>****</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div
                      onClick={() => handleProductClick(trandingProductItem)}
                      className={`${
                        trandingProductItem.seller?.user_certificate?.at(0) ===
                          "" || trandingProductItem.seller == null
                          ? "d-none"
                          : "d-flex"
                      } product_c-flexItems  justify-content-start justify-content-sm-end justify-content-md-start  flex-grow-1 align-items-center`}
                    >
                      <div className="product_c-flexItem">
                        <img
                          src={images.rsProductCertificate}
                          alt="product-certificate"
                        />
                      </div>

                      <div className="d-flex flex-column ps-3 ps-md-1 ps-lg-3">
                        <div className="product_c-data">
                          <h3>Zertifizierungen</h3>
                        </div>
                        {trandingProductItem.seller?.user_certificate?.map(
                          (userCertificate, i) => (
                            <div key={i} className="product_c-data">
                              <p>{userCertificate}</p>
                            </div>
                          )
                        )}
                      </div>
                    </div>

                    <div className="d-flex flex-column  justify-content-between">
                      <div className="trendingProductPrice mt-sm-4 mt-md-0">
                        {isLoggedIn ? (
                          <div className="trendingProductPrice d-flex justify-content-md-between mb-3 mb-md-0  ">
                            Preis pro {trandingProductItem.unit_of_measurement}
                            :
                            <span className="pe-3 ms-3 ms-md-0">{`€ ${trandingProductItem.unit_price_eur}`}</span>
                          </div>
                        ) : (
                          <div className="trendingProductPrice d-flex justify-content-md-between mb-3 mb-md-0 ">
                            Preis pro {trandingProductItem.unit_of_measurement}
                            : <span className="pe-3 ms-3 ms-md-0">€ *****</span>
                          </div>
                        )}
                      </div>
                      <div className="d-flex  justify-content-md-between mt-2 mt-md-0 ">
                        <div className="btn-addToBasket ">
                          <button
                            onClick={() => handleAddToCart(trandingProductItem)}
                          >
                            {/* <AddCircleIcon /> */}+ Warenkorb
                          </button>
                        </div>
                        <div className="btn-buyNow ms-0 ms-sm-5 ms-md-0">
                          <Link to="/login">
                            <button>
                              {/* <AddCircleIcon /> */}
                              Jetzt kaufen
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card.Title>
              </Card.Body>
            </Card>
          </div>
          <ModalLogIn show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      )}
    </>
  );
};

export default ProductCard;
