import React from "react";
import "./Aboutus.css";
import AboutRawStuff from "./AboutRawStuff/AboutRawStuff";
import AboutUsDetail from "./AboutUsDetail/AboutUsDetail";
import AboutusBenefits from "./AboutusBenefits/AboutusBenefits";

const Aboutus = () => {
  return (
    <>
      <div className="aboutus-container">
        <AboutRawStuff />
        <AboutUsDetail />
        <AboutusBenefits />
      </div>
    </>
  );
};

export default Aboutus;
