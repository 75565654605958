import React, { useState } from "react";
import DoneIcon from "@mui/icons-material/Done";
import { useLocale, useTranslation } from "@psyycker/react-translation";
import { useNavigate } from "react-router-dom";
import PaymentModal from "./PaymentModal";
import membershipENDE from "../../Constants/Contents/MembershipENDE";

function SellerPricingPlan({ ...props }) {
  let token = localStorage.getItem("token");
  token = JSON.parse(token);
  const role = token?.role;

  const navigate = useNavigate();

  //Get Language Translation starts
  const { getTranslation } = useTranslation();
  const [locale, setLocale] = useLocale();
  const onButtonClick = () => {
    var lang = document.getElementById("lang").value;
    var selectedx = "You have selected";
    if (lang == "English") {
      setLocale("en-US");
    }

    if (lang == "Germany") {
      setLocale("german");
    }

    if (lang == "Dutch") {
      setLocale("de");
    }

    if (lang == "French") {
      setLocale("fr-FR");
    }
  };

  //Get Language Translation ends
  const [modalb1show, setModalb1show] = useState(false);

  if (token) {
    if (props.pricingtype) {
      return (
        <div className="pricing-container">
          <div className="container">
            <div className="pricing-card-container">
              <div className="d-flex  uppercard row mt-md-n5 mt-n3">
                {role === "seller" ? (
                  <div className="pricingPlan col-md">
                    <div className="plan-price-title">
                      <div className="plan-title">
                        {membershipENDE.seller.yearly.titlede}
                      </div>
                    </div>
                    <div className="description-info">
                      <div>
                        {membershipENDE.seller.yearly.bodyde.map(
                          (item, index) => {
                            return (
                              <div
                                key={index}
                                className="d-flex plan-decription"
                              >
                                <DoneIcon color="success" />
                                <p>
                                  <strong>{Object.keys(item)}: </strong>
                                  {Object.values(item)}
                                </p>
                              </div>
                            );
                          }
                        )}
                      </div>

                      <div className="select-plan">
                        <button
                          className="btn"
                          style={{ color: "#ffffff" }}
                          onClick={() => setModalb1show(true)}
                        >
                          Plan wählen
                        </button>
                        <PaymentModal
                          show={modalb1show}
                          onHide={() => setModalb1show(false)}
                          pricingtype={"yearly"}
                          price={"948"}
                          role={"Seller"}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="pricing-container">
          <div className="container">
            <div className="pricing-card-container">
              <div className="d-flex  uppercard row mt-md-n5 mt-n3">
                {role === "seller" ? (
                  <div className="pricingPlan col-md">
                    <div className="plan-price-title">
                      <div className="plan-title">
                        {membershipENDE.seller.monthly.titlede}
                      </div>
                      <div className="d-none">
                        <span className="plan-price">€50</span>
                        <span className="plan-term">
                          <sup> /per month</sup>
                        </span>
                      </div>
                    </div>
                    <div className="description-info">
                      <div>
                        {membershipENDE.seller.monthly.bodyde.map(
                          (item, index) => {
                            return (
                              <div
                                key={index}
                                className="d-flex plan-decription"
                              >
                                <DoneIcon color="success" />
                                <p>
                                  <strong>{Object.keys(item)}: </strong>
                                  {Object.values(item)}
                                </p>
                              </div>
                            );
                          }
                        )}
                      </div>

                      <div className="select-plan">
                        <button
                          className="btn"
                          style={{ color: "#ffffff" }}
                          onClick={() => setModalb1show(true)}
                        >
                          Plan wählen
                        </button>
                        <PaymentModal
                          show={modalb1show}
                          onHide={() => setModalb1show(false)}
                          pricingtype={"monthly"}
                          price={"79"}
                          role={"Seller"}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }
  } else {
    if (props.pricingtype) {
      return (
        <div className="pricing-container">
          <div className="container">
            <div className="pricing-card-container">
              <div className="d-flex  uppercard row mt-md-n5 mt-n3">
                <div
                  className="pricingPlan col-md"
                  onClick={() => navigate("/login")}
                >
                  <div className="plan-price-title">
                    <div className="plan-title">Buyer Membership plan</div>
                    <div>
                      <span className="plan-price">€240</span>
                      <span className="plan-term">
                        <sup> /per year</sup>
                      </span>
                    </div>
                  </div>
                  <div className="description-info">
                    <div>
                      <div className="d-flex plan-decription">
                        <DoneIcon color="success" />
                        <p>
                          <strong>
                            {getTranslation({
                              translationKey: "ProductRanking",
                            })}
                            :
                          </strong>{" "}
                          Products from Silver members receive a moderate boost
                          in visibility in search results and category listings.
                        </p>
                      </div>
                      <div className="d-flex plan-decription">
                        <DoneIcon color="success" />
                        <p>
                          <strong>Number of product listings:</strong>
                          Up to 50 active product listings.
                        </p>
                      </div>
                      <div className="d-flex plan-decription">
                        <DoneIcon color="success" />
                        <p>
                          <strong>Featured products:</strong> Ability to
                          designate up to 5 products as "Featured," increasing
                          visibility on category pages or promotional sections.
                        </p>
                      </div>
                      <div className="d-flex plan-decription">
                        <DoneIcon color="success" />
                        <p>
                          <strong>Customer support:</strong> Access to priority
                          customer support via email and phone.
                        </p>
                      </div>
                      <div className="d-flex plan-decription">
                        <DoneIcon color="success" />
                        <p>
                          <strong>Seller dashboard:</strong> Advanced analytics
                          and reporting features, including sales data and
                          customer insights.
                        </p>
                      </div>
                    </div>

                    <div className="select-plan">
                      <button style={{ color: "#ffffff" }}>Plan wählen</button>
                    </div>
                  </div>
                </div>

                <div
                  className="pricingPlan col-md"
                  onClick={() => navigate("/login")}
                >
                  <div className="plan-price-title">
                    <div className="plan-title">Seller Membership plan</div>
                    <div>
                      <span className="plan-price">€600</span>
                      <span className="plan-term">
                        <sup> /per year</sup>
                      </span>
                    </div>
                  </div>
                  <div className="description-info">
                    <div>
                      <div className="d-flex plan-decription">
                        <DoneIcon color="success" />
                        <p>
                          <strong>
                            {getTranslation({
                              translationKey: "ProductRanking",
                            })}
                            :
                          </strong>{" "}
                          Products from Silver members receive a moderate boost
                          in visibility in search results and category listings.
                        </p>
                      </div>
                      <div className="d-flex plan-decription">
                        <DoneIcon color="success" />
                        <p>
                          <strong>Number of product listings:</strong>
                          Up to 50 active product listings.
                        </p>
                      </div>
                      <div className="d-flex plan-decription">
                        <DoneIcon color="success" />
                        <p>
                          <strong>Featured products:</strong> Ability to
                          designate up to 5 products as "Featured," increasing
                          visibility on category pages or promotional sections.
                        </p>
                      </div>
                      <div className="d-flex plan-decription">
                        <DoneIcon color="success" />
                        <p>
                          <strong>Customer support:</strong> Access to priority
                          customer support via email and phone.
                        </p>
                      </div>
                      <div className="d-flex plan-decription">
                        <DoneIcon color="success" />
                        <p>
                          <strong>Seller dashboard:</strong> Advanced analytics
                          and reporting features, including sales data and
                          customer insights.
                        </p>
                      </div>
                    </div>

                    <div className="select-plan">
                      <button style={{ color: "#ffffff" }}>Plan wählen</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="pricing-container">
          <div className="container">
            <div className="pricing-card-container">
              <div className="d-flex  uppercard row mt-md-n5 mt-n3">
                <div
                  className="pricingPlan col-md"
                  onClick={() => navigate("/login")}
                >
                  <div className="plan-price-title">
                    <div className="plan-title">Buyer Membership plan</div>
                    <div>
                      <span className="plan-price">€20</span>
                      <span className="plan-term">
                        <sup> /per month</sup>
                      </span>
                    </div>
                  </div>
                  <div className="description-info">
                    <div>
                      <div className="d-flex plan-decription">
                        <DoneIcon color="success" />
                        <p>
                          <strong>
                            {getTranslation({
                              translationKey: "ProductRanking",
                            })}
                            :
                          </strong>{" "}
                          Products from Silver members receive a moderate boost
                          in visibility in search results and category listings.
                        </p>
                      </div>
                      <div className="d-flex plan-decription">
                        <DoneIcon color="success" />
                        <p>
                          <strong>Number of product listings:</strong>
                          Up to 50 active product listings.
                        </p>
                      </div>
                      <div className="d-flex plan-decription">
                        <DoneIcon color="success" />
                        <p>
                          <strong>Featured products:</strong> Ability to
                          designate up to 5 products as "Featured," increasing
                          visibility on category pages or promotional sections.
                        </p>
                      </div>
                      <div className="d-flex plan-decription">
                        <DoneIcon color="success" />
                        <p>
                          <strong>Customer support:</strong> Access to priority
                          customer support via email and phone.
                        </p>
                      </div>
                      <div className="d-flex plan-decription">
                        <DoneIcon color="success" />
                        <p>
                          <strong>Seller dashboard:</strong> Advanced analytics
                          and reporting features, including sales data and
                          customer insights.
                        </p>
                      </div>
                    </div>

                    <div className="select-plan">
                      <button style={{ color: "#ffffff" }}>Plan wählen</button>
                    </div>
                  </div>
                </div>

                <div
                  className="pricingPlan col-md"
                  onClick={() => navigate("/login")}
                >
                  <div className="plan-price-title">
                    <div className="plan-title">Seller Membership plan</div>
                    <div>
                      <span className="plan-price">€50</span>
                      <span className="plan-term">
                        <sup> /per month</sup>
                      </span>
                    </div>
                  </div>
                  <div className="description-info">
                    <div>
                      <div className="d-flex plan-decription">
                        <DoneIcon color="success" />
                        <p>
                          <strong>
                            {getTranslation({
                              translationKey: "ProductRanking",
                            })}
                            :
                          </strong>{" "}
                          Products from Silver members receive a moderate boost
                          in visibility in search results and category listings.
                        </p>
                      </div>
                      <div className="d-flex plan-decription">
                        <DoneIcon color="success" />
                        <p>
                          <strong>Number of product listings:</strong>
                          Up to 50 active product listings.
                        </p>
                      </div>
                      <div className="d-flex plan-decription">
                        <DoneIcon color="success" />
                        <p>
                          <strong>Featured products:</strong> Ability to
                          designate up to 5 products as "Featured," increasing
                          visibility on category pages or promotional sections.
                        </p>
                      </div>
                      <div className="d-flex plan-decription">
                        <DoneIcon color="success" />
                        <p>
                          <strong>Customer support:</strong> Access to priority
                          customer support via email and phone.
                        </p>
                      </div>
                      <div className="d-flex plan-decription">
                        <DoneIcon color="success" />
                        <p>
                          <strong>Seller dashboard:</strong> Advanced analytics
                          and reporting features, including sales data and
                          customer insights.
                        </p>
                      </div>
                    </div>

                    <div className="select-plan">
                      <button style={{ color: "#ffffff" }}>Plan wählen</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default SellerPricingPlan;
