import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { images } from "../../Constants";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "./EditProfilePage.css";
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

import { useLocale, useTranslation } from "@psyycker/react-translation";

const EditProfilePage = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [orderId, setOrderId] = useState("");
  const [errorPopUp, setErrorPopUp] = useState("");
  // const [confirmationBox, setConfirmationBox] = useState("")
  const [orderStatus, setOrderStatus] = useState("");
  const [showCancelRefund, setShowCancelRefund] = useState(false);
  const order = {
    status: orderStatus,
  };

  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("token");
    navigate("/");
    window.location.reload();
  };

  // let userName = localStorage.getItem("userName");
  let profileData = localStorage.getItem("token");
  let parseProfiledata = JSON.parse(profileData);
  let Token = useSelector((state) => state.userAuth.token);
  let newtoken = JSON.parse(Token);

  //Get Language Translation starts
  const { getTranslation } = useTranslation();
  const [locale, setLocale] = useLocale();
  const onButtonClick = () => {
    var lang = document.getElementById("lang").placeholder;
    var selectedx = "You have selected";
    if (lang == "English") {
      setLocale("en-US");
    }

    if (lang == "Germany") {
      setLocale("german");
    }

    if (lang == "Dutch") {
      setLocale("de");
    }

    if (lang == "French") {
      setLocale("fr-FR");
    }
  };
  //Get Language Translation ends

  //mm
  let nm = newtoken.user.name;
  let stat = newtoken.user.status;
  let phone = newtoken.user.phone;
  let em = newtoken.user.email;
  let add = newtoken.user.address;

  const [formData, setFormData] = useState({
    name: nm ? nm : "",
    status: stat ? stat : "Verified",
    phone: phone ? phone : "",
    email: em ? em : "",
    address: add ? add : "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Make the API call using Axios
      const response = await axios.put(
        "https://api.rawstuff.deals/api/v1/update_profile",
        formData,
        {
          headers: {
            Authorization: "Bearer " + newtoken.token,
            "Content-Type": "application/json",
          },
        }
      );

      // console.log('tt', response.headers);
      const updatedData = response.data;

      const { name, phone, email, address } = updatedData;
      setFormData({ ...formData, name, phone, email, address });
      toast.success("Your profile has been updated successfully!");
    } catch (error) {
      console.error("Error updating profile:", error.message);
      toast.error("Error updating profile.");
    }
  };
  //mm

  return (
    <div className="dashboard mt-0">
      <br/>
      <div class="row">
        <div class="col-sm">
          <div className="col-md-12">
            <div class="card">
              <div class="card-header">Profil bearbeiten</div>
              <div class="card-body">
                <form>
                  <div class="form-group row">
                    <label for="staticEmail" class="col-sm-2 col-form-label">
                    Name
                    </label>
                    <div class="col-sm-10">
                      <input
                        type="text"
                        readonly
                        class="form-control-plaintext"
                        id="name"
                        value={formData.name}
                      />
                    </div>
                  </div>

                  <div class="form-group row">
                    <label for="staticEmail" class="col-sm-2 col-form-label">
                      Status
                    </label>
                    <div class="col-sm-10">
                      <input
                        type="text"
                        readonly
                        class="form-control-plaintext"
                        id="email"
                        value={formData.status}
                      />
                    </div>
                  </div>

                  <div class="form-group row">
                    <label for="staticEmail" class="col-sm-2 col-form-label">
                      Email
                    </label>
                    <div class="col-sm-10">
                      <input
                        type="text"
                        readonly
                        class="form-control-plaintext"
                        id="email"
                        value={formData.email}
                      />
                    </div>
                  </div>

                  <div class="form-group row">
                    <label for="staticEmail" class="col-sm-2 col-form-label">
                    Telefone
                    </label>
                    <div class="col-sm-10">
                      <input
                        type="text"
                        readonly
                        class="form-control-plaintext"
                        id="phone"
                        value={formData.phone}
                      />
                    </div>
                  </div>

                  <div class="form-group row">
                    <label for="staticEmail" class="col-sm-2 col-form-label">
                    Addresse
                    </label>
                    <div class="col-sm-10">
                      <input
                        type="text"
                        readonly
                        class="form-control-plaintext"
                        id="address"
                        value={
                          parseProfiledata.user.street +
                          ", " +
                          parseProfiledata.user.house_number +
                          ", " +
                          parseProfiledata.user.city +
                          ", " +
                          parseProfiledata.user.country
                        }
                      />
                    </div>
                  </div>

                  <div class="form-group row">
                    <label for="staticEmail" class="col-sm-2 col-form-label">
                    Umsatzsteuer ID
                    </label>
                    <div class="col-sm-10">
                      <input
                        type="text"
                        readonly
                        class="form-control-plaintext"
                        id="email"
                        value={newtoken.user.vat_number}
                      />
                    </div>
                  </div>

                  <div class="form-group row">
                    <label for="staticEmail" class="col-sm-2 col-form-label">
                    Ansprechpartner
                    </label>
                    <div class="col-sm-10">
                      <input
                        type="text"
                        readonly
                        class="form-control-plaintext"
                        id="email"
                        value={
                          newtoken.user.authorized_rep_firstName +
                          " " +
                          newtoken.user.authorized_rep_lastName
                        }
                      />
                    </div>
                  </div>

                  <div class="form-group row">
                    <label for="staticEmail" class="col-sm-2 col-form-label">
                      Position
                    </label>
                    <div class="col-sm-10">
                      <input
                        type="text"
                        readonly
                        class="form-control-plaintext"
                        id="email"
                        value={newtoken.user.authorized_rep_position}
                      />
                    </div>
                  </div><br/>

                  <div class="form-group row">
                    <label for="inputPassword" class="col-sm-2 col-form-label">
                      <a href="/dashboard">Zurück</a>
                    </label>
                    <div class="col-sm-10">
                      <a
                        href="#modal1"
                        
                        style={{ color: "#ffffff", border:"2px solid #cccccc", 
                        borderRadius:"30px", 
                        padding:"10px", 
                        color:"#000000" }}
                      >
                        Aktualisierungsprofil
                      </a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/*-- Modal starts here --**/}

        <div id="modal1" class="overlay" style={{ marginTop: "9%" }}>
          <div class="popup">
            <h4>
             Wichtiger Hinweis
            </h4>
            <a class="close" href="/dashboard/edit-profile">
              &times;
            </a>
            <hr/>
            <div class="content">
            Bitte  <b> {formData.name} </b> Sie uns über unsere offizielle E-Mail-Adresse, um uns die Gründe mitzuteilen, die Sie zur 
            Aktualisierung Ihrer Kontodaten veranlasst haben.
              <br /> <br />
              <b>
              Bitte vergewissern Sie sich, dass Sie uns über die mit Ihrem <br/>
              Konto verknüpfte E- Mail schreiben ({formData.email})
              </b>
              <br />
              <a
                href="mailto:info@rawstuff.deals"
                className="popupfooterlink"
                title="This is a Dependency on Payment Gateway. Will be treated in subequent Sprint"
              >
                <i class="fa-solid fa-inbox" style={{ color: "#000000" }}></i>
                &nbsp; Email Rawstuff
              </a>
            </div>
          </div>
        </div>
        {/*-- Modal Ends here--**/}
      </div>
    </div>
  );
};

export default EditProfilePage;
