import React from "react";
import { images } from "../../Constants";
import productcardENDE from "../../Constants/Contents/ProductcardENDE";

function Certificate({ product }) {
  if (
    product === null ||
    product?.seller === null ||
    product?.seller === undefined ||
    product?.seller?.user_certificate === null ||
    product?.seller?.user_certificate === undefined ||
    typeof product?.seller?.user_certificate === "string" ||
    product?.seller?.user_certificate.length === 0 ||
    product?.seller?.user_certificate[0] === "" ||
    product?.seller?.user_certificate[0] === undefined ||
    product?.seller?.user_certificate[0] === null
  ) {
    return <div></div>;
  }

  return (
    <div>
      <div className="product_c-flexItems d-flex justify-content-start justify-content-sm-end justify-content-md-start  flex-grow-1 align-items-center">
        <div className="product_c-flexItem">
          <img src={images.rsProductCertificate} alt="product-certificate" />
        </div>
        <div className="d-flex flex-column ps-3 ps-md-1 ps-lg-3">
          <div className="product_c-data">
            <h3>{productcardENDE.certificationsde}</h3>
          </div>
          {product?.seller?.user_certificate?.map(
            (productCertificate, index) => (
              <div key={index} className="product_c-data">
                <p>{productCertificate}</p>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
}

export default Certificate;
