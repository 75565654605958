import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const Services = () => {
  const [data] = useState([
    {
      "Konto verwalten": (
        <div>
          <p>
            Wie kann ich mein Rawstuff Deals Konto erstellen?<br></br>
            Um ein Konto bei Rawstuff Deals zu erstellen, besuchen Sie unsere
            Website und klicken Sie auf "Registrieren". Folgen Sie den
            Anweisungen, um Ihre Kontoinformationen einzugeben und Ihr Konto zu
            erstellen.
          </p>
          <p>
            Wie kann ich meine Kontoinformationen aktualisieren?<br></br> Melden
            Sie sich in Ihrem Konto an und gehen Sie zu "Konto verwalten". Dort
            können Sie Ihre persönlichen Informationen aktualisieren.
          </p>
          Wie kann ich mein Konto löschen?<br></br> Um Ihr Konto zu löschen,
          kontaktieren Sie bitte unseren Kundensupport. Wir werden Ihnen bei der
          Abwicklung dieser Anfrage behilflich sein.
        </div>
      ),
    },
    {
      "Aufträge verwalten": (
        <div>
          <p>
            Wie kann ich eine Bestellung aufgeben?
            <br></br>
            Um eine Bestellung aufzugeben, durchsuchen Sie unsere Produkte,
            wählen Sie das gewünschte aus und fügen Sie es Ihrem Warenkorb
            hinzu. Gehen Sie dann zur Kasse, um die Bestellung abzuschließen.
          </p>
          <p>
            Wie kann ich den Status meiner Bestellung überprüfen?<br></br>
            Sie können den Status Ihrer Bestellung verfolgen, indem Sie sich in
            Ihr Konto einloggen und zu "Bestellungen" gehen. Dort sehen Sie den
            aktuellen Status und können weitere Informationen finden.
          </p>
        </div>
      ),
    },
    {
      Versandpolitik: (
        <div>
          <p>
            Welche Versandmethoden bietet der Verkäufer an?<br></br>
            Die verfügbaren Versandmethoden können je nach Verkäufer variieren.
            Informationen zu den angebotenen Versandoptionen finden Sie
            normalerweise auf der Produktseite oder während des Bestellvorgangs.
          </p>
        </div>
      ),
    },
    {
      "Rawstuff Zahlungsmöglichkeiten über Stripe": (
        <div>
          <p>
            Ist meine Zahlung sicher, wenn ich Stripe verwende?<br></br>
            Ja, Stripe setzt strenge Sicherheitsmaßnahmen ein, um Ihre
            Zahlungsdaten zu schützen. Die Plattform entspricht den höchsten
            Sicherheitsstandards und verwendet Verschlüsselungstechnologien, um
            Ihre Daten sicher zu übertragen und zu speichern.
          </p>

          <p>
            Wie kann ich meine Zahlung überprüfen oder nachverfolgen?<br></br>
            Sie können Ihre Zahlungen über Stripe in Ihrem Konto oder auf der
            Plattform, auf der Sie eine Zahlung getätigt haben, überprüfen. In
            Ihrem Stripe-Konto finden Sie detaillierte Informationen zu jeder
            Transaktion.
          </p>
        </div>
      ),
    },
    {
      "Geld verdienen mit uns": (
        <div>
          <p>
            Wie kann ich Partner werden und Geld verdienen?<br></br>
            Sie können Partner werden, indem Sie sich für unser Partnerprogramm
            anmelden. Nach der Registrierung erhalten Sie spezielle Partnerlinks
            oder Codes, die Sie an potenzielle Käufer oder Verkäufer weitergeben
            können. Wenn diese über Ihren Link aktiv werden, erhalten Sie
            Provisionen.
          </p>
        </div>
      ),
    },
    { "Lernen Sie uns kennen": "coming soon - Hilfe Seite mit Erklärvideos" },
  ]);
  const [preview, setPreview] = useState(data[0]);

  const getObjectByPropertyName = (propertyName) => {
    return data.find((item) => Object.keys(item)[0] === propertyName);
  };
  const handlePreview = (key) => {
    setPreview(getObjectByPropertyName(key));
  };

  const navigate = useNavigate();
  const handleOptionclick = (title) => {
    navigate(`/support/details/${title}`);
  };

  return (
    <div className="container-fluid">
      <div className="container customerservice mt-lg-5 mt-sm-3 my-lg-4">
        Kundenservice
      </div>
      <div className="services-wrapper">
        <div className="container">
          <div className="row py-5">
            <div className="col-sm-3 col-xs-3 leftwrapper mr-3">
              <p onClick={() => handlePreview("Konto verwalten")}>
                Konto verwalten
              </p>
              <p onClick={() => handlePreview("Aufträge verwalten")}>
                Aufträge verwalten
              </p>

              <p onClick={() => handlePreview("Versandpolitik")}>
                Versandpolitik
              </p>
              <p
                onClick={() =>
                  handlePreview("Rawstuff Zahlungsmöglichkeiten über Stripe")
                }
              >
                Rawstuff Zahlungsmöglichkeiten über Stripe
              </p>
              <p onClick={() => handlePreview("Geld verdienen mit uns")}>
                Geld verdienen mit uns
              </p>
              <p onClick={() => handlePreview("Lernen Sie uns kennen")}>
                Lernen Sie uns kennen
              </p>
            </div>
            <div className="col-sm-8 col-xs-8">
              <h2>{Object.keys(preview)[0]}</h2>
              <div>{preview[Object.keys(preview)[0]]}</div>

              {/* <p
                onClick={() => handleOptionclick(Object.keys(preview)[0])}
                className="previewmore d-none"
              >
                {" "}
                <ArrowForwardIosIcon
                  fontSize="small"
                  style={{ color: "#EC672C", width: "12px", height: "12px" }}
                />
                read more ...
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
