import React, { useRef } from "react";
import EuroIcon from "@mui/icons-material/Euro";
import ModalLogIn from "../../Regstration/ModalLogIn";
import { fetchProductDetail } from "../../../redux/Slice/productdetailSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import AddToWishList from "../../WhishList/AddToWishList";
import BuyNOwButton from "../BuyNOwButton";
import Certificate from "../Certificate";
import { convertCountryCodeToCountryName } from "../../SearchResultPage/ProductCard";
import productcardENDE from "../../../Constants/Contents/ProductcardENDE";

export const ProductCard = ({ ...props }) => {
  var jsonString = localStorage.getItem("token");
  var jsonObject = JSON.parse(jsonString);
  var token = jsonObject?.token;

  const [modalShow, setModalShow] = React.useState(false);
  const selectCardRef = useRef(null);

  const handleCardClick = () => {
    setModalShow(true);

    if (selectCardRef.current) {
      selectCardRef.current.classList.add("add-bg");
      setTimeout(() => {
        selectCardRef.current.classList.remove("add-bg");
      }, 30000);
    }
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  //click for details function
  const handleProductClick = (popularProductItem) => {
    dispatch(fetchProductDetail(popularProductItem.id));
    navigate("/productDetailPage");
  };

  return (
    <div className="container-fluid">
      {token ? (
        <div>
          {props.currentPosts.map((trend, id) => (
            <div key={id} className="trending-product-card clickwl">
              <di className="row">
                <div
                  onClick={() => handleProductClick(trend)}
                  className="col-sm-12 col-md-6 col-lg-4"
                >
                  <div className="productName">
                    {trend.name.charAt(0).toUpperCase() + trend.name.slice(1)}
                  </div>
                  <div className="product-infoo product-infoo-p">
                    <p className="product-weight">
                      {productcardENDE.minde}:
                      <span>
                        {trend.min_quantity}
                        {trend.unit_of_measurement}
                      </span>
                    </p>
                    <p className="product-weight">
                      {productcardENDE.maxde} :
                      <span>
                        {trend.max_quantity}
                        {trend.unit_of_measurement}
                      </span>
                    </p>
                  </div>
                  <p className="product-destination">
                    {productcardENDE.destde}:{" "}
                    {trend?.warehouse?.country &&
                      convertCountryCodeToCountryName(trend.warehouse?.country)}
                  </p>
                </div>

                <div
                  className="d-flex product-certificate-group align-items-center gap-2 col-sm-12 col-md-6 col-lg-4"
                  ref={selectCardRef}
                  onClick={() => handleProductClick(trend)}
                >
                  <Certificate product={trend} />
                </div>

                <div className="col-sm-12 col-md">
                  <div className="float-lg-end">
                    <div className="d-flex  gap-5 justify-content-lg-between">
                      <div className="product-price">
                        {productcardENDE.pricede} {trend.unit_of_measurement}:
                      </div>
                      <div className="product-price-value">
                        €{trend.unit_price_eur}
                      </div>
                    </div>
                    <div className="d-flex justify-content-betweenn product-actions">
                      <div className="like-button hovered">
                        <AddToWishList product_id={trend.id} />
                      </div>
                      <div
                        className="addtocart_button hovered d-nonee px-1 px-sm-3"
                        onClick={() => props.handleAddToCart(trend)}
                      >
                        + {productcardENDE.addtocartde}
                      </div>
                      <BuyNOwButton product={trend} />
                    </div>
                  </div>
                </div>
              </di>
            </div>
          ))}
        </div>
      ) : (
        <div>
          {props.currentPosts.map((trend, id) => (
            <div key={id} className="trending-product-card clickwl">
              <div
                className="trending-product-card-content row"
                onClick={handleCardClick}
              >
                <div className="col-sm-12 col-md-6 col-lg-3">
                  <div className="productName pb-3">{trend.name}</div>
                  <div className="d-flex justify-content-between gap-5 product-infoo">
                    <p className="product-cas-number">
                      {productcardENDE.casnumberde}:{" "}
                      <span>({trend.cas_number})</span>
                    </p>
                  </div>
                  <div className="d-flex product-weight-wraper">
                    <p className="product-weight">
                      {productcardENDE.minde}:{" "}
                      <span className="product-wspan">****</span>
                    </p>
                    <p className="product-weight">
                      {productcardENDE.maxde}:{" "}
                      <span className="product-wspan">****</span>
                    </p>
                  </div>
                </div>

                <div className="col-sm-12 col-md-6 col-lg-4 d-flex align-items-center">
                  <div className="d-flex product-certificate-group gap-2">
                    <Certificate />
                  </div>
                </div>

                <div className="action-group col-sm-12 col-md">
                  <div className="float-lg-end">
                    <div className="d-flex  align-items-center gap-1">
                      <div className="product-price">
                        {productcardENDE.pricede} {trend.unit_of_measurement}:
                      </div>
                      <div className="product-price-value">
                        <EuroIcon />
                        ******
                      </div>
                    </div>
                    <div className="d-flex product-actions ">
                      <div
                        className="addtocart_buttonm hovered px-1 px-sm-3"
                        onClick={() => props.handleAddToCart(trend)}
                      >
                        + {productcardENDE.addtocartde}
                      </div>
                      <div className="buynow_button hovered px-1 px-sm-3">
                        {productcardENDE.buynowde}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="loginPopup">
            <ModalLogIn show={modalShow} onHide={() => setModalShow(false)} />
          </div>
        </div>
      )}
    </div>
  );
};
