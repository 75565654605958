import React, { useState, useEffect, useRef } from "react";
import "./ProductDetailPage.css";
import "./ProductDocument.css";
import { useSelector } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

function ProductDescription({ productDetailData }) {
  // state to check if the user have active plan or not?
  const [haveActivePlan, setHaveActivePlan] = useState(false);
  const [activeTab, setActiveTab] = useState("documentAndCertificate");
  const [subActiveTab, setSubActiveTab] = useState(
    "SellerDocumentAndCertificate"
  );
  const buyReturnContentRef = useRef(null);
  // get token from state
  const Token = useSelector((state) => state.userAuth.token);
  let newtoken = JSON.parse(Token);
  //  console.log("newtoken",newtoken);

  useEffect(() => {
    // Adjust the height of the Buy & Return content container when the activeTab changes
    if (buyReturnContentRef.current) {
      buyReturnContentRef.current.style.height = "auto"; // Reset the height to auto

      // Delay the height calculation to the next render cycle
      setTimeout(() => {
        const height = buyReturnContentRef.current.scrollHeight;
        buyReturnContentRef.current.style.height = `${height}px`;
      }, 0);
    }
  }, [activeTab]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setSubActiveTab("SellerDocumentAndCertificate");
  };

  const handleSubTabClick = (tab) => {
    setSubActiveTab(tab);
  };
  const handleSubTabClickmain = (tab) => {
    setActiveTab(tab);
    setSubActiveTab("");
  };

  // request to check if the user have active plan or not
  useEffect(() => {
    const checkMembershipPlan = async () => {
      try {
        const membershipPlanRes = await axios.get(
          "https://api.rawstuff.deals/api/v1/membership",
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${newtoken.token}`,
            },
          }
        );
        const data = membershipPlanRes.data.memberships.at(0).status;
        if (data === "ACTIVE") {
          setHaveActivePlan(true);
        }
        // console.log("membership plan",data)
      } catch (error) {
        console.log(error.response.data.message);
        throw toast.error(error.response.data.message);
      }
    };
    checkMembershipPlan();
  }, []);
  console.log("prodactDetailData", productDetailData);
  return (
    <div className="product-description-container">
      <div className="main-tabs">
        {/* {activeTab === "review"} */}
        {activeTab === "buy-return"}
        {activeTab === "documentAndCertificate"}

        <div className="tab-name-wrappers">
          <div
            className={` main-tab ${
              activeTab === "documentAndCertificate" ? "active" : ""
            }`}
            onClick={() => handleTabClick("documentAndCertificate")}
          >
            Dokumente & Zertifikate
          </div>
          {activeTab === "documentAndCertificate" && (
            <div className="selector" />
          )}
        </div>
        <div className="tab-name-wrappers">
          <div
            className={`main-tab ${activeTab === "buy-return" ? "active" : ""}`}
            onClick={() => handleSubTabClickmain("buy-return")}
          >
            BUY & RETURN POLICY
          </div>
          <div className="my-selected-one">
            {activeTab === "buy-return" && <div className="selector" />}
          </div>
        </div>
      </div>

      <div className="content-section rawstuff-section">
        {activeTab === "buy-return" && (
          <div></div>
        )}
      </div>
      {activeTab === "documentAndCertificate" && (
        <div className="sub-tabs">
          <div className="RawMaterial_subtab">
            <div
              className={`sub-tab ${
                subActiveTab === "RawMaterialDocumentAndCertificate"
                  ? "active"
                  : ""
              }`}
              onClick={() =>
                handleSubTabClick("RawMaterialDocumentAndCertificate")
              }
            >
              Rohstoff Dokument & Zertifikat
            </div>
            {subActiveTab === "RawMaterialDocumentAndCertificate" && (
              <div className="selectors" />
            )}
          </div>
          <div className="SellerDocument_subtab">
            <div
              className={`sub-tab ${
                subActiveTab === "SellerDocumentAndCertificate" ? "active" : ""
              }`}
              onClick={() => handleSubTabClick("SellerDocumentAndCertificate")}
            >
              Verkäufer Dokument & Zertifikat
            </div>
            {subActiveTab === "SellerDocumentAndCertificate" && (
              <div className="selectors" />
            )}
          </div>
        </div>
      )}
      {/* Add an outer wrapper for both sub-tab contents */}
      {haveActivePlan ? (
        <div
          className="sub-tab-contents"
          style={{
            overflowy: "scroll",
            overscrollbehavior: "bold",
            height: "560px",
          }}
        >
          {subActiveTab === "RawMaterialDocumentAndCertificate" && (
            <div className="material">
              <div className="material-head-txt">
                <h3>Raw Material Document</h3>
              </div>
              <div className="raw-material-content">
                <div className="Raw-Material">
                  {productDetailData.document_path.map(
                    (document_pathItem, i) => (
                      <div className="certeficate-img" key={i}>
                        <img src={document_pathItem} alt="raw-material doc" />
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          )}
          {subActiveTab === "SellerDocumentAndCertificate" && (
            <div className="material">
              <div className="material-head-txt">
                <h3>Verkäufer Dokument</h3>
              </div>
              <div className="seller-content">
                <div className="Raw-Material">
                  {productDetailData.verification_document.map(
                    (sellerDocumentVerification, i) => (
                      <div className="certeficate-img" key={i}>
                        <img
                          src={sellerDocumentVerification}
                          alt="sellerDocumentVerification"
                        />
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div
          className="sub-tab-contents"
          style={{
            overflowy: "scroll",
            overscrollbehavior: "bold",
            height: "560px",
          }}
        >
          {subActiveTab === "RawMaterialDocumentAndCertificate" && (
            <div className="material">
              <div className="material-head-txt">
                <h3>Rohstoff Dokument</h3>
                <p>
                  You are running a basic account, subscribe to membership plan
                  to see the raw material document. <br />
                  Please subscribe in order to upgrade your account &nbsp;
                  <Link to="/membership">Subscribe Now</Link>
                </p>
              </div>

              <div className="raw-material-content">
                <div className="Raw-Material">
                  <div className="certeficate-img"></div>
                  <div className="certeficate-img"></div>
                  <div className="certeficate-img"></div>
                </div>
              </div>
              <div className="raw-material-content">
                <div className="Raw-Material">
                  <div className="certeficate-img"></div>
                  <div className="certeficate-img"></div>
                  <div className="certeficate-img"></div>
                </div>
              </div>
              <div className="raw-material-content">
                <div className="Raw-Material">
                  <div className="certeficate-img"></div>
                  <div className="certeficate-img"></div>
                  <div className="certeficate-img"></div>
                </div>
              </div>
              <div className="raw-material-content">
                <div className="Raw-Material">
                  <div className="certeficate-img"></div>
                  <div className="certeficate-img"></div>
                  <div className="certeficate-img"></div>
                </div>
              </div>
              <div className="raw-material-content">
                <div className="Raw-Material">
                  <div className="certeficate-img"></div>
                  <div className="certeficate-img"></div>
                  <div className="certeficate-img"></div>
                </div>
              </div>
              <div className="raw-material-content">
                <div className="Raw-Material">
                  <div className="certeficate-img"></div>
                  <div className="certeficate-img"></div>
                  <div className="certeficate-img"></div>
                </div>
              </div>
              <div className="raw-material-content">
                <div className="Raw-Material">
                  <div className="certeficate-img"></div>
                  <div className="certeficate-img"></div>
                  <div className="certeficate-img"></div>
                </div>
              </div>
              <div className="raw-material-content">
                <div className="Raw-Material">
                  <div className="certeficate-img"></div>
                  <div className="certeficate-img"></div>
                  <div className="certeficate-img"></div>
                </div>
              </div>
            </div>
          )}
          {subActiveTab === "SellerDocumentAndCertificate" && (
            <div className="material">
              <div className="material-head-txt">
                <h3>Seller Document</h3>
                <p>
                  You are running a basic account,subscribe to membership plan
                  to see the seller document. <br />
                  Please subscribe in order to upgrade your account &nbsp;
                  <Link to="/membership">Subscribe Now</Link>
                </p>
              </div>

              <div className="seller-content">
                <div className="Raw-Material">
                  <div className="certeficate-img"></div>
                  <div className="certeficate-img"></div>
                  <div className="certeficate-img"></div>
                </div>
              </div>
              <div className="seller-content">
                <div className="Raw-Material">
                  <div className="certeficate-img"></div>
                  <div className="certeficate-img"></div>
                  <div className="certeficate-img"></div>
                </div>
              </div>
              <div className="seller-content">
                <div className="Raw-Material">
                  <div className="certeficate-img"></div>
                  <div className="certeficate-img"></div>
                  <div className="certeficate-img"></div>
                </div>
              </div>
              <div className="seller-content">
                <div className="Raw-Material">
                  <div className="certeficate-img"></div>
                  <div className="certeficate-img"></div>
                  <div className="certeficate-img"></div>
                </div>
              </div>
              <div className="seller-content">
                <div className="Raw-Material">
                  <div className="certeficate-img"></div>
                  <div className="certeficate-img"></div>
                  <div className="certeficate-img"></div>
                </div>
              </div>
              <div className="seller-content">
                <div className="Raw-Material">
                  <div className="certeficate-img"></div>
                  <div className="certeficate-img"></div>
                  <div className="certeficate-img"></div>
                </div>
              </div>
              <div className="seller-content">
                <div className="Raw-Material">
                  <div className="certeficate-img"></div>
                  <div className="certeficate-img"></div>
                  <div className="certeficate-img"></div>
                </div>
              </div>
              <div className="seller-content">
                <div className="Raw-Material">
                  <div className="certeficate-img"></div>
                  <div className="certeficate-img"></div>
                  <div className="certeficate-img"></div>
                </div>
              </div>
              <div className="seller-content">
                <div className="Raw-Material">
                  <div className="certeficate-img"></div>
                  <div className="certeficate-img"></div>
                  <div className="certeficate-img"></div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default ProductDescription;
