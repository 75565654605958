import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Slider from "react-slick";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "./PopularCollection.css";
import PopularProductCard from "./PopularProductCard/PopularProductCard";
import {useLocale, useTranslation} from '@psyycker/react-translation';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "red" }}
      onClick={onClick}
    >
      NEXT
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "green" }}
      onClick={onClick}
    >
      BACK
    </div>
  );
}

const PopularCollection = () => {
  const [popularProducts, setPopularProducts] = useState([]);
  const slider = React.useRef(null);
  const [active, setActive] = useState(false);
  //  var jsonString = localStorage.getItem("token");
  //  var jsonObject = JSON.parse(jsonString);
  //  var token = jsonObject.token;

  useEffect(() => {
    const fetchPopularProducts = async () => {
      try {
        const response = await axios.get(
          "https://api.rawstuff.deals/api/v1/products/getHottestProducts",
          {
            headers: {
              Accept: "application/json",
              // Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = response.data;
        setPopularProducts(data.data);
        // localStorage.setItem("orderId", data.data.id);
        // setShowCancelRefund(data.data.status === "pending");
        // console.log("popular Trending", data.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchPopularProducts();
  }, []);

  //Get Language Translation starts
  const {getTranslation} = useTranslation();
  const [locale, setLocale] = useLocale();
  const onButtonClick = () => {
    var lang = document.getElementById('lang').value;
    var selectedx = "You have selected";
    if(lang=="English"){
      setLocale('en-US');
    }

    if(lang=="Germany"){
      setLocale('german')
    }

    if(lang=="Dutch"){
      setLocale('de')
    }

    if(lang=="French"){
      setLocale('fr-FR')
    }
  }

  //Get Language Translation ends


  var bestseller = getTranslation({translationKey: 'Best Seller'});
  var Highlights = getTranslation({translationKey: 'Highlights'});
  var Toprated = getTranslation({ translationKey: "Top Product" });

  const items = [
    { id: 1, headingName: bestseller },
    { id: 2, headingName: Highlights },
    { id: 3, headingName: Toprated},
  ];

  const [activeItemId, setActiveItemId] = useState(items[0]?.id);

  const handleItemClick = (itemId) => {
    setActiveItemId(itemId);
    handleNextBtnClick()
  };
  const handleNextBtnClick = () => {
    slider?.current?.slickNext();
  }

  const settings = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 3,

    // customPaging: function (i) {
    //   return <p>{i + 1}</p>;
    // },

    // responsive: [
    //   {
    //     breakpoint: 1424,
    //     settings: {
    //       slidesToShow: 3,
    //       slidesToScroll: 3,
    //       infinite: false,
    //       dots: false,
    //     },
    //   },

    //   {
    //     breakpoint: 1124,
    //     settings: {
    //       slidesToShow: 2,
    //       slidesToScroll: 2,
    //       infinite: false,
    //       dots: false,
    //     },
    //   },
    //   {
    //     breakpoint: 800,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //       initialSlide: 2,
    //     },
    //   },
    // ],

    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

   
  return (
    <div className="PopularProducts-container">
      <h3 className="product-heading-txt text-center">
        {/* {getTranslation({translationKey: 'PopularCollections'})}   */}
        Beliebteste Produkte
      </h3>
      <section className="popularcollection-header">
        <ul className="popularcollection-links d-flex justify-content-center gap-3 align-items-center">
          {items.map((item) => (
            <li
              // onClick={handleNextBtnClick}
              key={item.id}
              className={`popularProduct-list-item ${
                activeItemId === item.id ? "active" : ""
              }`}
              onClick={() => handleItemClick(item.id)}
            >
              {item.headingName}
            </li>
          ))}
        </ul>
      </section>

      <Slider ref={slider} {...settings}>
        {popularProducts?.map((popularProductItem, index) => {
          return (
            <div className="mb-3" key={index}>
              <PopularProductCard popularProductItem={popularProductItem} />
            </div>
          );
        })}
      </Slider>

      <div className="prev-btn-next mt-1 mb-2  d-flex justify-content-between align-items-center ">
        <button
          className="btn-prev"
          onClick={() => slider?.current?.slickPrev()}
        >
          <span className="btn-prev">
            <ArrowBackIcon />
          </span>
          {/* {getTranslation({ translationKey: "Previous" })} */}
          Vorherige
        </button>
        <button onClick={() => slider?.current?.slickNext()}>
          {/* {getTranslation({ translationKey: "Next" })} */}
          Nächste
          <span className="btn-next">
            <ArrowForwardIcon />
          </span>
        </button>
      </div>
    </div>
  );
};

export default PopularCollection;
