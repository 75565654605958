import React, { useEffect, useState } from "react";
import { Bredcrumb } from "./components/Bredcrumb";
import { ArrowRight1 } from "./icons/ArrowRight1";
import "./ProductDetailPage.css";
import { useDispatch, useSelector } from "react-redux";
import RelatedProduct from "./RelatedProduct";
import ProductDescription from "./ProductDescription";
import { Link, useParams } from "react-router-dom";
import { fetchProductDetail } from "../../redux/Slice/productdetailSlice";
import { getToCart } from "../../redux/Slice/getCartSlice";
import LastSeen from "./LastSeen";
import Form from "react-bootstrap/Form";
import { useTranslation } from "@psyycker/react-translation";
import AddToWishList from "../../components/WhishList/AddToWishList";
import BuyNOwButton from "../MarketPlace/BuyNOwButton";
import "../ShoppingCart/ShoppingPage.css";
import { toast } from "react-toastify";
import axios from "axios";

export default function ProductDetailsPage() {
  const { id } = useParams();
  const { productDetail } = useSelector((state) => state.productDetail);
  const [currentQuantity, setCurrentQuantity] = useState("");
  const [selectedQuantities, setSelectedQuantities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [enterQuantity, setenterQuantity] = useState("");
  const [showTextWrapper, setShowTextWrapper] = useState(false);

  const customQuantityAdd = (event) => {
    const selectedValue = parseFloat(event.target.value);
    setenterQuantity((prevQuantity) => {
      const newQuantity = isNaN(selectedValue)
        ? productDetail.min_quantity
        : selectedValue;
      return newQuantity;
    });
  };

  const payload =
    enterQuantity !== 0
      ? [{ unit: productDetail.unit_of_measurement, quantity: enterQuantity }]
      : [];
  const dispatch = useDispatch();

  useEffect(() => {
    const storedProductDetail = JSON.parse(
      localStorage.getItem("productDetail")
    );

    if (storedProductDetail) {
      dispatch(fetchProductDetail(storedProductDetail));
    } else {
      dispatch(fetchProductDetail(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(fetchProductDetail(id));
  }, [dispatch, id]);

  useEffect(() => {
    const storageKeyPrefix = `product_${id}_`;

    localStorage.setItem(
      `${storageKeyPrefix}currentQuantity`,
      JSON.stringify(currentQuantity)
    );
    localStorage.setItem(`${storageKeyPrefix}selectedCountry`, selectedCountry);
    localStorage.setItem(`${storageKeyPrefix}enterQuantity`, enterQuantity);
    localStorage.setItem(
      `${storageKeyPrefix}showTextWrapper`,
      JSON.stringify(showTextWrapper)
    );
    localStorage.setItem(
      `${storageKeyPrefix}selectedQuantities`,
      JSON.stringify(selectedQuantities)
    );
  }, [
    currentQuantity,
    selectedCountry,
    enterQuantity,
    showTextWrapper,
    selectedQuantities,
    id,
  ]);

  useEffect(() => {
    const storageKeyPrefix = `product_${id}_`;

    const storedCurrentQuantity = JSON.parse(
      localStorage.getItem(`${storageKeyPrefix}currentQuantity`)
    );
    const storedSelectedCountry = localStorage.getItem(
      `${storageKeyPrefix}selectedCountry`
    );
    const storedEnterQuantity = localStorage.getItem(
      `${storageKeyPrefix}enterQuantity`
    );
    const storedShowTextWrapper = JSON.parse(
      localStorage.getItem(`${storageKeyPrefix}showTextWrapper`)
    );
    const storedSelectedQuantities = JSON.parse(
      localStorage.getItem(`${storageKeyPrefix}selectedQuantities`)
    );

    // Update state with stored values
    setCurrentQuantity(storedCurrentQuantity || productDetail.min_quantity);
    setSelectedCountry(storedSelectedCountry || "");
    setenterQuantity(storedEnterQuantity || []);
    setShowTextWrapper(storedShowTextWrapper || false);
    setSelectedQuantities(storedSelectedQuantities || []);
  }, [id]);

  useEffect(() => {
    if (id) {
      dispatch(fetchProductDetail(id));
    }
    dispatch(getToCart());
  }, [dispatch, id]);
  
  
  //Get Language Translation starts
  const { getTranslation } = useTranslation();
  const [isAddingToCart, setIsAddingToCart] = useState(false);

  const handleAddToCart = async (product_id, payload) => {
    try {
 setIsAddingToCart(true); // Disable the button
      const cartItem = localStorage.getItem("cartItem");

      var jsonString = localStorage.getItem("token");
      var jsonObject = JSON.parse(jsonString);
      var token = jsonObject.token;

      const isValidQuantity =
        parseFloat(enterQuantity, 10) >= productDetail.min_quantity;
      const isValidQuantitys =
        parseFloat(enterQuantity, 10) <= productDetail.max_quantity;
      if (!isValidQuantity) {
        toast.error(
          "Die Menge ist nicht gültig. Bitte geben Sie eine Menge ein, die größer als oder gleich der zulässigen Mindestmenge ist."
        );
        return;
      }
      if (!isValidQuantitys) {
        toast.error(
          "Die Menge ist nicht gültig. Bitte geben Sie eine Menge ein, die kleiner oder gleich der zulässigen Höchstmenge ist."
        );
        return;
      }

      if (!cartItem) {
        // Cart is empty, create a new cart
        console.log("empty", payload);
        if (payload.length === 0) {
          payload = [
            {
              unit: productDetail.unit_of_measurement,
              quantity: productDetail.min_quantity,
            },
          ];
        }
        const response = await axios.post(
          "https://api.rawstuff.deals/api/v1/carts/addToCart",
          { product_id: product_id, quantity: payload },
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        localStorage.setItem("cartItem", response.data.data.id);
        dispatch(getToCart());
        return response.data;
      } else {
        // Cart exists, add product to the existing cart
        const response = await axios.post(
          "https://api.rawstuff.deals/api/v1/carts/addToCart",
          {
            product_id: product_id,
            quantity: payload,
            cart_id: cartItem,
          },
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          toast.success("Produkt erfolgreich in den Warenkorb gelegt");
        }
        dispatch(getToCart());
        setIsAddingToCart(false); // enable the button
        return response.data;
      }
    } catch (error) {
      console.log(error.response.data.message);
      throw toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    const storageKeyPrefix = `product_${id}_`;

    localStorage.getItem(
      `${storageKeyPrefix}currentQuantity`,
      JSON.stringify(currentQuantity)
    );
    localStorage.getItem(`${storageKeyPrefix}selectedCountry`, selectedCountry);
    localStorage.getItem(`${storageKeyPrefix}enterQuantity`, enterQuantity);
    localStorage.getItem(
      `${storageKeyPrefix}showTextWrapper`,
      JSON.stringify(showTextWrapper)
    );
    localStorage.getItem(
      `${storageKeyPrefix}selectedQuantities`,
      JSON.stringify(selectedQuantities)
    );
  }, [
    currentQuantity,
    selectedCountry,
    enterQuantity,
    showTextWrapper,
    selectedQuantities,
    id,
  ]);

  const formatNumber = (value) => {
    if (value !== undefined && value !== null) {
      const roundedValue = Math.abs(value - Math.floor(value)) >= 0.5 ? Math.ceil(value * 100) / 100 : Math.floor(value * 100) / 100;
      return roundedValue.toFixed(2);
    } else {
      return '0.00';
    }
  };
  return (
    <>
      <div className="product-details-page" id="rowstaff">
        <div className="div">
          <div style={{ left: "12px" }} className="bredcrumb-wrapper">
            <Bredcrumb
              bredcrumbBaseDivStyle={{
                fontFamily: "Plus Jakarta Sans', Helvetica",
                left: "-10px",
              }}
              bredcrumbBaseIcon={
                <ArrowRight1
                  style={{
                    height: "12px",
                    minWidth: "12px",
                    // position: "relative",
                  }}
                />
              }
              
              bredcrumbBaseIcon1={
                <ArrowRight1
                  style={{
                    height: "12px",
                    minWidth: "12px",
                    // position: "relative",
                  }}
                />
              }
              bredcrumbBaseMenuStyle={{
                fontFamily: "'Plus Jakarta Sans', Helvetica",
              }}
              bredcrumbBaseMenuStyleOverride={{
                fontFamily: "'Plus Jakarta Sans', Helvetica",
              }}
              bredcrumbBaseText={
                <Link className="market" to="/marketplace">
                  {getTranslation({ translationKey: "Market Place" })}
                </Link>
              }
              bredcrumbBaseText1={
                <Link className="market" to="/marketplace">
                  {getTranslation({ translationKey: "Produkt" })}
                </Link>
              }
              bredcrumbBaseText2="Produkt Details"
              number="five"
              override={
                <ArrowRight1
                  style={{
                    height: "12px",
                    minWidth: "12px",
                    position: "relative",
                  }}
                />
              }
              styleOverride={{
                left: "48px",
                top: "17px",
              }}
              visible={false}
              visible1={false}
            />
          </div>
          <div className="rawstaff-one">
            <div className="frames">
              <div className="frame-2">
                <div className="text-wrapper-2">{productDetail.name}</div>
                <div className="frame-3">
                  <div className="frame-4">
                    <div className="text-wrapper-3">
                    Auf Lager: {productDetail.amount}
                             
                              {productDetail.unit_of_measurement}
                    </div>
                  </div>
                </div>
              </div>

              <div className="frame-8">
                <div className="frame-9">
                  <div className="text-wrapper-6">Preis :</div>
                  <div className="frame-10">
                    <div className="text-wrapper-7">
                      €{productDetail.unit_price_eur}
                    </div>
                  </div>
                </div>
                <div>
                  Mindestmenge
                  <strong className="mr-1">
                    :{productDetail.min_quantity}{" "}
                    {productDetail.unit_of_measurement}{" "}
                  </strong>
                  Maximale Menge{" "}
                  <strong className="mr-1">
                    :{productDetail.max_quantity}{" "}
                    {productDetail.unit_of_measurement}
                  </strong>
                </div>
                <div className="frame-12">
                  <div className="CAS-number">
                    {getTranslation({ translationKey: "Rabatt" })}:
                  </div>
                  <div className="qv discount">
                    {productDetail?.discount_rate}%
                  </div>
                </div>
                <div className="frame-11">
                  <div className="text-wrapper-9">
                    {getTranslation({ translationKey: "Menge" })} :
                  </div>

                  <Form.Group
                    className="register-label units"
                    controlId="exampleForm.ControlInput1"
                  >
                    <div className="amount">
                      <Form.Label className=" mr-3">Betrag</Form.Label>
                      <Form.Control
                        className="p-2 amount-inputs register-borders"
                        type="text"
                        placeholder=""
                        name="const"
                        style={{ fontSize: "12px", color: "#18233e" }}
                        onChange={customQuantityAdd}
                      />
                    </div>
                    <div className="amount">
                      <Form.Label className=""> Einheit</Form.Label>

                      <Form.Label className="mt-2 kg">
                        {" "}
                        {productDetail.unit_of_measurement}
                      </Form.Label>
                    </div>
                  </Form.Group>
                </div>

                <div className="frame-12">
                  <div className="CAS-number">
                    {getTranslation({ translationKey: "CAS-Nummer" })}:
                  </div>
                  <div className="qv">{productDetail?.cas_number}</div>
                </div>
                <div className="frame-13">
                  <div className="text-wrapper-11">
                    {getTranslation({ translationKey: "Produkt" })} SKU:
                  </div>
                  <div className="text-wrapper-12">
                    {productDetail?.product_sku}
                  </div>
                </div>
                <div className="frame-13">
                  <div className="text-wrapper-11">
                    {getTranslation({ translationKey: "Lager Land" })}:
                  </div>
                  <div className="text-wrapper-12">
                    {" "}
                    {productDetail?.warehouse?.country}
                  </div>
                </div>
              </div>
            </div>
            <div className="productdetail-descreption">
              <div>
                <h5 className="product-detail">
                  {getTranslation({ translationKey: "Beschreibung" })} :
                </h5>
                <p className="product-detail-pargraph">
                  {productDetail.description}
                </p>
              </div>
              <div className="frame-23">
                <div className="frame-24">
                  <BuyNOwButton product={productDetail} />

                  <div className="add-bt">
                    <button
                      style={{
                        minWidth: "114px",
                        width: "unset",
                        cursor: " pointer",
                        height: "40px",
                        borderRadius: "4px",
                        border: "1px solid",
                        borderColor: "#e1e1e1",
                        boxShadow: "0px 2px 2px #0000000d",
                        position: "relative",
                        fontFamily: "Plus Jakarta Sans",
                        fontWeight: "600",
                        letterSpacing: "0",
                        lineHeight: "normal",
                      }}
                      onClick={() => handleAddToCart(productDetail.id, payload)}
                      // onClick={handleAddToCart(productDetail.id)}
                    >
                    
                      {getTranslation({ translationKey: "Warenkorb" })}
                    </button>
                  </div>
                  <div className="path-wrapper d-flex align-items-center justify-content-center">
                    <AddToWishList product_id={productDetail.id} />
                  </div>
                </div>
              </div>
            </div>

            <div className="rawstaff-whole">
              <div className="overlap-wrapper">
                <div style={{ background: "#eeeeee", padding: "3%" }}>
                  <center>
                    <h4 textAlign="cenetr">
                      {getTranslation({ translationKey: "Kalkulation" })}
                    </h4>
                  </center>
                  <table style={{ width: "100%", textAlign: "center" }}>
                    <tr
                      style={{
                        width: "auto",
                        margin: "2%",
                        height: "40px",
                        textAlign: "center",
                        background: "#ffffff",
                        padding: "6%",
                      }}
                    >
                      <th
                        style={{
                          margin: "2%",
                          textAlign: "center",
                        }}
                      >
                        {getTranslation({ translationKey: "Menge" })}
                      </th>
                      <th
                        style={{
                          margin: "2%",
                          // textAlign: "center",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {getTranslation({ translationKey: "Preis" })}
                        <article className="pl-5">
                          {" "}
                          {getTranslation({ translationKey: "Rabatt" })}
                        </article>
                      </th>
                    </tr>
                    <tr
                      style={{
                        width: "100%",
                        margin: "2%",
                        textAlign: "center",
                        padding: "1rem",
                      }}
                    >
                      <td>
                        <div className="text-wrapper-27">
                          {enterQuantity} {productDetail.unit_of_measurement}
                        </div>
                      </td>
                      <td
                        style={{
                          margin: "2%",
                          // textAlign: "center",
                          display: "flex",
                          // justifyContent:"space-between",
                        }}
                      >
                        <div className="text-wrapper-28">
                          €{productDetail.unit_price_eur}
                        </div>
                        <div className="text-wrapper-28 pl-5 discount">
                         - €
                          {formatNumber(
                            productDetail.discount_amount * enterQuantity
                          )}
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>

              <div className="frame-314">
                <div style={{ overflow: "scroll" }}>
                  <table
                    style={{
                      width: "75%",
                      textAlign: "center",
                      margin: "auto",
                    }}
                  >
                    <tr
                      style={{
                        height: "auto",
                        margin: "2%",
                        textAlign: "center",
                        background: "#ffffff",
                      }}
                    >
                      <td>
                        <h5 style={{ marginLeft: "2.3rem" }}>Insgesamt </h5>
                      </td>
                      <td></td>
                      <td>
                        <h5 style={{ marginLeft: "8rem" }}>
                          {enterQuantity > 0 ? (
                            <h5 style={{ marginLeft: "4.8rem" }}>
                              €
                              {formatNumber(
                                enterQuantity * productDetail?.unit_price_eur -
                                productDetail?.discount_amount * enterQuantity
                              )}
                            </h5>
                          ) : null}
                        </h5>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                    </tr>
                  </table>
                </div>
                {/* <div className="frame-324">
                  <div className="sold-by">
                    {getTranslation({ translationKey: "Sold By" })}
                  </div>
                  <div className="text-wrapper-32">Rawstuff Deals</div>
                </div> */}
              </div>
            </div>
          </div>

          <div className="rawstaff-second-section">
            <ProductDescription productDetailData={productDetail} />
          </div>
        </div>
      </div>
      <div>
        <RelatedProduct />
        <LastSeen />
      </div>
    </>
  );
}
