import React from "react";
import { images } from "../../Constants";
import "./EmailVerification.css";
import { Link } from "react-router-dom";

const EmailVerificationSuccessful = () => {

  return (
<div>
<h2>Email Verification</h2>
  <div className="email-Verfication-container">
    <div>
      {/* <Link to="/register" className="back-arrow">
       <img src={images.backArrow} alt="back arrow" /> <h5 className="back">Back</h5>
     </Link> */}
    </div>
    <div className="Verify-your">
      <article>
        {" "}
        <div className="righticon">
          <img src={images.righticon} alt="righticon" />
        </div>
      </article>
      <h4 className="Account-verified">Konto erfolgreich registriert</h4>
      <h5 className="your-account-paragraph">
      Ihr Konto wird derzeit überprüft. Sobald der Überprüfungsprozess abgeschlossen ist,
      </h5>
      <h5 className="your-account-paragraph">
      erhalten Sie Informationen per E-Mail. Wir danken Ihnen!
        
      </h5>
    </div>
    <Link to="/">
      <button className="email-bt" >
      Zur Startseite gehen
      </button>
    </Link>
  </div>
</div>
  );
};


export default EmailVerificationSuccessful
