import React, { useState, useEffect } from "react";
import { Modal, Dropdown, Row, Col } from "react-bootstrap";
import { ArrowsDiagramsArrow4 } from "../productDetailPage/icons/ArrowsDiagramsArrow4";
import { Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";
import {
  europeanCountries,
  europeanCountriesCode,
} from "../ManageWarehouse/ManageNewAdress";
import { convertCountryCodeToCountryName } from "../SearchResultPage/ProductCard";

const AddressDropdown = ({ warehouse, id }) => {
  const [isChanged, setIsChanged] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [address, setAddress] = useState([]);
  const [newWarehouseDetails, setNewWarehouseDetails] = useState({
    name: "",
    company: "",
    // street1: "",
    city: "",
    zip: "",
    country: "",
  });

  let token = localStorage?.getItem("token");
  token = JSON.parse(token)?.token;

  useEffect(() => {
    const fetchwarehouse = async () => {
      try {
        const response = await axios.get(
          "https://api.rawstuff.deals/api/v1/warehouses",
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const warehouseData = response.data.data;
        setAddress(warehouseData);
      } catch (error) {
        console.log(error);
      }
    };

    fetchwarehouse();
  }, []);

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const [errors, setErrors] = useState({
    name: "",
    company: "",
    // street1: "",
    city: "",
    zip: "",
    country: "",
  });

  const createWarehouseInShippo = async () => {
    try {
      var jsonString = localStorage.getItem("token");
      var jsonObject = JSON.parse(jsonString);
      var Token = jsonObject?.token;

      let formIsValid = true;
      const newErrors = { ...errors };

      Object.keys(newWarehouseDetails).forEach((key) => {
        if (newWarehouseDetails[key] === "" || !newWarehouseDetails[key]) {
          formIsValid = false;
          newErrors[key] = `${
            key.charAt(0).toUpperCase() + key.slice(1)
          } is required`;
        } else {
          newErrors[key] = "";
        }
      });

      if (!formIsValid) {
        setErrors(newErrors);
        return;
      }

      // Include country code in the warehouse details
      newWarehouseDetails["country"] =
        europeanCountriesCode[newWarehouseDetails["country"]];
      // Make a request to create a warehouse
      const response = await fetch(
        "https://api.rawstuff.deals/api/v1/warehouses",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${Token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newWarehouseDetails),
        }
      );

      if (response.ok) {
        // Extract relevant information from the warehouse creation response
        const warehouseData = await response.json();
        const warehouseId = warehouseData.data.id;

        try {
          const res = axios.post(
            "https://api.rawstuff.deals/api/v1/carts/changeCartItemWarehouse",
            {
              cartItemId: id,
              warehouseId: warehouseId,
            },
            {
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${Token}`,
                "Content-Type": "application/json",
              },
            }
          );

          // toast.success(" Warehouse creted and changed successfully.");
          toast.success("Lager erfolgreich erstellt und geändert.");
        } catch (error) {
          // toast.error("An error occurred. Please try again later.");
          toast.error(
            "Ein Fehler ist aufgetreten. Bitte versuchen Sie es später noch einmal."
          );
        }

        handleModalClose();
      } else {
        // toast.error("Failed to create a warehouse?. Please try again.");
        toast.error(
          "Konnte kein Lager erstellt werden? Bitte versuche es erneut."
        );
      }
    } catch (error) {
      // toast.error("An error occurred. Please try again later.");
      toast.error(
        "Ein Fehler ist aufgetreten. Bitte versuchen Sie es später noch einmal."
      );
    }
  };

  const handleFormInputChange = (event) => {
    const { name, value } = event.target;
    setNewWarehouseDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));

    if (newWarehouseDetails[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }

    if (name === "zip") {
      const isValidZipCode = /^[a-zA-Z0-9]{4,7}$/.test(value);

      if (!isValidZipCode) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Invalid zip code",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
      }
    }

    setIsChanged(true);
  };

  const handleAddressChange = (e) => {
    const { value } = e.target;
    // console.log("address changed", value);

    try {
      var jsonString = localStorage.getItem("token");
      var jsonObject = JSON.parse(jsonString);
      var Token = jsonObject?.token;

      const res = axios.post(
        "https://api.rawstuff.deals/api/v1/carts/changeCartItemWarehouse",
        {
          cartItemId: id,
          warehouseId: value,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${Token}`,
            "Content-Type": "application/json",
          },
        }
      );
      // window.location.reload();
      // toast.success(" Warehouse changed successfully.");
      toast.success("Lager wurde erfolgreich geändert.");
    } catch (error) {
      // toast.error("An error occurred. Please try again later.");
      toast.error(
        "Ein Fehler ist aufgetreten. Bitte versuchen Sie es später noch einmal."
      );
    }
  };

  return (
    <div className="address-container">
      <article className="address-pargraph">
        {isChanged ? (
          <>
            {newWarehouseDetails.name},{newWarehouseDetails.company},
            {convertCountryCodeToCountryName(newWarehouseDetails.country)},{" "}
            {newWarehouseDetails.city},{newWarehouseDetails.zip}
          </>
        ) : (
          <>
            {warehouse?.name}, {warehouse?.company},{" "}
            {convertCountryCodeToCountryName(warehouse?.country)},{" "}
            {warehouse?.city}, {warehouse?.zip}
          </>
        )}
      </article>

      <div className="product-adress-seller" onClick={() => setModalOpen(true)}>
        <Dropdown.Toggle as={CustomToggle}>Ändern</Dropdown.Toggle>
      </div>
      <Modal
        id="shopping-address-bg"
        show={modalOpen}
        onHide={handleModalClose}
      >
        <Modal.Body>
          <Modal.Header closeButton>
            <Modal.Title className="add-new-address ">
              Neue Adresse oder Adresse ändern
            </Modal.Title>
          </Modal.Header>
          <div>
            <h6 className="mt-2">Adresse ändern</h6>
            <select
              name="address"
              className="form-select"
              onChange={(e) => handleAddressChange(e)}
            >
              <option value="option1">Adresse auswählen</option>
              {address?.map((adr, index) => (
                <option key={index} value={adr.id}>
                  {adr.name}, {adr.company}, {adr.country}, {adr.city}
                </option>
              ))}
            </select>
          </div>
          <Form>
            <h6 className=" mt-4 mb-2 mb-0">Neue Adresse hinzufügen </h6>
            {/* <hr className="m-0" /> */}
            <Row>
              <Form.Group controlId="formGridName">
                <Form.Label className="">Lieferadresse Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  required
                  value={newWarehouseDetails.name}
                  onChange={handleFormInputChange}
                />
                <span className="text-danger">{errors.name}</span>
              </Form.Group>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="formGridCompany">
                  <Form.Label className="mt-3 mb-1">Unternehmen</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    name="company"
                    value={newWarehouseDetails.company}
                    onChange={handleFormInputChange}
                  />
                  <span className="text-danger">{errors.company}</span>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Form.Group controlId="formGridCountry">
                <Form.Label className="mt-3 mb-1">Land</Form.Label>
                <Form.Control
                  as="select"
                  name="country"
                  required
                  value={newWarehouseDetails.country}
                  onChange={handleFormInputChange}
                >
                  <option value="">Wähle Land</option>
                  {europeanCountries.map((country) => (
                    <option key={country} value={country}>
                      {country}
                    </option>
                  ))}
                </Form.Control>
                <span className="text-danger">{errors.country}</span>
              </Form.Group>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group controlId="formGridCity">
                  <Form.Label className="mt-3  mb-1">Stadt</Form.Label>
                  <Form.Control
                    type="text"
                    name="city"
                    required
                    value={newWarehouseDetails.city}
                    onChange={handleFormInputChange}
                    style={{ borderRadius: "8px" }}
                  />
                  <span className="text-danger">{errors.city}</span>
                </Form.Group>
              </Col>
              {/* <Col>
                <Form.Group controlId="formGridStreet1">
                  <Form.Label className="mt-3 mb-1">Street 1</Form.Label>
                  <Form.Control
                    type="text"
                    name="street1"
                    required
                    value={newWarehouseDetails.street1}
                    onChange={handleFormInputChange}
                  />
                  <span className="text-danger">{errors.street1}</span>
                </Form.Group>
              </Col> */}
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group controlId="formGridZip">
                  <Form.Label className="mt-3  mb-1">Postleitzahl</Form.Label>
                  <Form.Control
                    type="text"
                    name="zip"
                    required
                    value={newWarehouseDetails.zip}
                    onChange={handleFormInputChange}
                  />
                  <span className="text-danger">{errors.zip}</span>
                </Form.Group>
              </Col>
            </Row>

            <Form.Group className="position-relative">
              <Form.Check
                className="Save-my-location"
                required
                name="terms"
                label="Speichere diese Adresse für künftige Einkäufe"
                // onChange={handleChange}
                feedbackType="invalid"
                id="validationFormik106"
                feedbackTooltip
                style={{
                  height: "48px",
                  width: "30rem",
                  // margin: "0.5rem",
                  paddingleft: "1.5rem",
                  border: "#E1E1E1",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "1rem",
                }}
              />
            </Form.Group>
            <Button
              className="Save-Delivery"
              style={{ margin: "0.3rem" }}
              variant="secondary"
              onClick={() => createWarehouseInShippo()}
            >
              Save Delivery Address
            </Button>
            <Button
              className="cancel-bt"
              style={{
                margin: "0.3rem",
                background: "#fff",
                color: "#2C2C2C",
                border: "1px solid #2C2C2C",
              }}
              onClick={handleModalClose}
            >
              Cancel
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <ArrowsDiagramsArrow4
      color="#1577EA"
      style={{
        position: "relative",
      }}
    />
  </a>
));

export default AddressDropdown;
