const order = {
  ordereditemsen: "Ordered Items",
  ordereditemsde: "Bestellte Artikel",
  ordnumen: "Order Number",
  ordnumde: "Bestellnummer",
  staten: "Status",
  statde: "Status",
  dateen: "Date",
  datede: "Datum",
  amnten: "Amount",
  amntde: "Betrag",
  acten: "Action",
  actde: "Aktion",
};

Object.freeze(order);

export default order;
