import React from "react";
import { images } from "../../../Constants";
import "./AboutusBenefits.css";
import { Link } from "react-router-dom";

const AboutusBenefits = () => {
  return (
    <div className="AboutusBenefits-container">
      <div className="AboutusBenefits-headtext">
        <h3 className="mt-3">
          Vorteile von Rawstuff Deal für Käufer und Verkäufer
        </h3>
        <p>
          Treten Sie dem Rawstuff Deals Marketplace bei und profitieren Sie als
          Käufer von einem breiten Angebot an Rohstoffen zu wettbewerbsfähigen
          Preisen. <br />
          Als Verkäufer erhalten Sie eine erweiterte Reichweite und direkten
          Zugang zu einer Vielzahl von potenziellen Käufern. Optimieren Sie Ihre
          Beschaffungs- und Verkaufsprozesse und maximieren Sie Ihre Gewinne mit
          Rawstuff Deals.
        </p>
      </div>
      <div className="bayerseller-benefit-container">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap  ">
          <div className="col-md-6  me-md-2 mb-3 mb-md-0">
            <div className="buyer-benefits-wrapper">
              <h3>Vorteile für Käufer</h3>
              <div className="buyerbenefit-lists">
                <img
                  className="me-3"
                  src={images.markTickIcon}
                  alt="markTickIcon"
                />
                <p>Qualifizierte Verkäufer mit geprüften Rohstoffen</p>
              </div>
              <div className="buyerbenefit-lists">
                <img
                  className="me-3"
                  src={images.markTickIcon}
                  alt="markTickIcon"
                />
                <p>Fortschrittliche Such- und Filterfunktion</p>
              </div>
              <div className="buyerbenefit-lists">
                <img
                  className="me-3"
                  src={images.markTickIcon}
                  alt="markTickIcon"
                />
                <p>Anbietervergleich</p>
              </div>
              <div className="buyerbenefit-lists">
                <img
                  className="me-3"
                  src={images.markTickIcon}
                  alt="markTickIcon"
                />
                <p>Sichere und transparente Transaktionen</p>
              </div>
              <div className="buyerbenefit-lists">
                <img
                  className="me-3"
                  src={images.markTickIcon}
                  alt="markTickIcon"
                />
                <p>Effiziente Beschaffungsprozesse</p>
              </div>
              <div className="mt-3">
                <Link to="/register">
                  <button>Jetzt registrieren</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-6 ms-md-2">
            <div className="seller-benefits-wrapper">
              <h3>Vorteile für Verkäufer</h3>
              <div className="sellerbenefit-lists">
                <img
                  className="me-3"
                  src={images.markTickIcon}
                  alt="markTickIcon"
                />
                <p>Breites Käufernetzwerk</p>
              </div>
              <div className="sellerbenefit-lists">
                <img
                  className="me-3"
                  src={images.markTickIcon}
                  alt="markTickIcon"
                />
                <p>Erhöhte Sichtbarkeit der Produkte</p>
              </div>
              <div className="sellerbenefit-lists">
                <img
                  className="me-3"
                  src={images.markTickIcon}
                  alt="markTickIcon"
                />
                <p>Effiziente Verkaufsprozesse</p>
              </div>
              <div className="sellerbenefit-lists">
                <img
                  className="me-3"
                  src={images.markTickIcon}
                  alt="markTickIcon"
                />
                <p>Zielgerichtetes Marketing</p>
              </div>
              <div className="sellerbenefit-lists">
                <img
                  className="me-3"
                  src={images.markTickIcon}
                  alt="markTickIcon"
                />
                <p>Kostenreduktion im Vertrieb und Marketing</p>
              </div>

              <div className="mt-3">
                <Link to="/register">
                  <button>Jetzt registrieren</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutusBenefits;
