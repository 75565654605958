import React, { useEffect, useState } from "react";
import "./AboutUsDetail.css";

const AboutUsDetail = () => {
  const [contentList, setContentList] = useState([]);
  // const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    // Fetch content from the backend endpoint
    // fetch("your_backend_endpoint_url")
    //   .then((response) => response.json())
    //   .then((data) => setContentList(data))
    //   .catch((error) => console.error("Error fetching content:", error));
    setContentList(aboutDetail);
  }, []);
  const aboutDetail = [
    {
      id: 1,
      title: "Entstehung",
      description: [
        "Aus unserer Historie heraus erkannten wir schon früh den hohen Bedarf an Transparenz im Rohstoffmarkt. In der aktuellen Situation stellt es einen Rohstoffhändler immer wieder vor eine Herausforderung, das gesamte Marktpotential auszuschöpfen. Auf der Gegenseite stellt es einen Einkäufer vor das Problem den gesamten Markt im Überblick zu behalten.",
        "Daher haben wir uns die Aufgabe gestellt, ein passendes Werkzeug für dieses Problem zu erstellen. Mit diesem Werkzeug ist es möglich, in kürzester Zeit auf der Angebotsseite eine qualifiziert hohe Reichweite zu erzielen. Auf der Gegenseite kann hiermit der Einkäufer schnellstmöglich verschiedene Angebote sortieren, auswerten und kaufen.Für die einzelnen Unternehmen bedeutet dies eine massive Zeitersparnis.",
      ],
      imageUrl: "image here",
    },
    {
      id: 2,
      title: "Philosophie",
      description: [
        "Bei Rawstuff Deals dreht sich unsere Philosophie um die Kernprinzipien Transparenz, Effizienz und Eigenverantwortung. Wir glauben an die Schaffung eines Marktplatzes, auf dem Integrität und Vertrauen die Grundlage für jede Transaktion bilden.",
        "Wir haben uns zum Ziel gesetzt, sowohl Käufer als auch Verkäufer zu unterstützen, indem wir ihnen die Werkzeuge, Ressourcen und Möglichkeiten zur Verfügung stellen, die sie für ihren Erfolg benötigen. Ob es darum geht, Käufern eine große Auswahl an Produkten zu bieten oder Verkäufern eine Plattform, auf der sie ihre Angebote präsentieren und ihre Reichweite vergrößern können - wir wollen jedem Teilnehmer des Marktplatzes die Möglichkeit geben, seine Ziele zu erreichen und sein Potenzial zu maximieren",
      ],
      imageUrl: "image here",
    },
    {
      id: 3,
      title: "Ziel",
      description: [
        "Wir von Rawstuff Deals haben es uns zur Aufgabe gemacht, den B2B-Marktplatz für Rohstoffe für Nahrungsergänzungsmittel zu revolutionieren, indem wir eine umfassende Plattform bereitstellen, auf der Käufer und Verkäufer nahtlos miteinander in Kontakt treten, handeln und erfolgreich sein können. Unser Ziel ist es, sowohl Käufer als auch Verkäufer zu unterstützen, indem wir eine breite Palette von Rohstoffen anbieten, einfache Transaktionen ermöglichen und eine dynamische Marktplatzumgebung fördern. Unser Ziel ist es, den Beschaffungsprozess für Käufer zu rationalisieren, indem wir ihnen den Zugang zu Millionen von Produkten mit Leichtigkeit und Effizienz ermöglichen.",
        "Begleiten Sie uns auf unserem Weg, die Landschaft des Rohstoffhandels neu zu gestalten und unendliche Möglichkeiten auf dem Markt für Nahrungsergänzungsmittel zu erschließen.",
      ],
      imageUrl: "image here",
    },
  ];

  return (
    <div id="scroll-down" className="aboutusdetail-container container">
      {contentList?.map((content, index) => (
        <div
          key={index}
          className={` d-flex aboutus-content justify-content-between justify-content-md-center flex-column-reverse align-items-center ${
            index % 2 != 0 ? "flex-md-row-reverse" : "flex-md-row "
          }`}
        >
          <div className=" col-md-5 me-0">
            <h2 className="aboutus-title">{content.title}</h2>
            <p className="aboutus-description">{content.description[0]}</p>
            <p className="aboutus-description">{content.description[1]}</p>
          </div>
          <div className="col-md-5 ms-0 mb-3 mb-sm-5 mb-md-0 mt-3 mt-md-0">
            <div className="aboutus-img">
              <p>{content.imageUrl}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AboutUsDetail;
