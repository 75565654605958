import React, {useState} from 'react';
import './style.css';
import Banner from './Banner';
import PricingPlan from './PricingPlan';
import SellerPricingPlan from './SellerPricingPlan';

function MembershipPageSeller() {
  const [pricingtype, setPricingTYe] = useState(0);

  return (
    <div className='top100'>
      <div className=''>
        <div className=''>
         <Banner setPricingTYe={setPricingTYe}/>
         <SellerPricingPlan pricingtype={pricingtype}/>
        </div>
      </div>
    </div>
  )
}

export default MembershipPageSeller;