import React from "react";
import { images } from "../../Constants";

function Certificate({ product }) {
  let certificate = product?.seller?.user_certificate?.split(",");

  if (!certificate) {
    return <div></div>;
  }

  return (
    <div>
      <div className="product_c-flexItems d-flex justify-content-start justify-content-sm-end justify-content-md-start  flex-grow-1 align-items-center">
        <div className="product_c-flexItem">
          <img src={images.rsProductCertificate} alt="product-certificate" />
        </div>
        <div className="d-flex flex-column ps-3 ps-md-1 ps-lg-3">
          <div className="product_c-data">
            <h3>Zertifizierungen</h3>
          </div>
          {certificate?.map((productCertificate, index) => (
            <div key={index} className="product_c-data">
              <p>{productCertificate}</p>
            </div>
          ))}
        </div>
      </div>{" "}
    </div>
  );
}

export default Certificate;
