import React, { useState } from "react";
// import { useLocale, useTranslation } from "@psyycker/react-translation";

function Banner({ ...props }) {
  const [activeButton, setActiveButton] = useState(0);

  const handleButtonClick = (index) => {
    setActiveButton(index);
    props.setPricingTYe(index);
  };

  //Get Language Translation starts
  // const { getTranslation } = useTranslation();
  // const [locale, setLocale] = useLocale();
  // const onButtonClick = () => {
  //   var lang = document.getElementById("lang").value;
  //   var selectedx = "You have selected";
  //   if (lang == "English") {
  //     setLocale("en-US");
  //   }

  //   if (lang == "Germany") {
  //     setLocale("german");
  //   }

  //   if (lang == "Dutch") {
  //     setLocale("de");
  //   }

  //   if (lang == "French") {
  //     setLocale("fr-FR");
  //   }
  // };
  //Get Language Translation ends

  return (
    <div className="membership-banner container-fluid">
      <div className="membership-banner-content">
        <p className="membership">Membership</p>
        <div className="membership-plans">Membership Plan</div>
        <p className="membership-simple">
          {/* Simple Transparent pricing that grows with you. */}
          Melden Sie sich jetzt für den Membership Plan an und erleben Sie,{" "}
          <br></br>wie Rawstuff Deals Ihren Rohstoffhandel einfacher, sicherer
          und profitabler macht.
        </p>
        <div className="billing-button">
          <button
            type="button"
            className={`btn monthly-billing ${
              activeButton === 0 ? "bg-white" : ""
            }`}
            onClick={() => handleButtonClick(0)}
          >
            Monatliche Abrechnung
          </button>
          <button
            type="button"
            className={`btn annual-billing ${
              activeButton === 1 ? "bg-white" : ""
            }`}
            onClick={() => handleButtonClick(1)}
          >
            Jährliche Abrechnung
          </button>
        </div>
      </div>
    </div>
  );
}

export default Banner;
