import React, { useEffect, useState } from "react";
import "../MainHero/HeroSection/HeroSection.css";
import AlgoSearch from "../SearchEngine/AlgoSearch";
import { Link } from "react-router-dom";
import { useTranslation } from "@psyycker/react-translation";
import { useSelector } from "react-redux";
import "./UpdatedMarketPlace/Category.css";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const HeroSection = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  let [userSignIn, updateUserSingIn] = useState(
    localStorage.getItem("token") ? true : false
  );

  let Token = useSelector((state) => state.userAuth?.token);
  // useeffect to make true if the user is logged in else false setIsLoggedIn
  useEffect(() => {
    if (Token) {
      // User is logged in
      setIsLoggedIn(true);
    } else {
      // User is not logged in
      setIsLoggedIn(false);
    }
  }, [Token]);

  //Get Language Translation starts
  const { getTranslation } = useTranslation();

  return (
    <div className="hero-section-container h-100 py-4 py-sm-1">
      <div className="container-fluid ">
        <div className="hero-section-flexContainer bg-primaryy">
          {!userSignIn && (
            <div className="hero-section-welcome">
              <h1> Willkommen zu Rawstuff Deals. </h1>
            </div>
          )}
          <div className="hero-section-catagories-serach">
            {userSignIn && (
              <div className="hero-section-catagories hover-text">Produkt</div>
            )}
            <div className="search-engine w-100">
              <AlgoSearch />
            </div>
          </div>

          {!userSignIn && (
            <div className="btn-login-container">
              <Link to="/login">
                <button
                  type="button"
                  onClick={() => {
                    updateUserSingIn(true);
                  }}
                  className="btn-login-txt"
                >
                  Einloggen
                </button>
              </Link>
            </div>
          )}
          {isLoggedIn ? (
            <div className="have-no-account bg-white p-3 text-center">
              <div className="p-2">
                <div className="connectrawstuff-connect p-3">
                  <div>
                    Connect - Rawstuff Deals finden den richtigen Anbieter für
                    Sie.
                  </div>
                  Erstellen Sie eine Anfrage und erhalten Sie die Anbieter
                  zeitnah.
                </div>
                <Link to="" className="link-connect">
                  <button className="connect-button" onClick={handleShow}>
                    Eine Anfrage stellen
                  </button>
                  <PopUpModal show={show} handleClose={handleClose} />
                </Link>
              </div>
            </div>
          ) : (
            <div className="have-no-account">
              <p>
                Noch nicht angemeldet?
                <Link to="/register">Jetzt Registrieren</Link>
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeroSection;

function PopUpModal({ show, handleClose }) {
  const handlesubmit = () => {
    window.location.href = "mailto:info@rawstuff.deals";
    setTimeout(() => {
      handleClose();
    }, 3000);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        style={{
          top: "30%",
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Rawstuff</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Contact Rawstuff for creating a request</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handlesubmit}>
            Email Us
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
