import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  hit: [],
  isLoading: false,
  error: null,
};

const AlgoliaSlice = createSlice({
  name: "algolia",
  initialState,
  reducers: {
    setHit: (state, action) => {
      console.log("algo", state);
      state.hit = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { setHit, setLoading, setError } = AlgoliaSlice.actions;
export default AlgoliaSlice.reducer;
