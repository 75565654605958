import React, { useEffect, useState } from "react";
import "./OrderTrackingStatus.css";

const OrderTrackingStatus = ({ order, cancelOrder }) => {
//   const [progress, setProgress] = useState(0);

//   useEffect(() => {
//     setProgress(
//       order.status === "Completed" ? 1 : order.status === "Shipped" ? 0.5 : 0
//     );
//   }, [order.status]);

  return (
    <div className="order-tracking-status">
      <div className="order-tracking-status__steps">
        <div
          key="1"
          className={`order-tracking-status__step ${
            order.status === "pending" ? "active" : ""
          }`}
        >
          Bestellung in Bearbeitung
        </div>
        <div
          key="2"
          className={`order-tracking-status__step ${
            order.status === "on_delivery" ? "active" : ""
          }`}
        >
          Bestellung versandt
        </div>
        <div
          key="3"
          className={`order-tracking-status__step ${
            order.status === "delivered" ? "active" : ""
          }`}
        >
          Bestellung geliefert
        </div>
        <div
          key="4"
          className={`order-tracking-status__step ${
            order.status === "canceled" ? "active" : ""
          }`}
        >
          Auftrag storniert
        </div>
      </div>
      {cancelOrder}
    </div>
  );
};

export default OrderTrackingStatus;
