import React from "react";
import "./ShippingOptions.css";

const ShippingOptions = () => {
  return (
    <>
      <div className="border p-3">
        <div className="shipping-method-wrapper">
          <div className="txt-style mt-1 mb-3">Shipping method</div>
          <div className="shippingOptions-txt">
            <input
              className="input-radio-active"
              type="radio"
              name="freeshipping"
              value=""
              checked="checked"
            />
            <span className="ms-1">
              <label htmlFor="freeshipping">Free Shipping</label>
            </span>
            <p className="shippingOptionsd-description-txt ms-3">
              (10 to 15 days)
            </p>
          </div>
          <div className="shippingOptions-txt">
            <input
              className="input-radio-active"
              type="radio"
              name="freeshipping"
              value=""
            />
            <span className="ms-1">
              <label htmlFor="freeshipping">Express Shipping + $8.9</label>
            </span>
            <p className="shippingOptionsd-description-txt ms-3">
              (5 to 7 days)
            </p>
          </div>
        </div>
        <div className="shipping-packaging-container border-bottom">
          <div className="txt-style mt-1 mb-3">Packaging</div>
          <div className="shippingOptions-txt">
            <input
              className="input-radio-active"
              type="radio"
              name="freeshipping"
              value=""
            />
            <span className="ms-1">
              <label htmlFor="freeshipping">Bulk</label>
            </span>
            <p className="shippingOptionsd-description-txt ms-3">
              Bulk product pakcaging
            </p>
          </div>
          <div className="shippingOptions-txt">
            <input
              className="input-radio-active"
              type="radio"
              name="freeshipping"
              value=""
            />
            <span className="ms-1">
              <label htmlFor="freeshipping">Individual Units+ $13.35</label>
            </span>
            <p className="shippingOptionsd-description-txt ms-3">
              Individual units product pakcaging
            </p>
          </div>
          <div className="shippingOptions-txt">
            <input
              className="input-radio-active"
              type="radio"
              name="freeshipping"
              value=""
            />
            <span className="ms-1">
              <label htmlFor="freeshipping">Retail Ready + $100</label>
            </span>
            <p className="shippingOptionsd-description-txt ms-3">
              Retail ready products
            </p>
          </div>
        </div>
        <div className="d-flex justify-content-between mt-2">
          <div>finalPrice</div>
          <div>final value</div>
        </div>
      </div>
    </>
  );
};

export default ShippingOptions;
