import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define an initial state
const initialState = {
  order: null,
  status: 'pending',
  error: null,
};

// Define the asynchronous thunk for handling the checkout process
export const handleCheckout = createAsyncThunk('checkout/handleCheckout', async (_, { getState }) => {
  try {
    const state = getState();
    // console.log("tokentest", state.userAuth.token);
    const newtoken = state.userAuth.token; 
    const authToken = JSON.parse(newtoken);

    

    // Assuming you have auth slice with newtoken 
// console.log("token",newtoken);
// console.log("tokenparse", authToken.token);
    const apiUrl = 'https://api.rawstuff.deals/api/v1/orders/createOrder';
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append("Authorization", `Bearer ${authToken.token}`);

    const raw = {
      method: 'stripe',
      payment_status: 'pending',
      order_note: 'Order from user',
    };
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(raw),
      redirect: 'follow',
    };
    const response = await fetch(apiUrl, requestOptions);
    const data = await response.json();
    localStorage.setItem("order_id", data.data.id);
    // console.log("create order", data.data?.id)

    return data.data;
  } catch (error) {
    throw error;
  }
});

// Create a Redux slice
const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(handleCheckout.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(handleCheckout.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.order = action.payload;
      })
      .addCase(handleCheckout.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

// Export the asynchronous thunk for use in components
// export { handleCheckout };
// Export the reducer for use in the Redux store

export default checkoutSlice.reducer;