import React from "react";
import Categories from "../Categories";
import FreeSpaceForAdvert from "../FreeSpaceForAdvert";
import Trending from "./Trending";
import MarketPlaceFooter from "../MarketPlaceFooter";
import BestSoldProduct from "./BestSoldproduct";
import TopRated from "./TopRated";
import LatestUpload from "./LatestUpload";
import HeroSection from "../NewHero";

function UpdatedMarketPlace() {
  return (
    <div className="top100">
      <div>
        <HeroSection />
      </div>
      <div className="mt-5 w-90">
        <Categories />
      </div>
      <div className="mt-5 d-none d-md-block">
        <FreeSpaceForAdvert />
      </div>
      <div className="mt-5">
        <Trending />
      </div>
      <div className="mt-5">
        <BestSoldProduct />
      </div>
      <div className="mt-5">
        <TopRated />
      </div>
      <div className="mt-5">
        <LatestUpload />
      </div>
      <div className="d-nonee">
        <MarketPlaceFooter />
      </div>
    </div>
  );
}

export default UpdatedMarketPlace;
