import React, { useState, useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useDispatch, useSelector } from "react-redux";
import { addToCarts } from "../../redux/Slice/ShoppingCartSlice";
import { addToWishList } from "../../redux/Slice/WishListSlice";
import "../MarketPlace/UpdatedMarketPlace/NewStyle.css";
import { ProductCard } from "../MarketPlace/UpdatedMarketPlace/ProductCard";

import { toast } from "react-toastify";

function LastSeen() {
  const [trendingobj1, setTrendingObj1] = useState([]);
  const [loading, setLoading] = useState(false);
  let [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(2);
  const [startofpage, setstartofpage] = useState(true);
  let Token = useSelector((state) => state.userAuth?.token);
  const dispatch = useDispatch();
  const handleAddToCart = (trend) => {
    dispatch(addToCarts(trend.id));
    if (Token && Token) {
      toast.success("Produkt erfolgreich in den Warenkorb gelegt");
    }
  };

  const handleAddToWishList = (trend) => {
    dispatch(addToWishList(trend.id));
  };

  useEffect(() => {
    // Fetch order data from the backend API
    const fetchTrendingProducts = async () => {
      setLoading(true);
      try {
        const url =
          "https://api.rawstuff.deals/api/v1/products/getTrendingProducts";

        const response = await fetch(url, {
          headers: {
            Accept: "application/json",
          },
        });
        const data = await response.json();
        setTrendingObj1(data.data);
        setLoading(false);
      } catch (error) {
        console.log(error.response.data.message);
        throw toast.error(error.response.data.message);
      
      }
    };
    fetchTrendingProducts();
  }, []);

  // previus product
  const previous = () => {
    if (currentPage > 1) {
      if (currentPage === 2) {
        setstartofpage(true);
      } else {
        setstartofpage(false);
      }
      setCurrentPage(currentPage - 1);
    } else {
      setstartofpage(true);
    }
  };

  // next product
  const next = () => {
    if (currentPage < trendingobj1.length) {
      setCurrentPage(currentPage + 1);
      setstartofpage(false);
    }
  };

  // Get current posts
  const indexOfLastPost = currentPage + postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = trendingobj1.slice(indexOfFirstPost, indexOfLastPost);

  return (
    <div className="w-90">
      <div className="">
        <div className="trending-product container-fluid mb-2">
        Zuletzt hochgeladenes Produkt
        </div>
        <div>
          {loading ? (
            <h3>Laden...</h3>
          ) : (
            <ProductCard
              currentPosts={currentPosts}
              handleAddToCart={handleAddToCart}
              handleAddToWishList={handleAddToWishList}
            />
          )}
          {trendingobj1.length > 2 && (
            <div className="d-flex justify-content-between buttons container-fluid">
              <div
                className={`${startofpage ? "startofpage" : "button-previous"}`}
                onClick={previous}
              >
                <ArrowBackIcon />
                <div>Vorherige</div>
              </div>
              <div class="button-next" onClick={next}>
                <div>Weiter</div>
                <ArrowForwardIcon />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default LastSeen;
