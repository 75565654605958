import React, { useEffect, useRef, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { images } from "../../Constants";
import "./NavBar.css";
import AlgoSearch from "../SearchEngine/AlgoSearch";
import { Link } from "react-router-dom";
import ShoppingCart from "../ShoppingCart/ShoppingCart";
import { useSelector } from "react-redux";
import LogIn from "../Regstration/LogIn";
import { useLocale, useTranslation } from "@psyycker/react-translation";

const NavBar = () => {
  // global cart state
  const getCart = useSelector((state) => state.getCart);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // get user ;
  let profileData = localStorage.getItem("token");
  let parseProfiledata = JSON.parse(profileData);
  let Token = useSelector((state) => state.userAuth.token);
  let newtoken = JSON.parse(Token);
  // console.log(newtoken.user.name);

  let [userSignIn, updateUserSingIn] = useState(
    localStorage.getItem("token") ? true : false
  );

  var jsonString = localStorage.getItem("token");
  var jsonObject = JSON.parse(jsonString);
  var token = jsonObject?.token; // Replace 'token' with your token key
  var role = jsonObject?.role;
  if (role === "admin" || role === "seller") {
    var href = `https://api.rawstuff.deals/products`;
  } else {
    var href = "/dashboard";
  }
  if (role === "admin" || role === "seller") {
    var hrefProfile = `https://api.rawstuff.deals/users/getProfile`;
  } else {
    var hrefProfile = "/dashboard/profile";
  }
  if (role === "admin" || role === "seller") {
    var hrefProfileEdit = `https://api.rawstuff.deals/users/getProfile`;
  } else {
    var hrefProfileEdit = "/dashboard/edit-profile";
  }

  // use ref for nab bar
  const navItemRef = useRef();
  const mobiledashboard_items = useRef();

  // togglable menu
  const toggleActive = () => {
    navItemRef.current.classList.toggle("active");
    mobiledashboard_items.current.classList.remove("active");
  };

  // handle close
  const HandlerClose = () => {
    navItemRef.current.classList.remove("active");
  };
  //  handl mobile dashboard
  const HandlerMobileDashboard = () => {
    mobiledashboard_items.current.classList.toggle("active");
    navItemRef.current.classList.remove("active");
  };
  // remove active class conditionally
  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window?.innerWidth > 992) {
        navItemRef?.current?.classList?.remove("active");
        mobiledashboard_items?.current?.classList?.remove("active");
      }
      // console.log(window.innerWidth>992);
    });
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      // console.log(scrollY);
      if (scrollY > 294) {
        setShowSearchBar(true);
      } else {
        setShowSearchBar(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  useEffect(() => {
    if (Token) {
      // User is logged in
      setIsLoggedIn(true);
    } else {
      // User is not logged in
      setIsLoggedIn(false);
    }
  }, []);
  //Get Language Translation starts
  const { getTranslation } = useTranslation();
  const [locale, setLocale] = useLocale();
  const onButtonClick = () => {
    var lang = document.getElementById("lang").value;
    var selectedx = "You have selected";
    if (lang == "English") {
      setLocale("en-US");
    }

    if (lang == "Germany") {
      setLocale("german");
    }

    if (lang == "Dutch") {
      setLocale("de");
    }

    if (lang == "French") {
      setLocale("fr-FR");
    }
  };

  //Get Language Translation ends

  return (
    <div className="rawstuff__navbar-container">
      <nav className="d-none d-lg-block">
        <div className="rawstuff__uper-nav d-flex justify-content-between">
          <div className="rawstuff__navbar-logo">
            <a href="/">
              <img src={images.logo_rawstuff} alt="logo_rawstuff" />
            </a>
          </div>
          {isLoggedIn ? (
            <div className="rawStuff_leftsideNavwithoutlogin d-flex">
              <div className="pt-2 pb-2 pe-3">
                <li>
                  <img
                    className="pe-1"
                    src={images.pinLocation}
                    alt="location-icon"
                  />

                  <Link to="/dashboard/ordertracking">
                    {/* {getTranslation({ translationKey: "TrackOrder" })} */}
                    Auftrag verfolgen
                  </Link>
                </li>
              </div>
              <div className="pt-2 pb-2 pe-3">
                <li>

                  <div id="google_translate_element"></div>
                </li>
              </div>
              <div className="rawstuff__navbar-iconlinks">
                <ul className="d-flex pt-2 pb-2 pe-5 ps-3">
                  <li>
                    <a href="/wishlist">
                      <img src={images.romanceHeart} alt="romance-Heart" />
                    </a>
                  </li>
                  <li>
                    {token ? (
                      <Link to="/shoppingcart">
                        <img src={images.basket} alt="shopping-basket" />
                        <ShoppingCart />
                      </Link>
                    ) : (
                      <Link to="/login">
                        <img src={images.basket} alt="shopping-basket" />
                        <ShoppingCart />
                      </Link>
                    )}
                  </li>
                </ul>
              </div>
              <div className="rawstuff__navbar-logIn-db-btn  ">
                {/* <div className="userProfile-title mt-1">
                {isLoggedIn ? "Profile" : "Log In"}
                Guest
                Dashboard
              </div>
              <div className="ms-1  ">
                <img src={images.downArrow} alt="down-arrow" />
              </div> */}
                <Dropdown>
                  <Dropdown.Toggle>
                    <b>Hi</b>&nbsp; {parseProfiledata.user?.name}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href={href}>
                      <img className="pe-3" src={images.menu} alt="profile" />
                      {/* {getTranslation({ translationKey: "Visit" })}{" "}
                      {getTranslation({ translationKey: "Dashboard" })} */}
                      Dashboard
                    </Dropdown.Item>

                    <Dropdown.Item href={hrefProfile}>
                      <img
                        className="pe-3"
                        src={images.ProfileSvg}
                        alt="profile"
                      />
                      {/* {getTranslation({ translationKey: "Profile" })} */}
                      Profil
                    </Dropdown.Item>
                    {/* <Dropdown.Item href="javascript:alert('This is a Dependency for subsequent sprint')">
                      <img className="pe-3" src={images.sales} alt="sales" />
                      Sales
                    </Dropdown.Item> */}
                    {/* <Dropdown.Item href="javascript:alert('This is a Dependency for subsequent sprint')">
                      <img
                        className="pe-3"
                        src={images.invoice}
                        alt="supports"
                      />
                      Invoice
                    </Dropdown.Item> */}
                    <Dropdown.Item href={hrefProfileEdit}>
                      <img
                        className="pe-3"
                        src={images.companySetting}
                        alt="Edit Profile"
                      />
                      Profil bearbeiten
                    </Dropdown.Item>

                    <Dropdown.Item
                      className="nav-logout"
                      href="#/action-3"
                      style={{ background: "#ffffff" }}
                    >
                      {userSignIn && (
                        <small className=" text-center">
                          <a
                            className="dropdown-logOut"
                            href="/"
                            onClick={() => {
                              localStorage.removeItem("token");
                              updateUserSingIn(false);
                            }}
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              color: "#18233e",
                              fontsize: "16px",
                              fontfamily: " var(--font-base)",
                              lineheight: "22px",
                              letterspacing: "-0.1px",
                              fontweight: "600 !important",
                            }}
                          >
                            <div>
                              <img
                                className="pe-3"
                                src={images.logOut}
                                alt="supports"
                              />
                            </div>
                            <div>Ausloggen</div>
                          </a>
                        </small>
                      )}
                      {!userSignIn && (
                        <div>
                          <Link
                            to="/login"
                            type="button"
                            onClick={() => {
                              updateUserSingIn(true);
                            }}
                          >
                            {/* {getTranslation({ translationKey: "Login" })} */}
                            Einloggen
                          </Link>
                        </div>
                      )}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          ) : (
            <div className="rawStuff_languageTranslation d-flex">
              <div className="pt-2 pb-2 px-4">
                <li>
                  <img
                    className="pe-1"
                    src={images.languageTranslation}
                    alt="lang-icon"
                  />
                  <div class='search-box'>
                    <form class="search-form">
                      <div id="google_translate_element"></div>
                    </form>
                  </div>

                  {/*<select
                    id="lang"
                    className="language"
                    onChange={onButtonClick}
                  >
                    <option>Deutsch</option>
                    <option>Englisch</option>
                    <option>Niederländisch</option>
                    <option>Französisch</option>
                  </select>
                  <span className="ps-1">
                    <img src={images.downArrow} alt="downArrow-icon" />
                  </span> */}
                </li>
              </div>
              <Link to="/login">
                <div className="rawstuff__navbar-logIn-db-btn ">
                  <div className="me-1">
                    <img src={images.userIcon} alt="user-profile" />
                  </div>
                  <div className="login_db-title mt-1">
                    {/* {getTranslation({ translationKey: "Login" })} */}
                    Einloggen
                  </div>
                </div>
              </Link>
            </div>
          )}
        </div>
        <div className="rawstuff__lower-nav">
          <ul className="d-flex rawstuff_lowerNav-list-items">
            {/* <li className="rawstuff_lowerNav-list-item">
              <a href="javascript:alert('This is a Dependency for subsequent sprint')">
                <img src={images.menu} alt="menu-icon" />{" "}
                {getTranslation({ translationKey: "Category" })}
              </a>
            </li> */}
            <li className="rawstuff_lowerNav-list-item">
              <Link to="/marketplace">
                {getTranslation({ translationKey: "MarketPlace" })}
              </Link>
            </li>
            <li className="rawstuff_lowerNav-list-item">
              <Link to="/membership">
                {" "}
                {getTranslation({ translationKey: "Membership" })}
              </Link>
            </li>
            <li className="rawstuff_lowerNav-list-item">
              <a href="aboutus">
                {/* {getTranslation({ translationKey: "AboutUs" })} */}
                Über uns
              </a>
            </li>
            <li className="rawstuff_lowerNav-list-item">
              <a href="/contactme">
                {/* {getTranslation({ translationKey: "Contact" })} */}
                Kontakt
              </a>
            </li>
            <li className="rawstuff_lowerNav-list-item ">
              <a href="/support">
                {getTranslation({ translationKey: "Support" })}
              </a>
            </li>
            <li className=" rawstuff_lowerNav-list-item ">
              <div
                className={`search-engine search-onheader ${showSearchBar ? "show" : "hide"
                  }`}
              >
                <AlgoSearch />
              </div>
            </li>
          </ul>
        </div>
      </nav>
      {/* mobile navbar */}
      {isLoggedIn ? (
        <section className="mobile-nav-section">
          <nav className="mobile-nabbar-container d-flex justify-content-around align-items-center d-lg-none">
            <div
              className=" humburger-menu mt-3"
              aria-controls="mobile-list-items"
              aria-expanded="false"
              onClick={toggleActive}
            >
              <img src={images.humburger_menu} alt="humburger-menu" />
            </div>
            <div className="res-logo-container mt-3">
              <Link to="/">
                <img src={images.logo_rawstuff} alt="logo" />
              </Link>
            </div>
            <div className="d-flex">
              <div className="rawstuff__navbar-iconlinks">
                <ul className="d-flex pt-2 pb-2 pe-4   pe-sm-5 ps-2 ps-md-3">
                  <li>
                    <a href="/wishlist">
                      <img src={images.romanceHeart} alt="romance-Heart" />
                    </a>
                  </li>
                  <li>
                    {token ? (
                      <Link to="/shoppingcart">
                        <img src={images.basket} alt="shopping-basket" />
                        <ShoppingCart />
                      </Link>
                    ) : (
                      <Link to="/login">
                        <img src={images.basket} alt="shopping-basket" />
                        <ShoppingCart />
                      </Link>
                    )}
                  </li>
                </ul>
              </div>
              <div
                className="dashboard-Icon mt-2"
                onClick={HandlerMobileDashboard}
              >
                <img src={images.dashboard_Icon} alt="dashboard-icon" />
              </div>
            </div>
          </nav>
          <section
            id="mobile-mennu-lists"
            className="mobile-menu-lists d-block d-lg-none"
          >
            <div
              ref={navItemRef}
              className="mobile-list-items px-4 py-2 bg-light"
              data-visible="false"
            >
              <div className="d-flex justify-content-between py-3">
                <div>MENU</div>
                <div onClick={HandlerClose}>
                  <img src={images.close_Icon} alt="close-icon" />
                </div>
              </div>
              <Link to="/shoppingcart" onClick={HandlerClose}>
                <div className="mobile-cart-contianer d-flex justify-content-between  py-3">
                  <div className=" cart-contianer">
                    <img src={images.basket} alt="basket" />{" "}
                    <span className="ps-2">wagen</span>
                  </div>
                  <div>
                    {!getCart.cartItemsget.total_distinct_quantity
                      ? 0
                      : getCart.cartItemsget.total_distinct_quantity}
                  </div>
                </div>
              </Link>
              <Link to="/wishlist" onClick={HandlerClose}>
                <div className="mobile-cart-contianer d-flex justify-content-between  py-3">
                  <div className="cart-contianer">
                    <img src={images.romanceHeart} alt="favorite" />
                    <span className="ps-2">Wunschzettel</span>
                  </div>
                  {/* <div>1000+</div> */}
                </div>
              </Link>
              <Link to="/dashboard/ordertracking" onClick={HandlerClose}>
                <div className=" mobile-cart-contianer py-3">
                  <img src={images.pinLocation} alt="order" />
                  <span className="ps-2">Auftrag verfolgen</span>
                </div>
              </Link>
              <div className=" py-3">
                <img
                  className="pe-1"
                  src={images.languageTranslation}
                  alt="lang-icon"
                />
                <div id="google_translate_element"></div>
                {/* <select
                  className="ps-2 language"
                  id="lang"
                  onChange={onButtonClick}
                >
                  <option>Englisch</option>
                  <option>Niederländisch</option>
                  <option>Deutsch</option>
                  <option>Französisch</option>
                </select>
               <span className="ps-1">
                    <img src={images.downArrow} alt="downArrow-icon" />
                  </span> */}
              </div>
              <div className="rawstuff__lower-nav border-top">
                <ul className="d-flex flex-column rawstuff_lowerNav-list-items">
                  {/* <li className="rawstuff_lowerNav-list-item">
                  <a href="javascript:alert('This is a Dependency for subsequent sprint')">
                    <img src={images.menu} alt="menu-icon" />{" "}
                    {getTranslation({ translationKey: "Category" })}
                  </a>
                </li> */}
                  <li
                    className="rawstuff_lowerNav-list-item  py-3"
                    onClick={HandlerClose}
                  >
                    <Link to="/marketplace">
                      {getTranslation({ translationKey: "MarketPlace" })}
                    </Link>
                  </li>
                  <li
                    className="rawstuff_lowerNav-list-item  py-3"
                    onClick={HandlerClose}
                  >
                    <Link to="/membership">
                      {" "}
                      {getTranslation({ translationKey: "Membership" })}
                    </Link>
                  </li>
                  <li
                    className="rawstuff_lowerNav-list-item  py-3"
                    onClick={HandlerClose}
                  >
                    <a href="aboutus">
                      {/* {getTranslation({ translationKey: "AboutUs" })} */}
                      Über uns
                    </a>
                  </li>
                  <li
                    className="rawstuff_lowerNav-list-item  py-3"
                    onClick={HandlerClose}
                  >
                    <a href="/contactme">
                      {/* {getTranslation({ translationKey: "Contact" })} */}
                      Kontakt
                    </a>
                  </li>
                  <li
                    className="rawstuff_lowerNav-list-item  py-3"
                    onClick={HandlerClose}
                  >
                    <a href="/support">
                      {getTranslation({ translationKey: "Support" })}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section className="mobile-dashboard-list d-block d-lg-none">
            <ul ref={mobiledashboard_items} className="mobile-dashboard-items">
              <li>
                <a href={href}>
                  <img className="pe-3" src={images.menu} alt="menu" />

                  <span>Dashboard</span>
                </a>
              </li>
              <li>
                <a href={hrefProfile}>
                  <img className="pe-3" src={images.ProfileSvg} alt="profile" />
                  <span>Profil</span>
                </a>
              </li>
              {/* <li>
                <a href="">
                  <img className="pe-3" src={images.sales} alt="sales" />

                  <span> Sales</span>
                </a>
              </li> */}
              {/* <li>
                <a href="">
                  <img className="pe-3" src={images.invoice} alt="Invoice" />

                  <span>Invoice</span>
                </a>
              </li> */}
              <li>
                <a href={hrefProfileEdit}>
                  <img
                    className="pe-3"
                    src={images.companySetting}
                    alt="companySetting"
                  />
                  <span>Profil bearbeiten</span>
                </a>
              </li>
              <li>
                {userSignIn && (
                  <small className=" text-center">
                    <a
                      className="dropdown-logOut"
                      href="/"
                      onClick={() => {
                        localStorage.removeItem("token");
                        updateUserSingIn(false);
                      }}
                    >
                      <img
                        className="pe-3"
                        src={images.logOut}
                        alt="supports"
                      />

                      <span>Ausloggen</span>
                    </a>
                  </small>
                )}
              </li>
              <li>
                {!userSignIn && (
                  <small className=" text-center">
                    <Link
                      className="dropdown-logOut"
                      to="/login"
                      onClick={() => {
                        updateUserSingIn(true);
                      }}
                    >
                      <img className="pe-3" src={images.login} alt="supports" />

                      <span>
                        {" "}
                        {/* {getTranslation({ translationKey: "Login" })} */}
                        Einloggen
                      </span>
                    </Link>
                  </small>
                )}
              </li>
            </ul>
          </section>
        </section>
      ) : (
        <section className="mobile-nav-section">
          <nav className="mobile-nabbar-container d-flex justify-content-around d-lg-none">
            <div
              className="humburger-menu mt-3"
              aria-controls="mobile-list-items"
              aria-expanded="false"
              onClick={toggleActive}
            >
              <img src={images.humburger_menu} alt="humburger-menu" />
            </div>
            <div className="res-logo-container mt-3">
              <Link to="/">
                <img src={images.logo_rawstuff} alt="logo" />
              </Link>
            </div>
            <div className="d-flex">
              <Link to="/login">
                <div className="rawstuff__navbar-logIn-db-btn ">
                  <div className="me-1">
                    <img src={images.userIcon} alt="user-profile" />
                  </div>
                  <div className="login_db-title mt-1">
                    {/* {getTranslation({ translationKey: "Login" })} */}
                    Einloggen
                  </div>
                </div>
              </Link>
            </div>
          </nav>
          <section
            id="mobile-mennu-lists"
            className="mobile-menu-lists d-block d-lg-none"
          >
            <div
              ref={navItemRef}
              className="mobile-list-items px-4 py-2 bg-light"
              data-visible="false"
            >
              <div className="d-flex justify-content-between py-3">
                <div>MENÜ</div>
                <div onClick={HandlerClose}>
                  <img src={images.close_Icon} alt="close-icon" />
                </div>
              </div>
              <div className=" py-3">
                <img
                  className="pe-1"
                  src={images.languageTranslation}
                  alt="lang-icon"
                />

                <div id="google_translate_element"></div>
                {/* <select
                  className="ps-2 language"
                  id="lang"
                  onChange={onButtonClick}
                >
                  <option>Deutsch</option>
                  <option>Englisch</option>
                  <option>Niederländisch</option>
                  <option>Französisch</option>
                </select>
               <span className="ps-1">
                    <img src={images.downArrow} alt="downArrow-icon" />
                  </span> */}
              </div>
              <div className="rawstuff__lower-nav border-top">
                <ul className="d-flex flex-column rawstuff_lowerNav-list-items">
                  {/* <li className="rawstuff_lowerNav-list-item">
                  <a href="javascript:alert('This is a Dependency for subsequent sprint')">
                    <img src={images.menu} alt="menu-icon" />{" "}
                    {getTranslation({ translationKey: "Category" })}
                  </a>
                </li> */}
                  <li
                    className="rawstuff_lowerNav-list-item  py-3"
                    onClick={HandlerClose}
                  >
                    <Link to="/marketplace">
                      {getTranslation({ translationKey: "MarketPlace" })}
                    </Link>
                  </li>
                  <li
                    className="rawstuff_lowerNav-list-item  py-3"
                    onClick={HandlerClose}
                  >
                    <Link to="/membership">
                      {" "}
                      {getTranslation({ translationKey: "Membership" })}
                    </Link>
                  </li>
                  <li
                    className="rawstuff_lowerNav-list-item  py-3"
                    onClick={HandlerClose}
                  >
                    <a href="aboutus">
                      {/* {getTranslation({ translationKey: "AboutUs" })} */}
                      Über uns
                    </a>
                  </li>
                  <li
                    className="rawstuff_lowerNav-list-item  py-3"
                    onClick={HandlerClose}
                  >
                    <a href="/contactme">
                      {/* {getTranslation({ translationKey: "Contact" })} */}
                      Kontakt
                    </a>
                  </li>
                  <li
                    className="rawstuff_lowerNav-list-item  py-3"
                    onClick={HandlerClose}
                  >
                    <a href="/support">
                      {getTranslation({ translationKey: "Support" })}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section className="mobile-dashboard-list d-block d-lg-none">
            <ul ref={mobiledashboard_items} className="mobile-dashboard-items">
              <li>
                <a href={href}>
                  <img className="pe-3" src={images.menu} alt="menu" />

                  <span>Dashboard </span>
                </a>
              </li>
              <li>
                <a href={hrefProfile}>
                  <img className="pe-3" src={images.ProfileSvg} alt="profile" />
                  <span>Profil</span>
                </a>
              </li>
              {/* <li>
                <a href="">
                  <img className="pe-3" src={images.sales} alt="sales" />

                  <span> Sales</span>
                </a>
              </li> */}
              {/* <li>
                <a href="">
                  <img className="pe-3" src={images.invoice} alt="Invoice" />

                  <span>Invoice</span>
                </a>
              </li> */}
              <li>
                <a href={hrefProfileEdit}>
                  <img
                    className="pe-3"
                    src={images.companySetting}
                    alt="companySetting"
                  />
                  <span>Profil bearbeiten</span>
                </a>
              </li>
              <li>
                {userSignIn && (
                  <small className=" text-center">
                    <a
                      className="dropdown-logOut"
                      href="/"
                      onClick={() => {
                        localStorage.removeItem("token");
                        updateUserSingIn(false);
                      }}
                    >
                      <img
                        className="pe-3"
                        src={images.logOut}
                        alt="supports"
                      />

                      <span>Ausloggen</span>
                    </a>
                  </small>
                )}
              </li>
              <li>
                {!userSignIn && (
                  <small className=" text-center">
                    <Link
                      className="dropdown-logOut"
                      to="/login"
                      onClick={() => {
                        updateUserSingIn(true);
                      }}
                    >
                      <img className="pe-3" src={images.login} alt="login" />

                      <span>
                        {" "}
                        {/* {getTranslation({ translationKey: "Login" })} */}
                        Einloggen
                      </span>
                    </Link>
                  </small>
                )}
              </li>
            </ul>
          </section>
        </section>
      )}
    </div>
  );
};

export default NavBar;