import React from "react";
import { useSelector } from "react-redux";
import "./ShoppingCart.css";

const ShoppingCart = () => {
  const getCart = useSelector((state) => state.getCart);
  if (!getCart.cartItemsget.total_distinct_quantity) {
    return (
      <div className="shoppingCart">
        <span>0</span>
      </div>
    );
  }

  return (
    <div className="shoppingCart">
      {/* <ShoppingCartIcon /> */}
      <span>{getCart.cartItemsget.total_distinct_quantity}</span>
    </div>
  );
};

export default ShoppingCart
