import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginUser } from "../../redux/Slice/userAuthSlice";
import { toast } from "react-toastify";
import "./Login.css";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Form from "react-bootstrap/Form";
import {  useTranslation } from "@psyycker/react-translation";
import { images } from "../../Constants";
// import { Button } from "react-bootstrap/Button";
import { useLocation } from "react-router-dom";
import { handleStripeOnBoard } from "../../redux/Slice/stripeOnBoardingSlice";
import axios from "axios";

export default function LogIn() {
  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch(handleStripeOnBoard());

  const [passwordError, setPasswordError] = useState("");

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!user || !pwd) {
      toast.error("Bitte E-Mail und Passwort angeben");
      return;
    }
    setIsLoading(true);
    try {
      const result = await dispatch(
        loginUser({ email: user, password: pwd })
      ).unwrap();
      navigate("/");
      // window.location.reload();
      toast.success("Anmeldung erfolgreich");
      var authInfo = localStorage.getItem("token");
      var jsonObject = JSON.parse(authInfo);
      var role = jsonObject.role;
      var token = jsonObject?.token;
      if (token) {
        // request to check if the user have active plan or not
          try {
            const membershipPlanRes = await axios.get(
              "https://api.rawstuff.deals/api/v1/membership",
              {
                headers: {
                  Accept: "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            const data = membershipPlanRes.data;
            const dataRes = JSON.stringify(data);
            localStorage.setItem("membershipStatus", dataRes);
            // console.log("membership plan", data);
          } catch (error) {
            console.log("error found:", error);
          } 
        
      }
      if (role === "admin" || role === "seller") {
        window.location.href = `https://api.rawstuff.deals/webLogin?token=${token}`;
      } else {
        window.location.reload();
      }
    } catch (error) {
      // Extract the error message

      const errorMessage =
        error.message && error.message.message
          ? error.message.message
          : "An unexpected error occurred. Please try again later.";
      console.error("Full error object:", error);

      // Handle specific error messages
      if (errorMessage.toLowerCase().includes("invalid login credentials")) {
        toast.error("Falsche E-Mail oder falsches Passwort. Bitte versuchen Sie es erneut.");
      } else if (
        errorMessage.toLowerCase().includes("your account is not activated")
      ) {
        toast.error(
          "Ihr Konto ist nicht aktiviert. Bitte kontaktieren Sie das Support-Team."
        );
        navigate("/accountUnderReview"); // Adjust the route accordingly
      }
      setIsLoading(false);
    }
  };

  const handleUserInput = (e) => setUser(e.target.value);
  const handlePwdInput = (e) => setPwd(e.target.value);

  //Get Language Translation starts
  const { getTranslation } = useTranslation();
  const location = useLocation();
  const [callbackHandled, setCallbackHandled] = useState(false);

  const handleCallbackEffect = async () => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");
    const error = queryParams.get("error");

    try {
      console.log("Error handling callback:", error);

      if (token && !callbackHandled) {
        localStorage.setItem("token", JSON.stringify({ token }));
        if (window.location.pathname !== "/") {
          window.location.href = "/";
          toast.success("Anmeldung erfolgreich");
        }
      } else if (error && !callbackHandled) {
        console.log("Error handling callbackssss:", error);
        toast.error(`${error}`);
      }
      // else if (error.toLowerCase().includes('your account is not activated')) {
      //   toast.error('Your account is not activated. Please contact support team.');
      //   navigate('/accountUnderReview'); // Adjust the route accordingly
      // }

      setCallbackHandled(true);
    } catch (error) {
      console.log(error.response.data.message);
      throw toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    handleCallbackEffect();
  }, [location.search, callbackHandled]);

  const handleThirdPartyLoginGoogle = () => {
    window.location.href =
      "https://api.rawstuff.deals/api/v1/oauth/login/google";
  };

  const handleThirdPartyLoginMicrosoft = () => {
    window.location.href =
      "https://api.rawstuff.deals/api/v1/oauth/login/microsoft";
  };
  const handleThirdPartyLoginApple = () => {
    window.location.href =
      "https://api.rawstuff.deals/api/v1/oauth/login/apple";
  };

  return (
    <>
      <section className=" container auth auths popup-login" id="popup">
        <div className="row justify-content-center user-auth">
          <div className="card">
            <h1 className="loginheader">
              {getTranslation({ translationKey: "Einloggen" })}{" "}
            </h1>
            <form onSubmit={handleSubmit} className="card__form">
              <input
                type="hidden"
                name="_token"
                value="Si3iGvJp4JK75ZyUQSuOVNHDGaCu0g7zx2u8OBET"
              />
              <div className="form-group">
                <label htmlFor="email">
                  {getTranslation({ translationKey: "Email" })}{" "}
                </label>
                <input
                  type="email"
                  className="form-controls mt-1"
                  name="email"
                  value={user}
                  onChange={handleUserInput}
                  id="email"
                  required
                />
              </div>
              <div className="form-group password-label">
                <div className="password-lable">
                  <label className="password-lable-word" htmlFor="password">
                    {getTranslation({ translationKey: "Passwort" })}{" "}
                  </label>
                  <small className="mb-2 text-center ">
                    <a
                      href="./ResetPassword"
                      className="ml-1 link"
                      style={{
                        color: "#18233E",
                        fontfamily: "Roboto",
                        fontsize: " 13px",
                        fontstyle: "normal",
                        fontweight: "400",
                        lineheight: "18px",
                        letterspacing: "-0.2px",
                      }}
                    >
                      {getTranslation({ translationKey: "Passwort vergessen" })}
                    </a>
                  </small>
                </div>
                <div className="passwordd-input">
                  <div>
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-controls"
                      name="password"
                      id="password"
                      onChange={handlePwdInput}
                      value={pwd}
                      required=""
                    />
                  </div>
                  <div className="visiblityIcon-container">
                    {showPassword ? (
                      <VisibilityOff
                        className="password-icon"
                        onClick={handlePasswordVisibility}
                      />
                    ) : (
                      <Visibility
                        className="password-icon"
                        onClick={handlePasswordVisibility}
                      />
                    )}
                  </div>
                </div>
                <div className="error-message">{passwordError}</div>{" "}
                {/* Display password error */}
              </div>
              <div className="remembet-me">
                <article>
                  {" "}
                  {["checkbox"].map((type) => (
                    <Form.Check key={type} id={`check-api-${type}`}>
                      <Form.Check.Input type={type} isValid />
                    </Form.Check>
                  ))}{" "}
                </article>
                <article className="ml-2 mt-1">
                  {getTranslation({ translationKey: "Erinnere mich" })}{" "}
                </article>
              </div>
              <div className="form-group btn-logins">
                <button
                  className={`mt-4 btn-login ${isLoading ? "Laden" : ""}`}
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading
                    ? "Laden..."
                    : getTranslation({ translationKey: "Einloggen" })}
                </button>
                <div className="registerOr">
                  <hr />
                  <article className="or">OR</article>
                  <hr />
                </div>
                <button
                  className="mb-3 login-with-Google"
                  style={{ backgroundColor: "#FFFFFF" }}
                  onClick={handleThirdPartyLoginGoogle}
                >
                  {" "}
                  <img src={images.Google} alt="google-icon" />
                  {getTranslation({ translationKey: "Mit Google Anmelden" })}
                </button>
                <button
                  appId="YOUR_FACEBOOK_APP_ID"
                  autoLoad={false}
                  fields="name,email,picture"
                  style={{
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #D6DAE1",
                  }}
                  className="MicrosoftLogin login-with-Google mb-3"
                  onClick={handleThirdPartyLoginMicrosoft}
                >
                  <img src={images.Microsoft} alt="" />
                  {getTranslation({ translationKey: "Mit Microsoft Anmelden" })}
                   
                </button>
                <br />
                <button
                  className="login-with-Google"
                  style={{
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #D6DAE1",
                  }}
                  onClick={handleThirdPartyLoginApple}
                >
                  <img src={images.Apple} alt="" />
                  {getTranslation({ translationKey: "Mit Apple Anmelden" })}
                  
                </button>
              </div>
              <div className="mb-3 mt-3 text-center">
                <div className="text-center register-Account-yet">
                  {getTranslation({ translationKey: "Sie haben noch kein Konto?" })}{" "}
                  <Link to="/register">
                    {getTranslation({ translationKey: "Registrieren" })}
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}
