import PropTypes from "prop-types";
import React from "react";

export const ArrowsDiagramsArrow4 = ({ color = "#323232", style }) => {
  return (
    <svg
      className="arrows-diagrams-arrow-4"
      style={style}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path-3"
        d="M8 10L12 14L16 10"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};

ArrowsDiagramsArrow4.propTypes = {
  color: PropTypes.string,
};
