import React, { useEffect, useRef, useState } from "react";
import Card from "react-bootstrap/Card";
import "./PopularProductCard.css";
import { Link, useNavigate } from "react-router-dom";
import { fetchProductDetail } from "../../../../redux/Slice/productdetailSlice";
import { useDispatch, useSelector } from "react-redux";
import { addToCarts } from "../../../../redux/Slice/ShoppingCartSlice";
import ModalLogIn from "../../../Regstration/ModalLogIn";
import { toast } from "react-toastify";
import { images } from "../../../../Constants";

const PopularProductCard = ({ popularProductItem }) => {
  // check global cart state to check whether it is empty or not.
  const getCart = useSelector((state) => state.getCart);

  //  useState to identify if the user is login or not to render ui according to that.
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  let Token = useSelector((state) => state.userAuth?.token);
  const [modalShow, setModalShow] = React.useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const selectCardRef = useRef(null);
  // const [removeBg, setRemoveBg] = useState();
  // const removeOnclick=

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // console.log("cert", popularProductItem.seller?.user_certificate?.at(0)=="");

  const handleCardClick = () => {
    setIsClicked(true);
    setModalShow(true);

    if (selectCardRef.current) {
      selectCardRef.current.classList.add("add-bg");
      setTimeout(() => {
        selectCardRef.current.classList.remove("add-bg");
      }, 30000);
    }
  };

  const handleProductClick = (popularProductItem) => {
    dispatch(fetchProductDetail(popularProductItem.id));
    if (Token && Token) {
      navigate("/productDetailPage");
    } else {
      navigate("/login");
    }
  };

  //  useEffect to identify if the user is login or not to render ui according to that.
  useEffect(() => {
    if (Token && Token) {
      // User is logged in
      setIsLoggedIn(true);
    } else {
      // User is not logged in
      setIsLoggedIn(false);
    }
  }, []);

  const handleAddToCart = (popularProductItem) => {
    dispatch(addToCarts(popularProductItem.id));
    if (Token && Token){
      toast.success("Produkt erfolgreich in den Warenkorb gelegt");
    }
  };

  const handleBuyButton = (popularProductItem) => {
    if (getCart.cartItemsget.total_distinct_quantity === 0) {
      handleAddToCart(popularProductItem);
    }
  };
  return (
    <>
      {isLoggedIn ? (
        <div>
          <div className="popular_productCard">
            <Card>
              <Card.Body>
                <Card.Title>
                  <div className="product-items d-flex flex-column flex-md-row justify-content-between">
                    <div
                      onClick={() => handleProductClick(popularProductItem)}
                      className="d-flex flex-column  justify-content-between pe-4 pe-md-1 pe-lg-4"
                    >
                      <div className="popular_productName-text">
                        {popularProductItem.name}
                      </div>
                      <div className="d-flex ">
                        <div className="popular_product-smtxtContent popular_productCas_number">
                          Cas -Nummer:
                          <span>{`  ( ${popularProductItem.cas_number})`}</span>
                        </div>
                      </div>

                      {isLoggedIn ? (
                        <div className="d-flex mb-2 mb-md-0">
                          <div className="popular_product-smtxtContent pe-4">
                            Min Menge:
                            <span>{`${popularProductItem.min_quantity} ${popularProductItem.unit_of_measurement} `}</span>
                          </div>
                          <div className="popular_product-smtxtContent">
                            Max Menge:
                            <span>{`${popularProductItem.max_quantity} ${popularProductItem.unit_of_measurement}`}</span>
                          </div>
                        </div>
                      ) : (
                        <div className="d-flex mb-2 mb-md-0">
                          <div className="popular_product-smtxtContent pe-4">
                            Min Menge: <span>****</span>
                          </div>
                          <div className="popular_product-smtxtContent">
                            Max Menge: <span>****</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div
                      onClick={() => handleProductClick(popularProductItem)}
                      className={`${
                        popularProductItem.seller?.user_certificate?.at(0) ===
                          "" || popularProductItem.seller == null
                          ? "d-none"
                          : "d-flex"
                      } product_c-flexItems  justify-content-start justify-content-sm-end justify-content-md-start  flex-grow-1 align-items-center`}
                    >
                      <div className="product_c-flexItem">
                        <img
                          src={images.rsProductCertificate}
                          alt="product-certificate"
                        />
                      </div>

                      <div className="d-flex flex-column ps-3 ps-md-1 ps-lg-3">
                        <div className="product_c-data">
                          <h3>Zertifizierungen</h3>
                        </div>
                        {popularProductItem.seller?.user_certificate?.map(
                          (userCertificate, i) => (
                            <div key={i} className="product_c-data">
                              <p>{userCertificate}</p>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                    <div className="d-flex flex-column  justify-content-between">
                      <div
                        onClick={() => handleProductClick(popularProductItem)}
                        className="trendingProductPrice mt-sm-4 mt-md-0"
                      >
                        {isLoggedIn ? (
                          <div className="trendingProductPrice d-flex justify-content-md-between mb-3 mb-md-0 ">
                            Preis pro {popularProductItem.unit_of_measurement}
                            :
                            <span className="pe-3 ms-3 ms-md-0">{`€ ${popularProductItem.unit_price_eur}`}</span>
                          </div>
                        ) : (
                          <div className="trendingProductPrice d-flex justify-content-md-between mb-3 mb-md-0">
                            Preis pro {popularProductItem.unit_of_measurement}
                            :<span className="pe-3 ms-3 ms-md-0">€ *****</span>
                          </div>
                        )}
                      </div>
                      <div className="d-flex justify-content-md-between mt-2 mt-md-0 ">
                        <div className="btn-addToBasket">
                          <button
                            onClick={() => handleAddToCart(popularProductItem)}
                          >
                            {/* <AddCircleIcon /> */}+ Warenkorb
                          </button>
                        </div>
                        <div className="btn-buyNow ms-0 ms-sm-5 ms-md-0">
                          <Link to="/shoppingcart">
                            {getCart.cartItemsget.total_distinct_quantity ===
                            0 ? (
                              <button
                                onClick={() =>
                                  handleAddToCart(popularProductItem)
                                }
                              >
                                {/* <AddCircleIcon /> */}
                                Jetzt kaufen
                              </button>
                            ) : (
                              <button>
                                {/* <AddCircleIcon /> */}
                                Jetzt kaufen
                              </button>
                            )}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card.Title>
                <Card.Text></Card.Text>
                    
              </Card.Body>
            </Card>
          </div>
        </div>
      ) : (
        <div>
          <div
            className={`popular_productCard ${isClicked ? "clicked" : ""}`}
            onClick={handleCardClick}
          >
            <Card ref={selectCardRef}>
              <Card.Body className="loginPopups">
                <Card.Title>
                  <div className="product-items d-flex flex-column flex-md-row justify-content-between ">
                    <div className="d-flex flex-column  justify-content-between pe-4 pe-md-1 pe-lg-4">
                      <div className="popular_productName-text">
                        {popularProductItem.name}
                      </div>
                      <div className="d-flex ">
                        <div className="popular_product-smtxtContent popular_productCas_number">
                          cas Number:
                          <span>{`  ( ${popularProductItem.cas_number})`}</span>
                        </div>
                      </div>

                      {isLoggedIn ? (
                        <div className="d-flex mb-2 mb-md-0">
                          <div className="popular_product-smtxtContent pe-4">
                            Min Menge:{" "}
                            <span>{popularProductItem.min_quantity}</span>
                          </div>
                          <div className="popular_product-smtxtContent">
                            Max Menge:{" "}
                            <span>{popularProductItem.max_quantity}</span>
                          </div>
                        </div>
                      ) : (
                        <div className="d-flex mb-2 mb-md-0">
                          <div className="popular_product-smtxtContent pe-4">
                            Min Menge: <span>****</span>
                          </div>
                          <div className="popular_product-smtxtContent">
                            Max Menge: <span>****</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div
                      onClick={() => handleProductClick(popularProductItem)}
                      className={`${
                        popularProductItem.seller?.user_certificate?.at(0) ===
                          "" || popularProductItem.seller == null
                          ? "d-none"
                          : "d-flex"
                      } product_c-flexItems  justify-content-start justify-content-sm-end justify-content-md-start  flex-grow-1 align-items-center`}
                    >
                      <div className="product_c-flexItem">
                        <img
                          src={images.rsProductCertificate}
                          alt="product-certificate"
                        />
                      </div>

                      <div className="d-flex flex-column ps-3 ps-md-1 ps-lg-3">
                        <div className="product_c-data">
                          <h3>Zertifizierungen</h3>
                        </div>
                        {popularProductItem.seller?.user_certificate?.map(
                          (userCertificate, i) => (
                            <div key={i} className="product_c-data">
                              <p>{userCertificate}</p>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                    <div className="d-flex flex-column  justify-content-between">
                      <div className="trendingProductPrice mt-sm-4 mt-md-0">
                        {isLoggedIn ? (
                          <div className="trendingProductPrice d-flex justify-content-md-between mb-3 mb-md-0 ">
                            Preis pro {popularProductItem.unit_of_measurement}
                            :
                            <span className="pe-3 ms-3 ms-md-0">{`€ ${popularProductItem.unit_price_eur}`}</span>
                          </div>
                        ) : (
                          <div className="trendingProductPrice d-flex justify-content-md-between mb-3 mb-md-0 ">
                            Preis pro {popularProductItem.unit_of_measurement}
                            :<span className="pe-3 ms-3 ms-md-0">€ *****</span>
                          </div>
                        )}
                      </div>
                      <div className="d-flex  justify-content-md-between mt-2 mt-md-0 ">
                        <div className="btn-addToBasket">
                          <button
                            onClick={() => handleAddToCart(popularProductItem)}
                          >
                            {/* <AddCircleIcon /> */}+ Warenkorb
                          </button>
                        </div>
                        <div className="btn-buyNow ms-0 ms-sm-5 ms-md-0">
                          <Link to="/login">
                            <button>Jetzt kaufen</button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card.Title>
                <Card.Text></Card.Text>
                    
              </Card.Body>
            </Card>
          </div>
          <div className="loginPopup">
            <ModalLogIn show={modalShow} onHide={() => setModalShow(false)} />
          </div>
        </div>
      )}
    </>
  );
};

export default PopularProductCard;
