import React from "react";

function MarketPlaceFooter() {
  return (
    <div className="mpft">
      <div className="borderf"></div>
      <div className="container">
        <div className="row mft2">
          <div className="step-1 col-sm-12 col-md">
            <div className="description">
              {/* Lorem ipsum dolor sit amet, consectetur adipisicing elitseddo */}
              Nutzen Sie die Vorteile des anpassungsfähigen Einkaufs auf unserem
              B2B-Marktplatz
            </div>
            <div className="title">Gesicherte Geschäfte</div>

            <div className="icon">
              <div className="background"></div>

              <svg
                className="_1-base-elements-icons-system-offer"
                height="24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 640 512"
              >
                <path d="M184 48H328c4.4 0 8 3.6 8 8V96H176V56c0-4.4 3.6-8 8-8zm-56 8V96H64C28.7 96 0 124.7 0 160v96H192 352h8.2c32.3-39.1 81.1-64 135.8-64c5.4 0 10.7 .2 16 .7V160c0-35.3-28.7-64-64-64H384V56c0-30.9-25.1-56-56-56H184c-30.9 0-56 25.1-56 56zM320 352H224c-17.7 0-32-14.3-32-32V288H0V416c0 35.3 28.7 64 64 64H360.2C335.1 449.6 320 410.5 320 368c0-5.4 .2-10.7 .7-16l-.7 0zm320 16a144 144 0 1 0 -288 0 144 144 0 1 0 288 0zM496 288c8.8 0 16 7.2 16 16v48h32c8.8 0 16 7.2 16 16s-7.2 16-16 16H496c-8.8 0-16-7.2-16-16V304c0-8.8 7.2-16 16-16z" />
              </svg>
            </div>
          </div>

          <div className="step-2 col-sm-12 col-md">
            <div className="description">
              {/* Lorem ipsum dolor sit amet, consectetur adipisicing elitseddo */}
              Flexiblen Einkaufsoptionen, die sich nahtlos an die Bedürfnisse
              Ihres Unternehmens anpassen.
            </div>

            <div className="title">
              {/* Flexible Buying */}
              Flexibel einkaufen
            </div>

            <div className="icon">
              <div className="background"></div>

              <svg
                className="_1-base-elements-icons-system-shopping-cart"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="24" height="24" fill="white" />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.99609 18C6.89609 18 6.00609 18.9 6.00609 20C6.00609 21.1 6.89609 22 7.99609 22C9.09609 22 9.99609 21.1 9.99609 20C9.99609 18.9 9.09609 18 7.99609 18ZM1.99609 3C1.99609 3.55 2.44609 4 2.99609 4H3.99609L7.59609 11.59L6.24609 14.03C5.51609 15.37 6.47609 17 7.99609 17H18.9961C19.5461 17 19.9961 16.55 19.9961 16C19.9961 15.45 19.5461 15 18.9961 15H7.99609L9.09609 13H16.5461C17.2961 13 17.9561 12.59 18.2961 11.97L21.8761 5.48C22.2461 4.82 21.7661 4 21.0061 4H6.20609L5.53609 2.57C5.37609 2.22 5.01609 2 4.63609 2H2.99609C2.44609 2 1.99609 2.45 1.99609 3ZM17.9961 18C16.8961 18 16.0061 18.9 16.0061 20C16.0061 21.1 16.8961 22 17.9961 22C19.0961 22 19.9961 21.1 19.9961 20C19.9961 18.9 19.0961 18 17.9961 18Z"
                  fill="#2C2C2C"
                />
                <mask
                  id="mask0_1736_2890"
                  className="luminance"
                  maskUnits="userSpaceOnUse"
                  x="1"
                  y="2"
                  width="22"
                  height="20"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.99609 18C6.89609 18 6.00609 18.9 6.00609 20C6.00609 21.1 6.89609 22 7.99609 22C9.09609 22 9.99609 21.1 9.99609 20C9.99609 18.9 9.09609 18 7.99609 18ZM1.99609 3C1.99609 3.55 2.44609 4 2.99609 4H3.99609L7.59609 11.59L6.24609 14.03C5.51609 15.37 6.47609 17 7.99609 17H18.9961C19.5461 17 19.9961 16.55 19.9961 16C19.9961 15.45 19.5461 15 18.9961 15H7.99609L9.09609 13H16.5461C17.2961 13 17.9561 12.59 18.2961 11.97L21.8761 5.48C22.2461 4.82 21.7661 4 21.0061 4H6.20609L5.53609 2.57C5.37609 2.22 5.01609 2 4.63609 2H2.99609C2.44609 2 1.99609 2.45 1.99609 3ZM17.9961 18C16.8961 18 16.0061 18.9 16.0061 20C16.0061 21.1 16.8961 22 17.9961 22C19.0961 22 19.9961 21.1 19.9961 20C19.9961 18.9 19.0961 18 17.9961 18Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask0_1736_2890)"></g>
              </svg>
            </div>
          </div>

          <div className="step-3 col-sm-12 col-md">
            <div className="description">
              {/* Lorem ipsum dolor sit amet, consectetur adipisicing elitseddo */}
              Entdecken Sie eine Sinfonie verlockender Angebote auf unserem
              Marktplatz mit vielfältigen Möglichkeiten.
            </div>

            <div className="title">
              {/* Tons of Offers */}
              Töne der Angebote
            </div>

            <div className="icon">
              <div className="background"></div>

              <svg
                className="_1-base-elements-icons-system-offer"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="24" height="24" fill="white" />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M21.41 11.58L12.41 2.58C12.05 2.22 11.55 2 11 2H4C2.9 2 2 2.9 2 4V11C2 11.55 2.22 12.05 2.59 12.42L11.59 21.42C11.95 21.78 12.45 22 13 22C13.55 22 14.05 21.78 14.41 21.41L21.41 14.41C21.78 14.05 22 13.55 22 13C22 12.45 21.77 11.94 21.41 11.58ZM5.5 7C4.67 7 4 6.33 4 5.5C4 4.67 4.67 4 5.5 4C6.33 4 7 4.67 7 5.5C7 6.33 6.33 7 5.5 7Z"
                  fill="#2C2C2C"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div className="borderf"></div>
    </div>
  );
}

export default MarketPlaceFooter;
