import Cookies from "js-cookie";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast, Slide } from "react-toastify";
import axios from "axios";

const CART_STORAGE_KEY = "cartItems";

export const loadCartFromStorage = () => {
  const cart = Cookies.get(CART_STORAGE_KEY);
  return cart ? JSON.parse(cart) : [];
};

const saveToCartStorage = (cart, cartTotalQuantity, cartTotalAmount) => {
  localStorage.setItem(CART_STORAGE_KEY, JSON.stringify(cart));
  localStorage.setItem("cartTotalAmount", JSON.stringify(cartTotalAmount));
  localStorage.setItem("cartTotalQuantity", JSON.stringify(cartTotalQuantity));
  sessionStorage.setItem(CART_STORAGE_KEY, JSON.stringify(cart));
  Cookies.set(CART_STORAGE_KEY, JSON.stringify(cart), { expires: 1, path: "" });
};

export const addToCarts = createAsyncThunk(
  "cartData/fetchProducts",
  async (product_id) => {
    try {
      const cartItem = localStorage.getItem("cartItem");

      var jsonString = localStorage.getItem("token");
      var jsonObject = JSON.parse(jsonString);
      var token = jsonObject.token;

      if (!cartItem) {
        // Cart is empty, create a new cart
        console.log("empty");
        const response = await axios.post(
          "https://api.rawstuff.deals/api/v1/carts/addToCart",
          {
            product_id: product_id,
            quantity: [
              {
                quantity: 1,
                unit: "kg",
              },
            ],
          },
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        localStorage.setItem("cartItem", response.data.data.id);
        return response.data;
      } else {
        // Cart exists, add product to the existing cart
        const response = await axios.post(
          "https://api.rawstuff.deals/api/v1/carts/addToCart",
          {
            product_id: product_id,
            quantity: [
              {
                quantity: 1,
                unit: "kg",
              },
            ],
            cart_id: cartItem,
          },
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log("res", response.data.data.id);
        return response.data;
      }
    } catch (error) {
      console.log(error, "addtocart");
      // throw toast.error(error.response.data.message);
    }
  }
);

const initialState = {
  cartItems: loadCartFromStorage(),
};

const shoppingCartSlice = createSlice({
  name: "shoppingCart",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(addToCarts.fulfilled, (state, action) => {
      const cartItem = action.payload;
      state.cart = cartItem?.data;
    });
  },
});

export const isValidItem = (item) => {
  // validation criteria and logic here
  return item.hasOwnProperty("id") && item.hasOwnProperty("name");
};

export const isInStock = (item) => {
  // check item is in stock
  // item  from db must have stock property
  return item.amount > 0;
};

export default shoppingCartSlice.reducer;
