import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const initialState = {
  cartItemsget: [],
};

export const getToCart = createAsyncThunk(
  "cartData/fetchProducts",
  async () => {
    try {
      const cartItem = localStorage.getItem("cartItem");

      var jsonString = localStorage.getItem("token");
      var jsonObject = JSON.parse(jsonString);
      var token = jsonObject.token;

      const response = await axios.get(
        "https://api.rawstuff.deals/api/v1/carts/getCart",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // localStorage.setItem("cartItem");
      return response.data;
    } catch (error) {
      console.log(error.response.data.message);
      // throw toast.error(error.response.data.message);
      
    }
  }
);

export const clearCarts = createAsyncThunk(
  "cartData/fetchclearCart",
  async (id) => {
    try {
      var jsonString = localStorage.getItem("token");
      var jsonObject = JSON.parse(jsonString);
      var token = jsonObject.token;
        

     const id = JSON.parse(localStorage.getItem('getcart'))
      console.log("clear", id);
      if (!id) {
        return "Cart is already empty";
      } else {
        const response = await axios.delete(
          `https://api.rawstuff.deals/api/v1/carts/clearCart/${id}`,
          {
            method: "DELETE", // Use "DELETE" for a DELETE request
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        localStorage.removeItem("cartItem");
        return response.data; // You might want to return some data from the response if needed
      }
    } catch (error) {
      throw Error("An error occurred. Please try again.", " ", error);
    }
  }
);

export const removeFromCarts = createAsyncThunk(
  "cartData/fetchclearCart",
  async (cart_id) => {
    try {
      var jsonString = localStorage.getItem("token");
      var jsonObject = JSON.parse(jsonString);
      var token = jsonObject.token;
      const response = await axios.delete(
        `https://api.rawstuff.deals/api/v1/carts/deleteFromCart/${cart_id}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response;
    } catch (error) {
      throw Error("An error occurred. Please try again.", " ", error);
    }
  }
);

export const updateQuantitys = createAsyncThunk(
  "cartData/fetchupdateQuantitys",
  async ({ productId, quantity, btId }) => {
    // console.log(quantity, "quantity")
    // console.log(btId, "btId")
    // console.log(productId, "productId")

    try {
      var jsonString = localStorage.getItem("token");
      var jsonObject = JSON.parse(jsonString);
      var token = jsonObject.token;
      const data = {
          productId: productId,
          quantity: {
              unit: "kg",
              quantity:quantity,
              id:btId,
          }
      }
      const response = await axios.put(
        `https://api.rawstuff.deals/api/v1/carts/updateQuantity/${productId}`,
        data,
        {
          method: "put",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      localStorage.setItem("cartItem2", response.data.data);
      return response.data;
    } catch (error) {
      throw Error("An error occurred. Please try again.", " ", error);
    }
  }
);

const getSlice = createSlice({
  name: "getCart",
  initialState,
  reducers: {
    
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) =>
        action.type === getToCart.fulfilled.type ||
        action.type === removeFromCarts.fulfilled.type ||
        action.type === clearCarts.fulfilled.type ||
        action.type === updateQuantitys.fulfilled.type,
      (state, action) => {
        if (action.type === getToCart.fulfilled.type) {
          const getCartItems = action.payload;
          state.cartItemsget = { ...state.cartItemsget, ...getCartItems?.data };
          localStorage.setItem("getcart", JSON.stringify(state.cartItemsget.id));
        } else if (action.type === removeFromCarts.fulfilled.type) {
          const removefromCart = action.payload;
          state.cartItemsget = {
            ...state.cartItemsget,
            id: removefromCart.data.id,
          };
          localStorage.setItem("getcart", JSON.stringify(state.cartItemsget));
          
        } else if (action.type === clearCarts.fulfilled.type) {
          state.cartItemsget = {};
          localStorage.setItem("getcart", JSON.stringify(state.cartItemsget));
        } else if (action.type === updateQuantitys.fulfilled.type) {
          const updateQuantity = action.payload;
          // state.cartItemsget = updateQuantitys.;
          localStorage.setItem("getcart", JSON.stringify(state.cartItemsget));
        }
      }
    );
  },
});

export default getSlice.reducer;
