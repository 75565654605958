import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import "./RegistrationPlatForm.css";
import AdvertHero from "../AdvertHero/AdvertHero";
import NewsLetter from "../NewsLetter/NewsLetter";
import { Link } from "react-router-dom";
import {useLocale, useTranslation} from '@psyycker/react-translation';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const RegistrationPlatForm = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  let [userSignIn, updateUserSingIn] = useState(
    localStorage.getItem("token") ? true : false
    
  );
 let userName= localStorage.getItem("userName");
   let Token = useSelector((state) => state.userAuth?.token);
  // useeffect to make true if the user is logged in else false setIsLoggedIn
    useEffect(() => {
      
      if (Token) {
        // User is logged in
        setIsLoggedIn(true);
      } else {
        // User is not logged in
        setIsLoggedIn(false);
      }
    }, []);

  
 //Get Language Translation starts
 const {getTranslation} = useTranslation();
 const [locale, setLocale] = useLocale();
 const onButtonClick = () => {
   var lang = document.getElementById('lang').value;
   var selectedx = "You have selected";
   if(lang=="English"){
     setLocale('en-US');
   }

   if(lang=="Germany"){
     setLocale('german')
   }

   if(lang=="Dutch"){
     setLocale('de')
   }

   if(lang=="French"){
     setLocale('fr-FR')
   }
 }

 //Get Language Translation ends

  return (
    <div id="registrationPlatForm" className="registrationPlatForm-container">
      <section className="container-fluid">
        <div className="registrationPlatForm-title text-center pb-5">
          {/* {getTranslation({ translationKey: "TheB2Bplatform" })} */}
          Die optimale B2B-Plattform, um Ihre Einkaufsentscheidungen zu
          revolutionieren
        </div>
        <div className="registrationPlatForm-wrapper">
          <div className="row justify-content-between mb-4">
            <div className="col-md me-lg-2 commonFlexItems mb-5 mb-lg-0">
              <div className="flexItems-content">
                <div className="flexItems-leftTitle">
                  {" "}
                  Registrieren Sie sich als Käufer und profitieren von diesen
                  Vorteilen:
                </div>
                <div className="d-flex mb-2 flexItem-content-w ">
                  <CheckIcon />
                  <p className="registrationPlatForm-leftTxt">
                    {/* {getTranslation({ translationKey: "SearchCompanies" })} */}
                    Qualifizierte Verkäufer mit geprüften Rohstoffen
                  </p>
                </div>
                <div className="d-flex mb-2  ">
                  <CheckIcon />
                  <p className="registrationPlatForm-leftTxt">
                    {/* {getTranslation({ translationKey: "ViewSales" })} */}
                    Fortschrittliche Such- und Filteroptionen
                  </p>
                </div>
                <div className="d-flex mb-2  ">
                  <CheckIcon />
                  <p className="registrationPlatForm-leftTxt">
                    {/* {getTranslation({ translationKey: "ContactMultiple" })} */}
                    Anbietervergleich
                  </p>
                </div>
                <div className="d-flex mb-2  ">
                  <CheckIcon />
                  <p className="registrationPlatForm-leftTxt">
                    {/* {getTranslation({ translationKey: "ContactMultiple" })} */}
                    Sichere und transparente Transaktionen
                  </p>
                </div>
                <div className="d-flex mb-2  ">
                  <CheckIcon />
                  <p className="registrationPlatForm-leftTxt">
                    {/* {getTranslation({ translationKey: "ContactMultiple" })} */}
                    Effiziente Beschaffungsprozesse
                  </p>
                </div>
                <div className="btn-buyer-wrapper">
                  {/******* Buyer Register Button*/}
                  {!userSignIn && (
                    <Link to="/register">
                      <button className="btn-text">
                        {/* {getTranslation({
                          translationKey: "Register For Free",
                        })}{" "} */}
                        zur Anmeldung
                      </button>
                    </Link>
                  )}
                  {/******** Buyer Register Button Ends Here */}

                  {/******* Buyer Register Button*/}
                  {userSignIn && (
                    <Link to="/membership">
                      <button className="btn-text">
                        {/* {getTranslation({ translationKey: "Get Started" })}{" "} */}
                        Los geht's
                      </button>
                    </Link>
                  )}
                  {/******** Buyer Register Button Ends Here */}
                </div>
              </div>
            </div>
            <div className="col-md ms-lg-2 commonFlexItems">
              <div className="flexItems-content">
                <div className="flexItems-rightTitle">
                  {/* {getTranslation({ translationKey: "RegisterAsSeller" })}{" "} */}
                  Registrieren Sie sich als Verkäufer und profitieren Sie von
                  diesen Vorteilen:
                </div>
                <div className=" mb-2 mb-sm-3 d-md-5  flexItem-content-w">
                  {/* <p className="registrationPlatForm-rightTxt ">
                    {getTranslation({ translationKey: "FindOut" })}
                  </p> */}
                  <div className="d-flex mb-2  ">
                    <CheckIcon />
                    <p className="registrationPlatForm-leftTxt">
                      {/* {getTranslation({ translationKey: "ContactMultiple" })} */}
                      Zugang zu einem breiten Käufernetzwerk
                    </p>
                  </div>
                  <div className="d-flex mb-2  ">
                    <CheckIcon />
                    <p className="registrationPlatForm-leftTxt">
                      {/* {getTranslation({ translationKey: "ContactMultiple" })} */}
                      Erhöhte Sichtbarkeit der Produkte
                    </p>
                  </div>
                  <div className="d-flex mb-2  ">
                    <CheckIcon />
                    <p className="registrationPlatForm-leftTxt">
                      {/* {getTranslation({ translationKey: "ContactMultiple" })} */}
                      Effiziente Verkaufsprozesse
                    </p>
                  </div>
                  <div className="d-flex mb-2  ">
                    <CheckIcon />
                    <p className="registrationPlatForm-leftTxt">
                      {/* {getTranslation({ translationKey: "ContactMultiple" })} */}
                      Zielgerichtetes Marketing
                    </p>
                  </div>
                  <div className="d-flex mb-2  ">
                    <CheckIcon />
                    <p className="registrationPlatForm-leftTxt">
                      {/* {getTranslation({ translationKey: "ContactMultiple" })} */}
                      Kostenreduktion im Vertrieb und Marketing
                    </p>
                  </div>
                </div>
                <br />
                <div className="btn-seller-wrapper">
                  {/******* Buyer Register Button*/}
                  {!userSignIn && (
                    <Link to="/register">
                      <button className="btn-text">
                        {/* {getTranslation({
                          translationKey: "To Inside Business",
                        })} */}
                        zur Anmeldung
                      </button>
                    </Link>
                  )}
                  {/******** Buyer Register Button Ends Here */}

                  {/******* Buyer Register Button*/}
                  {userSignIn && (
                    <Link to="/membership">
                      <button className="btn-text">
                        {/* {getTranslation({ translationKey: "Get Started" })} */}
                        Los geht's
                      </button>
                    </Link>
                  )}
                  {/******** Buyer Register Button Ends Here */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <AdvertHero />
      {/* <NewsLetter /> */}
    </div>
  );
};

export default RegistrationPlatForm;
