import PropTypes from "prop-types";
import React from "react";
import { ArrowRight7 } from "../../icons/ArrowRight7";
import "./style.css";

export const BredcrumbBase = ({
  style,
  icon,
  state,
  menuStyle,
  text = "Menu",
  override = (
    <ArrowRight7
      style={{
        height: "12px",
        minWidth: "12px",
        position: "relative",
      }}
    />
  ),
}) => {
  return (
    <div className="bredcrumb-base">
      {icon && (
        <div className={`menu ${state}`} style={menuStyle}>
          {text}
        </div>
      )}

      {style === "style-1" && icon && (
        <div className="base-badge-dot">
          <div className="dot" />
        </div>
      )}

      {!icon && <div className={`text-wrapper state-${state}`}>{text}</div>}

      {style === "style-2" && <>{override}</>}
    </div>
  );
};

BredcrumbBase.propTypes = {
  style: PropTypes.oneOf(["style-2", "style-1"]),
  icon: PropTypes.bool,
  state: PropTypes.oneOf(["inactive", "active"]),
  text: PropTypes.string,
};
