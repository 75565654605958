import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { timeConvert } from './TicketTable';
import ticket from '../../Constants/Contents/Ticket';

function TicketDetailsModal(props) {
  const [selectedValue, setSelectedValue] = useState('');
  const handleSubmit = async(e) => {
    e.preventDefault();
    // Handle form submission logic here
    let token = localStorage.getItem("token");
    token = JSON.parse(token).token;

    try {
      await axios.put(
        `https://api.rawstuff.deals/api/v1/support/${props.info.id}`,
        {
          email: props.info.email,
          status: 'OPEN'
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Status updated successfully");
      props.fetchdata();
    } catch {
      toast.error("something went wrong");
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{
        color: "black",
        overflowY: "scroll",
        height: "90vh",
        top: "15%",
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Your Ticket Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='mb-4'>
          <div>
            <p>
              <strong>{ticket.emailde}: </strong>
              {props.info.email}
            </p>
            <p>
              <strong>{ticket.subjectde}: </strong>
              {props.info.subject}
            </p>
            <p>
              <strong>{ticket.ticketide}: </strong>
              {props.info.ticket_id}
            </p>
            <div>
              <p>
                <strong>Created At: </strong>
                {timeConvert(props.info.created_at)}
              </p>
              <div className='message-container'>
                <strong>Message: </strong>
                <div className='message-content'>
                  {props.info.message}
                </div>
              </div>
              <div className='d-none'>
                <strong>Attachment: </strong>
                <div className='message-content'>
                  <img src={props.info.image} alt="No Image" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <form onSubmit={(e) => handleSubmit(e)}>
          <select
            className="form-select mb-2"
            aria-label="Default select example"
            value={selectedValue}
            onChange={(event) => setSelectedValue(event.target.value)}
            defaultValue={props.info.status}
          >
            <option>{props.info.status}</option>
            <option>REOPEN</option>
          </select>

          <button type="submit" className="sendbtn mt-2">
            Re Open Ticket
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default TicketDetailsModal;