import PropTypes from "prop-types";
import React from "react";
import { ArrowRight7 } from "../../icons/ArrowRight7";
import { BredcrumbBase } from "../BredcrumbBase";
import "./style.css";

export const Bredcrumb = ({
  style,
  number,
  styleOverride,
  bredcrumbBaseMenuStyle,
  bredcrumbBaseIcon = (
    <ArrowRight7
      style={{
        height: "12px",
        minWidth: "114px",
        position: "relative",
      }}
    />
  ),
  bredcrumbBaseText = "Menu",
  bredcrumbBaseMenuStyleOverride,
  override = (
    <ArrowRight7
      style={{
        height: "12px",
        minWidth: "12px",
        position: "relative",
      }}
    />
  ),
  bredcrumbBaseText1 = "Menu",
  bredcrumbBaseDivStyle,
  bredcrumbBaseIcon1 = (
    <ArrowRight7
      style={{
        height: "12px",
        minWidth: "12px",
        position: "relative",
      }}
    />
  ),
  bredcrumbBaseText2 = "Menu",
  visible = true,
  visible1 = true,
}) => {
  return (
    <div className="bredcrumb" style={styleOverride}>
      {["four", "three", "two"].includes(number) && (
        <>
          <BredcrumbBase
            icon
            override={
              style === "style-2" ? (
                <ArrowRight7
                  style={{
                    height: "12px",
                    minWidth: "12px",
                    position: "relative",
                  }}
                />
              ) : undefined
            }
            state="inactive"
            style={style === "style-2" ? "style-2" : "style-1"}
            text="Menu"
          />
          <BredcrumbBase
            icon={number === "two" ? false : true}
            override={
              style === "style-2" && ["four", "three"].includes(number) ? (
                <ArrowRight7
                  style={{
                    height: "12px",
                    minWidth: "12px",
                    position: "relative",
                  }}
                />
              ) : undefined
            }
            state={number === "two" ? "active" : "inactive"}
            style={
              number === "two" || style === "style-1" ? "style-1" : "style-2"
            }
            text="Menu"
          />
        </>
      )}

      {["four", "three"].includes(number) && (
        <BredcrumbBase
          icon={number === "four" ? true : undefined}
          override={
            style === "style-2" && number === "four" ? (
              <ArrowRight7
                style={{
                  height: "12px",
                  minWidth: "12px",
                  position: "relative",
                }}
              />
            ) : undefined
          }
          state={number === "four" ? "inactive" : "active"}
          style={
            style === "style-2" && number === "four" ? "style-2" : "style-1"
          }
          text="Menu"
        />
      )}

      {number === "four" && (
        <BredcrumbBase
          icon={false}
          state="active"
          style="style-1"
          text="Menu"
        />
      )}

      {number === "five" && (
        <>
          <BredcrumbBase
            icon
            menuStyle={bredcrumbBaseMenuStyle}
            override={bredcrumbBaseIcon}
            state="inactive"
            style={style === "style-2" ? "style-2" : "style-1"}
            text={bredcrumbBaseText}
          />
          <BredcrumbBase
            icon
            menuStyle={bredcrumbBaseMenuStyleOverride}
            override={override}
            state="inactive"
            style={style === "style-2" ? "style-2" : "style-1"}
            text={bredcrumbBaseText1}
          />
          <BredcrumbBase
            icon
            menuStyle={bredcrumbBaseDivStyle}
            override={bredcrumbBaseIcon1}
            state="inactive"
            style={style === "style-2" ? "style-2" : "style-1"}
            text={bredcrumbBaseText2}
          />
          <>
            {visible && (
              <BredcrumbBase
                icon
                override={
                  style === "style-2" ? (
                    <ArrowRight7
                      style={{
                        height: "12px",
                        minWidth: "12px",
                        position: "relative",
                      }}
                    />
                  ) : undefined
                }
                state="inactive"
                style={style === "style-2" ? "style-2" : "style-1"}
                text="Menu"
              />
            )}
          </>
          <>
            {visible1 && (
              <BredcrumbBase
                icon={false}
                state="active"
                style="style-1"
                text="Menu"
              />
            )}
          </>
        </>
      )}
    </div>
  );
};

Bredcrumb.propTypes = {
  style: PropTypes.oneOf(["style-2", "style-1"]),
  number: PropTypes.oneOf(["two", "five", "three", "four"]),
  bredcrumbBaseText: PropTypes.string,
  bredcrumbBaseText1: PropTypes.string,
  bredcrumbBaseText2: PropTypes.string,
  visible: PropTypes.bool,
  visible1: PropTypes.bool,
};
