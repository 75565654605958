import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { DeleteRounded } from '@mui/icons-material';


export const RemoveFromList = ({id, list_name, handleDelete}) => {
  return (
    <div
      onClick={()=>removeFromwishList(id, list_name, handleDelete)}
      className="like-button hovered"
    >
      <DeleteRounded />
    </div>
  )
}

export default RemoveFromList;


const removeFromwishList = async(id, list_name, handleDelete) => {
  let jsonString = localStorage.getItem('token');
  let token = JSON.parse(jsonString).token;


    try {
      const url =
        `https://api.rawstuff.deals/api/v1/wish_list/${id}`;

      await axios.delete(url,
        {
          data: {
            list_name: list_name,
          },
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        }
      )
      handleDelete(list_name);
      // toast.success("Successfully removed from wishlist");
      toast.success("Erfolgreich vom Merkzettel entfernt.");
    }
    catch (error) {
      toast.error("Unable to remove the product from default wishlist")
      console.error(error);
    }
}