import React from "react";
import termsandconditionsEN from "../../Constants/Contents/TermsandconditionsEN";

function Termsandconditions() {
  return (
    <div className="top-300">
      <div className="container">
        <div className="my-5">
          <div className="banner bg-primar">
            <div className="headercr2 ml-n5">{termsandconditionsEN.header}</div>
          </div>

          <div className="d-flex justify-content-center align-items-center flex-column mb-5 col col-lgg-6 mx-auto col-md-9 col-sm-12 col-xs-12 bg-secondar">
            <div className="bodytxt">
              <div className="mb-2">
                1. {termsandconditionsEN.sectiononetitle}
              </div>

              <p>{termsandconditionsEN.sectiononebody}</p>
            </div>

            <div className="bodytxt">
              <div className="mb-2">
                2. {termsandconditionsEN.sectiontwotitle}
              </div>
              {termsandconditionsEN.sectiontwobody.map((item, index) => (
                <p key={index}>
                  ({index + 1}) {item}
                </p>
              ))}
            </div>

            <div className="bodytxt">
              <div className="mb-2">
                3. {termsandconditionsEN.sectionthreetitle}
              </div>
              {termsandconditionsEN.sectionthreebody.map((item, index) => (
                <p key={index}>
                  ({index + 1}) {item}
                </p>
              ))}
            </div>

            <div className="bodytxt">
              <div className="mb-2">
                4. {termsandconditionsEN.sectionfourtitle}
              </div>
              {termsandconditionsEN.sectionfourbody.map((item, index) => (
                <p key={index}>
                  ({index + 1}) {item}
                </p>
              ))}
            </div>

            <div className="bodytxt">
              <div className="mb-2">
                5. {termsandconditionsEN.sectionfivetitle}
              </div>
              {termsandconditionsEN.sectionfivebody.map((item, index) => (
                <p key={index}>
                  ({index + 1}) {item}
                </p>
              ))}
            </div>

            <div className="bodytxt">
              <div className="mb-2">
                6. {termsandconditionsEN.sectionsixtitle}
              </div>
              {termsandconditionsEN.sectionsixbody.map((item, index) => (
                <p key={index}>
                  ({index + 1}) {item}
                </p>
              ))}
            </div>

            <div className="bodytxt">
              <div className="mb-2">
                7. {termsandconditionsEN.sectionseventitle}
              </div>
              {termsandconditionsEN.sectionsevenbody.map((item, index) => (
                <p key={index}>
                  ({index + 1}) {item}
                </p>
              ))}
            </div>

            <div className="bodytxt">
              <div className="mb-2">
                8. {termsandconditionsEN.sectioneighttitle}
              </div>

              <p>{termsandconditionsEN.sectioneightbody}</p>
            </div>

            <div className="bodytxt">
              <div className="mb-2">
                9. {termsandconditionsEN.sectionninetitle}
              </div>

              <p>{termsandconditionsEN.sectionninebody}</p>
            </div>

            <div className="bodytxt">
              <div className="mb-2">
                10. {termsandconditionsEN.sectiontentitle}
              </div>
              {termsandconditionsEN.sectiontenbody.map((item, index) => (
                <p key={index}>
                  ({index + 1}) {item}
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Termsandconditions;
