import React from "react";

function FreeSpaceForAdvert() {
  return (
    <div className="w-90 d-sm-none d-md-block">
      <div className="container-fluid">
        <div className="">
          <div className="advert-hero-carousel d-flex justify-content-between px-5">
            <div className=" advert-title-g">
              <div className="advert-title">Free space for Advertising?</div>
              <div className="advert-subtitle py-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </div>
            </div>
            <div className="advert-title-g2">
              <div className="advert-image">
                <h4>Image</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FreeSpaceForAdvert;