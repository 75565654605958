import React, { useEffect, useState } from "react";
import "./AlertInfo.css"

const AlertInfo = () => {
  const [getAllInfo, setGetAllInfo] = useState([]);
  const alertInfomation = [
    {
      id: "001",
      title: "B2B-Anbieter",
      amountInNumber: "640,000",
    },
    {
      id: "002",
      title: "Industriezweig",
      amountInNumber: "129",
    },
    {
      id: "003",
      title: "Transaktionen",
      amountInNumber: "350,000",
    },
    {
      id: "004",
      title: "Produkte",
      amountInNumber: "600,000",
    },
    {
      id: "005",
      title: "Käufer pro Monat",
      amountInNumber: "1.4 million",
    },
  ];
  useEffect(() => {
    setGetAllInfo(alertInfomation);
  }, []);

  return (
    <div className="alertInfo-container">
      <div className="container">
        <div className="alertInfo-flex-container row d-flex justify-content-between align-items-center">
          {getAllInfo?.map((getInfoItem) => (
            <div
              key={getInfoItem.id}
              className="alertInfo-item col text-center"
            >
              <div className="alertInfo-item-number">
                {getInfoItem.amountInNumber}
              </div>
              <div className="alertInfo-item-title">{getInfoItem.title}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AlertInfo;
