import React from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

export default function Error() {
  const searchParams = new URLSearchParams(window.location.search);

  const navigate = useNavigate();
  
  return (
    <div
      id="error"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        background: "#DBDEE1",
        height: "100vh",
      }}
    >
      <h2
        style={{
          color: "#ec672c",
          fontSize: "40px",
          fontFamily: "var(--font-base)",
          margin: "0",
        }}
      >
        Seite nicht gefunden
      </h2>
      <h3
        style={{
          color: "#333",
          fontSize: "18px",
          fontFamily: "var(--font-base)",
          marginTop: "16px",
        }}
      >
       
      </h3>
      <button
        type="button"
        style={{
          color: "#ec672c",
          borderRadius: "6px",
          border: "1px solid #ec672c",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          padding: "8px 16px",
          fontFamily: "var(--font-base)",
          fontSize: "16px",
          marginTop: "24px",
          cursor: "pointer",
        }}
        onClick={() => navigate("/")}
      >
        Geh zurück nach hause
      </button>
    </div>
  );
}
