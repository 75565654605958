import React, { useEffect, useRef, useState } from "react";
import "./HomeHero.css";
import { toast, Slide } from "react-toastify";
import RegistrationPlatForm from "./registrationPlatForm/RegistrationPlatForm";
import TrendingProducts from "./TrendingProducts/TrendingProducts";
import PopularCollection from "./PopularCollection/PopularCollection";
import AlertInfo from "./AlertInfo/AlertInfo";
import HeroSection from "./HeroSection/HeroSection";
import { Modal, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import axios from "axios";
import { ref } from "yup";
import { Link } from "react-router-dom";


const HomeHero = () => {
  // state to check if the user have active plan or not?
  const [showReminder, setShowReminder] = useState(false);
  const [showSubPlan, setShowSubPlan] = useState(false);
  const handleShowSubPlanClose = () => setShowSubPlan(false);
  const handleShowSubPlan = () => setShowSubPlan(true);

  // show reminder to the user to subscribe membershipe plan
  const Token = useSelector((state) => state.userAuth?.token);
  let newtoken = JSON.parse(Token);
  newtoken = newtoken?.token;
  // get user data
  const getUserData = localStorage.getItem("token");
  let UserData = JSON.parse(getUserData);
  // console.log("get user data", UserData?.user?.name);
  // get membership status data
  const membershipStatus = localStorage.getItem("membershipStatus");

  useEffect(() => {
    const membershipStatusData = JSON.parse(membershipStatus || "{}");
    const membershipStatusResult =
      membershipStatusData.memberships?.[0]?.status;

    // Check if the membership status is "CANCELED" and the user is logged in
    if (membershipStatusResult === "CANCELED" && newtoken) {
      setShowReminder(true);
      handleShowSubPlan(true);
      const reminderTimeout = setTimeout(() => {
        setShowReminder(false);
        handleShowSubPlan(false);
      }, 30000);

      // Cleanup the timeout to avoid memory leaks
      return () => clearTimeout(reminderTimeout);
    }
  }, [newtoken, membershipStatus]);

  return (
    <div className="rawstuff__hero-section">
      <HeroSection />
      <AlertInfo />
      <PopularCollection />
      <TrendingProducts />
      <RegistrationPlatForm />
      {/* <div useRefReminder={ref} className="subscription-reminder">
          You are running basic account, please subscribe to membership plan?
        </div> */}
      {/* {showReminder && (
        <div className="subscription-reminder">
          <h5 className="text-center mb-0">Subscribtion Reminder</h5>
          <span>Hi {UserData?.user?.name}</span> You are running a basic
          account, which means you will not have full access to all features.
          <div className="d-flex justify-content-between">
            <p className="pt-2">
              Please subscribe in order to upgrade your account
            </p>
            <button>
              <Link to="/membership">
                <i className="fa-solid fa-check"></i> &nbsp; Subscribe Now
              </Link>
            </button>
          </div>
        </div>
      )} */}
      {/* modal popup to remind the user subscrib membership plan */}
      {showReminder && (
        <Modal
          size="lg"
          show={showSubPlan}
          onHide={handleShowSubPlanClose}
          dialogClassName="custom-agb-modal custom-mdal-membership"
        >
          <Modal.Header closeButton>
            <Modal.Title>
               Subscription Reminder
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="agb-body-modal">
              <p>
                <span>Hi, {UserData.user.name} </span>
                You are running a basic account,which means you will not have
                full access to all features.
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <p>Please subscribe in order to upgrade your account</p>
            <Button variant="primary">
              <Link to="/membership">
              <i className="fa-solid fa-check"></i> &nbsp; Subscribe Now
              </Link>
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default HomeHero;
