import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Payment.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

const Payment = () => {
  const Navigate = useNavigate();
  const [selectedPaymentOption, setSelectedPaymentOption] =
    useState("rawstuffewallet");
  const [amount, setAmount] = useState("");
  const [ewallet, setEwallet] = useState([]);
  // get order data
  let getOrderData = useSelector((state) => state.orderSlice);
  // console.log("getOrderData", getOrderData);

  let Token = useSelector((state) => state.userAuth.token);
  let newtoken = JSON.parse(Token);
  // console.log("token", newtoken.token);
  // ? user info here
  let profileData = localStorage.getItem("token");
  let parseProfiledata = JSON.parse(profileData);
  const handleAmount = (e) => {
    setAmount(e.target.value);
  };
  // get e-wallet data from e-wallet endpoints
  useEffect(() => {
    const fetchEwallet = async () => {
      try {
        const response = await axios.get(
          "https://api.rawstuff.deals/api/v1/ewallet",
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${newtoken.token}`,
            },
          }
        );
        const data = response.data;
        setEwallet(data.data.walletInfo);
        // localStorage.setItem("orderId", data.data.id);
        // setShowCancelRefund(data.data.status === "pending");
        console.log("e-wallet", data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchEwallet();
  }, []);

  const handleTopUp = () => {
    // send the data request to server
    const apiUrl = "https://api.rawstuff.deals/api/v1/payments/topUp";
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${newtoken.token}`);
    var raw = JSON.stringify({
      amount: amount,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = fetch(apiUrl, requestOptions);
    response
      .then((res) => res.json())
      .then((res) => {
        // setPaymentInfo(res);
        let paymentData = JSON.stringify(res.data);
        localStorage.setItem("payment", paymentData);
        Navigate("/proceedtopayment");
        console.log(res);
      })
      .catch((error) => console.log(error));
  };

  const handleOrderAndPayment = async () => {
    try {
      const order_id = getOrderData.order?.id;

      if (order_id) {
        // Continue with payment logic if needed
        const paymentApiUrl =
          "https://api.rawstuff.deals/api/v1/payments/stripePay";
        const paymentHeaders = new Headers();
        paymentHeaders.append("Content-Type", "application/json");
        paymentHeaders.append("Authorization", `Bearer ${newtoken.token}`);
        const paymentRaw = JSON.stringify({
          order_id: order_id,
        });

        const paymentRequestOptions = {
          method: "POST",
          headers: paymentHeaders,
          body: paymentRaw,
          redirect: "follow",
        };

        const paymentResponse = await fetch(
          paymentApiUrl,
          paymentRequestOptions
        );
        const paymentData = await paymentResponse.json();

        console.log("Payment data:", paymentData);
        window.location.href = paymentData.data?.paylink;

        // Optionally navigate or update UI after successful payment
        console.log("Checkout, Stripe onboarding, and payment successful!");
      } else {
        console.error("Error: Order ID not found.");
      }
    } catch (error) {
      // Handle errors
      console.error("Error during checkout and payment:", error);
    }
  };

  return (
    <>
      <div className="payment-container">
        <div className="d-md-flex justify-content-between">
          <div className="selectPayment-method">
            <p>Pay via Stripe :</p>
          </div>
          {/* <div className="time-counter">
            <p>counter</p>
            <p>Time left to buy this product</p>
          </div> */}
        </div>
        <section className="paymentinfo-section border px-3">
          <div className="paymentinfo-header  d-md-flex mt-3">
            <p className="me-5">Personal Info</p>
            {/* <Link className="ms-3" to="/EditProfile">
              edit
            </Link> */}
          </div>

          <div className="paymentinfo-details mb-3 border-bottom">
            <p className="userInfo-key">
              Name:
              <span className="userInfo-value">
                {parseProfiledata.user.name}
              </span>
            </p>
            <p className="userInfo-key">
              Email:
              <span className="userInfo-value">
                {parseProfiledata.user.email}
              </span>
            </p>
            <p className="userInfo-key">
              Billing address:{" "}
              <span className="userInfo-value">Demo billing address</span>
            </p>
          </div>
          {/* <div className="paymentinfo-title ">
            <p className=" border-bottom pb-3">payment Info</p>
          </div> */}
          {/* <div className="rs-ewallet-container d-flex justify-content-between flex-column border-bottom"> */}
          {/* <div className="PaymentOptions-label">
              <label>
                <input
                  type="radio"
                  name="selectPaymentOptions"
                  value="directPay"
                  onChange={() => setSelectedPaymentOption("directPay")}
                  checked={selectedPaymentOption === "directPay"}
                />
                Direct Pay
              </label>
            </div> */}
          {/* <div className="PaymentOptions-label d-md-flex justify-content-between">
              <label>
                <input
                  type="radio"
                  name="selectPaymentOptions"
                  value="rawstuffewallet"
                  onChange={() => setSelectedPaymentOption("rawstuffewallet")}
                  checked={selectedPaymentOption === "rawstuffewallet"}
                />
                RawStuff E-wallet
              </label>
              <div class="account-balance pb-3  ">
                <p className="PaymentOptions-priceValue">{`€${ewallet.balance}`}</p>
                <p>account balance</p>
              </div>
            </div> */}
          {/* </div> */}
          <section className="select-RawstuffEwallet">
            {/* <div className="rs-current-container d-md-flex justify-content-between border-bottom py-3 ">
              <div className="sumofcurrent-cart">
                <p className="PaymentOptions-label">sum of the current cart</p>
              </div>
              <div class="sumofcurrent-cart pe-5  ">
                <p className="PaymentOptions-priceValue sumOfCurrentCart-value">
                  {`€ ${getOrderData.order?.total_taxed_price?.toFixed(2)}`}
                </p>
              </div>
            </div>
            <div className="rs-ewallet-container d-md-flex justify-content-between  py-3 ">
              <div className="ewallet">
                <p className="PaymentOptions-label">E-wallet balance</p>
              </div>
              <div class="ewallet pe-5  ">
                <p className="PaymentOptions-priceValue">{`€${ewallet.balance}`}</p>
              </div>
            </div> */}

            {/* <div className="pay-btn mb-3">
              <button>pay</button>
            </div> */}
            {/* <div className="pb-3 border-bottom">
              <h3>Recharge your e-wallet</h3>
            </div>
            <div className="E-wallet-amount mb-3">
              <label className="py-2" htmlFor="EnterAmount">
                Enter Amount
              </label>
              <br />
              <input
                type="text"
                name="amount"
                placeholder="5000"
                onChange={handleAmount}
              />
            </div> */}
            <div className="pay-btn mb-3">
              <button onClick={handleOrderAndPayment}>
                Proceed To Payment
              </button>
            </div>
          </section>
          {/* conditionally render directPay and rawstuff e-wallet balance */}
          {/* {selectedPaymentOption === "directPay" ? (
            <section className="select-Directpay">
              <div className="rs-current-container d-md-flex justify-content-between border-bottom py-3 ">
                <div className="sumofcurrent-cart">
                  <p className="PaymentOptions-label">
                    sum of the current cart
                  </p>
                </div>
                <div class="sumofcurrent-cart pe-5  ">
                  <p className="PaymentOptions-priceValue sumOfCurrentCart-value ">
                    $1451.97
                  </p>
                </div>
              </div>
              <div className="rs-ewallet-container d-md-flex justify-content-between  py-3 ">
                <div className="ewallet">
                  <p className="PaymentOptions-label">E-wallet balance</p>
                </div>
                <div class="ewallet pe-5  ">
                  <p className="PaymentOptions-priceValue ">$29986</p>
                </div>
              </div>

              <div className="pay-btn mb-3">
                <button onClick={handlePayment}>pay</button>
              </div>
            </section>
          ) : (
            <section className="select-RawstuffEwallet">
              <div className="rs-current-container d-md-flex justify-content-between border-bottom py-3 ">
                <div className="sumofcurrent-cart">
                  <p className="PaymentOptions-label">
                    sum of the current cart
                  </p>
                </div>
                <div class="sumofcurrent-cart pe-5  ">
                  <p className="PaymentOptions-priceValue sumOfCurrentCart-value">
                    $1451.97
                  </p>
                </div>
              </div>
              <div className="rs-ewallet-container d-md-flex justify-content-between  py-3 ">
                <div className="ewallet">
                  <p className="PaymentOptions-label">E-wallet balance</p>
                </div>
                <div class="ewallet pe-5  ">
                  <p className="PaymentOptions-priceValue">$29986</p>
                </div>
              </div>

              <div className="pay-btn mb-3">
                <button>pay</button>
              </div>
              <div className="pb-3 border-bottom">
                <h3>Recharge your e-wallet</h3>
              </div>
              <div className="E-wallet-amount mb-3">
                <label className="py-2" htmlFor="EnterAmount">
                  Enter Amount
                </label>
                <br />
                <input type="text" name="amount" placeholder="5000" />
              </div>
              <div className="pay-btn mb-3">
                <button>Topup Balance</button>
              </div>
            </section>
          )} */}
        </section>
      </div>
    </>
  );
};

export default Payment;
