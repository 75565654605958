import React, { useState } from "react";
import { BsStarFill, BsStarHalf, BsStar } from "react-icons/bs";

const Stars = ({ reviews }) => {
  const [selectedStars, setSelectedStars] = useState(0);

  const handleStarClick = (clickedIndex) => {
    setSelectedStars(clickedIndex + 1);
  };

  const tempStars = Array.from({ length: 5 }, (_, index) => {
    const number = index + 0.5;
    return (
      <span
        key={index}
        className="stars"
        style={{ padding: "0.5rem", paddingBottom: "0rem", opacity: "0.8" }}
        onClick={() => handleStarClick(index)}
      >
        {selectedStars >= index + 1 ? (
          <BsStarFill style={{ color: "yellow", borderColor: "#215aa1" }} />
        ) : selectedStars >= number ? (
          <BsStarHalf style={{ color: "yellow", borderColor: "#215aa1" }} />
        ) : (
          <BsStar style={{ color: "yellow", borderColor: "#215aa1" }} />
        )}
      </span>
    );
  });
  localStorage.setItem("reviews", selectedStars);

  return (
    <div className="stars-container">
      <article className="text-wrapper-4">
        {tempStars} {selectedStars}
        {reviews} Review(s)
      </article>
    </div>
  );
};

export default Stars;