import React from "react";
import "./AddTickets.css";
import Table from "./TicketTable";

const AddTicket = () => {
  return (
    <div className="Dashboard-main-container">
      <div className=" container-fluid mt-3">
        <div className="row w-90 mx-auto justify-content-between gap-5">
          <div className="col-sm ticketwrapper">
            <div>
              <Table />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTicket;
