import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "./DashboardPage.css";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import buydb from "../../Constants/Contents/DashboardsidebarENDE";

const DashboardPage = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [totalorder, setTotalorder] = useState(0);
  const [pendingorder, setPendingorder] = useState(0);
  const [plan, setPlan] = useState([]);

  localStorage.setItem("orderedItem", totalorder);

  const navigate = useNavigate();

  useEffect(() => {
    let newtoken = JSON.parse(localStorage.getItem("token"));
    newtoken = newtoken?.token;

    const fechorder = async () => {
      const response = await axios.get(
        "https://api.rawstuff.deals/api/v1/orders/getUserOrders",
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${newtoken}`,
          },
        }
      );
      const data = response.data;
      setTotalorder(data.count);

      const response2 = await axios.get(
        "https://api.rawstuff.deals/api/v1/orders/getUserOrders?status=pending",
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${newtoken}`,
          },
        }
      );
      const data2 = response2.data;
      setPendingorder(data2.count);

      const response3 = await axios.get(
        "https://api.rawstuff.deals/api/v1/membership",
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${newtoken}`,
          },
        }
      );
      const data3 = response3.data;
      //If not subscribed response/data3.memberships will be an empty array
      setPlan(data3.memberships);
    };
    if (!newtoken) {
      navigate("/login");
      return;
    }
    fechorder();
  }, [navigate]);

  let profileData = localStorage.getItem("token");
  let parseProfiledata = JSON.parse(profileData);
  let Token = useSelector((state) => state.userAuth.token);
  let newtoken = JSON.parse(Token);

  const cancelPlan = async () => {
    try {
      await axios.patch(
        `https://api.rawstuff.deals/api/v1/membership/${plan[0]?.id}`,
        { status: "CANCELED" },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${newtoken.token}`,
          },
        }
      );

      const response3 = await axios.get(
        "https://api.rawstuff.deals/api/v1/membership",
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${newtoken.token}`,
          },
        }
      );
      const data3 = response3.data;
      setPlan(data3.memberships);

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      console.log(error);
    }
  };

  if (!newtoken) {
    navigate("/login");
    return;
  }

  return (
    <div className="dashboardd">
      <div class="row" style={{ marginTop: "0%" }}>
        <div class="col-sm">
          <div className="col-sm d-none">
            <div class="alert alert-success" role="alert">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb"></ol>
              </nav>
            </div>
          </div>

          <div class="col-sm">
            <div className="col-md-12"></div>
            <div style={{ padding: "1%" }}>
              <div class="row">
                <div class="col-sm-6">
                  <div className="leftColumn">
                    <p style={{ height: "210px" }}>
                      <h5>
                        {" "}
                        {buydb.accinfode}
                        <hr />
                      </h5>
                      <font>{parseProfiledata?.user?.name}</font>
                      <br />
                      <font> {buydb.emailde}</font>{" "}
                      {parseProfiledata?.user?.email}
                      <br />
                      <font> {buydb.telde}</font>{" "}
                      {parseProfiledata?.user?.phone} <br />
                      <font>{buydb.cityde}:</font>{" "}
                      {parseProfiledata?.user?.city} <br />
                      <font>{buydb.zipcodede}:</font>{" "}
                      {parseProfiledata?.user?.postal_code}
                      <br />
                      <font> {buydb.adressde}</font>{" "}
                      {parseProfiledata?.user?.street},{" "}
                      {parseProfiledata?.user?.house_number},{" "}
                      {parseProfiledata?.user?.city},{" "}
                      {parseProfiledata?.user?.country}
                    </p>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="rightColumn">
                    <p align="centerr">
                      <h5>
                        {buydb.planinfode}
                        <hr />
                      </h5>
                      <font>{buydb.statusde}:</font> {plan[0]?.status}
                      <br />
                      <font>
                        {buydb.typede}: {plan[0]?.membership_type}
                      </font>
                      <br />
                      <font>{buydb.expirede}:</font> {plan[0]?.end_date}
                      <br />
                      <button class="btn btn-secondary btn-sm">
                        <a
                          href="/membership"
                          className="text-white"
                          style={{ textDecoration: "none" }}
                        >
                          <i class="fa fa-plus">&nbsp;&nbsp;</i>
                          {buydb.renewde}
                        </a>
                      </button>
                      &nbsp;
                      <button
                        class="btn btn-secondary btn-sm text-white"
                        onClick={handleShow}
                      >
                        <i class="fa fa-cancel">&nbsp;&nbsp;</i>
                        {buydb.cancelde}
                      </button>
                      <br/>
                      <p>
                        <div id="div1" style={{background:"none", padding:"1px"}}>
                   <small style={{fontSize:"12px"}}> 
                   ** Das monatliche Abonnement verlängert sich automatisch bis zur Kündigung durch den Nutzer und bietet kontinuierlichen
                   Zugang zu exklusiven Vorteilen bis zum Enddatum des aktuellen Abrechnungszyklus **
                   
                    </small>
                    </div>
                    </p>
                    </p>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*** Second Grid starts Here ****/}
          <div class="col-sm">
            <div className="col-md-12"></div>
            <div style={{ padding: "3%" }}>
              <div class="row">
                <div class="col-sm-6">
                  <div className="leftColumn">
                    <p>
                      <center>
                        {/***** FIRST Chart Starts here */}

                        <div
                          class="pie no-round"
                          title="Total Of Products Ordered"
                        >
                          {" "}
                          {totalorder}
                        </div>
                        <h5>{buydb.totalorderde}</h5>
                        <button
                          className="btn"
                          onClick={() =>
                            navigate("/dashboard/purchased-items/total")
                          }
                        >
                          {buydb.viewallde}
                        </button>
                        {/***** FIRST Chart Ends here */}
                      </center>
                    </p>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="rightColumn">
                    <p>
                      <center>
                        {/***** Second Chart Starts here */}

                        <div
                          class="pie1 no-round"
                          title="Total Of Pending Order"
                        >
                          {pendingorder}
                        </div>
                        <h5 align="center">{buydb.pendingorderde}</h5>
                        <button
                          className="btn"
                          onClick={() =>
                            navigate("/dashboard/purchased-items/pending")
                          }
                        >
                          {buydb.viewallde}
                        </button>
                        {/***** second Chart Ends here */}
                      </center>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/**Membership Modal starts here *****/}
        <br /> <br />{" "}
        <Modal
          show={show}
          onHide={handleClose}
          className="custom-popup"
          style={{ marginTop: "17%", height: "auto" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Cancel Plan</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="E-wallet-amount">
              <h5>Are you sure you want to cancel your subscription?</h5>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button onClick={() => cancelPlan()} variant="danger">
              Cancel Subscription
            </Button>
          </Modal.Footer>
        </Modal>
        {/*** Membership Modal ends here *****/}
      </div>
    </div>
  );
};

export default DashboardPage;
