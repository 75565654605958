import React, { useRef } from "react";
import "./style.css";
import ModalLogIn from "../../Regstration/ModalLogIn";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchProductDetail } from "../../../redux/Slice/productdetailSlice";
import AddToWishList from "../../WhishList/AddToWishList";
import BuyNOwButton from "../../MarketPlace/BuyNOwButton";
import { europeanCountriesCode } from "../../ManageWarehouse/ManageNewAdress";
import Certificate from "../../MarketPlace/Certificate";
import productcardENDE from "../../../Constants/Contents/ProductcardENDE";

export const convertCountryCodeToCountryName = (countryCode) => {
  const entry = Object.entries(europeanCountriesCode).find(
    ([_, value]) => value === countryCode
  );
  return entry ? entry[0] : null;
};

export const ProductCard = ({ ...props }) => {
  var jsonString = localStorage.getItem("token");
  var jsonObject = JSON.parse(jsonString);
  var token = jsonObject?.token;

  const [modalShow, setModalShow] = React.useState(false);
  const selectCardRef = useRef(null);

  const handleCardClick = () => {
    setModalShow(true);

    if (selectCardRef.current) {
      selectCardRef.current.classList.add("add-bg");
      setTimeout(() => {
        selectCardRef.current.classList.remove("add-bg");
      }, 30000);
    }
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleProductClick = (popularProductItem) => {
    dispatch(fetchProductDetail(popularProductItem.id));
    navigate("/productDetailPage");
  };

  return (
    <div className="container-fluidd">
      {token ? (
        <div className="">
          {props.currentPosts.map((trend, id) => (
            <div
              key={id}
              className="trending-product-card clickwl container-fluid"
            >
              <div className="trending-product-card-contentt row">
                <div
                  onClick={() => handleProductClick(trend)}
                  className="col-md-6 col-lg-4"
                >
                  <div className="productName">{trend.name}</div>

                  <div className="product-infoo product-info-result">
                    <div className="d-flex col-sm row px-0">
                      <div className="col-sm d-flex">
                        <p className="product-weight">
                          {productcardENDE.minde}:
                          <span>
                            &nbsp;{trend.min_quantity}
                            {trend.unit_of_measurement}
                          </span>
                        </p>
                        <p className="product-weight">
                          {productcardENDE.maxde}:
                          <span>
                            &nbsp;{trend.max_quantity}
                            {trend.unit_of_measurement}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="product-destination  col-sm px-0">
                    {productcardENDE.destde}:
                    <span>
                      &nbsp;
                      {convertCountryCodeToCountryName(trend.warehouse.country)}
                    </span>
                  </div>
                </div>

                <div className="col-md-6 col-lg-3 d-flex align-items-center pt-4">
                  <div className="d-flex gap-1">
                    <Certificate product={trend} />
                  </div>
                </div>

                <div className="col-md">
                  <div className="float-lg-end">
                    <div className="d-flex gap-5 justify-content-lg-between">
                      <div className="product-price">
                        {productcardENDE.pricede} {trend.unit_of_measurement}:
                      </div>
                      <div className="product-price-value">
                        €{trend.unit_price_eur}
                      </div>
                    </div>
                    <div className="d-flex product-actions">
                      <div className="hovered like-button">
                        <AddToWishList product_id={trend.id} />
                      </div>
                      <div
                        className="hovered addtocart_button px-sm-2 px-1"
                        onClick={() => props.handleAddToCart(trend)}
                      >
                        + {productcardENDE.addtocartde}
                      </div>
                      <BuyNOwButton product={trend} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="">
          {props.currentPosts.map((trend, id) => (
            <div
              key={id}
              className="trending-product-card clickwl container-fluid"
              onClick={handleCardClick}
            >
              <div className="trending-product-card-content">
                <div className="row">
                  <div className="col-md-6 col-lg-4">
                    <div className="productName pb-3">{trend.name}</div>
                    <p>
                      {productcardENDE.casnumberde} :
                      <span className="cas_number">({trend.cas_number})</span>
                    </p>

                    <div className="product-destination  col-sm px-0">
                      {productcardENDE.destde} :
                      <span>
                        &nbsp;
                        {convertCountryCodeToCountryName(
                          trend.warehouse.country
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-4 d-flex align-items-center">
                    <div className="d-flex gap-sm-1">
                      <Certificate />
                    </div>
                  </div>

                  <div className="col-md col-lg d-flex justify-content-lg-end align-items-center">
                    <div className="d-flex gap-2 gap-sm-3">
                      <div className="sellersNumber ">
                        {productcardENDE.sellersde} ({trend.number_of_sellers})
                      </div>
                      <div className="viewProduct-button px-3">
                        <p>{productcardENDE.viewproductde}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="loginPopup">
            <ModalLogIn show={modalShow} onHide={() => setModalShow(false)} />
          </div>
        </div>
      )}
    </div>
  );
};
