import React from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import "./Footer.css";
import { images } from "../../Constants";
import { useLocale, useTranslation } from "@psyycker/react-translation";
import { Link } from "react-router-dom";

const Footers = () => {
  //Get Language Translation starts
  const { getTranslation } = useTranslation();
  const [locale, setLocale] = useLocale();
  const onButtonClick = () => {
    var lang = document.getElementById("lang").value;
    var selectedx = "You have selected";
    if (lang == "English") {
      setLocale("en-US");
    }

    if (lang == "Germany") {
      setLocale("german");
    }

    if (lang == "Dutch") {
      setLocale("de");
    }

    if (lang == "French") {
      setLocale("fr-FR");
    }
  };

  //Get Language Translation ends

  return (
    <div className="Footer-main-wrapper">
      <div className="footer-container">
        <div className="container-fluid">
          <div className="row justify-content-between">
            <div className="col-lg-3  text-md-left">
              <div className="rawstuff__footer-logo mb-2">
                <Link to="/">
                  <img src={images.logo_rawstuff} alt="rawstuff-log_footer" />
                </Link>
              </div>
              <div className="rawstuff__footer-content">
                <p>
                  Willkommen bei unserem revolutionären Rohstoff-Marketplace,
                  der zukunftsorientierte Käufer und geprüfte Verkäufer in einer
                  sicheren Umgebung vereint, um effizienten, transparenten und
                  vertrauenswürdigen Handel zu ermöglichen.
                </p>
              </div>
              <div className="s-media-icon-container">
                <ul className="d-flex ">
                  <li className="s-media-icon-item">
                    <a href="">
                      <i class="fa-brands fa-xing"></i>
                    </a>
                  </li>
                  <li className="s-media-icon-item">
                    <a href="">
                      <i className="bi bi-envelope"></i>
                    </a>
                  </li>
                  <li className="s-media-icon-item">
                    <a href="">
                      <i class="fa-brands fa-linkedin"></i>
                    </a>
                  </li>

                  <li className="s-media-icon-item">
                    <a href="">
                      <i class="fa-brands fa-x-twitter"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="usefull-links col-lg-3 ">
              <h3 className="mb-2">
                {" "}
                {/* {getTranslation({ translationKey: "Footer" })} */}
                {getTranslation({ translationKey: "Links" })}
              </h3>
              <ul>
                <li>
                  <Link to="/">
                    {/* {getTranslation({ translationKey: "Home" })} */}
                    Startseite
                    <NavigateNextIcon />
                  </Link>
                </li>
                <li>
                  <a href="/agbs">
                    {/* {getTranslation({ translationKey: "TermsAndCondition" })} */}
                    Bedingungen und Konditionen
                    <NavigateNextIcon />
                  </a>
                </li>
                <li>
                  <Link to="/contactme">
                    {/* {getTranslation({ translationKey: "Contact" })} */}
                    Kontakt
                    <NavigateNextIcon />
                  </Link>
                </li>
                <li>
                  <Link to="/support">
                    {/* {getTranslation({ translationKey: "Support" })} */}
                    Support
                    <NavigateNextIcon />
                  </Link>
                </li>
                <li>
                  <Link to="/faq">
                    {/* {getTranslation({ translationKey: "FAQs" })} */}
                    FAQs
                    <NavigateNextIcon />
                  </Link>
                </li>
                <li>
                  <Link to="/privacy-policy">
                    {/* {getTranslation({ translationKey: "Privacy and Policy" })} */}
                    Datenschutz und Richtlinien
                    <NavigateNextIcon />
                  </Link>
                </li>
                <li>
                  <Link to="/impressum">
                    {/* {getTranslation({ translationKey: "Impressum" })} */}
                    Impressum
                    <NavigateNextIcon />
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 text-md-left d-none d-lg-block">
              <h3 className="mb-3">
                {" "}
                {getTranslation({ translationKey: "Post" })}
              </h3>
              <div className="recent-post d-flex">
                <div className="recent-post-banner ">
                  <Link to="/recentPost1">
                    <img
                      src={images.recent_post_sm1}
                      alt="post-thumnail here"
                    />
                  </Link>
                </div>
                <div className="post-content ps-2">
                  <h3>Pressemitteilung:</h3>
                  <p>
                    <Link to="/recentPost1">
                      Rawstuff Deals: Die neue Ära im globalen Rohstoffhandel
                      beginnt Rawstuff Deals.
                    </Link>
                  </p>
                  <p>Feb 28. 2024</p>
                </div>
              </div>
              <div className="recent-post d-flex">
                <div className="recent-post-banner">
                  <Link to="/recentPost2">
                    <img
                      src={images.recent_post_sm2}
                      alt="post-thumnail here"
                    />
                  </Link>
                </div>
                <div className="post-content ps-2">
                  <h3 className="mb-1">Pressemitteilung:</h3>
                  <p>
                    <Link to="/recentPost2">
                      Treffen Sie uns auf der Vitafood in Genf vom 14. bis 15.
                      Mai, wo wir voller Enthusiasmus dabei sein werden!
                    </Link>
                  </p>
                  <p>Feb 28. 2024</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copy-right-content border-top">
        <p>
          {/* {getTranslation({ translationKey: "CopyRight" })}  */}
          Copyright &copy;&nbsp;{new Date().getFullYear()}. All rights reserved
          Rawstuff Deals.
          {/* {getTranslation({ translationKey: "AllRightsReserved" })} */}
        </p>
      </div>
    </div>
  );
};

export default Footers;
