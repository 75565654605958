import React, { useState } from "react";
import { formatDate } from "./Purchased";
import axios from "axios";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import details from "../../Constants/Contents/OrderdetailsENDE";
import "../ShoppingCart/ShoppingPage.css";
const Order = ({ orderDetails }) => {
  const {
    order_number,
    status,
    total_price,
    cart_items,
    created_at,
    total_tax,
    total_quantity,
    total_taxed_price,
    total_discount_amount,
    user,
    id,
  } = orderDetails;

  const [loading, setLoading] = useState(false);

  // recreate order
  const recreateOrder = async () => {
    try {
      setLoading(true);
      let token = localStorage.getItem("token");
      token = JSON.parse(token).token;

      const response = await axios.post(
        "https://api.rawstuff.deals/api/v1/orders/createOrder",
        {
          method: "stripe",
          payment_status: "pending",
          order_note: "RecreateOrder",
          order_id: id,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data;
      const orderId = data.data.id;
      if (response.status === 200) {
        const paylinkResponse = await axios.post(
          "https://api.rawstuff.deals/api/v1/payments/stripePay",
          {
            order_id: orderId,
          },
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (paylinkResponse.status === 200) {
          toast.success("Order Recreated");
          window.location.href = paylinkResponse.data.data.paylink;
        }
      }

      setLoading(false);
    } catch (error) {
      toast.error("Error recreating order");
    }
  };

  return (
    <div className="order ">
      <hr />
      <div className="row">
        <div className="col-sm col-md-3">
          <p>
            {details.userde}: <strong>{user?.name}</strong>
          </p>
          <p>
            {details.emailde}: <strong>{user?.email}</strong>
          </p>
          <p>
            {details.statusde}: <strong>{status}</strong>
          </p>
          <p>
            {details.datede}: <strong>{formatDate(created_at)}</strong>
          </p>
          <p>
            {details.ordernumde}: <strong>{order_number}</strong>
          </p>
        </div>
        <div className="col-sm col-md">
          {/* <p>
            Total Distinct Quantity: <strong>{total_distinct_quantity}</strong>
          </p> */}
          <p>
            {details.totalquantityde}: <strong>{total_quantity}</strong>
          </p>
          <p>
            {details.totaltaxde}: <strong>€{total_tax}</strong>
          </p>

          <p>
            {details.totaldiscountde} :{" "}
            <strong>€{total_discount_amount}</strong>
          </p>

          <p>
            {details.totalpricenettode}: <strong>€{total_price}</strong>
          </p>

          <p>
            {details.totalpricebrutode} : <strong>€{total_taxed_price}</strong>
          </p>
        </div>

        <div className="col-sm col-md">
          {/* i want to create spinner here on the button */}
          <button className="btn btn-secondary" onClick={() => recreateOrder()}>
            {loading ? <Spinner animation="border" /> : details.recreateorderde}
          </button>
        </div>
      </div>

      <div className="order-details mt-3">
        <h3>{details.itemsde}:</h3>
        <div className="table-responsive mt-0 row">
          <div className="table-wrapper">
            <table
              className="table table-striped table-hover table-bordered table-responsive-sm"
              style={{ textAlign: "left" }}
            >
              <thead>
                <tr>
                  <th className="w-10">S/N</th>
                  <th className="w-10">{details.pruductde} </th>
                  <th className="w-10">{details.quantityde}</th>
                  <th className="">{details.unitpricede}</th>
                  <th className="w-10">{details.unitde}</th>
                  <th className="w-10">{details.discountde}(%)</th>
                  <th className="w-10">{details.taxde}(%)</th>
                  <th className="">{details.taxpricede}</th>
                  <th className="">{details.totalpricenettode}*</th>
                  <th className="">{details.shippingaddressde}</th>
                </tr>
              </thead>
              <tbody>
                {cart_items?.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.product.name}</td>
                    <td>{item.quantity}</td>
                    <td>€{item.product.unit_price_eur}</td>
                    <td>{item.product.unit_of_measurement}</td>
                    <td>{item.discount_rate}</td>
                    <td>{item.tax_rate}</td>
                    <td>€{item.tax.toFixed(2)}</td>
                    <td>€{item.price.toFixed(2)}</td>
                    <td className="address-cell">
                      {item.warehouse ? (
                        <div className="p-0 d-flex gap-2">
                          {item.warehouse.country && (
                            <p className="p-0 m-0">{item.warehouse.country},</p>
                          )}
                          {item.warehouse.city && (
                            <p className="p-0 m-0">{item.warehouse.city}</p>
                          )}
                          {item.warehouse.state && (
                            <p className="p-0 m-0">{item.warehouse.state},</p>
                          )}
                          {item.warehouse.street1 && (
                            <p className="p-0 m-0">{item.warehouse.zip}</p>
                          )}
                        </div>
                      ) : (
                        <p>No warehouse information available</p>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="d-flex important">
        <article className="wichtiger">Wichtiger Hinweis:</article>{" "}
        <article className="wichtigers">
          Auf die Steuerschuldnerschaft des Leistungsempfängers wird hingewiesen
        </article>
      </div>
    </div>
  );
};

export default Order;
