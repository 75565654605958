const address = {
  addressen: "Address",
  addressde: "Adresse",
  companyen: "Company",
  companyde: "Unternehmen",
  countryen: "Country",
  countryde: "Land",
  cityen: "City",
  cityde: "Stadt",
  zipen: "Zip code",
  zipde: "Postleitzahl",
  actionen: "Action",
  actionde: "Aktion",
  cancelen: "Cancel",
  cancelde: "Schließen",
  noen: "No",
  node: "Nr.",
  addnewaddressen: "Add New Address",
  addnewaddressde: "Neue Adresse anlegen",
  deliveryaddressnameen: "Delivery Address Name",
  deliveryaddressnamede: "Adresse Name",
  savemylocationen: "Save my location for future visit",
  savemylocationde: "meinen standort für kunftige besuche speichern",
  savedeliveryaddressen: "Save Delivery Address",
  savedeliveryaddressde: "neue Adresse sichern",
  selectcountryen: "Select Country",
  selectcountryde: "Land auswählen",
  editaddressen: "Edit Address",
  editaddressde: "Adresse bearbeiten",
  savechangesen: "Save Changes",
  savechangesde: "Speichern",
};

Object.freeze(address);

export default address;
