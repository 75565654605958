import React, { useState, useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useDispatch, useSelector } from "react-redux";
import { addToCarts } from "../../redux/Slice/ShoppingCartSlice";
import { ProductCard } from "./Withalgolia/ProductCard";
import { toast } from "react-toastify";

function SimilarProduct() {
  const [trendingobj1, setTrendingObj1] = useState([]);
  const [loading, setLoading] = useState(false);
  let [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(3);
  const [startofpage, setstartofpage] = useState(true);
  let Token = useSelector((state) => state.userAuth?.token);

  const dispatch = useDispatch();
  const handleAddToCart = (trend) => {
    dispatch(addToCarts(trend.id));

    if (Token && Token) {
      toast.success("Produkt erfolgreich in den Warenkorb gelegt");
    }
  };

  useEffect(() => {
    const fetchTrendingProducts = async () => {
      setLoading(true);
      try {
        const url =
          "https://api.rawstuff.deals/api/v1/products/getHottestProducts";

        const response = await fetch(url, {
          headers: {
            Accept: "application/json",
          },
        });
        const data = await response.json();
        setTrendingObj1(data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching hot product:", error);
      }
    };
    fetchTrendingProducts();
  }, []);

  // previus product
  const previous = () => {
    if (currentPage > 1) {
      if (currentPage === 2) {
        setstartofpage(true);
      } else {
        setstartofpage(false);
      }
      setCurrentPage(currentPage - 1);
    } else {
      setstartofpage(true);
    }
  };

  // next product
  const next = () => {
    if (currentPage < trendingobj1.length) {
      setCurrentPage(currentPage + 1);
      setstartofpage(false);
    }
  };

  // Get current posts
  const indexOfLastPost = currentPage + postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = trendingobj1.slice(indexOfFirstPost, indexOfLastPost);

  return (
    <div className="container-fluid">
      <div className="mxx-33i">
        <div className="best-title">
          {/* Similar Product */}
          Ähnliches Produkt
        </div>
        <div>
          {loading ? (
            <h3>Loading..</h3>
          ) : (
            <ProductCard
              currentPosts={currentPosts}
              handleAddToCart={handleAddToCart}
            />
          )}
          <div className="d-flex justify-content-between buttons">
            <div
              className={`${startofpage ? "startofpage" : "button-previous"}`}
              onClick={previous}
            >
              <ArrowBackIcon />
              <div>Vorherige</div>
            </div>
            <div class="button-next" onClick={next}>
              <div>Nächste</div>
              <ArrowForwardIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SimilarProduct;
