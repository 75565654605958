import React, { useEffect, useRef, useState } from "react";
import { connectHits } from "react-instantsearch-dom";
import { europeanCountriesCode } from "../ManageWarehouse/ManageNewAdress";

import { useDispatch, useSelector } from "react-redux";
import { addToCarts } from "../../redux/Slice/ShoppingCartSlice";

import "./style.css";
import AddToWishList from "../WhishList/AddToWishList";

import ModalLogIn from "../Regstration/ModalLogIn";
import { index } from "../../App";
import { fetchProductDetail } from "../../redux/Slice/productdetailSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BuyNOwButton from "../MarketPlace/BuyNOwButton";
import Certificate from "./CertificateForAlgoliaProduct";
import productcardENDE from "../../Constants/Contents/ProductcardENDE";

var jsonString = localStorage.getItem("token");
var jsonObject = JSON.parse(jsonString);
var token = jsonObject?.token;

export const convertCountryCodeToCountryName = (countryCode) => {
  const entry = Object.entries(europeanCountriesCode).find(
    ([_, value]) => value === countryCode
  );
  return entry ? entry[0] : null;
};
function Hits({ hits }) {
  let searchtext = localStorage.getItem("SearchQuery");

  const [myhit, setMyhit] = useState([]);
  const navigate = useNavigate();

  //click for details function
  const handleProductClick = (popularProductItem) => {
    dispatch(fetchProductDetail(popularProductItem.id));
    navigate("/productDetailPage");
    localStorage.setItem("SearchQuery", "");
  };

  useEffect(() => {
    const search = () => {
      index
        .search(searchtext)
        .then((hits) => {
          setMyhit(hits);
        })
        .catch((er) => {
          console.error("errorr index searching..");
        });
    };
    search();
  }, []);

  let pagehit;

  if (myhit.length > 0) {
    pagehit = myhit;
    // pagehit = hits;
  } else {
    pagehit = hits;
  }
  const dispatch = useDispatch();
  let Token = useSelector((state) => state.userAuth?.token);

  const handleAddToCart = (trend) => {
    dispatch(addToCarts(trend.id));
    if (Token && Token) {
      toast.success("Produkt erfolgreich in den Warenkorb gelegt");
    }
  };

  const [modalShow, setModalShow] = useState(false);
  const selectCardRef = useRef(null);

  const handleCardClick = () => {
    setModalShow(true);

    if (selectCardRef.current) {
      selectCardRef.current.classList.add("add-bg");
      setTimeout(() => {
        selectCardRef.current.classList.remove("add-bg");
      }, 30000);
    }
  };
  if (pagehit.length === 0) {
    return (
      <div className="text-center">
        <h3>
          No result found for <i>{searchtext}</i>
        </h3>
      </div>
    );
  }
  if (token) {
    return (
      <ol className="p-0 mx-0">
        {pagehit.map((hit) => (
          <li
            key={hit.id}
            className="trending-product-card clickwl container-fluid"
          >
            <div className="row">
              <div
                className="col-md-6 col-lg-4"
                onClick={() => handleProductClick(hit)}
              >
                <div className="productName">{hit.name}</div>
                <div className="product-infoo product-info-result">
                  <div className="d-flex col-sm row px-0">
                    <div className="col-sm d-flex">
                      <p className="product-weight">
                        {productcardENDE.minde} :
                        <span>
                          &nbsp;{hit.min_quantity}
                          {hit.unit_of_measurement}
                        </span>
                      </p>
                      <p className="product-weight">
                        {productcardENDE.maxde} :
                        <span>
                          &nbsp;{hit.max_quantity}
                          {hit.unit_of_measurement}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="product-destination col-sm px-0">
                  {productcardENDE.destde}:&nbsp;
                  {hit?.warehouse?.country &&
                    convertCountryCodeToCountryName(hit?.warehouse?.country)}
                </div>
              </div>

              <div
                className="col-md-6 col-lg row"
                onClick={() => handleProductClick(hit)}
              >
                <div className="d-flex align-items-center gap-1  pt-3 col align-self-end">
                  <Certificate product={hit} />
                </div>
              </div>

              <div className="col-md col-lg">
                <div className="float-lg-end">
                  <div className="d-flex gap-5  justify-content-lg-between">
                    <div className="product-price">
                      {productcardENDE.pricede} {hit.unit_of_measurement}:
                    </div>
                    <div className="product-price-value">
                      €{hit.unit_price_eur}
                    </div>
                  </div>
                  <div className="d-flex product-actions">
                    <div className="like-button hovered">
                      <AddToWishList product_id={hit.id} />
                    </div>
                    <div
                      className="addtocart_button hovered px-sm-2 px-1"
                      onClick={() => handleAddToCart(hit)}
                    >
                      + {productcardENDE.addtocartde}
                    </div>
                    <BuyNOwButton product={hit} />
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ol>
    );
  } else {
    return (
      <ol className="p-0">
        {pagehit.map((hit) => (
          <li
            onClick={handleCardClick}
            className="trending-product-card clickwl container-fluid"
            key={hit.objectID}
          >
            <div className="trending-product-card-content">
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <div className="productName pb-3">{hit.name}</div>
                  <p>
                    {productcardENDE.casnumberde}:
                    <span className="cas_number">({hit.cas_number})</span>
                  </p>
                  <div className="product_destination">
                    <p>
                      {productcardENDE.destde}:{" "}
                      {hit.warehouse?.country &&
                        convertCountryCodeToCountryName(hit.warehouse?.country)}
                    </p>
                  </div>
                </div>

                <div className="col-sm-12 col-md-6 col-lg-4 d-flex align-items-center">
                  <div className="d-flex gap-sm-1">
                    <Certificate />
                  </div>
                </div>

                <div className="col-sm-12 col-md col-lg d-flex justify-content-lg-end align-items-center">
                  <div className="d-flex gap-2 gap-sm-3">
                    <div className="sellersNumber">
                      {productcardENDE.sellersde} ({hit.number_of_sellers})
                    </div>
                    <div className="viewProduct-button px-3">
                      <p>{productcardENDE.viewproductde}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
        <div className="loginPopup">
          <ModalLogIn show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </ol>
    );
  }
}

export const CustomHits = connectHits(Hits);
