import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ticket from '../../Constants/Contents/Ticket';

function AddTicketModal({ show, handleClose, fetchdata }) {
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [photo, setPhoto] = useState(null);
  const [errors, setErrors] = useState({});

  const [successMessage, setSuccessMessage] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setErrors({});
  };

  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
    setErrors({});
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
    setErrors({});
    setSuccessMessage(false);
    setLoading(false);
  };

  const handlePhotoChange = (event) => {
    setPhoto(event.target.files[0]);
    setErrors({});
    setSuccessMessage(false);
    setLoading(false);
  };

  const validateForm = () => {
    const newErrors = {};

    if (!email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Invalid email address";
    }

    if (!subject.trim()) {
      newErrors.subject = "Subject is required";
    }

    if (!message.trim()) {
      newErrors.message = "Message is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let token = localStorage.getItem("token");
      token = JSON.parse(token);
      token = token.token;

     
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      };

      if (validateForm()) {
        // Send form data to backend
        try {
          setLoading(true);
          await axios.post(
            "https://api.rawstuff.deals/api/v1/support",
            {
              email,
              subject,
              message,
              image: photo,
            },
            config
          );
          setSuccessMessage(true);
          setLoading(false);
          setEmail("");
          setSubject("");
          setMessage("");
          setPhoto(null);
          fetchdata();
        } catch (error) {
          let newError = {};
          newError.email = "Something went wrong, try again!";
          setErrors(newError);
          setLoading(false);
          setSuccessMessage(false);
        }
      }
    } catch (error) {
      let newError = {};
      newError.email = "Please login first!";
      setErrors(newError);
      setLoading(false);
      setSuccessMessage(false);

      setTimeout(() => {
        navigate("/login");
      }, 2000);
    }
  };

  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleClose}
      style={{
        color: "black",
        overflowY: "scroll",
        height: "90vh",
        top: "19%",
      }}
    >
      <Modal.Header closeButton className="p-4">
        <Modal.Title className="fw-bold">{ticket.addticketde}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="container" onSubmit={handleSubmit}>
          <Form.Group
            className="border-bottom  row"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label className="col-sm-2 infld mt-0">
              {ticket.emailde}
            </Form.Label>
            <Form.Control
              className="col-sm border-0"
              type="email"
              value={email}
              onChange={handleEmailChange}
              autoFocus
              isInvalid={!!errors.email}
            />
          </Form.Group>

          <Form.Group
            className="border-bottom row"
            controlId="exampleForm.ControlSelect1"
          >
            <Form.Label className="col-sm-2 mt-1 infld">{ticket.subjectde}</Form.Label>
            <Form.Control
              className="col-sm border-0"
              type="text"
              value={subject}
              onChange={handleSubjectChange}
              isInvalid={!!errors.subject}
            />
          </Form.Group>

          <Form.Group
            className="mb-3 row border-bottom"
            controlId="exampleForm.ControlTextarea1"
          >
            <Form.Label className="col-sm-2 infld">{ticket.bodyde}</Form.Label>
            <Form.Control
              className="col-sm border-0"
              as="textarea"
              value={message}
              onChange={handleMessageChange}
              rows={3}
              isInvalid={!!errors.message}
            />
          </Form.Group>

          {/* <Form.Group className="" controlId="exampleForm.ControlFile1">
            <Form.Label className="">Upload Photo</Form.Label>
            <Form.Control
              className="border-0 "
              type="file"
              onChange={handlePhotoChange}
            />
          </Form.Group> */}
          <button
            type="submit"
            className={`sendbtn ${
              Object.keys(errors).length !== 0 && "disabled"
            } ${loading && "disabled"}`}
            disabled={
              Object.keys(errors).length !== 0 || loading
            }
          >
            {ticket.sendde} {"  "}
            {loading && (
              <span>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </span>
            )}
          </button>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {successMessage && (
          <p className="text-success">Message sent successfully</p>
        )}

        {errors && (
          <p className="text-danger">{errors[Object.keys(errors)[0]]}</p>
        )}
        <button
          className="btn btn-clr mr-3"
          onClick={handleClose}
          type="button"
        >
          {ticket.closede}
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddTicketModal;