import React, { useState, useEffect } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { europeanCountries, europeanCountriesCode } from "./ManageNewAdress";
import address from "../../Constants/Contents/Address";

function EditWarehouse({
  modalOpen,
  handleModalClose,
  fetchwarehouse,
  warehouseDetails,
}) {
  const [newWarehouseDetails, setNewWarehouseDetails] = useState({});
  const [wareId, setWareId] = useState();
  const [errors, setErrors] = useState({
    name: "",
    company: "",
    // street1: "",
    city: "",
    zip: "",
    country: "",
  });

  const handleFormInputChange = (event) => {
    const { name, value } = event.target;
    setNewWarehouseDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));

    if (newWarehouseDetails[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }

    if (name === "zip") {
      const isValidZipCode = /^[a-zA-Z0-9]{4,7}$/.test(value);

      if (!isValidZipCode) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Invalid zip code",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
      }
    }
  };

  const EditWareHouse = async () => {
    try {
      var jsonString = localStorage.getItem("token");
      var jsonObject = JSON.parse(jsonString);
      var Token = jsonObject?.token;

      let formIsValid = true;
      const newErrors = { ...errors };

      Object.keys(newWarehouseDetails).forEach((key) => {
        if (newWarehouseDetails[key] === "" || !newWarehouseDetails[key]) {
          formIsValid = false;
          newErrors[key] = `${
            key.charAt(0).toUpperCase() + key.slice(1)
          } is required`;
        } else {
          newErrors[key] = "";
        }
      });

      if (!formIsValid) {
        setErrors(newErrors);
        return;
      }

      const isValidZip = /^[a-zA-Z0-9]{4,7}$/.test(newWarehouseDetails["zip"]);

      if (!isValidZip) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          zip: "Invalid zip code",
        }));
        return;
      }

      // Include country code in the warehouse details
      // newWarehouseDetails["country_code"] =
      // europeanCountriesCode[newWarehouseDetails["country"]];
      newWarehouseDetails["country"] =
        europeanCountriesCode[newWarehouseDetails["country"]];

      // Make a request to create a warehouse
      const response = await fetch(
        `https://api.rawstuff.deals/api/v1/warehouses/${wareId}`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${Token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newWarehouseDetails),
        }
      );

      if (response.ok) {
        // toast.success("Warehouse edited successfully!");
        toast.success("Lager erfolgreich bearbeitet!");
        fetchwarehouse();
        handleModalClose();
      } else {
        // toast.error("Failed to edit a warehouse?. Please try again.");
        toast.error("Fehler beim Bearbeiten eines Lagers? Bitte versuche es erneut.")
      }
    } catch (error) {
      // toast.error("An error occurred. Please try again later.");
      toast.error(
        "Ein Fehler ist aufgetreten. Bitte versuchen Sie es später noch einmal."
      );
    }
  };

  useEffect(() => {
    if (warehouseDetails) {
      const { id, name, company, city, zip, country } = warehouseDetails;
      setWareId(id);
      setNewWarehouseDetails({ name, company, city, zip, country });
    }
  }, [warehouseDetails]);

  return (
    <Modal id="shopping-address-bg" show={modalOpen} onHide={handleModalClose}>
      <Modal.Body>
        <Modal.Header closeButton>
          <Modal.Title className="add-new-address">
            {address.editaddressde}
          </Modal.Title>
        </Modal.Header>
        <Form>
          <Row>
            <Form.Group controlId="formGridName">
              <Form.Label className="">
                {address.deliveryaddressnamede}
              </Form.Label>
              <Form.Control
                type="text"
                name="name"
                required
                value={newWarehouseDetails.name}
                onChange={handleFormInputChange}
              />
              <span className="text-danger">{errors.name}</span>
            </Form.Group>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="formGridCompany">
                <Form.Label className="mt-3 mb-1">
                  {address.companyde}
                </Form.Label>
                <Form.Control
                  type="text"
                  required
                  name="company"
                  value={newWarehouseDetails.company}
                  onChange={handleFormInputChange}
                />
                <span className="text-danger">{errors.company}</span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Form.Group controlId="formGridCountry">
              <Form.Label className="mt-3 mb-1">{address.countryde}</Form.Label>
              <Form.Control
                as="select"
                name="country"
                required
                value={newWarehouseDetails.country}
                onChange={handleFormInputChange}
              >
                <option value="">{newWarehouseDetails?.country}</option>
                {europeanCountries.map((country) => (
                  <option key={country} value={country}>
                    {country}
                  </option>
                ))}
              </Form.Control>
              <span className="text-danger">{errors.country}</span>
            </Form.Group>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group controlId="formGridCity">
                <Form.Label className="mt-3  mb-1">{address.cityde}</Form.Label>
                <Form.Control
                  type="text"
                  name="city"
                  required
                  value={newWarehouseDetails.city}
                  onChange={handleFormInputChange}
                  style={{ borderRadius: "8px" }}
                />
                <span className="text-danger">{errors.city}</span>
              </Form.Group>
            </Col>
            {/* <Col>
              <Form.Group controlId="formGridStreet1">
                <Form.Label className="mt-3 mb-1">Street 1</Form.Label>
                <Form.Control
                  type="text"
                  name="street1"
                  required
                  value={newWarehouseDetails.street1}
                  onChange={handleFormInputChange}
                />
                <span className="text-danger">{errors.street1}</span>
              </Form.Group>
            </Col> */}
          </Row>
          <Row className="mb-2">
            <Col xs={12} md={6}>
              <Form.Group controlId="formGridZip">
                <Form.Label className="mt-3  mb-1">{address.zipde}</Form.Label>
                <Form.Control
                  type="text"
                  name="zip"
                  required
                  value={newWarehouseDetails.zip}
                  onChange={handleFormInputChange}
                />
                <span className="text-danger">{errors.zip}</span>
              </Form.Group>
            </Col>
          </Row>

          <Form.Group className="position-relative d-none">
            <Form.Check
              className="Save-my-location"
              required
              name="terms"
              label="Save my location for future visit"
              // onChange={handleChange}
              feedbackType="invalid"
              id="validationFormik106"
              feedbackTooltip
              style={{
                height: "48px",
                width: "30rem",
                // margin: "0.5rem",
                paddingleft: "1.5rem",
                border: "#E1E1E1",
                display: "flex",
                alignItems: "center",
                fontSize: "1rem",
              }}
            />
          </Form.Group>
          <Button
            className="Save-Delivery"
            style={{ margin: "0.3rem" }}
            variant="secondary"
            onClick={() => EditWareHouse()}
          >
            {address.savechangesde}
          </Button>
          <Button
            className="cancel-bt"
            onClick={handleModalClose}
            variant="secondary"
          >
            {address.cancelde}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default EditWarehouse;
