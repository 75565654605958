import React, { useEffect, useState } from "react";
import { Highlight, Hits } from "react-instantsearch-dom";
import { Link, useNavigate } from "react-router-dom";
import "./AutoComplete.css";
// import "./custom-highlight.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setHit,
  setLoading,
  setError,
} from "../../../redux/Slice/AlgoriaSlice";
import { toast } from "react-toastify";

// *hit widget from algolia
export const Hit = ({ hit }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const isLoading = useSelector((state) => state.algolia.isLoading);
  // const error = useSelector((state) => state.algolia.error);

  var jsonString = localStorage.getItem("token");
  var jsonObject = JSON.parse(jsonString);
  var token = jsonObject?.token; // Replace 'token' with your token key

  const onclickhandler = () => {
    dispatch(setHit(hit));
    navigate(`/result/${hit.name}`);
    localStorage.setItem('SearchQuery', hit.name);
  };

  return (
    <ul
      onClick={onclickhandler}
      className="d-flex  align-items-center mx-1 p-3 w-100  autocoplet_list-content "
    >
      <h1 className="col-4 text-left">
        <Link className="search-links" to="/result">
          <Highlight attribute="name" hit={hit} />
        </Link>
        {/* {token ? (
            <Link className="search-links" to="/searchProductDetail">
              <Highlight attribute="name" hit={hit} />
            </Link>
          ) : (
            <Link className="search-links" to="/login">
              <Highlight attribute="name" hit={hit} />
            </Link>
          )} */}
      </h1>

      <p className="autocomplete_cas-number col-4 text-center">
        <Highlight attribute="cas_number" hit={hit} />
        {/* {`(${hit.cas_number})`} */}
      </p>

      <p className="sellers col-4 text-end">{`sellers(${hit.number_of_sellers})`}</p>
    </ul>
  );
};

const AutoComplete = () => {
  return <Hits hitComponent={Hit} />;
  // return null;
};

export default AutoComplete;
