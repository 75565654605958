import React, { useState } from "react";
import "./Coupon.css";

const Coupon = () => {
  const [couponCode, setCouponCode] = useState("");

  const handleApplyCoupon = () => {
    // Add your coupon application logic here
    if (couponCode === "SUMMER2023") {
      // Coupon code is valid
      // Apply the discount or perform any necessary operations
      // Reset the coupon code input
      setCouponCode("");
    } else {
      // Coupon code is invalid
      // Reset the coupon code input
      setCouponCode("");
    }
  };

  return (
    <div className="border mb-3 mt-4 p-3">
      <p>Have a coupon?</p>
      <div className="coupon-code-input-container d-flex">
        <input
          className="coupon-code-input form-control"
          type="text"
          id="coupon-code"
          placeholder="Coupon code"
          value={couponCode}
          onChange={(e) => setCouponCode(e.target.value)}
        />
        <button className="btn-apply" onClick={handleApplyCoupon}>
          Apply
        </button>
      </div>
    </div>
  );
};

export default Coupon;
