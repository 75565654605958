import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

export const fetchProductDetail = createAsyncThunk(
  "cartData/fetchProductDetail",
  async (id, thunkAPI) => {
    try {
      const response = await fetch(
        `https://api.rawstuff.deals/api/v1/products/${id}`
      );

      const data = await response.json();
      // Save the fetched data to local storage
      localStorage.setItem('productDetail', JSON.stringify(data.data));

      return data.data;
    } catch (error) {
      console.log(error.response.data.message);
      // throw toast.error(error.response.data.message);
    }
  }
);

const initialProductDetail = JSON.parse(localStorage.getItem('productDetail')) || [];

const productDetailSlice = createSlice({
  name: "productDetail",
  initialState: {
    status: "idle",
    error: null,
    productDetail: initialProductDetail,
  },
  reducers: {
    filterItems: (state, { payload }) => {
      const newItems = state.productDetail.filter((item) =>
        item.name.toLowerCase().includes(payload.toLowerCase())
      );
      state.productDetail = newItems;
    },
    updateSearch: (state, { payload }) => {
      state.searchParams = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProductDetail.fulfilled, (state, { payload }) => {
      state.status = "succeeded";
      state.productDetail = payload;
      localStorage.setItem("productDetail", JSON.stringify(payload));
    });
    builder.addCase(fetchProductDetail.rejected, (state, { error }) => {
      state.status = "failed";
      state.error = error.message;
    });
  },
});

export const { filterItems, updateSearch } = productDetailSlice.actions;
export default productDetailSlice.reducer;

// In your component where you have access to id
// Dispatch fetchProductDetail only if id exists
// For example:
/*
if (id) {
  dispatch(fetchProductDetail(id));
}
*/
