import React, { useRef } from "react";
import EuroIcon from "@mui/icons-material/Euro";
import ModalLogIn from "../Regstration/ModalLogIn";
import { fetchProductDetail } from "../../redux/Slice/productdetailSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import RemoveFromList from "./RemoveFromList";
import productcardENDE from "../../Constants/Contents/ProductcardENDE";
import BuyNOwButton from "../MarketPlace/BuyNOwButton";
export const ProductCard = ({ ...props }) => {
  var jsonString = localStorage.getItem("token");
  var jsonObject = JSON.parse(jsonString);
  var token = jsonObject?.token;

  const [modalShow, setModalShow] = React.useState(false);
  const selectCardRef = useRef(null);

  const handleCardClick = () => {
    setModalShow(true);

    if (selectCardRef.current) {
      selectCardRef.current.classList.add("add-bg");
      setTimeout(() => {
        selectCardRef.current.classList.remove("add-bg");
      }, 30000);
    }
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  //click for details function
  const handleProductClick = (popularProductItem) => {
    dispatch(fetchProductDetail(popularProductItem.id));
    navigate("/productDetailPage");
  };

  return (
    <div>
      {props.currentPosts && props.currentPosts.length > 0 ? (
        <div className="popular-product-card">
          {token ? (
            <div>
              {props.currentPosts.map((trend, id) => (
                <div
                  key={id}
                  className="trending-product-card clickwl container"
                >
                  <div className="row">
                    <div
                      onClick={() => handleProductClick(trend)}
                      className="col-sm d-flex"
                    >
                      <div>
                        <div className="productName">{trend.name}</div>
                        <div className="product-infoo product-infoo-p">
                          <p className="product-weight">
                            {productcardENDE.minde}:{" "}
                            <span>
                              {trend.min_quantity} {trend.unit_of_measurement}
                            </span>
                          </p>
                          <p className="product-weight">
                            {productcardENDE.maxde}:{" "}
                            <span>
                              {trend.max_quantity} {trend.unit_of_measurement}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm d-flex flex-column align-items-xl-end">
                      <div className="d-flex gap-5 align-items-start justify-content-start">
                        <div className="product-price">
                          {productcardENDE.pricede} {trend.unit_of_measurement}:
                        </div>
                        <div className="product-price-value">
                          €{trend.unit_price_eur}
                        </div>
                      </div>

                      <div className="d-flex product-actions">
                        <RemoveFromList
                          id={trend.id}
                          list_name={props.list_name}
                          handleDelete={props.handleDelete}
                        />
                        <div
                          className="addtocart_button hovered px-1 px-sm-3"
                          onClick={() => props.handleAddToCart(trend)}
                        >
                          + {productcardENDE.addtocartde}
                        </div>
                        <BuyNOwButton product={trend} />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div>
              {props.currentPosts.map((trend, id) => (
                <div
                  key={id}
                  className="trending-product-card clickwl container"
                >
                  <div className="row" onClick={handleCardClick}>
                    <div className="col-sm d-flex">
                      <div>
                        <div className="productName pb-3">{trend.name}</div>
                        <div className="d-flex product-weight-wraper">
                          <p className="product-weight">
                            MinOQ: <span className="product-wspan">****</span>
                          </p>
                          <p className="product-weight">
                            MaxOQ: <span className="product-wspan">****</span>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm d-flex">
                      <div className="ms-xl-auto">
                        <div className="d-flex align-items-center">
                          <div className="product-price mr-5 pr-2">
                            Price per kg:
                          </div>
                          <div className="product-price-value">
                            <EuroIcon />
                            ******
                          </div>
                        </div>
                        <div className="d-flex product-actions">
                          <div
                            className="addtocart_buttonm"
                            onClick={() => props.handleAddToCart(trend)}
                          >
                            +Add to cart{" "}
                          </div>
                          <div className="buynow_button">Buy Now</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div className="loginPopup">
                <ModalLogIn
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                />
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>Leerer Merkzettel</div>
      )}
    </div>
  );
};
