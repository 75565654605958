import React from 'react';
import SimilarProduct from './SimilarProduct';
import Filter from './Filter';
import Result from './Result';

function SearchResultPage() {
  return (
    <div className='mx-sm-5 mt-5 pt-5'>
      <div className='mt-5 pt-5'>
        {/* <Filter /> */}
      </div>
      <div className='mt-5'>
        <Result />
      </div> 
      <div className='mt-5'>
        <SimilarProduct />
      </div>
    </div>
  )
}

export default SearchResultPage;
