import React, { useEffect, useState } from "react";
import Order from "./Order";
import { useParams } from "react-router-dom";
import axios from "axios";

function PurchasedItemDetails() {
  const { id } = useParams();

  let token = localStorage.getItem("token");
  token = JSON.parse(token).token;

  const [order, setOrder] = useState([]);

  useEffect(() => {
    document.title = `Purchased Item Details - ${id}`;
    window.scrollTo(0, 0);
    const fetch = async () => {
      const res = await axios.get(
        `https://api.rawstuff.deals/api/v1/orders/getOrder/${id}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = res.data;
      setOrder(data.data);
    };

    fetch();
  }, [id, token]);

  return (
    <div className="dashboard">
      <br /> <br /> <br /> <br /> <br />
      <div class="row">
        <div className="container">
          <h2 className="text-underline ">
            {/* Order Details */}
            Details Zur Bestellung
          </h2>
          <Order orderDetails={order} />
        </div>
      </div>
    </div>
  );
}

export default PurchasedItemDetails;
