import React, { useState, useEffect, useRef } from "react";
import { SearchBox } from "react-instantsearch-dom";
import "./AlgoSearch.css";
import AutoComplete from "./AutoComplete/AutoComplete";
import { useNavigate } from "react-router-dom";

function AlgoSearch() {
  // *AutoComplete use state and useEffect
  const [showAutocomplete, setShowAutocomplete] = useState(false);
  const autocompleteRef = useRef(null);
  const navigate = useNavigate();

  const handleFocus = () => {
    setShowAutocomplete(true);
  };
  const handleClearAll = () => {
    setShowAutocomplete(false);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        autocompleteRef.current &&
        !autocompleteRef.current.contains(event.target)
      ) {
        setShowAutocomplete(false);
      }
    };
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [autocompleteRef]);
  // *AutoComplete use state and useEffect end

  const [input, setInput] = useState("");
  const [errors, setErrors] = useState({});

  const handleSearchInputChange = (event) => {
    const { value } = event.currentTarget;
    setInput(value);
    setErrors(null);
  };

  //For JS
  function validateInputAgainstJSCode(inputField) {
    // Use a regex pattern to restrict input to allowed characters and inputs
    const allowedCharacters = /^[a-zA-Z0-9 \-,()']+$/;
    if (inputField) {
      if (!inputField.match(allowedCharacters)) {
        inputField = inputField.replace(/[^\w\s\-,()']/gi, "");
        return false;
      }
    }
    return true;
  }

  const validateForm = () => {
    let errors = {};
    //check input aginst HTML
    const htmlRegex = /<[\s\S]*?>/;
    //JS ERROR
    const js = validateInputAgainstJSCode(input);

    if (!input) {
      alert("Empty, please provide text.");
      errors.message = "Empty, please provide text.";
    } else if (htmlRegex.test(input)) {
      alert("Please do not give me HTML tag.");
      errors.message = "Please do not give me HTML tag.";
    } else if (!js) {
      // alert("Please do not give me javascript code");
      // errors.message = "Please do not give me javascript code";
    } else {
      errors.message = null;
    }
    return errors;
  };
  const handleSearchSubmit = (event) => {
    event.preventDefault();
    if (input !== "") {
      localStorage.setItem("SearchQuery", input);
      navigate(`/result/${input}`);
    }
    const error = validateForm();
    if (error) {
      setErrors(error);
    }
  };

  const [inp, setInp] = useState("");
  useEffect(() => {
    let searchtext = localStorage.getItem("SearchQuery");
    setInp(searchtext);
  });

  return (
    <div className="search-engine-a">
      <div className="search-auto-container" ref={autocompleteRef}>
        <SearchBox
          hitsPerPage={2}
          onClick={handleFocus}
          translations={{ placeholder: "Rohmaterialien" }}
          showClear={false}
          onSubmit={(event) => {
            handleSearchSubmit(event);
          }}
          onChange={handleSearchInputChange}
          // defaultRefinement={inp}
        />
        <div className="autocomplete-popup">
          {showAutocomplete && (
            <div className=" recent_search-clearall d-flex justify-content-between py-3 px-3 bg-white ">
              <div>Recent</div>
              <div onClick={handleClearAll}>clear all</div>
            </div>
          )}

          {showAutocomplete && <AutoComplete />}
        </div>
      </div>
    </div>
  );
}

export default AlgoSearch;