import { configureStore } from "@reduxjs/toolkit";
import shoppingCartReducer from "./Slice/ShoppingCartSlice";
import ProductReducer from "./Slice/ProductSlice";
import thunkMiddleware from "redux-thunk";
import getCartSlice from "./Slice/getCartSlice";
import authReducer from "./Slice/userAuthSlice";
import orderIdReducer from "./Slice/OrderDetailSlice";
import productDetailReducer from "./Slice/productdetailSlice";
import searcheDetailReducer from "./Slice/AlgoriaSlice";
// import shippingReducer from './Slice/ShippingSlice';
import checkoutSlice from "../redux/Slice/CreatOrderSlice";
import stripeOnBoardReducer from "../redux/Slice/stripeOnBoardingSlice";
import WishListSlice from "./Slice/WishListSlice";

const store = configureStore({
  reducer: {
    products: ProductReducer,
    cart: shoppingCartReducer,
    getCart: getCartSlice,
    userAuth: authReducer,
    orderID: orderIdReducer,
    productDetail: productDetailReducer,
    hit: searcheDetailReducer,
    // shipping: shippingReducer,
    orderSlice: checkoutSlice,
    stripeOnBoard: stripeOnBoardReducer,
    wishlist: WishListSlice,
  },
  middleware: [thunkMiddleware],
});
export default store;

