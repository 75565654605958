import React from 'react'
import { useParams } from 'react-router-dom'

const Details = () => {
    const { title } = useParams()
  return (
    <div className='top-300 container'>
        <div className='col col-lgg-6 col-md-9 col-sm-12 col-xs-12 mx-auto'>
            <div className='text-center'>
                <div className='hellodetails mb-3'>{title}</div>
                <p>Your privacy is important to us at Untitled. We respect your privacy regarding any information we may collect from you across our website.</p>
            </div>
            <div className='section1 my-5'>
                <div className='header1'>Lorem ipsum dolor, consectetur adipisicing elit.</div>
                <p>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rerum
                    similique rem, tenetur hic blanditiis eius repudiandae
                    laboriosam alias dolorem nam aspernatur voluptate, sint earum,
                    amet omnis quam commodi delectus itaque? Commodi labore magnam
                    excepturi beatae, vero rerum illo fuga, suscipit in eos ab
                    mollitia odio optio repellat ratione perferendis neque non
                    dignissimos, molestiae dolorum aspernatur odit deserunt natus?
                    Nobis, non. Debitis recusandae dignissimos neque animi accusamus
                    magnam modi totam, commodi perspiciatis numquam ipsam error
                </p>
            </div>
            <div className='section1 my-5'>
                <div className='header1'>Lorem ipsum dolor, consectetur adipisicing elit.</div>
                <p>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rerum
                    similique rem, tenetur hic blanditiis eius repudiandae
                    laboriosam alias dolorem nam aspernatur voluptate, sint earum,
                    amet omnis quam commodi delectus itaque? Commodi labore magnam
                    excepturi beatae, vero rerum illo fuga, suscipit in eos ab
                    mollitia odio optio repellat ratione perferendis neque non
                    dignissimos, molestiae dolorum aspernatur odit deserunt natus?
                    Nobis, non. Debitis recusandae dignissimos neque animi accusamus
                    magnam modi totam, commodi perspiciatis numquam ipsam error
                </p>
            </div>
            <div className='section1 my-5'>
                <div className='header1'>Lorem ipsum dolor, consectetur adipisicing elit.</div>
                <p>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rerum
                    similique rem, tenetur hic blanditiis eius repudiandae
                    laboriosam alias dolorem nam aspernatur voluptate, sint earum,
                    amet omnis quam commodi delectus itaque? Commodi labore magnam
                    excepturi beatae, vero rerum illo fuga, suscipit in eos ab
                    mollitia odio optio repellat ratione perferendis neque non
                    dignissimos, molestiae dolorum aspernatur odit deserunt natus?
                    Nobis, non. Debitis recusandae dignissimos neque animi accusamus
                    magnam modi totam, commodi perspiciatis numquam ipsam error
                </p>
            </div>
        </div>
    </div>
  )
}

export default Details;