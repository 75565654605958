import React, { useState, useEffect } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TicketDetailsModal from "./TicketDetailsModal";
import axios from "axios";
import AddTicketModal from "./AddTicketModal";
import ticket from '../../Constants/Contents/Ticket';

export const timeConvert = (time) => {
  const date = new Date(time);
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  return date.toLocaleString(undefined, options);
};

const Table = () => {
  let [data, setData] = useState([]);

  data = data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

  const [showdetails, setShowdetails] = useState(false);

  const fetchdata = async () => {
    let token = localStorage.getItem("token");
    token = JSON.parse(token).token;

    const response = await axios.get(
      "https://api.rawstuff.deals/api/v1/support",
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = response.data;

    setData(data.tickets);
  };

  useEffect(() => {
    fetchdata();
  }, []);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [info, setInfo] = useState({});

  const sendinfo = (data) => {
    setShowdetails(true);
    setInfo(data);
  };

  return (
    <div>
      <div className="d-flex gap-5 pt-4 ticketheader mb-5">
        <div>{ticket.ticketde}</div>
        <button
          onClick={() => handleShow()}
          className="btn btn-dark px-4 rounded-5"
        >
          {ticket.addticketde}
        </button>
        <AddTicketModal
          show={show}
          handleClose={handleClose}
          fetchdata={fetchdata}
        />
      </div>

      <table className="table table-responsive-sm">
        <thead>
          <tr className="">
            <th style={{ textAlign: "left" }}>{ticket.ticketide}</th>
            <th style={{ textAlign: "left" }}>{ticket.subjectde}</th>
            <th style={{ textAlign: "left" }}>{ticket.timede}</th>
            <th style={{ textAlign: "left" }}>{ticket.statusde}</th>
            <th >{ticket.viewde}</th>
          </tr>
        </thead>
        <tbody className="">
          {data.map((item) => (
            <tr key={item.id}>
              <td>{item.ticket_id}</td>
              <td>{item.subject}</td>
              <td>{timeConvert(item.created_at)}</td>
              <td className="flex gap-5">{item.status}</td>
              <td>
                <button
                  className="btn btn-light"
                  onClick={() => sendinfo(item)}
                >
                  <VisibilityIcon />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <TicketDetailsModal
        show={showdetails}
        info={info}
        fetchdata={fetchdata}
        onHide={() => setShowdetails(false)}
      />
    </div>
  );
};

export default Table;
