import React, { useEffect, useState } from "react";
import axios from "axios";
import Slider from "react-slick";
import "./TrendingProducts.css";
import ProductCard from "./ProductCard/ProductCard";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {useLocale, useTranslation} from '@psyycker/react-translation';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "red" }}
      onClick={onClick}
    >
      NEXT
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "green" }}
      onClick={onClick}
    >
      BACK
    </div>
  );
}

const TrendingProducts = () => {
  const [trendingProduct, setTrendingProduct] = useState([]);
  const slider = React.useRef(null);
  useEffect(() => {
    const fetchTrendingProducts = async () => {
      try {
        const response = await axios.get(
          "https://api.rawstuff.deals/api/v1/products/getTrendingProducts",
          {
            headers: {
              Accept: "application/json",
              // Authorization: `Bearer ${newtoken.token}`,
            },
          }
        );
        const data = response.data;
        setTrendingProduct(data.data);
        // localStorage.setItem("orderId", data.data.id);
        // setShowCancelRefund(data.data.status === "pending");
        // console.log("trending", data.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchTrendingProducts();
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 2,

    // customPaging: function (i) {
    //   return <p>{i + 1}</p>;
    // },

    // responsive: [
    //   {
    //     breakpoint: 1424,
    //     settings: {
    //       slidesToShow: 3,
    //       slidesToScroll: 3,
    //       infinite: false,
    //       dots: false,
    //     },
    //   },

    //   {
    //     breakpoint: 1124,
    //     settings: {
    //       slidesToShow: 2,
    //       slidesToScroll: 2,
    //       infinite: false,
    //       dots: false,
    //     },
    //   },
    //   {
    //     breakpoint: 800,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //       initialSlide: 2,
    //     },
    //   },
    // ],

    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

   //Get Language Translation starts
   const {getTranslation} = useTranslation();
   const [locale, setLocale] = useLocale();
   const onButtonClick = () => {
     var lang = document.getElementById('lang').value;
     var selectedx = "You have selected";
     if(lang=="English"){
       setLocale('en-US');
     }
 
     if(lang=="Germany"){
       setLocale('german')
     }
 
     if(lang=="Dutch"){
       setLocale('de')
     }
 
     if(lang=="French"){
       setLocale('fr-FR')
     }
   }
 
   //Get Language Translation ends

  return (
    <div className="TrendingProducts-container">
      <h3 className="product-heading-txt">
        {/* {getTranslation({translationKey: 'TrendingProducts'})}  */}
        Häufig angesehene Produkte
      </h3>

      <Slider ref={slider} {...settings}>
        {trendingProduct?.map((trandingProductItem, index) => {
          return (
            <div className="mb-3" key={index}>
              <ProductCard trandingProductItem={trandingProductItem} />
            </div>
          );
        })}
      </Slider>

      <div className="prev-btn-next mt-1 mb-2  d-flex justify-content-between align-items-center ">
        <button
          className="btn-prev"
          onClick={() => slider?.current?.slickPrev()}
        >
          <span className="btn-prev">
            <ArrowBackIcon />
          </span>
          {/* {getTranslation({ translationKey: "Previous" })} */}
          Vorherige
        </button>
        <button onClick={() => slider?.current?.slickNext()}>
          {/* {getTranslation({ translationKey: "Next" })} */}
          Nächste
          <span className="btn-next">
            <ArrowForwardIcon />
          </span>
        </button>
      </div>
    </div>
  );
};

export default TrendingProducts;
