import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "./HeroSection.css";
import AlgoSearch from "../../SearchEngine/AlgoSearch";
import { Link } from "react-router-dom";
import { useLocale, useTranslation } from "@psyycker/react-translation";
import { useDispatch, useSelector } from "react-redux";
import CustomRefinementList from "../../MarketPlace/UpdatedMarketPlace/Crl";

const HeroSection = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  let [userSignIn, updateUserSingIn] = useState(
    localStorage.getItem("token") ? true : false
  );

  let Token = useSelector((state) => state.userAuth?.token);
  // useeffect to make true if the user is logged in else false setIsLoggedIn
  useEffect(() => {
    if (Token) {
      // User is logged in
      setIsLoggedIn(true);
    } else {
      // User is not logged in
      setIsLoggedIn(false);
    }
  }, []);

  //Get Language Translation starts
  const { getTranslation } = useTranslation();
  const [locale, setLocale] = useLocale();
  const onButtonClick = () => {
    var lang = document.getElementById("lang").value;
    var selectedx = "You have selected";
    if (lang == "English") {
      setLocale("en-US");
    }

    if (lang == "Germany") {
      setLocale("german");
    }

    if (lang == "Dutch") {
      setLocale("de");
    }

    if (lang == "French") {
      setLocale("fr-FR");
    }
  };

  //Get Language Translation ends

  return (
    <div className="hero-section-container">
      <div className="container-fluid">
        <div className="hero-section-flexContainer">
          <div className="hero-section-welcome">
            {/* <h1>{getTranslation({ translationKey: "WelcomeText" })} </h1> */}
            <h1>Willkommen zu Rawstuff Deals</h1>
            Ihrem Portal zu einer zukunftsweisenden Plattform im Rohstoffhandel.
          </div>
          <div className="hero-section-catagories-serach">
            {/* <div className="hero-section-catagories">
              {getTranslation({ translationKey: "Category" })}
              <span>
                <KeyboardArrowDownIcon />
              </span>
            </div> */}

            <div className="hero-section-catagories">
              {/* <CustomRefinementList attribute="Product" /> */}
              Produkt
            </div>

            <div className="search-engine">
              <AlgoSearch />
            </div>
          </div>

          {userSignIn && (
            <small className="text-center">
              <a
                className="mt-2 btn"
                href="/"
                onClick={() => {
                  localStorage.removeItem("token");
                  updateUserSingIn(false);
                }}
              >
                <div className="btn-login-container">
                  {/* <button className="btn-login-txt">Log Out</button> */}
                  <button className="btn-login-txt">Ausloggen</button>
                </div>
              </a>
            </small>
          )}
          {!userSignIn && (
            <div className="btn-login-container">
              <Link to="/login">
                <button
                  type="button"
                  onClick={() => {
                    updateUserSingIn(true);
                  }}
                  className="btn-login-txt"
                >
                  {/* {getTranslation({ translationKey: "Login" })} */}
                  Einloggen
                </button>
              </Link>
            </div>
          )}
          {isLoggedIn ? (
            <div className="have-no-account">
              <p>
                {/* {getTranslation({ translationKey: "Welcome" })} */}
                Willkommen &nbsp;
                {/* {getTranslation({ translationKey: "NotCustomer" })} &nbsp; */}
                <Link to="/marketplace">
                  {/* {getTranslation({ translationKey: "RegisterHere" })} */}
                  {/* {getTranslation({ translationKey: "Visit" })}&nbsp;{" "}
                  {getTranslation({ translationKey: "MarketPlace" })} */}
                  Besuchen Sie den Market Place
                </Link>
              </p>
            </div>
          ) : (
            <div className="have-no-account">
              <p>
                {/* {getTranslation({ translationKey: "NotCustomer" })} */}
                Noch nicht angemeldet? &nbsp;
                <Link to="/register">
                  {/* {getTranslation({ translationKey: "RegisterHere" })} */}
                  Jetzt Registrieren
                </Link>
              </p>
            </div>
          )}
          <div className="have-no-account learn-more-toregister">
            <p>
              <a href="#registrationPlatForm">
                {/* {getTranslation({
                  translationKey: "Learn   More  About  Registration",
                })} */}
                Erfahren Sie mehr über die Vorteile einer Registrierung
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
