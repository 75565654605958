import React, { useState } from "react";
import { ContactMeApi } from "../../redux/Slice/userAuthSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "./contactMe.css";
import { images } from "../../Constants";

function ContactMe() {
  const initialState = {
    userFullName: "",
    userEmail: "",
    userphoneNum: "",
    massege: "",
  };

  const [userFullName, setUserFullName] = useState(initialState.userFullName);
  const [userEmail, setUserEmail] = useState(initialState.userEmail);
  const [userphoneNum, setUserphoneNum] = useState(initialState.userphoneNum);
  const [massege, setPwd] = useState(initialState.massege);

  const [isLoading, setIsLoading] = useState(false);
  const [massegeError, setMassegeError] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const resetForm = () => {
    setUserFullName("");
    setUserEmail("");
    setUserphoneNum("");
    setPwd("");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!userFullName) {
      toast.error("Bitte Namen angeben");
      return;
    }
    if (!userEmail) {
      toast.error("Bitte email angeben");
      return;
    }
    if (!userphoneNum) {
      toast.error("Bitte Telefonnummer angeben");
      return;
    }
    
    if (!massege) {
      toast.error("Please provide message");
      return;
    }
    

    setIsLoading(true);
    try {
      await dispatch(
        ContactMeApi({
          name: userFullName,
          email: userEmail,
          phoneNumber: userphoneNum,
          message: massege,
        })
      ).unwrap();
      // navigate("/");
      toast.success("Kontaktaufnahme erfolgreich");
      resetForm();
    } catch (error) {
      if (error.status === "FETCH_ERROR") {
        toast.error("Connection problem");
      } else {
        toast.error("Ungültige Anfrage!!! Bitte versuche es erneut");
      }
      setIsLoading(false);
    }
  };

  const handlePwdInput = (e) => {
    const massege = e.target.value;
    setPwd(massege);

    if (massege.length < 150) {
      setMassegeError("Massege weniger als mindestens 150 Wörter");
    } else {
      setMassegeError("");
    }
  };

  const handleUserInput = (e) => setUserFullName(e.target.value);
  const handleUserEmail = (e) => setUserEmail(e.target.value);
  const handlePwdPhoneNum = (e) => setUserphoneNum(e.target.value);

  const sendEmail = () => {
    const subject = encodeURIComponent("Contact Us Form Submission");
    const body = encodeURIComponent(
      `Name: ${userFullName}\nEmail: ${userEmail}\nPhone: ${userphoneNum}\nMessage: ${massege}`
    );
    const mailtoLink = `mailto:info@rawstuff.deals?subject=${subject}&body=${body}`;
    window.location.href = mailtoLink;
  };

  const provideSupport = () => {
    
    const subject = encodeURIComponent("Contact Us Form Submission");
    const body = encodeURIComponent(
      `Name: ${userFullName}\nEmail: ${userEmail}\nPhone: ${userphoneNum}\nMessage: ${massege}`
    );
    const mailtoLink = `mailto:info@rawstuff.deals?subject=${subject}&body=${body}`;
    window.location.href = mailtoLink;
  };

  return (
      <section className="contactmeContainer" style={{ marginTop: "11rem" ,marginBottom:"10rem"}}>
              <div className="contactMe-div mt-4">
               <div className="contact-me-header-div">
               <h2 class="mt-4 contact-me-header">
               Wir freuen uns auf Ihre Nachricht!
                </h2>
                <h5 className="contact-me-header-title">
                Schreiben Sie uns und wir werden so schnell wie möglich antworten. Ihre Rückmeldung
und Fragen sind uns wichtig.
                  {/* Send us message we will response as soon as possible */}
                </h5>
               </div>
                <div className="form-containers">
                  <form
                    method="POST"
                    onSubmit={handleSubmit}
                    class="card__form_contactme "
                  >
                    <input
                      type="hidden"
                      name="_token"
                      value="8LwNrSiQHI2zTVLmPmGM97BY6UKF18q9I3S2tJKl"
                    />
                    <div class="form-row">
                      <div class="form-group form-group-contactme col-md-12">
                        <div className=" mt-4 input-btn" for="name">
                          <article>
                        <img src={images.userProfiles} alt="mail" />
                          </article>{" "}
                          {/* <article>Name</article>{" "} */}
                        </div>
                        <input
                          type="text"
                          class="mt-4 form-control"
                          name="name"
                          value={userFullName}
                          onChange={handleUserInput}
                          id="f_name"
                          placeholder="Name"
                        />
                      </div>
                    </div>
                    <div class="form-group form-group-contactme">
                      <div className="input-btn" for="name">
                        <article>
                        <img src={images.phonenum} alt="mail" />
                        </article>{" "}
                        {/* <article>Phone Number</article>{" "} */}
                      </div>
                      <input
                        type="mumber"
                        class="form-control"
                        name="phone"
                        value={userphoneNum}
                        onChange={handlePwdPhoneNum}
                        id="phone"
                        placeholder="Telefonnummer"
                      />
                    </div>
                    <div class="form-group form-group-contactme">
                      <div className="input-btn" for="name">
                        <article>
                        <img src={images.mailApp} alt="mail" />
                        </article>{" "}
                        {/* <article>Email</article> */}
                      </div>
                      <input
                        type="email"
                        class="form-control"
                        name="email"
                        value={userEmail}
                        onChange={handleUserEmail}
                        id="email"
                        placeholder="Email"
                      />
                    </div>

                    <div class="form-row">
                      <div class="form-group form-group-contactmes col-md-6">
                        <textarea
                          type="massege"
                          className="form-control"
                          name="massege"
                          id="massege"
                          value={massege}
                          onChange={handlePwdInput}
                          placeholder="Geben Sie hier Ihre Nachricht ein..."
                        ></textarea>
                      </div>
                    </div>

                    <div class="form-group-btnsend">
                      <button class="mt-4 btn-sendmessage" type="submit">
                      senden
                      </button>
                    </div>
                  </form>
                  <div className="contactmail-container">
                    <div className="contactme-mail" onClick={sendEmail}>
                      <article className="mailImage">
                        <img src={images.mailApp} alt="mail" />
                      </article>
                      <article className="mail-us">Email</article>
                    </div>
                    <div className="contactme-mail" onClick={provideSupport}>
                      <article className="mailImage">
                        <img src={images.Headphones} alt="headphone" />
                      </article>
                      <article  className="mail-us">Support</article>
                    </div>
                    <div className="find-us">
                      <article>Finden Sie uns in den sozialen Medien:</article>
                      <div className="s-media-icon-container">
                        <ul className="d-flex contactme-socalmedia">
                        <li className="s-media-icon-item">
                    <a href="">
                      <i class="fa-brands fa-xing"></i>
                    </a>
                  </li>
                  <li className="s-media-icon-item">
                    <a href="">
                      <i className="bi bi-envelope"></i>
                    </a>
                  </li>
                  <li className="s-media-icon-item">
                    <a href="">
                      <i class="fa-brands fa-linkedin"></i>
                    </a>
                  </li>

                  <li className="s-media-icon-item">
                    <a href="">
                      <i class="fa-brands fa-x-twitter"></i>
                    </a>
                  </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
      </section>
  );
}
export default ContactMe;