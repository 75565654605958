import React, { useState } from "react";
import DoneIcon from "@mui/icons-material/Done";
import { useTranslation } from "@psyycker/react-translation";
import { useNavigate } from "react-router-dom";
import PaymentModal from "./PaymentModal";
import membershipENDE from "../../Constants/Contents/MembershipENDE";

function PricingPlan({ ...props }) {
  let token = localStorage.getItem("token");
  token = JSON.parse(token);
  const role = token?.role;

  const navigate = useNavigate();

  //Get Language Translation starts
  const { getTranslation } = useTranslation();

  //Get Language Translation ends
  const [modalb1show, setModalb1show] = useState(false);

  if (token) {
    if (props.pricingtype) {
      return (
        <div className="pricing-container">
          <div className="container">
            <div className="pricing-card-container">
              <div className="d-flex  uppercard row mt-md-n5 mt-n3">
                {role === "buyer" ? (
                  <div className="pricingPlan col-md">
                    <div className="plan-price-title">
                      <div className="plan-title">
                        {membershipENDE.buyer.yearly.titlede}
                      </div>
                    </div>
                    <div className="description-info">
                      <div>
                        {membershipENDE.buyer.monthly.bodyde.map(
                          (item, index) => {
                            return (
                              <div
                                key={index}
                                className="d-flex plan-decription"
                              >
                                <DoneIcon color="success" />
                                <p>
                                  <strong>{Object.keys(item)}: </strong>
                                  {Object.values(item)}
                                </p>
                              </div>
                            );
                          }
                        )}
                      </div>

                      <div className="select-plan">
                        <button
                          className="btn"
                          style={{ color: "#ffffff" }}
                          onClick={() => setModalb1show(true)}
                        >
                          Plan wählen
                        </button>
                        <PaymentModal
                          show={modalb1show}
                          onHide={() => setModalb1show(false)}
                          pricingtype={"monthly"}
                          price={"20"}
                          role={"buyer"}
                        />
                      </div>
                    </div>
                  </div>
                ) : role === "seller" ? (
                  <div className="pricingPlan col-md">
                    <div className="plan-price-title">
                      <div className="plan-title">
                        {membershipENDE.seller.yearly.titlede}
                      </div>
                    </div>
                    <div className="description-info">
                      <div>
                        {membershipENDE.seller.yearly.bodyde.map(
                          (item, index) => {
                            return (
                              <div
                                key={index}
                                className="d-flex plan-decription"
                              >
                                <DoneIcon color="success" />
                                <p>
                                  <strong>{Object.keys(item)}: </strong>
                                  {Object.values(item)}
                                </p>
                              </div>
                            );
                          }
                        )}
                      </div>

                      <div className="select-plan">
                        <button
                          className="btn"
                          style={{ color: "#ffffff" }}
                          onClick={() => setModalb1show(true)}
                        >
                          Plan wählen
                        </button>
                        <PaymentModal
                          show={modalb1show}
                          onHide={() => setModalb1show(false)}
                          pricingtype={"yearly"}
                          price={"948"}
                          role={"Seller"}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="pricing-container">
                    <div className="container">
                      <div className="pricing-card-container">
                        <div className="d-flex  uppercard row mt-md-n5 mt-n3">
                          <div
                            className="pricingPlan col-md"
                            onClick={() => navigate("/login")}
                          >
                            <div className="plan-price-title">
                              <div className="plan-title">
                                {membershipENDE.buyer.yearly.titlede}
                              </div>
                            </div>
                            <div className="description-info">
                              <div>
                                {membershipENDE.buyer.yearly.bodyde.map(
                                  (item, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className="d-flex plan-decription"
                                      >
                                        <DoneIcon color="success" />
                                        <p>
                                          <strong>{Object.keys(item)}: </strong>
                                          {Object.values(item)}
                                        </p>
                                      </div>
                                    );
                                  }
                                )}
                              </div>

                              <div className="select-plan">
                                <button
                                  className="btn"
                                  style={{ color: "#ffffff" }}
                                  onClick={() => setModalb1show(true)}
                                >
                                  Plan wählen
                                </button>
                                <PaymentModal
                                  show={modalb1show}
                                  onHide={() => setModalb1show(false)}
                                  pricingtype={"monthly"}
                                  price={"50"}
                                  role={"Seller"}
                                />
                              </div>
                            </div>
                          </div>

                          <div
                            className="pricingPlan col-md"
                            onClick={() => navigate("/login")}
                          >
                            <div className="plan-price-title">
                              <div className="plan-title">
                                {membershipENDE.seller.yearly.titlede}
                              </div>
                            </div>
                            <div className="description-info">
                              <div>
                                {membershipENDE.seller.yearly.bodyde.map(
                                  (item, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className="d-flex plan-decription"
                                      >
                                        <DoneIcon color="success" />
                                        <p>
                                          <strong>{Object.keys(item)}: </strong>
                                          {Object.values(item)}
                                        </p>
                                      </div>
                                    );
                                  }
                                )}
                              </div>

                              <div className="select-plan">
                                <button
                                  className="btn"
                                  style={{ color: "#ffffff" }}
                                  onClick={() => setModalb1show(true)}
                                >
                                  Plan wählen
                                </button>
                                <PaymentModal
                                  show={modalb1show}
                                  onHide={() => setModalb1show(false)}
                                  pricingtype={"monthly"}
                                  price={"50"}
                                  role={"Seller"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="pricing-container">
          <div className="container">
            <div className="pricing-card-container">
              <div className="d-flex  uppercard row mt-md-n5 mt-n3">
                {role === "buyer" ? (
                  <div className="pricingPlan col-md">
                    {/* Membership Plan für Käufer – 29 EUR/Monat */}
                    <div className="plan-price-title">
                      <div className="plan-title">
                        {membershipENDE.buyer.monthly.titlede}
                      </div>
                      <div className="d-none">
                        <span className="plan-price">€20</span>
                        <span className="plan-term">
                          <sup> /per month</sup>
                        </span>
                      </div>
                    </div>
                    <div className="description-info">
                      <div>
                        {membershipENDE.buyer.monthly.bodyde.map(
                          (item, index) => {
                            return (
                              <div
                                key={index}
                                className="d-flex plan-decription"
                              >
                                <DoneIcon color="success" />
                                <p>
                                  <strong>{Object.keys(item)}: </strong>
                                  {Object.values(item)}
                                </p>
                              </div>
                            );
                          }
                        )}
                      </div>

                      <div className="select-plan">
                        <button
                          className="btn"
                          style={{ color: "#ffffff" }}
                          onClick={() => setModalb1show(true)}
                        >
                          Plan wählen
                        </button>
                        <PaymentModal
                          show={modalb1show}
                          onHide={() => setModalb1show(false)}
                          pricingtype={"monthly"}
                          price={"20"}
                          role={"buyer"}
                        />
                      </div>
                    </div>
                  </div>
                ) : role === "seller" ? (
                  <div className="pricingPlan col-md">
                    <div className="plan-price-title">
                      <div className="plan-title">
                        {membershipENDE.seller.monthly.titlede}
                      </div>
                      <div className="d-none">
                        <span className="plan-price">€50</span>
                        <span className="plan-term">
                          <sup> /per month</sup>
                        </span>
                      </div>
                    </div>
                    <div className="description-info">
                      <div>
                        {membershipENDE.seller.monthly.bodyde.map(
                          (item, index) => {
                            return (
                              <div
                                key={index}
                                className="d-flex plan-decription"
                              >
                                <DoneIcon color="success" />
                                <p>
                                  <strong>{Object.keys(item)}: </strong>
                                  {Object.values(item)}
                                </p>
                              </div>
                            );
                          }
                        )}
                      </div>

                      <div className="select-plan">
                        <button
                          className="btn"
                          style={{ color: "#ffffff" }}
                          onClick={() => setModalb1show(true)}
                        >
                          Plan wählen
                        </button>
                        <PaymentModal
                          show={modalb1show}
                          onHide={() => setModalb1show(false)}
                          pricingtype={"monthly"}
                          price={"79"}
                          role={"Seller"}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="pricing-container">
                    <div className="container">
                      <div className="pricing-card-container">
                        <div className="d-flex  uppercard row mt-md-n5 mt-n3">
                          <div
                            className="pricingPlan col-md"
                            onClick={() => navigate("/login")}
                          >
                            <div className="plan-price-title">
                              <div className="plan-title">
                                {membershipENDE.buyer.monthly.titlede}
                              </div>
                            </div>
                            <div className="description-info">
                              <div>
                                {membershipENDE.buyer.monthly.bodyde.map(
                                  (item, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className="d-flex plan-decription"
                                      >
                                        <DoneIcon color="success" />
                                        <p>
                                          <strong>{Object.keys(item)}: </strong>
                                          {Object.values(item)}
                                        </p>
                                      </div>
                                    );
                                  }
                                )}
                              </div>

                              <div className="select-plan">
                                <button
                                  className="btn"
                                  style={{ color: "#ffffff" }}
                                  onClick={() => setModalb1show(true)}
                                >
                                  Plan wählen
                                </button>
                                <PaymentModal
                                  show={modalb1show}
                                  onHide={() => setModalb1show(false)}
                                  pricingtype={"monthly"}
                                  price={"50"}
                                  role={"Seller"}
                                />
                              </div>
                            </div>
                          </div>

                          <div
                            className="pricingPlan col-md"
                            onClick={() => navigate("/login")}
                          >
                            <div className="plan-price-title">
                              <div className="plan-title">
                                {membershipENDE.seller.monthly.titlede}
                              </div>
                            </div>
                            <div className="description-info">
                              <div>
                                {membershipENDE.seller.monthly.bodyde.map(
                                  (item, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className="d-flex plan-decription"
                                      >
                                        <DoneIcon color="success" />
                                        <p>
                                          <strong>{Object.keys(item)}: </strong>
                                          {Object.values(item)}
                                        </p>
                                      </div>
                                    );
                                  }
                                )}
                              </div>

                              <div className="select-plan">
                                <button
                                  className="btn"
                                  style={{ color: "#ffffff" }}
                                  onClick={() => setModalb1show(true)}
                                >
                                  Plan wählen
                                </button>
                                <PaymentModal
                                  show={modalb1show}
                                  onHide={() => setModalb1show(false)}
                                  pricingtype={"monthly"}
                                  price={"50"}
                                  role={"Seller"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }
  } else {
    if (props.pricingtype) {
      return (
        <div className="pricing-container">
          <div className="container">
            <div className="pricing-card-container">
              <div className="d-flex  uppercard row mt-md-n5 mt-n3">
                <div
                  className="pricingPlan col-md"
                  onClick={() => navigate("/login")}
                >
                  <div className="plan-price-title">
                    <div className="plan-title">
                      {membershipENDE.buyer.yearly.titlede}
                    </div>
                  </div>
                  <div className="description-info">
                    <div>
                      {membershipENDE.buyer.yearly.bodyde.map((item, index) => {
                        return (
                          <div key={index} className="d-flex plan-decription">
                            <DoneIcon color="success" />
                            <p>
                              <strong>{Object.keys(item)}: </strong>
                              {Object.values(item)}
                            </p>
                          </div>
                        );
                      })}
                    </div>

                    <div className="select-plan">
                      <button
                        className="btn"
                        style={{ color: "#ffffff" }}
                        onClick={() => setModalb1show(true)}
                      >
                        Plan wählen
                      </button>
                      <PaymentModal
                        show={modalb1show}
                        onHide={() => setModalb1show(false)}
                        pricingtype={"monthly"}
                        price={"50"}
                        role={"Seller"}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className="pricingPlan col-md"
                  onClick={() => navigate("/login")}
                >
                  <div className="plan-price-title">
                    <div className="plan-title">
                      {membershipENDE.seller.yearly.titlede}
                    </div>
                  </div>
                  <div className="description-info">
                    <div>
                      {membershipENDE.seller.yearly.bodyde.map(
                        (item, index) => {
                          return (
                            <div key={index} className="d-flex plan-decription">
                              <DoneIcon color="success" />
                              <p>
                                <strong>{Object.keys(item)}: </strong>
                                {Object.values(item)}
                              </p>
                            </div>
                          );
                        }
                      )}
                    </div>

                    <div className="select-plan">
                      <button
                        className="btn"
                        style={{ color: "#ffffff" }}
                        onClick={() => setModalb1show(true)}
                      >
                        Plan wählen
                      </button>
                      <PaymentModal
                        show={modalb1show}
                        onHide={() => setModalb1show(false)}
                        pricingtype={"monthly"}
                        price={"50"}
                        role={"Seller"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="pricing-container">
          <div className="container">
            <div className="pricing-card-container">
              <div className="d-flex  uppercard row mt-md-n5 mt-n3">
                <div
                  className="pricingPlan col-md"
                  onClick={() => navigate("/login")}
                >
                  <div className="plan-price-title">
                    <div className="plan-title">
                      {membershipENDE.buyer.monthly.titlede}
                    </div>
                  </div>
                  <div className="description-info">
                    <div>
                      {membershipENDE.buyer.monthly.bodyde.map(
                        (item, index) => {
                          return (
                            <div key={index} className="d-flex plan-decription">
                              <DoneIcon color="success" />
                              <p>
                                <strong>{Object.keys(item)}: </strong>
                                {Object.values(item)}
                              </p>
                            </div>
                          );
                        }
                      )}
                    </div>

                    <div className="select-plan">
                      <button
                        className="btn"
                        style={{ color: "#ffffff" }}
                        onClick={() => setModalb1show(true)}
                      >
                        Plan wählen
                      </button>
                      <PaymentModal
                        show={modalb1show}
                        onHide={() => setModalb1show(false)}
                        pricingtype={"monthly"}
                        price={"50"}
                        role={"Seller"}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className="pricingPlan col-md"
                  onClick={() => navigate("/login")}
                >
                  <div className="plan-price-title">
                    <div className="plan-title">
                      {membershipENDE.seller.monthly.titlede}
                    </div>
                  </div>
                  <div className="description-info">
                    <div>
                      {membershipENDE.seller.monthly.bodyde.map(
                        (item, index) => {
                          return (
                            <div key={index} className="d-flex plan-decription">
                              <DoneIcon color="success" />
                              <p>
                                <strong>{Object.keys(item)}: </strong>
                                {Object.values(item)}
                              </p>
                            </div>
                          );
                        }
                      )}
                    </div>

                    <div className="select-plan">
                      <button
                        className="btn"
                        style={{ color: "#ffffff" }}
                        onClick={() => setModalb1show(true)}
                      >
                        Plan wählen
                      </button>
                      <PaymentModal
                        show={modalb1show}
                        onHide={() => setModalb1show(false)}
                        pricingtype={"monthly"}
                        price={"50"}
                        role={"Seller"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default PricingPlan;
