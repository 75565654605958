import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Delete, Edit } from "@mui/icons-material";
import EditWarehouse from "./EditWarehouse";
import { convertCountryCodeToCountryName } from "../SearchResultPage/ProductCard";
import address from "../../Constants/Contents/Address";

function WareHouses({ fetchwarehouse, warehouse }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [WarehouseDetails, setWarehouseDetails] = useState([]);

  const deleteAddress = async (id) => {
    try {
      var jsonString = localStorage.getItem("token");
      var jsonObject = JSON.parse(jsonString);
      var Token = jsonObject?.token;

      await axios.delete(`https://api.rawstuff.deals/api/v1/warehouses/${id}`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${Token}`,
        },
      });

      fetchwarehouse();
      // toast.success("Address deleted successfully");
      toast.success("Adresse erfolgreich gelöscht");
    } catch (error) {
      console.log(error);
    }
  };

  const getWareDetails = async (id) => {
    try {
      let token = localStorage.getItem("token");
      token = JSON.parse(token).token;

      const res = await axios.get(
        `https://api.rawstuff.deals/api/v1/warehouses/${id}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = res.data;
      setWarehouseDetails(data.data);

      setModalOpen(true);
    } catch (error) {
      console.log("error");
    }
  };

  return (
    <div>
      <table
        className="table table-responsive-sm"
        style={{ textAlign: "left" }}
      >
        <thead>
          <tr>
            <th className="w-10">{address.node}</th>
            <th>{address.addressde}</th>
            <th>{address.companyde}</th>
            <th className="">{address.countryde}</th>
            <th className="">{address.cityde}</th>
            {/* <th>Street1</th> */}
            <th className="">{address.zipde}</th>
            <th className="text-center">{address.actionde}</th>
          </tr>
        </thead>
        <tbody>
          {warehouse?.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}.</td>
              <td>{item?.name}</td>
              <td>{item?.company}</td>
              <td>{convertCountryCodeToCountryName(item?.country)}</td>
              <td>{item?.city}</td>
              {/* <td>{item?.street1}</td> */}
              <td>{item?.zip}</td>
              <td className="d-flex">
                <button
                  className="btn"
                  onClick={() => getWareDetails(item?.id)}
                >
                  <Edit />
                </button>
                <button className="btn" onClick={() => deleteAddress(item?.id)}>
                  <Delete />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <EditWarehouse
        modalOpen={modalOpen}
        handleModalClose={() => setModalOpen(false)}
        fetchwarehouse={fetchwarehouse}
        warehouseDetails={WarehouseDetails}
      />
    </div>
  );
}

export default WareHouses;
