import React, { useState } from "react";
import "./EmailVerification.css";
import {  useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const EmailVerificationPage = () => {
  const [inputs, setInputs] = useState({
    number1: "",
    number2: "",
    number3: "",
    number4: "",
    number5: "",
    number6: "",
  });

  const concatenatedNumbers = Object.values(inputs).join("");
 
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();

  const inputRefs = Array.from({ length: 6 }, () => React.createRef());

  const handleInputChange = (event, index) => {
    const { name, value } = event.target;
  
    // Restrict input to a single character
    if (value.length === 1) {
      setInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
      }));
      // Move focus to the next input field
      if (index < inputRefs.length - 1 && value) {
        inputRefs[index + 1].current.focus();
      }
    }
  };
  
 
  const email = localStorage.getItem("email");

  const handleVerificationRequest = () => {
    const isInputsValid = Object.values(inputs).every(inputValue => inputValue !== '');

    if (!isInputsValid) {
      // Display an error message or prevent the request if inputs are not valid
      toast.error("Please fill in all verification fields.");
      return;
    }
    setSubmitted(true);
    const data = {
      email,
      otp: concatenatedNumbers,
    };
  
   

    sendVerificationRequest(data)
      .then((response) => {
        if (response.status === 201) {
          toast.success("Verification successful!");
        navigate("/emailVerificationSuccessful");

        } 
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          const errorMessage = error.response.data.error;
          console.log(errorMessage, "error");
          toast.error(errorMessage);
        } else {
          console.error("An error occurred:", error);
          toast.error("Invalid verification number. Please try again.");
          setInputs({
            number1: '',
            number2: '',
            number3: '',
            number4: '',
            number5: '',
            number6: '',
          });
        }
      });

      
  };
  
  const sendVerificationRequest = (data) => {
    return fetch("https://api.rawstuff.deals/api/v1/verifyOtp", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 201) {
          toast.success("Verification successful!");
          navigate("/emailVerificationSuccessful");
          return response.json();
        } else {
          
          throw new Error("Verification request failed");
          
        }
       
      })
      
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          const errorMessage = error.response.data.error;
          console.log(errorMessage, "error");
          toast.error(errorMessage);
        } else {
          console.error("An error occurred:", error);
          // toast.error("Invalid verification number. Please try again.");
        }
        throw error;
      });
  };
 
  const handlePaste = (event) => {
    event.preventDefault();
    const pasteData = event.clipboardData.getData("text/plain");
    const codeArray = pasteData.split("");

    // Update the input fields with the pasted code
    const newInputs = { ...inputs };
    for (let i = 0; i < Math.min(codeArray.length, 6); i++) {
      newInputs[`number${i + 1}`] = codeArray[i];
    }

    setInputs(newInputs);
  };
  
 const handleKeyDown = (event, index) => {
    if (event.key === "Backspace" && inputs[`number${index + 1}`] === "") {
      // Clear the previous input field
      if (index > 0) {
        inputRefs[index - 1].current.focus();
        setInputs((prevInputs) => ({
          ...prevInputs,
          [`number${index}`]: "",
        }));
      }
    }
  };

  const handleResendClick = async (event) => {
    event.preventDefault();
    try {
      const res = await axios.post(
        "https://api.rawstuff.deals/api/v1/requestResetPassword",
        {
          email: email,
        }
      );
      toast.success("Verification code sent to your email");
      return res
    } catch (error) {
      console.log(error.response.data.message);
      throw toast.error(error.response.data.message);
    }
  };

  return (
<div>
<h2>Email Verification</h2>
  <div className="">
      <div className="email-Verfication-container">
        {/* <div className="mt-5">
          <Link to="#" className="back-arrow">
            <img src={images.backArrow} alt="back arrow" /> <h5 className="back">Back</h5>
          </Link>
        </div> */}
        <div className="Verify-your">
          <h2>Bestätigen Sie Ihre E-Mail</h2>
          <h4 className="email-paragraph">
          Bitte geben Sie den OTP-Code aus Ihrer E-Mail ein, um fortzufahren.
          </h4>
          <h4 className="email-paragraph">
          (Bitte überprüfen Sie auch Ihren Spam-Ordner)
          </h4>
        </div>
        <div
          className="email-Verfication"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "416",
            height: "56px",
          }}
        >
          {Array.from({ length: 6 }).map((_, index) => (
        <input
        type="text"
        name={`number${index + 1}`}
        placeholder=""
        value={inputs[`number${index + 1}`]}
        onChange={(event) => handleInputChange(event, index)}
        onPaste={handlePaste}
        maxLength={1}
        ref={inputRefs[index]}
        key={`number${index + 1}`}
        required
        onKeyDown={(event) => handleKeyDown(event, index)}
      />
          ))}
          {submitted &&
            inputs.number1 === "" &&
            inputs.number2 === "" &&
            inputs.number3 === "" &&
            inputs.number4 === "" &&
            inputs.number5 === "" &&
            inputs.number6 === "" && (
              <div className="invalid-feedback">filed is required.</div>
            )}
        </div>
        <button
        onClick={() => {
         
          handleVerificationRequest();
        }}
        
        className="email-bt">
       Vollständige Überprüfung
        </button>
        <h5 className="receive-the-email">
        Haben Sie die E-Mail nicht erhalten?
          <div className="resend" onClick={handleResendClick}>
          Zum erneuten Senden anklicken!
          </div>
        </h5>
      </div>
  </div>
</div>
  );
};



export default EmailVerificationPage;