import React from "react";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";

const Footer = ({ handleShow }) => {
  return (
    <div className="footer-wrapper container my-2 my-sm-5">
      <div className="text-center">
        <div className="contactus">Kontakt aufnehmen</div>
        <p className="chooseway d-none">Choose a way to contact</p>
      </div>
      <div className="d-flex justify-content-center mt-4 ">
        <button className="btnn mx-0 d-none">
          <PhoneOutlinedIcon /> Phone
        </button>

        <button className="btnn mx-sm-3 mx-2">
          <a href="mailto:info@rawstuff.deals" className="text-black">
            <MailOutlinedIcon /> E-mail
          </a>
        </button>

        <button className="btnn d-none" onClick={() => handleShow()}>
          <QuestionAnswerIcon /> Chat
        </button>
      </div>
    </div>
  );
};

export default Footer;
