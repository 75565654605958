import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import "./ShoppingPage.css";
import { useTranslation } from "@psyycker/react-translation";
import { Trash, X } from "react-bootstrap-icons";
import LastSeen from "../productDetailPage/LastSeen";
import "./PriceDetails/PriceDetails";
import AddressDropdown from "./AddressDropdown";
import ChangeDeliveryDate from "./ChangeDeliveryDate";
import { Spinner } from "react-bootstrap";
import { Modal, Button, Form } from "react-bootstrap";
import {
  clearCarts,
  getToCart,
  removeFromCarts,
  updateQuantitys,
} from "../../redux/Slice/getCartSlice";
import { handleCheckout } from "../../redux/Slice/CreatOrderSlice";
import { toast } from "react-toastify";

const ShoppingPage = () => {
  // check the user they agreed on AGB law or not
  const [isChecked, setIsChecked] = useState(false);
  const [stripeLink, setStripeLink] = useState([]);

  const [show, setShow] = useState(false);
  const [showSubPlan, setShowSubPlan] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleShowSubPlanClose = () => setShowSubPlan(false);
  const handleShowSubPlan = () => setShowSubPlan(true);
  const getItems = useSelector((state) => state.getCart);

  const Token = useSelector((state) => state.userAuth.token);
  let newtoken = JSON.parse(Token);
  // get user data
  const getUserData = localStorage.getItem("token");
  let UserData = JSON.parse(getUserData);
  // get order data
  const [isHeaderChecked, setHeaderChecked] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [loadingStates, setLoadingStates] = useState(
    cartItems.reduce((acc, item) => {
      acc[item.id] = false;
      return acc;
    }, {})
  );
  const [loadingState, setLoadingState] = useState({});
  const [loadingStateUpadate, setLoadingStateUpdate] = useState({});
  const [keyForForceRender, setKeyForForceRender] = useState(0);
  const dispatch = useDispatch();
  // handle check box
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };
  // handle proceed to checkout and payment
  const handleProceedToCheckoutAndPayment = async () => {
    try {
      // Dispatch the asynchronous thunk to handle checkout
      const checkoutResult = await dispatch(handleCheckout()).unwrap();
      let order_id = localStorage.getItem("order_id");

      if (order_id) {
        // Continue with payment logic if needed
        const paymentApiUrl =
          "https://api.rawstuff.deals/api/v1/payments/stripePay";
        const paymentHeaders = new Headers();
        paymentHeaders.append("Content-Type", "application/json");
        paymentHeaders.append("Authorization", `Bearer ${newtoken.token}`);
        const paymentRaw = JSON.stringify({
          order_id: order_id,
        });

        const paymentRequestOptions = {
          method: "POST",
          headers: paymentHeaders,
          body: paymentRaw,
          redirect: "follow",
        };

        const paymentResponse = await fetch(
          paymentApiUrl,
          paymentRequestOptions
        );
        const paymentData = await paymentResponse.json();
        setStripeLink(paymentData);
        const paymentDataLink = JSON.stringify(paymentData);

        localStorage.setItem("paymentData", paymentDataLink);
        // console.log("payment-data", paymentData);
        // return paymentDataLink;
        if (
          paymentData?.message ===
          "Sorry you don't have an active plan. Kindly Subscribe toa plan"
        ) {
          handleShowSubPlan();
        } else {
          handleShow();
        }

        // window.location.href = paymentData.data?.paylink;

        // Optionally navigate or update UI after successful payment
      } else {
        console.error("Error: Order ID not found.");
      }
    } catch (error) {
      console.log(error.response.data.message);
      throw toast.error(error.response.data.message);
    }
  };

  // function that handle proceed to stripe payment and go to membership page
  const handleProceedToStripePayment = () => {
    if (stripeLink) {
      window.location.href = stripeLink.data?.paylink;
      handleShowSubPlanClose();
      handleClose();
    }
  };

  useEffect(() => {
    setCartItems(getItems.cartItemsget.cart_items || []);
  }, [getItems]);

  const handleUpdateCartQuantity = async (
    productId,
    quantity,
    btId,
    operation
  ) => {
    try {
      setLoadingStateUpdate((prevLoadingStates) => ({
        ...prevLoadingStates,
        [productId]: { ...prevLoadingStates[productId], [operation]: true },
      }));

      let updatedQuantity;

      if (operation === "add") {
        updatedQuantity = quantity + 1;
      } else if (operation === "sub") {
        updatedQuantity = quantity - 1;
      } else if (operation === "custom") {
        updatedQuantity = quantity;
      }

      const updatedCartItems = cartItems.map((item) => {
        if (item.id === productId) {
          return { ...item, quantity: updatedQuantity };
        }
        return item;
      });

      setCartItems(updatedCartItems);

      await dispatch(
        updateQuantitys({ productId, quantity: updatedQuantity, btId })
      );
      await dispatch(getToCart());
    } catch (error) {
      console.error("Error updating cart quantity:", error);

      // Show error toast
      toast.error("Error updating cart quantity. Please try again.", {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      setLoadingStateUpdate((prevLoadingStates) => ({
        ...prevLoadingStates,
        [productId]: { ...prevLoadingStates[productId], [operation]: false },
      }));
    }
  };

  const removeFromCart = async (id) => {
    try {
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        [id]: true,
      }));

      await dispatch(removeFromCarts(id));
      await dispatch(getToCart());

      setCartItems((prevCart) => prevCart.filter((item) => item.id !== id));
    } catch (error) {
      console.error("Error removing item from cart:", error);
    } finally {
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        [id]: false,
      }));
    }
  };

  const handleClearCart = async (id) => {
    try {
      setLoadingState({ clearCart: true });

      await dispatch(clearCarts(id));
      await dispatch(getToCart());
      setKeyForForceRender((prevKey) => prevKey + 1);
    } catch (error) {
      console.error("Error clearing the cart:", error);
    } finally {
      setLoadingState({ clearCart: false });
    }
  };

  const [isRefreshing, setIsRefreshing] = useState(false);
  useEffect(() => {
    if (isRefreshing) {
      dispatch(getToCart());
      setIsRefreshing(false);
    }
  }, [dispatch, isRefreshing]);

  useEffect(() => {
    const individualCheckboxes = document.querySelectorAll(
      '.cart-item input[type="checkbox"]'
    );
    individualCheckboxes.forEach((checkbox) => {
      checkbox.checked = isHeaderChecked;
    });
  }, [isHeaderChecked]);

  //Get Language Translation starts
  const { getTranslation } = useTranslation();

  const formatNumber = (value) => {
    if (value !== undefined && value !== null) {
      const roundedValue =
        Math.abs(value - Math.floor(value)) >= 0.5
          ? Math.ceil(value * 100) / 100
          : Math.floor(value * 100) / 100;
      return roundedValue.toFixed(2);
    } else {
      return "0.00";
    }
  };

  return (
    <>
      <div key={keyForForceRender} className="shopping-Cart-Page-container">
        {getItems.cartItemsget.cart_items?.length === 0 ? (
          <div className="empty-cart">
            <p>Ihr Warenkorb ist derzeit leer!</p>
            <div className="start-shopping">
              <Link to="/">
                <KeyboardBackspaceIcon />
                <span>Einkaufen beginnen...</span>
              </Link>
            </div>
          </div>
        ) : (
          <div className="container-fluid scroll-wrapper">
            <div className="shopping-cartPage" responsive="sm">
              <div className="shopping-header">
                <div className="shopping-header-list">
                  <li id="checkboxheader">
                    <input
                      // style={{ width: "24px", height: "24px" }}
                      type="checkbox"
                      name="checkbox"
                      className="checkboxheader"
                      checked={isHeaderChecked}
                      onChange={() => setHeaderChecked(!isHeaderChecked)}
                    />
                  </li>
                  <li className="product-cart-pages">
                    {getTranslation({ translationKey: "Produkt" })}
                  </li>
                  <li className="cart-quantity">
                    {getTranslation({ translationKey: "Menge" })}
                  </li>
                  <li className="cart-quantitys">
                    {getTranslation({
                      translationKey: "Preis",
                    })}
                  </li>
                  <li className="cart-address">
                    {getTranslation({ translationKey: "Adresse" })}
                  </li>
                  <li className="cart-shipping">
                    {getTranslation({ translationKey: "Versand" })}
                  </li>
                  <li className="cart-price">
                    {getTranslation({ translationKey: "Preis" })}
                  </li>
                  <li>
                    <div className="close-bts">
                      <button
                        title="Clear all cart items"
                        onClick={handleClearCart}
                        className={`remove-all -cartItem-btn €{
                          loadingState.clearCart ? "loading" : ""
                        }`}
                      >
                        {loadingState.clearCart ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="lg"
                            role="status"
                            aria-hidden="true"
                            style={{ color: "gray" }}
                          />
                        ) : (
                          <X size={35} />
                        )}
                      </button>
                    </div>
                  </li>
                </div>
              </div>

              <div className="cart-items-container">
                {getItems.cartItemsget.cart_items &&
                  getItems.cartItemsget.cart_items?.map((cartItem, index) => (
                    <div className="cart-item" key={index}>
                      <article>
                        <input type="checkbox" name="checkbox" id="checkbox" />
                      </article>
                      <div className="cart-item-product">
                        <div className="cartItem_list d-flex align-items-center justify-content-center">
                          <h3>{cartItem.product.name}</h3>
                        </div>
                      </div>

                      <div className="shop">
                        {cartItem.quantity_data.map((cartItems) => (
                          <div
                            key={cartItem.quantity.id}
                            className="cart-product-quantity"
                          >
                            <button
                              className="sub-bt"
                              onClick={() => {
                                handleUpdateCartQuantity(
                                  cartItem.id,
                                  cartItems.quantity,
                                  cartItems.id,
                                  "sub"
                                );
                              }}
                            >
                              {loadingStateUpadate[cartItems.id]?.sub ? (
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              ) : (
                                "-"
                              )}
                            </button>
                            <button className="count-quantity">
                              {cartItems.quantity}
                            </button>
                            <button
                              className="plus-bt"
                              onClick={() => {
                                handleUpdateCartQuantity(
                                  cartItem.id,
                                  cartItems.quantity,
                                  cartItems.id,
                                  "add"
                                );
                              }}
                            >
                              {loadingStateUpadate[cartItems.id]?.add ? (
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              ) : (
                                "+"
                              )}
                            </button>
                            <article className="kgs">
                              {cartItem.product.unit_of_measurement}
                            </article>
                            <div className="articless">
                              <article className="">
                                €{cartItem.product.unitPriceEur}
                              </article>
                            </div>
                          </div>
                        ))}
                      </div>

                      <div className="adress-container bg-primaryy">
                        <a className="text-wrapper-15">
                          <AddressDropdown
                            warehouse={cartItem.warehouse}
                            id={cartItem.id}
                          />
                        </a>
                      </div>
                      <div>
                        <div className="Shipping-Option">Versand Option</div>
                        <div className="Shipping-Option-delivery text-wrapper-15">
                          <ChangeDeliveryDate />
                        </div>
                      </div>
                      <div className="cart-product-total-price">
                        <div className="produt-pirce">
                          €{formatNumber(cartItem.price)}
                        </div>
                        <div className="product-tax-continer product-tax-continers">
                          <article className="product-tax">
                            <article>Rabatt</article>{" "}
                            <article className="product-tax-value discount">
                              {cartItem.discount_rate}%
                            </article>
                          </article>

                          <article className="product-tax-value discount">
                            - €{formatNumber(cartItem.discount_amount)}
                          </article>
                        </div>
                        <div className="product-tax-continer product-tax-continers">
                          <article className="product-tax">
                            <article>Steuer</article>{" "}
                            <article>{cartItem.tax_rate}%</article>
                          </article>
                          <article className="product-tax-value">
                            + €{formatNumber(cartItem.tax)}
                          </article>
                        </div>

                        <div className="product-tax-continer">
                          <article className="product-tax-total">
                            Insgesamt
                          </article>
                          <article className="product-tax-value-total">
                            €{formatNumber(cartItem.taxed_price)}
                          </article>
                        </div>
                      </div>
                      <div className="dital-delet">
                        <button
                          onClick={() => removeFromCart(cartItem.id)}
                          className={`remove_btsss p-2 €{
                            loadingStates[cartItem.id] ? "loading" : ""
                          }`}
                          disabled={loadingStates[cartItem.id]}
                        >
                          {loadingStates[cartItem.id] ? (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          ) : (
                            <Trash className="remove_btss" size={18} />
                          )}
                        </button>
                      </div>
                    </div>
                  ))}
              </div>

              <div className="Total-cart-product-total-price">
                <div className="new">
                  <div className="Total-product-tax-continer">
                    <div className="Total-product-subtotal">Zwischensumme</div>
                    <div className="Total-product-subtotal-value">
                      €{formatNumber(getItems.cartItemsget.total_price)}
                    </div>
                  </div>
                  <div className="Total-product-tax-continer">
                    <div className="Total-product-tax">Rabatt</div>
                    <div className="Total-product-tax-value discount">
                      -€
                      {formatNumber(
                        getItems.cartItemsget.total_discount_amount
                      )}
                    </div>
                  </div>
                  <div className="Total-product-tax-continer">
                    <div className="Total-product-tax">Mehrwertsteuer</div>
                    <div className="Total-product-tax-value">
                      €{formatNumber(getItems.cartItemsget.total_tax)}
                    </div>
                  </div>
                  <div className="Total-product-tax-continer whole-total">
                    <div className="product-tax-totals">
                      Umsatzsteuerpflichtige Gesamtsumme
                    </div>
                    <div className="Total-product-tax-value-total">
                      €{formatNumber(getItems.cartItemsget.total_taxed_price)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex important">
                <article className="wichtiger">Wichtiger Hinweis:</article>{" "}
                <article className="wichtigers">
                  Auf die Steuerschuldnerschaft des Leistungsempfängers wird
                  hingewiesen
                </article>
              </div>
              <div className="scroll-container">
                <div className="scroll-content"></div>
              </div>
              <div className="procced-button">
                <button className="continue-btn" type="button">
                  {" "}
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14 16L10 12L14 8"
                      stroke="#2C2C2C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>{" "}
                  <a href="/">Weiter einkaufen</a>
                </button>
                <Button
                  className="shopping-btn"
                  type="button"
                  onClick={handleProceedToCheckoutAndPayment}
                >
                  {/* <a onClick={handleProceedToCheckoutAndPayment}> */}
                  Zur Kasse
                  {/* </a> */}
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 16L14 12L10 8"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* modal popup to remind the user agreed to agb law */}

      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        dialogClassName="custom-agb-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Weiter zur Zahlung</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="agb-body-modal">
            <p>Begleichen Sie Ihre Zahlung bequem über Stripe.</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <p>
            Bevor Sie zur Zahlung weitergeleitet <br /> werden, bestätigen Sie
            Ihre Zustimmung <br /> zu den <Link to="/agbs">(AGB)</Link> von
            Rawstuff Deals.
          </p>
          <Form.Check
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />

          <Button
            variant="primary"
            onClick={handleProceedToStripePayment}
            disabled={!isChecked}
          >
            <i class="fa-solid fa-check"></i> &nbsp; Bezahlen
          </Button>
        </Modal.Footer>
      </Modal>
      {/* modal popup to remind the user subscrib membership plan */}
      <Modal
        size="lg"
        show={showSubPlan}
        onHide={handleShowSubPlanClose}
        dialogClassName="custom-agb-modal custom-modal-membership"
      >
        <Modal.Header closeButton>
          <Modal.Title>Erinnerung an das Abonnement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="agb-body-modal">
            <p>
              <span>Hi, {UserData.user.name} </span>
              Sie verwenden ein Basiskonto, das heißt, Sie haben nicht den
              vollen Zugang zu allen Funktionen haben.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <p>Melden Sie sich an, um Ihr Konto zu aktualisieren</p>
          <Button variant="primary" onClick={handleProceedToStripePayment}>
            <i className="fa-solid fa-check"></i> &nbsp; Jetzt abonnieren
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="cart-lastseen mb-5">
        <LastSeen />
        <br />
        <br />
        <br />
      </div>
    </>
  );
};

export default ShoppingPage;
