import React, { useState } from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SupportModal from "./SupportModal";
import { Collapse } from "react-bootstrap";

const FAQs = () => {
  const [faqs, setFaqs] = useState([
    {
      question: "Kann ich meinen Plan später ändern?",
      answer:
        "Ja, Sie können Ihren Plan monatlich anpassen. Für individuelle Lösungen  kontaktieren Sie  bitte das  Rawstuff-Team.",
      showAnswer: false,
    },
    {
      question: "Was ist Ihre Stornierungsrichtlinie?",
      answer:
        "Unsere Stornierungsrichtlinie ermöglicht es Ihnen, Ihr Abonnement jederzeit zu kündigen. Bitte lesen Sie unsere Nutzungsbedingungen für weitere Details.",
      showAnswer: false,
    },
    {
      question: "Kann ich mein Abonnement kündigen?",
      answer:
        "Ja, Sie können Ihr Abonnement jederzeit kündigen. Melden Sie sich einfach in Ihrem Konto an und gehen Sie im Dashboard auf den Menüpunkt Membership - hier finden Sie den Button Cancel Plan.",
      showAnswer: false,
    },
    {
      question: "Wie funktioniert die Membership Abrechnung?",
      answer:
        "Die Abrechnung erfolgt monatlich. Durchlaufen Sie den Onboarding-Prozess des Payment-Anbieters Stripe. Hier können Sie ganz bequem und ohne zusätzliche Kosten zahlen. Sie werden automatisch jeden Monat für Ihr Abonnement berechnet und erhalten eine Rechnung hierfür, es sei denn, Sie kündigen es.",
      showAnswer: false,
    },
    {
      question: "Wie ändere ich meine Kontodaten?",
      answer:
        "Sie können Ihre Kontodaten ändern, indem Sie sich in Ihr Konto einloggen und die Einstellungen aufrufen. Von dort aus können Sie eine Nachricht an das Rawstuff Deals Team via Email schicken oder Sie gehen über das Support Tool und senden eine Nachricht an das Support Team mit den Informationen zur Änderung. Einige Änderungen bedürfen einer Neufizierung Ihres Profils, daher müssen alle Änderungen durch den Administrator des Rawstuff Deals Teams durchgeführt werden. Denn nur so können wir Transparenz und Sicherheit auf dem Marketplace sicherstellen. Vielen Dank für Ihr Verständnis.",
      showAnswer: false,
    },
  ]);

  const toggleAnswer = (index) => {
    const updatedFaqs = [...faqs];
    updatedFaqs[index].showAnswer = !updatedFaqs[index].showAnswer;
    setFaqs(updatedFaqs);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="top-300 containerr">
      <div className="text-center my-5">
        <p className="faqfaq">FAQs</p>
        <div className="headfaq">Häufig gestellte
Fragen</div>
        <div className="text-center faqdsc mt-3">
          <p>
            Alles, was Sie über das Produkt und die Abrechnung wissen müssen.
            Finden Sie nicht die Antwort,
          </p>

          <p>die Sie suchen? Sprechen Sie mit unserem freundlichen Team.</p>
        </div>
      </div>
      <div className="col col-md-9 col-lg-6 mx-auto col-sm-12">
        <div className="">
          {faqs.map((faq, index) => (
            <div className="" key={index}>
              <div className="m-1">
                <div className={`faqs ${faq.showAnswer ? "actives" : ""}`}>
                  <div
                    className="d-flex gap-2"
                    onClick={() => toggleAnswer(index)}
                  >
                    {faq.showAnswer ? (
                      <RemoveCircleOutlineIcon sx={{ color: "#98A2B3" }} />
                    ) : (
                      <AddCircleOutlineIcon sx={{ color: "#98A2B3" }} />
                    )}{" "}
                    <p className="faq">{faq.question}</p>
                  </div>
                  {faq.showAnswer && <p className="px-4">{faq.answer}</p>}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="text-center stlh container">
        <div className="sthqh mb-4">
          {/* Still have questions? */}
          Sie haben noch Fragen?
        </div>
        <p className="sthqp">
          {/* Join over 4,000+ startups already growing with Untitled. */}
          Schließen Sie sich über 4.000 Startups an, die bereits mit Rawstuff wachsen.
        </p>
        <div className="d-flex">
          <button className="btnn">
            <a href="mailto:info@rawstuff.deals" className="text-black">
              {/* Email us */}
              Schreiben Sie uns eine E-Mail
            </a>
          </button>
          <button className="btnnc" onClick={() => handleShow()}>
            {/* Chat to our team */}
            Chatten Sie mit unserem Team
          </button>
        </div>
      </div>
      <SupportModal show={show} handleClose={handleClose} />
    </div>
  );
};

export default FAQs;