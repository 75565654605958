const ticket = {
  ticketen: "Ticket",
  ticketde: "Ticket",
  addticketen: "Add Ticket",
  addticketde: "Ticket erstellen",
  ticketiden: "Ticket Id",
  ticketide: "Ticket ID",
  emailen: "Email",
  emailde: "Email",
  subjecten: "Subject",
  subjectde: "Betreff",
  timeen: "Time",
  timede: "Datum",
  statusen: "Status",
  statusde: "Status",
  viewen: "View",
  viewde: "Anzeigen",
  bodyen: "Body",
  bodyde: "Nachricht",
  senden: "Send",
  sendde: "Senden",
  closeen: "Close",
  closede: "Abbrechen",
};

Object.freeze(ticket);

export default ticket;
