import React from "react";
import "./RP_Details.css";
import { images } from "../../Constants";
import { Link } from "react-router-dom";

const RP_Detail2 = () => {
  return (
    <div className="RP_Detail-container">
      <div className="container border px-4 pt-4 pb-5">
        <div className="recentPost-banner">
          <img src={images.recent_post2} alt="recentPost2" />
        </div>
        <h2 className="mb-2">Pressemitteilung:</h2>
        <p className="mb-1 pb-2">
          Treffen Sie uns auf der Vitafood in Genf vom 14. bis 15. Mai, wo wir
          voller Enthusiasmus dabei sein werden! Wir nutzen diese Gelegenheit,
          um uns mit Branchenexperten zu treffen, wertvolle Kontakte zu knüpfen
          und über die neuesten Entwicklungen im Rohstoffhandel zu diskutieren.
        </p>

        <p className="mb-1  pb-2">
          Wenn Sie mehr über Rawstuff Deals erfahren möchten oder an einem
          informellen Austausch interessiert sind, zögern Sie nicht, uns
          anzusprechen.
        </p>
        <p className="mb-1  pb-2">
          Wir freuen uns darauf, interessante Gespräche zu führen und unser
          Fachwissen zu teilen. Sie können uns auch vorab per E-Mail unter
          <Link> info@rawstuff.deals</Link> kontaktieren, wenn Sie Fragen haben
          oder weitere Informationen wünschen. Wir sehen uns auf der Vitafood!
        </p>
      </div>
    </div>
  );
};

export default RP_Detail2;
