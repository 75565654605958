// AccountUnderReview.js

import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './AccountUnderReview.css'; // Import your custom CSS file

const AccountUnderReview = () => {

  return (
    <div className="account-under-review">
        <Container> 
      <Row>
        <Col  xs={12}  className="account-under-reviews">
         <div className='account_Under'>
         <h2>Überprüfung des Kontos in Bearbeitung</h2>
          <h3>Bald startklar!

</h3>
         </div>
         <p className='text-left mb-3'>
         Hallo und Herzlich willkommen!
          </p>
          <p className='text-left mb-2'>
          Wir freuen uns, dass Sie sich für uns als Plattform entschieden haben. Wir überprüfen derzeit Ihre Anmeldedaten mit größter Präzision, um Ihnen ein optimales Erlebnis zu bieten. 
          Daher ist es noch nicht möglich, sich in Ihr Konto 
          einzuloggen. Sobald Ihre Daten geprüft und freigegeben sind, werden Sie umgehend informiert und Ihr Abenteuer kann beginnen.
          </p>

          <p className='text-left mb-2'>
          Bitte schauen Sie bald wieder vorbei - es lohnt sich! Wir sind fast fertig und können es kaum erwarten, Sie an Bord zu haben.


          </p>
          <p className='text-left mb-2'>Wir danken Ihnen für Ihre Geduld. Wir bereiten alles für Ihren perfekten Start vor!</p>
          <br />
          <br />

          <Button className='acc-go-back-bt'>
            <a href="/"> &lt;  Zurück</a>
          </Button>
        </Col>
      </Row>
      </Container>
    </div>
  );
};

export default AccountUnderReview;
