import React from "react";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-container">
      <div className="container border px-4 pt-4 pb-5">
        <p className="mb-2">Datenschutzerklärung</p>
        <h3 className="mb-2">
          I.Name und Anschrift des Verantwortlichen, Allgemeines
        </h3>
        <p className="mb-1 pb-2">
          Der Verantwortliche im Sinne der Datenschutz-Grundverordnung und
          anderer nationaler Datenschutzgesetze der Mitgliedsstaaten sowie
          sonstiger datenschutzrechtlicher Bestimmungen ist die.
        </p>
        <p className="mb-1 pb-2">Firma:</p>
        <p className="mb-1 pb-1">Rawstuff Deals GmbH</p>
        <p className="mb-1 pb-1">Zum Heidchen 6</p>
        <p className="mb-1 pb-1">56377 Nassau</p>
        <p className="mb-1 pb-2">vertreten durch ihren Geschäftsführer:</p>
        <p className="mb-1 pb-1">Sebastian Schneider</p>
        <p className="mb-1 pb-1">Telefon: +49 2604 3980552</p>
        <p className="mb-1 pb-1">E-Mail: info@rawstuff.deals</p>
        <p className="mb-1 pb-1">Website: www.rawstuff.deals</p>
        <h3 className="mb-2">
          II.Name und Anschrift des Datenschutzbeauftragten/ Verantwortliche für
          den Datenschutz, Allgemeines
        </h3>
        <p className="mb-1 pb-2">
          Der Datenschutzbeauftragte des Verantwortlichen ist die::
        </p>
        <p className="mb-1 pb-3">Colibri Concepts GmbH,</p>
        <p className="mb-1 pb-2">vertreten durch ihren Geschäftsführer:</p>
        <p className="mb-1 pb-1">Markus Hönnicke</p>
        <p className="mb-1 pb-1">Rhein-Mosel-Straße 97</p>
        <p className="mb-1 pb-3">56283 Gondershausen</p>
        <p className="mb-1 pb-2">Kontakt:</p>
        <p className="mb-1 pb-1">www.colibri-concepts.de</p>
        <p className="mb-1 pb-1">www.colibri-concepts.de</p>
        <h3 className="mb-2">III.Allgemeines zur Datenverarbeitung</h3>
        <h3 className="mb-2">
          1. Umfang der Verarbeitung personenbezogener Daten
        </h3>
        <p className="mb-1 ps-3 pb-2">
          Wir verarbeiten personenbezogene Daten unserer Nutzer grundsätzlich
          nur, soweit dies zur Bereitstellung einer funktionsfähigen Website
          sowie unserer Inhalte und Leistungen erforderlich ist. Die
          Verarbeitung personenbezogener Daten unserer Nutzer erfolgt regelmäßig
          nur nach Einwilligung des Nutzers. Eine Ausnahme gilt in solchen
          Fällen, in denen eine vorherige Einholung einer Einwilligung aus
          tatsächlichen Gründen nicht möglich ist und die Verarbeitung der Daten
          durch gesetzliche Vorschriften gestattet ist.
        </p>
        <h3 className="mb-2">
          2. Rechtsgrundlage für die Verarbeitung personenbezogener Daten
        </h3>
        <p className="mb-1 ps-3 pb-2">
          Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine
          Einwilligung der betroffenen Person einholen, dient Art. 6 Abs. 1 lit.
          a EU-Datenschutzgrundverordnung (DSGVO) als Rechtsgrundlage.
        </p>
        <p className="mb-1 ps-3 pb-2">
          Bei der Verarbeitung von personenbezogenen Daten, die zur Erfüllung
          eines Vertrages, dessen Vertragspartei die betroffene Person ist,
          erforderlich ist, dient Art. 6 Abs. 1 lit. b DSGVO als
          Rechtsgrundlage. Dies gilt auch für Verarbeitungsvorgänge, die zur
          Durchführung vorvertraglicher Maßnahmen erforderlich sind.
        </p>
        <p className="mb-1 ps-3 pb-2">
          Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer
          rechtlichen Verpflichtung erforderlich ist, der unser Unternehmen
          unterliegt, dient Art. 6 Abs. 1 lit. c DSGVO als Rechtsgrundlage.
        </p>
        <p className="mb-1 ps-3 pb-2">
          Für den Fall, dass lebenswichtige Interessen der betroffenen Person
          oder einer anderen natürlichen Person eine Verarbeitung
          personenbezogener Daten erforderlich machen, dient Art. 6 Abs. 1 lit.
          d DSGVO als Rechtsgrundlage.
        </p>
        <p className="mb-1 ps-3 pb-2">
          Ist die Verarbeitung zur Wahrung eines berechtigten Interesses unseres
          Unternehmens oder eines Dritten erforderlich und überwiegen die
          Interessen, Grundrechte und Grundfreiheiten des Betroffenen das
          erstgenannte Interesse nicht, so dient Art. 6 Abs. 1 lit. f DSGVO als
          Rechtsgrundlage für die Verarbeitung.
        </p>
        <h3 className="mb-2">3. Datenlöschung und Speicherdauer</h3>
        <p className="mb-1 ps-3 pb-2">
          Die personenbezogenen Daten der betroffenen Person werden gelöscht
          oder gesperrt, sobald der Zweck der Speicherung entfällt. Eine
          Speicherung kann darüber hinaus erfolgen, wenn dies durch den
          europäischen oder nationalen Gesetzgeber in unionsrechtlichen
          Verordnungen, Gesetzen oder sonstigen Vorschriften, denen der
          Verantwortliche unterliegt, vorgesehen wurde. Eine Sperrung oder
          Löschung der Daten erfolgt auch dann, wenn eine durch die genannten
          Normen vorgeschriebene Speicherfrist abläuft, es sei denn, dass eine
          Erforderlichkeit zur weiteren Speicherung der Daten für einen
          Vertragsabschluss oder eine Vertragserfüllung besteht
        </p>
        <h3 className="mb-2">
          IV. Bereitstellung der Website und Erstellung von Logfiles
        </h3>
        <h3 className="mb-2">
          1. Beschreibung und Umfang der Datenverarbeitung
        </h3>
        <p className="mb-1 ps-3 pb-1">
          Bei jedem Aufruf unserer Internetseite erfasst unser System
          automatisiert Daten und Informationen vom Computersystem des
          aufrufenden Rechners. Folgende Daten werden hierbei erhoben:
        </p>
        <p className="mb-1 ps-3 pb-2">
          a.) Informationen über den Browsertyp und die verwendete Version
          <br />
          b.) Das Betriebssystem des Nutzers <br />
          c.) Den Internet-Service-Provider des Nutzers <br />
          d.) Die IP-Adresse des Nutzers <br />
          e.) Datum und Uhrzeit des Zugriffs <br />
          f.) Websites, von denen das System des Nutzers auf unsere
          Internetseite gelangt <br />
          g.) Websites, die vom System des Nutzers über unsere Website
          aufgerufen werden
        </p>
        <p className="mb-1 ps-3 pb-2">
          Die Daten werden ebenfalls in den Logfiles unseres Systems
          gespeichert. Eine Speicherung dieser Daten zusammen mit anderen
          personenbezogenen Daten des Nutzers findet nicht statt.
        </p>
        <h3 className="mb-2">2. Rechtsgrundlage für die Datenverarbeitung</h3>
        <p className="mb-1 ps-3 pb-2">
          Rechtsgrundlage für die vorübergehende Speicherung der Daten und der
          Logfiles ist Art. 6 Abs.1 lit. f DSGVO.
        </p>
        <h3 className="mb-2">3. Zweck der Datenverarbeitung</h3>
        <p className="mb-1 ps-3 pb-2">
          Die vorübergehende Speicherung der IP-Adresse durch das System ist
          notwendig, um eine Auslieferung der Website an den Rechner des Nutzers
          zu ermöglichen. Hierfür muss die IP-Adresse des Nutzers für die Dauer
          der Sitzung gespeichert bleiben.
        </p>
        <p className="mb-1 ps-3 pb-2">
          Die Speicherung in Logfiles erfolgt, um die Funktionsfähigkeit der
          Website sicherzustellen. Zudem dienen uns die Daten zur Optimierung
          der Website und zur Sicherstellung der Sicherheit unserer
          informationstechnischen Systeme. Eine Auswertung der Daten zu
          Marketingzwecken findet in diesem Zusammenhang nicht statt.
        </p>
        <p className="mb-1 ps-3 pb-2">
          In diesen Zwecken liegt auch unser berechtigtes Interesse an der
          Datenverarbeitung nach Art. 6 Abs. 1 lit. f DSGVO.
        </p>
        <h3 className="mb-2">4. Dauer der Speicherung</h3>
        <p className="mb-1 ps-3 pb-2">
          Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes
          ihrer Erhebung nicht mehr erforderlich sind. Im Falle der Erfassung
          der Daten zur Bereitstellung der Website ist dies der Fall, wenn die
          jeweilige Sitzung beendet ist.
        </p>
        <p className="mb-1 ps-3 pb-2">
          Im Falle der Speicherung der Daten in Logfiles ist dies nach
          spätestens sieben Tagen der Fall. Eine darüberhinausgehende
          Speicherung ist möglich. In diesem Fall werden die IP-Adressen der
          Nutzer gelöscht oder verfremdet, sodass eine Zuordnung des aufrufenden
          Clients nicht mehr möglich ist.
        </p>
        <h3 className="mb-2">5. Widerspruchs- und Beseitigungsmöglichkei</h3>
        <p className="mb-1 ps-3 pb-2">
          Die Erfassung der Daten zur Bereitstellung der Website und die
          Speicherung der Daten in Logfiles ist für den Betrieb der
          Internetseite zwingend erforderlich. Es besteht folglich seitens des
          Nutzers keine Widerspruchsmöglichkeit.
        </p>
        <h3 className="mb-2">V.Verwendung von Cookies</h3>
        <h3 className="mb-2">
          1.Beschreibung und Umfang der Datenverarbeitung
        </h3>
        <p className="mb-1 ps-3 pb-2">
          Unsere Webseite verwendet Cookies. Bei Cookies handelt es sich um
          Textdateien, die im Internetbrowser bzw. vom Internetbrowser auf dem
          Computersystem des Nutzers gespeichert werden. Ruft ein Nutzer eine
          Website auf, so kann ein Cookie auf dem Betriebssystem des Nutzers
          gespeichert werden. Dieses Cookie enthält eine charakteristische
          Zeichenfolge, die eine eindeutige Identifizierung des Browsers beim
          erneuten Aufrufen der Website ermöglicht.
        </p>
        <p className="mb-1 ps-3 pb-2">
          Wir setzen Cookies ein, um unsere Website nutzerfreundlicher zu
          gestalten. Einige Elemente unserer Internetseite erfordern es, dass
          der aufrufende Browser auch nach einem Seitenwechsel identifiziert
          werden kann.
        </p>
        <p className="mb-1 ps-3 pb-2">
          In den Cookies werden dabei folgende Daten gespeichert und
          übermittelt: <br />
          a.) Spracheinstellungen <br />
          b.) Log-In-Informationen <br />
          Wir verwenden auf unserer Website darüber hinaus Cookies, die eine
          Analyse des Surfverhaltens der Nutzer ermöglichen. <br /> Auf diese
          Weise können folgende Daten übermittelt werden: <br />
          c.) Anzahl der Webseiten-Nutzer insgesamt <br />
          d.) Anzahl aller Webseiten-Sitzungen insgesamt <br />
          e.) Durchschnittliche Sitzungsdauer <br />
          f.) Nutzer nach Tageszeit <br />
          g.) Standorte/Land des Nutzers <br />
          h.) Besucherquellen <br />
          i.) Verweise <br />
          j.) Besuchte Seiten <br />
          Beim Aufruf unserer Website wird der Nutzer über die Verwendung von
          Cookies zu Analysezwecken informiert und seine Einwilligung zur
          Verarbeitung der in diesem Zusammenhang verwendeten personenbezogenen
          Daten eingeholt. In diesem Zusammenhang erfolgt auch ein Hinweis auf
          diese Datenschutzerklärung.
        </p>
        <h3 className="mb-2">2. Rechtsgrundlage für die Datenverarbeitung</h3>
        <p className="mb-1 ps-3 pb-2">
          Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten unter
          Verwendung technisch notweniger Cookies ist Art. 6 Abs. 1 lit. f
          DSGVO.
        </p>
        <p className="mb-1 ps-3 pb-2">
          Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten unter
          Verwendung von Cookies zu Analysezwecken ist bei Vorliegen einer
          diesbezüglichen Einwilligung des Nutzers Art. 6 Abs. 1 lit. a DSGVO.
        </p>
        <h3 className="mb-2">3. Zweck der Datenverarbeitung</h3>
        <p className="mb-1 ps-3 pb-2">
          Der Zweck der Verwendung technisch notwendiger Cookies ist, die
          Nutzung von Websites für die Nutzer zu vereinfachen. Einige Funktionen
          unserer Internetseite können ohne den Einsatz von Cookies nicht
          angeboten werden. Für diese ist es erforderlich, dass der Browser auch
          nach einem Seitenwechsel wiedererkannt wird. Für folgende Anwendungen
          benötigen wir Cookies: <br />
          a.) Übernahme von Spracheinstellungen <br />
          b.) Merken von Suchbegriffen <br />
          Die durch technisch notwendige Cookies erhobenen Nutzerdaten werden
          nicht zur Erstellung von Nutzerprofilen verwendet. <br />
          Die Verwendung der Analyse-Cookies erfolgt zu dem Zweck, die Qualität
          unserer Website und ihre Inhalte zu verbessern. Durch die
          Analyse-Cookies erfahren wir, wie die Website genutzt wird und können
          so unser Angebot stetig optimieren.
        </p>
        <h3 className="mb-2">
          4. Dauer der Speicherung, Widerspruchs- und Beseitigungsmöglichkeit
        </h3>
        <p className="mb-1 ps-3 pb-2">
          Cookies werden auf dem Rechner des Nutzers gespeichert und von diesem
          an unserer Seite übermittelt. Daher haben Sie als Nutzer auch die
          volle Kontrolle über die Verwendung von Cookies. Durch eine Änderung
          der Einstellungen in Ihrem Internetbrowser können Sie die Übertragung
          von Cookies deaktivieren oder einschränken. Bereits gespeicherte
          Cookies können jederzeit gelöscht werden. Dies kann auch automatisiert
          erfolgen. Werden Cookies für unsere Website deaktiviert, können
          möglicherweise nicht mehr alle Funktionen der Website vollumfänglich
          genutzt werden.
        </p>
        <h3 className="mb-2">VI.Social Media Plug-ins</h3>
        <p className="mb-1 ps-3 pb-2">
          Cookies werden auf dem Rechner des Nutzers gespeichert und von diesem
          an unserer Seite übermittelt. Daher haben Sie als Nutzer auch die
          volle Kontrolle über die Verwendung von Cookies. Durch eine Änderung
          der Einstellungen in Ihrem Internetbrowser können Sie die Übertragung
          von Cookies deaktivieren oder einschränken. Bereits gespeicherte
          Cookies können jederzeit gelöscht werden. Dies kann auch automatisiert
          erfolgen. Werden Cookies für unsere Website deaktiviert, können
          möglicherweise nicht mehr alle Funktionen der Website vollumfänglich
          genutzt werden.
        </p>
        <h3 className="mb-2">LinkedIn</h3>
        <p className="mb-1 ps-3 pb-2">
          Auf unserer Website kommen Plugins von LinkedIn zum Einsatz, um deren
          Nutzung persönlicher zu gestalten. Hierfür nutzen wir die „Gefällt
          mir“-Markierungen und Kommentare und die Funktion „Folgen“. Es handelt
          sich dabei um ein Angebot von LinkedIn. Muttergesellschaft ist die
          LinkedIn Corporation, 1000 W. Maude Avenue, Sunnyvale, CA 94085, USA.
          Für Europa ist die LinkedIn Ireland Unlimited Company, Attn: Legal
          Dept. (Privacy Policy and User Agreement), Wilton Plaza, Wilton Place,
          Dublin 2, Ireland Ansprechpartner. Eine online Kontaktmöglichkeit gibt
          es unter
          <a
            className="text-decoration-underline"
            href="http://www.linkedin.com/help/legacy/redirect/app/ask/path/ppq/loc/na/trk/microsites-frontend_legal_privacy-policy/"
            target="_blank"
          >
            www.linkedin.com/help/legacy/redirect/app/ask/path/ppq/loc/na/trk/microsites-frontend_legal_privacy-policy/.
          </a>
        </p>
        <p className="mb-1 ps-3 pb-2">
          Wenn Sie eine Seite unseres Webauftritts aufrufen, die ein solches
          Plugin enthält, baut Ihr Browser eine direkte Verbindung mit den
          Servern von LinkedIn auf. Der Inhalt des Plugins wird von LinkedIn
          direkt an Ihren Browser übermittelt und von diesem in die Webseite
          eingebunden.
        </p>
        <p className="mb-1 ps-3 pb-2">
          Durch die Einbindung der Plugins protokolliert LinkedIn Nutzungsdaten,
          wenn Sie unsere Dienste einschließlich unserer Websites, App- und
          Plattformtechnologie besuchen oder anderweitig nutzen, wenn Sie
          beispielsweise Inhalte (wie Lernvideos) oder Anzeigen betrachten oder
          anklicken (innerhalb oder außerhalb unserer Websites und Apps), eine
          Suche ausführen, eine unserer Apps für Mobilgeräte installieren oder
          aktualisieren, Artikel teilen oder sich um eine Stelle bewerben.
          LinkedIn verwendet Anmeldedaten, Cookies, Geräteinformationen und
          IP-Adressen, um Sie zu identifizieren und Ihre Nutzung zu
          protokollieren.
        </p>
        <p className="mb-1 ps-3 pb-2">
          Sind Sie bei LinkedIn angemeldet, kann LinekdIn den Besuch unserer
          Website Ihrem LinkedIn-Konto direkt zuordnen. Wenn Sie mit den Plugins
          interagieren, zum Beispiel „Gefällt mir“-Markierungen und Kommentare
          und die Funktion „Folgen“, wird die entsprechende Information
          ebenfalls direkt an einen Server von LinkedIn übermittelt und dort
          gespeichert. Die Informationen werden zudem auf LinkedIn
          veröffentlicht und Ihren LinkedIn-Kontakten angezeigt.
        </p>
        <p className="mb-1 ps-3 pb-2">
          LinkedIn kann diese Informationen zum Zwecke der Werbung,
          Marktforschung und bedarfsgerechten Gestaltung der LinkedIn-Seiten
          benutzen. Hierzu verwendet LinkedIn Daten zu Ihrer Person (wie Ihr
          Profil, Profile, die Sie sich angesehen haben, oder Daten, die über
          Adressbuch-Uploads oder Partnerintegrierung bereitgestellt wurden), um
          es anderen zu ermöglichen, Ihr Profil zu finden, Ihnen und anderen
          Mitgliedern (beispielsweise Mitgliedern, mit denen Sie Kontakte oder
          Joberfahrungen gemein haben), Kontakte vorzuschlagen und es Ihnen zu
          ermöglichen, andere Personen einzuladen, Mitglied zu werden und sich
          mit Ihnen zu vernetzen. Sie können außerdem zustimmen, dass wir Ihren
          genauen Standort oder die Tatsache, dass Sie sich in der Nähe zu
          anderen Personen befinden, für bestimmte Funktionen verwenden dürfen
          (z. B., um Ihnen andere Mitglieder in Ihrer Nähe vorzuschlagen, mit
          denen Sie sich vernetzen können, oder um den Anfahrtsweg zu einem
          neuen Job zu berechnen oder Ihren Kontakten mitzuteilen, dass Sie sich
          auf einer beruflichen Veranstaltung befinden).
        </p>
        <p className="mb-1 ps-3 pb-2">
          Wenn Sie nicht möchten, dass LinkedIn die über unseren Webauftritt
          gesammelten Daten Ihrem LinkedIn-Konto zuordnet, müssen Sie sich vor
          Ihrem Besuch unserer Website bei LinekdIn ausloggen.
        </p>
        <p className="mb-1 ps-3 pb-2">
          Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und
          Nutzung der Daten durch LinkedIn sowie Ihre diesbezüglichen Rechte und
          Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre entnehmen Sie
          bitte den Datenschutzhinweisen
          <a
            className="text-decoration-underline"
            href="https://de.linkedin.com/legal/privacy-policy?"
            target="_blank"
          >
            (https://de.linkedin.com/legal/privacy-policy?)
          </a>
          von LinkedIn.
        </p>
        <h3 className="mb-2">VII.Eingebundene Dienste und Inhalte Dritter</h3>
        <p className="mb-1 ps-3 pb-2">
          Wir nutzen auf unserer Website durch Drittanbieter bereitgestellte
          Dienste, Services und Inhalte (nachfolgend zusammenfassend als
          “Inhalte” bezeichnet). Für eine solche Einbindung ist eine
          Verarbeitung Ihrer IP-Adresse technisch notwendig, damit die Inhalte
          an Ihren Browser gesendet werden können. Ihre IP-Adresse wird daher an
          die jeweiligen Drittanbieter übermittelt. Diese Datenverarbeitungen
          erfolgen jeweils zur Wahrung unserer berechtigten Interessen an der
          Optimierung und dem wirtschaftlichen Betrieb unserer Website und
          beruhen auf der Rechtsgrundlage des Art. 6 Abs. 1 Buchst. f) DSGVO.
          Sie können dieser Datenverarbeitung jederzeit über die Einstellungen
          des verwendeten Browsers oder bestimmter BrowserErweiterungen
          widersprechen. Eine solche Erweiterung stellt beispielsweise die
          Matrix-basierte Firewall uMatrix für die Browser Firefox und
          Google-Chrome dar. Bitte beachten Sie, dass es hierdurch zu
          Funktionseinschränkungen auf der Website kommen kann. <br /> Wir haben
          in unsere Website Inhalte der folgenden durch Drittanbieter
          bereitgestellten Dienste eingebunden:
        </p>
        <h3 className="mb-2">1.Single Sign-On</h3>
        <p className="mb-1 ps-3 pb-2">
          Unsere Website bietet die Möglichkeit der Single Sign-On
          (SSO)-Authentifizierung, um Ihren Besuch auf unserer Website zu
          erleichtern. SSO ermöglicht es Ihnen, sich mit einem einzigen Satz von
          Anmeldeinformationen bei verschiedenen verbundenen Diensten
          anzumelden.
        </p>
        <p className="mb-1 ps-3 pb-2">
          Wenn Sie die SSO-Funktion verwenden, wird eine direkte Verbindung zu
          den Authentifizierungsservern hergestellt. Die erforderlichen
          Informationen zur Authentifizierung werden ausschließlich von unseren
          Servern verarbeitet und gespeichert.
        </p>
        <p className="mb-1 ps-3 pb-2">
          Wir erfassen und speichern keine persönlichen Daten aus Ihrem
          SSO-Logi-Prozess, außer denjenigen, die für die ordnungsgemäße
          Funktion und Sicherheit unseres Dienstes erforderlich sind. Dazu
          gehören möglicherweise Ihre SS-Identifikationsnummer und einige nicht
          personenbezogene Daten zur Authentifizierung.
        </p>
        <p className="mb-1 ps-3 pb-2">
          Die gesammelten Daten werden ausschließlich zur Authentifizierung und
          Sicherheit verwendet und nicht für andere Zwecke weitergegeben oder
          verkauft.
        </p>
        <h3 className="mb-2">2.Stripe</h3>
        <p className="mb-1 pb-2">
          Auf unserer Website nutzen wir den Bezahldienst Stripe Inc., 510
          Townsend Street, San Francisco, CA 94103, USA (im Folgenden "Stripe"),
          Anbieter in Europa: Stripe Payments Europe, Limited (SPEL), 1 Grand
          Canal Street Lower, Grand Canal Dock, Dublin, D02 H210, Irland um den
          Zahlungsvorgang zu optimieren.
        </p>
        <p className="mb-1 pb-2">
          Wenn Sie eine Seite unseres Webauftritts aufrufen, die ein Plugin von
          Stripe enthält, baut Ihr Browser eine direkte Verbindung mit den
          Servern von Stripe auf. Der Inhalt des Plugins wird von Stripe direkt
          an Ihren Browser übermittelt und von diesem in die Webseite
          eingebunden.
        </p>
        <p className="mb-1 pb-2">
          Durch die Einbindung der Plugins erhält Stripe die Information, dass
          Ihr Browser die entsprechende Seite unseres Webauftritts aufgerufen
          hat, auch wenn Sie kein Stripe-Konto besitzen oder gerade nicht bei
          Stripe eingeloggt sind. Diese Information (einschließlich Ihrer
          IP-Adresse) wird von Ihrem Browser direkt an einen Server von Stripe
          in den USA übermittelt und dort gespeichert.
        </p>
        <p className="mb-1 pb-2">
          Stripe erfordert die Übermittlung bestimmter Informationen während des
          Zahlungsvorgangs, einschließlich Ihrer Zahlungsdetails und
          gegebenenfalls anderer persönlicher Informationen, die für die
          Transaktion erforderlich sind.
        </p>
        <p className="mb-1 pb-2">
          Bitte beachten Sie, dass Stripe als eigenständiger Dienstleister
          handelt und wir keine Kontrolle über die von Stripe gesammelten Daten
          haben. Ihre Zahlungsinformationen werden direkt von Stripe verarbeitet
          und unterliegen den Datenschutzbestimmungen von Stripe.
        </p>
        <p className="mb-1 pb-2">
          Stripe kann Ihre Daten für interne Zwecke wie die Analyse von
          Zahlungsabwicklungen, die Betrugsprävention und die Verbesserung
          seiner Dienstleistungen verwenden. Diese Daten können auch für
          rechtliche Anforderungen oder zur Einhaltung von Vorschriften
          offengelegt werden.
        </p>
        <p className="mb-1 pb-2">
          Wir haben keinen Zugriff auf Ihre vollständigen Zahlungsinformationen,
          sondern erhalten lediglich eine Bestätigung über den erfolgreichen
          Abschluss der Transaktion.
        </p>
        <p className="mb-1 pb-2">
          Für weitere Informationen über die Datenschutzpraktiken von Stripe und
          Ihre Rechte im Zusammenhang mit Ihren Zahlungsdaten empfehlen wir
          Ihnen, die Datenschutzerklärung von Stripe unter{" "}
          <a
            className="text-decoration-underline"
            href="https://stripe.com/privacy "
            target="_blank"
          >
            https://stripe.com/privacy{" "}
          </a>
          zu lesen.
        </p>
        <p className="mb-1 pb-2">
          Wenn Sie nicht möchten, dass Stripe die über unseren Webauftritt
          gesammelten Daten verarbeitet, können Sie die Verwendung von
          Stripe-Plugins durch die Einstellung Ihres Browsers verhindern. Hierzu
          können Sie beispielsweise JavaScript-Plugins deaktivieren oder einen
          Script-Blocker verwenden.
        </p>
        <h3 className="mb-2">VIII.Kontaktformular und E-Mail-Kontakt</h3>
        <h3 className="mb-2">
          1. Beschreibung und Umfang der Datenverarbeitung
        </h3>
        <p className="mb-1 pb-2">
          Auf unserer Internetseite ist ein Kontaktformular vorhanden, welches
          für die elektronische Kontaktaufnahme genutzt werden kann. Nimmt ein
          Nutzer diese Möglichkeit wahr, so werden die in der Eingabemaske
          eingegeben Daten an uns übermittelt und gespeichert. Diese Daten sind:{" "}
          <br />
          a.) Name, Vorname <br />
          b.) E-Mail-Adresse <br />
          c.) Nachricht <br />
          Im Zeitpunkt der Absendung der Nachricht werden zudem folgende Daten
          gespeichert: <br />
          g.) Die IP-Adresse des Nutzers <br />
          h.) Datum und Uhrzeit der Registrierung <br />
          Für die Verarbeitung der Daten wird im Rahmen des Absendevorgangs Ihre
          Einwilligung eingeholt und auf diese Datenschutzerklärung verwiesen.
          Alternativ ist eine Kontaktaufnahme über die bereitgestellte
          E-Mail-Adresse möglich. In diesem Fall werden die mit der E-Mail
          übermittelten personenbezogenen Daten des Nutzers gespeichert. Es
          erfolgt in diesem Zusammenhang keine Weitergabe der Daten an Dritte.
          Die Daten werden ausschließlich für die Verarbeitung der Konversation
          verwendet.
        </p>
        <h3 className="mb-2">2.Rechtsgrundlage für die Datenverarbeitung</h3>
        <p className="mb-1 ps-3 pb-2">
          Rechtsgrundlage für die Verarbeitung der Daten ist bei Vorliegen einer
          Einwilligung des Nutzers Art. 6 Abs. 1 lit. a DSGVO.
        </p>
        <p className="mb-1 ps-3 pb-2">
          Rechtsgrundlage für die Verarbeitung der Daten, die im Zuge einer
          Übersendung einer E-Mail übermittelt werden, ist Art. 6 Abs. 1 lit. f
          DSGVO. Zielt der E-Mail-Kontakt auf den Abschluss eines Vertrages ab,
          so ist zusätzliche Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1
          lit. b DSGVO.
        </p>
        <h3 className="mb-2">3.Zweck der Datenverarbeitung</h3>
        <p className="mb-1 ps-3 pb-2">
          Die Verarbeitung der personenbezogenen Daten aus der Eingabemaske
          dient uns allein zur Bearbeitung der Kontaktaufnahme. Im Falle einer
          Kontaktaufnahme per E-Mail liegt hieran auch das erforderliche
          berechtigte Interesse an der Verarbeitung der Daten.
        </p>
        <p className="mb-1 ps-3 pb-2">
          Die sonstigen während des Absendevorgangs verarbeiteten
          personenbezogenen Daten dienen dazu, einen Missbrauch des
          Kontaktformulars zu verhindern und die Sicherheit unserer
          informationstechnischen Systeme sicherzustellen.
        </p>
        <h3 className="mb-2">4.Dauer der Speicherung</h3>
        <p className="mb-1 ps-3 pb-2">
          Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes
          ihrer Erhebung nicht mehr erforderlich sind. Für die personenbezogenen
          Daten aus der Eingabemaske des Kontaktformulars und diejenigen, die
          per E-Mail übersandt wurden, ist dies dann der Fall, wenn die
          jeweilige Konversation mit dem Nutzer beendet ist. Beendet ist die
          Konversation dann, wenn sich aus den Umständen entnehmen lässt, dass
          der betroffene Sachverhalt abschließend geklärt ist.
        </p>
        <p className="mb-1 ps-3 pb-2">
          Die während des Absendevorgangs zusätzlich erhobenen personenbezogenen
          Daten werden spätestens nach einer Frist von vierzehn Tagen gelöscht.
        </p>
        <h3 className="mb-2">5.Widerspruchs- und Beseitigungsmöglichkeit</h3>
        <p className="mb-1 ps-3 pb-2">
          Der Nutzer hat jederzeit die Möglichkeit, seine Einwilligung zur
          Verarbeitung der personenbezogenen Daten zu widerrufen. Nimmt der
          Nutzer per E-Mail-Kontakt mit uns auf, so kann er der Speicherung
          seiner personenbezogenen Daten jederzeit widersprechen. In einem
          solchen Fall kann die Konversation nicht fortgeführt werden. <br />{" "}
          Möchten Sie von Ihrem Widerrufs- oder Widerspruchsrecht Gebrauch
          machen, genügt eine E-Mail an
          <a
            className="text-decoration-underline"
            href="mailto:info@rawstuff.deals"
            target="_blank"
          >
            info@rawstuff.deals
          </a>
          . Alle personenbezogenen Daten, die im Zuge der Kontaktaufnahme
          gespeichert wurden, werden in diesem Fall gelöscht.
        </p>
        <h3 className="mb-2">IX.Registrierung</h3>
        <h3 className="mb-2">
          1.Beschreibung und Umfang der Datenverarbeitung
        </h3>
        <p className="mb-0 ps-3 pb-1">
          Auf unserer Internetseite bieten wir Nutzern die Möglichkeit, sich
          unter Angabe personenbezogener Daten zu registrieren. Nach dem
          Registrierungsprozess nutzen wir XY, des externen Anbieters, XY, um
          diese Daten zur verwalten. Weitere Information finden Sie unter:
          https://XXX/. Die Daten werden dabei in eine Eingabemaske eingegeben
          und an uns übermittelt und gespeichert. Eine Weitergabe der Daten an
          Dritte findet nicht statt. Folgende Daten werden im Rahmen des
          Registrierungsprozesses erhoben:
        </p>
        <ul className="mb-0">
          <li>• Vorname, Nachname/ ggf. Firmenname</li>
          <li>• Geschlecht: männlich, weiblich, divers</li>
          <li>• Adresse</li>
          <li>• Telefonnummer</li>
          <li>• Ggf. Steuernummer</li>
          <li>• Ggf. vertretungsberechtigte Person der Firma</li>
          <li>• Ggf. Unternehmensseite im Internet</li>
          <li>• Ggf. Registereintrag der Firma</li>
          <li>• Ggf. Bilder und Beschreibung von Waren</li>
          <li>• Emailadresse</li>
          <li>• Passwor</li>
        </ul>
        <p className="mb-0 ps-3 pb-0">
          Im Zeitpunkt der Registrierung werden zudem folgende Daten
          gespeichert:
        </p>
        <ul>
          <li>• Die IP-Adresse des Nutzers</li>
          <li>• Datum und Uhrzeit der Registrierung</li>
        </ul>
        <p className="mb-1 ps-3 pb-1">
          Im Rahmen des Registrierungsprozesses wird eine Einwilligung des
          Nutzers zur Verarbeitung dieser Daten eingeholt.
        </p>
        <h3 className="mb-2">2.Rechtsgrundlage für die Datenverarbeitung</h3>
        <p className="mb-1 ps-3 pb-2">
          Rechtsgrundlage für die Verarbeitung der Daten ist bei Vorliegen einer
          Einwilligung des Nutzers Art. 6 Abs. 1 lit. a DSGVO.
        </p>
        <p className="mb-1 ps-3 pb-2">
          Dient die Registrierung der Erfüllung eines Vertrages, dessen
          Vertragspartei der Nutzer ist oder der Durchführung vorvertraglicher
          Maßnahmen, so ist zusätzliche Rechtsgrundlage für die Verarbeitung der
          Daten Art. 6 Abs. 1 lit. b DSGVO.
        </p>
        <h3 className="mb-2">3.Zweck der Datenverarbeitung</h3>
        <p className="mb-1 ps-3 pb-2">
          Eine Registrierung des Nutzers ist zur Erfüllung eines Vertrages mit
          dem Nutzer oder zur Durchführung vorvertraglicher Maßnahmen
          erforderlich.
        </p>
        <h3 className="mb-2">4. Dauer der Speicherung</h3>
        <p className="mb-1 ps-3 pb-2">
          Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes
          ihrer Erhebung nicht mehr erforderlich sind.
        </p>
        <p className="mb-1 ps-3 pb-2">
          Dies ist für die während des Registrierungsvorgangs zur Erfüllung
          eines Vertrags oder zur Durchführung vorvertraglicher Maßnahmen dann
          der Fall, wenn die Daten für die Durchführung des Vertrages nicht mehr
          erforderlich sind. Auch nach Abschluss des Vertrags kann eine
          Erforderlichkeit, personenbezogene Daten des Vertragspartners zu
          speichern, bestehen, um vertraglichen oder gesetzlichen
          Verpflichtungen nachzukommen.
        </p>
        <h3 className="mb-2">5.Widerspruchs- und Beseitigungsmöglichkeit</h3>
        <p className="mb-1 ps-3 pb-2">
          Als Nutzer haben sie jederzeit die Möglichkeit, die Registrierung
          aufzulösen. Die über Sie gespeicherten Daten können Sie jederzeit
          abändern lassen.
        </p>
        <p className="mb-1 ps-3 pb-2">
          Sind die Daten zur Erfüllung eines Vertrages oder zur Durchführung
          vorvertraglicher Maßnahmen erforderlich, ist eine vorzeitige Löschung
          der Daten nur möglich, soweit nicht vertragliche oder gesetzliche
          Verpflichtungen einer Löschung entgegenstehen.
        </p>
        <h3 className="mb-2">X.Umgang mit Kommentaren und Beiträgen (Blog)</h3>
        <h3 className="mb-2">
          1.Beschreibung und Umfang der Datenverarbeitung
        </h3>
        <p className="mb-1 ps-3 pb-2">
          Auf unserer Internetseite haben Sie die Möglichkeit einen Beitrag oder
          Kommentar in einer chronologisch abwärts sortierten Liste von
          Einträgen zu hinterlassen (Blog). Nimmt ein Nutzer diese Möglichkeit
          wahr, so werden die in der Eingabemaske eingegeben Daten an uns
          übermittelt und gespeichert. Diese Daten sind:
        </p>
        <p className="mb-1 ps-5 pb-2">
          1. Name <br />
          2. E-Mailadresse{" "}
        </p>
        <p className="mb-1 ps-3 pb-2">
          Im Zeitpunkt der Absendung der Nachricht werden zudem folgende Daten
          gespeichert:
        </p>
        <p className="mb-1 ps-5 pb-2">
          1. Die IP-Adresse des Nutzers <br />
          2. Datum und Uhrzeit der Registrierung <br />
        </p>
        <p className="mb-1 ps-3 pb-2">
          Für die Verarbeitung der Daten wird im Rahmen des Absendevorgangs Ihre
          Einwilligung eingeholt und auf diese Datenschutzerklärung verwiesen.
          Es erfolgt in diesem Zusammenhang keine Weitergabe der Daten an
          Dritte. Die Daten werden ausschließlich für die Verarbeitung der
          Konversation verwendet.
        </p>
        <h3 className="mb-2">2.Rechtsgrundlage für die Datenverarbeitung</h3>
        <p className="mb-1 ps-3 pb-2">
          Rechtsgrundlage für die Verarbeitung der Daten ist bei Vorliegen einer
          Einwilligung des Nutzers Art. 6 Abs. 1 lit. a DSGVO.
        </p>
        <p className="mb-1 ps-3 pb-2">
          Rechtsgrundlage für die Verarbeitung der Daten, die im Zuge von
          Kommentaren und Beiträgen auf unserer Webpräsenz übermittelt werden,
          ist Art. 6 Abs. 1 lit. f DSGVO.
        </p>
        <h3 className="mb-2">3.Zweck der Datenverarbeitung</h3>
        <p className="mb-1 ps-3 pb-2">
          Die Verarbeitung der personenbezogenen Daten aus der Eingabemaske
          dient uns allein zur Identifizierung des Nutzers und der Sicherheit
          von uns als Websitebetreiber sowie der Korrektur von ggf.
          rechtswidrigen Inhalten. Hierin liegt auch das erforderliche
          berechtigte Interesse an der Verarbeitung der Daten.
        </p>
        <p className="mb-1 ps-3 pb-2">
          Die sonstigen während des Absendevorgangs verarbeiteten
          personenbezogenen Daten dienen dazu, einen Missbrauch der Blogfunktion
          zu verhindern und die Sicherheit unserer informationstechnischen
          Systeme sicherzustellen.
        </p>
        <h3 className="mb-2">4.Dauer der Speicherung</h3>
        <p className="mb-1 ps-3 pb-2">
          Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes
          ihrer Erhebung nicht mehr erforderlich sind. Für die personenbezogenen
          Daten aus der Eingabemaske zur Kommentarfunktion, ist dies dann der
          Fall, wenn der jeweilige Beitrag oder Kommentar entfernt wird.
        </p>
        <p className="mb-1 ps-3 pb-2">
          Die während des Absendevorgangs zusätzlich erhobenen personenbezogenen
          Daten werden spätestens nach einer Frist von sieben Tagen gelöscht.
        </p>
        <h3 className="mb-2">5.Widerspruchs- und Beseitigungsmöglichkeit</h3>
        <p className="mb-1 ps-3 pb-2">
          Der Nutzer hat jederzeit die Möglichkeit, seine Einwilligung zur
          Verarbeitung der personenbezogenen Daten zu widerrufen. Nimmt der
          Nutzer per E-Mail-Kontakt mit uns auf, so kann er der Speicherung
          seiner personenbezogenen Daten jederzeit widersprechen. In einem
          solchen Fall kann die Konversation nicht fortgeführt werden.
        </p>
        <p className="mb-1 ps-3 pb-2">
          Möchten Sie von Ihrem Widerrufs- oder Widerspruchsrecht Gebrauch
          machen, genügt eine E-Mail an
          <a
            className="text-decoration-underline"
            href="mailto:info@rawstuff.deals"
            target="_blank"
          >
            info@rawstuff.deals
          </a>
          Alle personenbezogenen Daten, die im Zuge der Kommentar- und
          Beitragsfunktion gespeichert wurden, werden in diesem Fall gelöscht.
        </p>
        <h3 className="mb-2">XI.Rechte der betroffenen Person</h3>
        <p className="mb-1 ps-3 pb-2">
          Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie
          Betroffener i.S.d. DSGVO und es stehen Ihnen folgende Rechte gegenüber
          dem Verantwortlichen zu:
        </p>
        <h3 className="mb-2">1.Auskunftsrecht</h3>
        <p className="mb-1 ps-3 pb-2">
          Sie können von dem Verantwortlichen eine Bestätigung darüber
          verlangen, ob personenbezogene Daten, die Sie betreffen, von uns
          verarbeitet werden. Liegt eine solche Verarbeitung vor, können Sie von
          dem Verantwortlichen über folgende Informationen Auskunft verlangen:{" "}
          <br />
          a.) die Zwecke, zu denen die personenbezogenen Daten verarbeitet
          werden; <br />
          b.) die Kategorien von personenbezogenen Daten, welche verarbeitet
          werden; <br />
          c.) die Empfänger bzw. die Kategorien von Empfängern, gegenüber denen
          die Sie betreffenden personenbezogenen Daten offengelegt wurden oder
          noch offengelegt werden; <br />
          d.) die geplante Dauer der Speicherung der Sie betreffenden
          personenbezogenen Daten oder, falls konkrete Angaben hierzu nicht
          möglich sind, Kriterien für die Festlegung der Speicherdauer; <br />
          e.) das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie
          betreffenden personenbezogenen Daten, eines Rechts auf Einschränkung
          der Verarbeitung durch den Verantwortlichen oder eines
          Widerspruchsrechts gegen diese Verarbeitung; <br />
          f.) das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;
          alle verfügbaren Informationen über die Herkunft der Daten, wenn die
          personenbezogenen Daten nicht bei der betroffenen Person erhoben
          werden; <br />
          g.) das Bestehen einer automatisierten Entscheidungsfindung
          einschließlich Profiling gemäß Art. 22 Abs. 1 und 4 DSGVO und –
          zumindest in diesen Fällen – aussagekräftige Informationen über die
          involvierte Logik sowie die Tragweite und die angestrebten
          Auswirkungen einer derartigen Verarbeitung für die betroffene Person.
        </p>
        <p className="mb-1 ps-3 pb-2">
          Ihnen steht das Recht zu, Auskunft darüber zu verlangen, ob die Sie
          betreffenden personenbezogenen Daten in ein Drittland oder an eine
          internationale Organisation übermittelt werden. In diesem Zusammenhang
          können Sie verlangen, über die geeigneten Garantien gem. Art. 46 DSGVO
          im Zusammenhang mit der Übermittlung unterrichtet zu werden.
        </p>
        <h3 className="mb-2">2.Recht auf Berichtigung</h3>
        <p className="mb-1 ps-3 pb-2">
          Sie haben ein Recht auf Berichtigung und/oder Vervollständigung
          gegenüber dem Verantwortlichen, sofern die verarbeiteten
          personenbezogenen Daten, die Sie betreffen, unrichtig oder
          unvollständig sind. Der Verantwortliche hat die Berichtigung
          unverzüglich vorzunehmen.
        </p>
        <h3 className="mb-2">3.Recht auf Einschränkung der Verarbeitung</h3>
        <p className="mb-1 ps-3 pb-2">
          Unter den folgenden Voraussetzungen können Sie die Einschränkung der
          Verarbeitung der Sie betreffenden personenbezogenen Daten verlangen:{" "}
          <br />
          a.) wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen
          für eine Dauer bestreiten, die es dem Verantwortlichen ermöglicht, die
          Richtigkeit der personenbezogenen Daten zu überprüfen; <br />
          b.) die Verarbeitung unrechtmäßig ist und Sie die Löschung der
          personenbezogenen Daten ablehnen und stattdessen die Einschränkung der
          Nutzung der personenbezogenen Daten verlangen; <br />
          c.) der Verantwortliche die personenbezogenen Daten für die Zwecke der
          Verarbeitung nicht länger benötigt, Sie diese jedoch zur
          Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
          benötigen, oder <br />
          d.) wenn Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1
          DSGVO eingelegt haben und noch nicht feststeht, ob die berechtigten
          Gründe des Verantwortlichen gegenüber Ihren Gründen überwiegen.
        </p>
        <p className="mb-1 ps-3 pb-2">
          Wurde die Einschränkung der Verarbeitung nach den o.g. Voraussetzungen
          eingeschränkt, werden Sie von dem Verantwortlichen unterrichtet, bevor
          die Einschränkung aufgehoben wird.
        </p>
        <h3 className="mb-2">4.Recht auf Löschung</h3>
        <h3 className="mb-2">a.) Löschungspflicht</h3>
        <p className="mb-1 ps-3 pb-2">
          Sie können von dem Verantwortlichen verlangen, dass die Sie
          betreffenden personenbezogenen Daten unverzüglich gelöscht werden, und
          der Verantwortliche ist verpflichtet, diese Daten unverzüglich zu
          löschen, sofern einer der folgenden Gründe zutrifft:
        </p>
        <p className="mb-1 ps-5 pb-2">
          i) Die Sie betreffenden personenbezogenen Daten sind für die Zwecke,
          für die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht
          mehr notwendig. <br />
          ii) Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung
          gem. Art. 6 Abs. 1 lit. a oder Art. 9 Abs. 2 lit. a DSGVO stützte, und
          es fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung.{" "}
          <br />
          iii) Sie legen gem. Art. 21 Abs. 1 DSGVO Widerspruch gegen die
          Verarbeitung ein und es liegen keine vorrangigen berechtigten Gründe
          für die Verarbeitung vor, oder Sie legen gem. Art. 21 Abs. 2 DSGVO
          Widerspruch gegen die Verarbeitung ein. <br />
          iv) Die Sie betreffenden personenbezogenen Daten wurden unrechtmäßig
          verarbeitet. <br />
          v) Die Löschung der Sie betreffenden personenbezogenen Daten ist zur
          Erfüllung einer rechtlichen Verpflichtung nach dem Unionsrecht oder
          dem Recht der Mitgliedstaaten erforderlich, dem der Verantwortliche
          unterliegt. <br />
          vi) Die Sie betreffenden personenbezogenen Daten wurden in Bezug auf
          angebotene Dienste der Informationsgesellschaft gemäß Art. 8 Abs. 1
          DSGVO erhoben.
        </p>

        <h3 className="mb-2">b.) Information an Dritte</h3>
        <p className="mb-1 ps-3 pb-2">
          Hat der Verantwortliche die Sie betreffenden personenbezogenen Daten
          öffentlich gemacht und ist er gem. Art. 17 Abs. 1 DSGVO zu deren
          Löschung verpflichtet, so trifft er unter Berücksichtigung der
          verfügbaren Technologie und der Implementierungskosten angemessene
          Maßnahmen, auch technischer Art, um für die Datenverarbeitung
          Verantwortliche, die die personenbezogenen Daten verarbeiten, darüber
          zu informieren, dass Sie als betroffene Person von ihnen die Löschung
          aller Links zu diesen personenbezogenen Daten oder von Kopien oder
          Replikationen dieser personenbezogenen Daten verlangt haben.
        </p>
        <h3 className="mb-2">c.)Ausnahmen</h3>
        <p className="mb-1 ps-3 pb-2">
          Das Recht auf Löschung besteht nicht, soweit die Verarbeitung
          erforderlich ist
        </p>
        <p className="mb-1 ps-5 pb-2">
          i.) zur Ausübung des Rechts auf freie Meinungsäußerung und
          Information;
          <br />
          ii.) zur Erfüllung einer rechtlichen Verpflichtung, die die
          Verarbeitung nach dem Recht der Union oder der Mitgliedstaaten, dem
          der Verantwortliche unterliegt, erfordert, oder zur Wahrnehmung einer
          Aufgabe, die im öffentlichen Interesse liegt oder in Ausübung
          öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen
          wurde; <br />
          iii.) aus Gründen des öffentlichen Interesses im Bereich der
          öffentlichen Gesundheit gemäß Art. 9 Abs. 2 lit. h und i sowie Art. 9
          Abs. 3 DSGVO; <br />
          iv.) für im öffentlichen Interesse liegende Archivzwecke,
          wissenschaftliche oder historische Forschungszwecke oder für
          statistische Zwecke gem. Art. 89 Abs. 1 DSGVO, soweit das unter
          Abschnitt a) genannte Recht voraussichtlich die Verwirklichung der
          Ziele dieser Verarbeitung unmöglich macht oder ernsthaft
          beeinträchtigt, oder <br />
          v.) zur Geltendmachung, Ausübung oder Verteidigung von
          Rechtsansprüchen.
        </p>
        <h3 className="mb-2">5.Recht auf Unterrichtung</h3>
        <p className="mb-1 ps-3 pb-2">
          Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der
          Verarbeitung gegenüber dem Verantwortlichen geltend gemacht, ist
          dieser verpflichtet, allen Empfängern, denen die Sie betreffenden
          personenbezogenen Daten offengelegt wurden, diese Berichtigung oder
          Löschung der Daten oder Einschränkung der Verarbeitung mitzuteilen, es
          sei denn, dies erweist sich als unmöglich oder ist mit einem
          unverhältnismäßigen Aufwand verbunden. <br /> Ihnen steht gegenüber
          dem Verantwortlichen das Recht zu, über diese Empfänger unterrichtet
          zu werden.
        </p>
        <h3 className="mb-2">6.Recht auf Datenübertragbarkeit</h3>
        <p className="mb-1 ps-3 pb-2">
          Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die
          Sie dem Verantwortlichen bereitgestellt haben, in einem
          strukturierten, gängigen und maschinenlesbaren Format zu erhalten.
          Außerdem haben Sie das Recht diese Daten einem anderen
          Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem die
          personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern
        </p>
        <p className="mb-1 ps-3 pb-2">
          a.) die Verarbeitung auf einer Einwilligung gem. Art. 6 Abs. 1 lit. a
          DSGVO oder Art. 9 Abs. 2 lit. a DSGVO oder auf einem Vertrag gem. Art.
          6 Abs. 1 lit. b DSGVO beruht und <br />
          b.) die Verarbeitung mithilfe automatisierter Verfahren erfolgt. In
          Ausübung dieses Rechts haben Sie ferner das Recht, zu erwirken, dass
          die Sie betreffenden personenbezogenen Daten direkt von einem
          Verantwortlichen einem anderen Verantwortlichen übermittelt werden,
          soweit dies technisch machbar ist. Freiheiten und Rechte anderer
          Personen dürfen hierdurch nicht beeinträchtigt werden. <br />
          Das Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung
          personenbezogener Daten, die für die Wahrnehmung einer Aufgabe
          erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung
          öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde
        </p>
        <h3 className="mb-2">7.Widerspruchsrecht</h3>
        <p className="mb-1 ps-3 pb-2">
          Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen
          Situation ergeben, jederzeit gegen die Verarbeitung der Sie
          betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1
          lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch
          für ein auf diese Bestimmungen gestütztes Profiling.
        </p>
        <p className="mb-1 ps-3 pb-2">
          Der Verantwortliche verarbeitet die Sie betreffenden personenbezogenen
          Daten nicht mehr, es sei denn, er kann zwingende schutzwürdige Gründe
          für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und
          Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung,
          Ausübung oder Verteidigung von Rechtsansprüchen.
        </p>
        <p className="mb-1 ps-3 pb-2">
          Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um
          Direktwerbung zu betreiben, haben Sie das Recht, jederzeit Widerspruch
          gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten
          zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das
          Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.
        </p>
        <p className="mb-1 ps-3 pb-2">
          Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so
          werden die Sie betreffenden personenbezogenen Daten nicht mehr für
          diese Zwecke verarbeitet.
        </p>
        <p className="mb-1 ps-3 pb-2">
          Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung von
          Diensten der Informationsgesellschaft – ungeachtet der Richtlinie
          2002/58/EG – Ihr Widerspruchsrecht mittels automatisierter Verfahren
          auszuüben, bei denen technische Spezifikationen verwendet werden.
        </p>
        <h3 className="mb-2">
          8.Recht auf Widerruf der datenschutzrechtlichen
          Einwilligungserklärung
        </h3>
        <p className="mb-1 ps-3 pb-2">
          Sie haben das Recht, Ihre datenschutzrechtliche Einwilligungserklärung
          jederzeit zu widerrufen. Durch den Widerruf der Einwilligung wird die
          Rechtmäßigkeit, der aufgrund der Einwilligung bis zum Widerruf
          erfolgten Verarbeitung nicht berührt.
        </p>
        <h3 className="mb-2">
          9.Automatisierte Entscheidung im Einzelfall einschließlich Profiling
        </h3>
        <p className="mb-1 ps-3 pb-2">
          Sie haben das Recht, nicht einer ausschließlich auf einer
          automatisierten Verarbeitung – einschließlich Profiling – beruhenden
          Entscheidung unterworfen zu werden, die Ihnen gegenüber rechtliche
          Wirkung entfaltet oder Sie in ähnlicher Weise erheblich
          beeinträchtigt. Dies gilt nicht, wenn die Entscheidung <br />
          a.) für den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen
          und dem Verantwortlichen erforderlich ist, <br />
          b.) aufgrund von Rechtsvorschriften der Union oder der
          Mitgliedstaaten, denen der Verantwortliche unterliegt, zulässig ist
          und diese Rechtsvorschriften angemessene Maßnahmen zur Wahrung Ihrer
          Rechte und Freiheiten sowie Ihrer berechtigten Interessen enthalten
          oder <br />
          c.) mit Ihrer ausdrücklichen Einwilligung erfolgt. <br />
          Allerdings dürfen diese Entscheidungen nicht auf besonderen Kategorien
          personenbezogener Daten nach Art. 9 Abs. 1 DSGVO beruhen, sofern nicht
          Art. 9 Abs. 2 lit. a oder g DSGVO gilt und angemessene Maßnahmen zum
          Schutz der Rechte und Freiheiten sowie Ihrer berechtigten Interessen
          getroffen wurden. <br />
          Hinsichtlich der in (1) und (3) genannten Fälle trifft der
          Verantwortliche angemessene Maßnahmen, um die Rechte und Freiheiten
          sowie Ihre berechtigten Interessen zu wahren, wozu mindestens das
          Recht auf Erwirkung des Eingreifens einer Person seitens des
          Verantwortlichen, auf Darlegung des eigenen Standpunkts und auf
          Anfechtung der Entscheidung gehört
        </p>
        <h3 className="mb-2">
          10. Recht auf Beschwerde bei einer Aufsichtsbehörde.
        </h3>
        <p className="mb-1 ps-3 pb-2">
          Unbeschadet eines anderweitigen verwaltungsrechtlichen oder
          gerichtlichen Rechtsbehelfs steht Ihnen das Recht auf Beschwerde bei
          einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres
          Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen
          Verstoßes, zu, wenn Sie der Ansicht sind, dass die Verarbeitung der
          Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt.
        </p>
        <p className="mb-1 ps-3 pb-2">
          Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde,
          unterrichtet den Beschwerdeführer über den Stand und die Ergebnisse
          der Beschwerde einschließlich der Möglichkeit eines gerichtlichen
          Rechtsbehelfs nach Art. 78 DSGVO.
        </p>
        <h3 className="mb-2">11.Stand der Datenschutzerklärung</h3>
        <p className="mb-1 ps-3 pb-2">
          Bei Änderungen unserer Prozesse passen wir die Informationen an. Stand
          dieser Datenschutzerklärung: 06. März 2024.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
