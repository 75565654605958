import React, { useEffect, useState } from "react";
import { Modal, Dropdown } from "react-bootstrap";
import { ArrowsDiagramsArrow4 } from "../productDetailPage/icons/ArrowsDiagramsArrow4";
import { useDispatch, useSelector } from "react-redux";
import { Chat } from "@mui/icons-material";
import { countryOptionss } from "../Regstration/Country";
import { useTranslation } from "@psyycker/react-translation";

const ChangeDeliveryDate = () => {
  const [productAddressModalOpen, setProductAddressModalOpen] = useState(false);
  const [contactSellerModalOpen, setContactSellerModalOpen] = useState(false);
  let getItems = useSelector((state) => state.getCart.cartItemsget);
  useEffect(() => {
    if (productAddressModalOpen || contactSellerModalOpen) {
      document.body.classList.add("blur-body");
    } else {
      document.body.classList.remove("blur-body");
    }

    return () => {
      document.body.classList.remove("blur-body");
    };
  }, [productAddressModalOpen, contactSellerModalOpen]);

  const handleProductAddressModalClose = () => {
    setProductAddressModalOpen(false);
  };

  const handleContactSellerModalClose = () => {
    setContactSellerModalOpen(false);
  };
  const { getTranslation } = useTranslation();
  return (
    <div className="address-container">
      <div>
        <div
          className="product-adress-seller"
          style={{ marginBottom: "0.6rem", marginTop: "0.6rem" }}
          onClick={() => setProductAddressModalOpen(true)}
        >
          <Dropdown.Toggle className="pro-address-shopping" as={CustomToggle}>
            {/* <button className='productbtn'> */}
<div className="kon">
Produkt Adresse
</div>
            {/* </button> */}
          </Dropdown.Toggle>
        </div>

        <Modal
          class="user-card"
          className={productAddressModalOpen ? "modal-blur" : ""}
          id="shopping-address-bgss"
          show={productAddressModalOpen}
          onHide={handleProductAddressModalClose}
        >
          <Modal.Body style={{ padding: "1rem 1.5rem 0rem 1.5rem " }}>
            <Modal.Header closeButton>
              <Modal.Title className="add-new-address">
                <h4 className="kon" closeButton>Produktlager</h4>
              </Modal.Title>
            </Modal.Header>
            <div>
              <div class="user-info">
                <p>
                  <strong>
                    {" "}
                    {getTranslation({ translationKey: "Country:" })}
                  </strong>
                  {
                    countryOptionss.find(
                      (item) =>
                        item.code ===
                        getItems.cart_items?.[0]?.product?.warehouse?.country
                    )?.value
                  }
                </p>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      <div>
        <div
          className="product-adress-seller"
          onClick={() => setContactSellerModalOpen(true)}
        >
          <Dropdown.Toggle as={CustomToggle}>
             {/* <button className='productbtn'> */}
               <div  className="kon"> 
               Kontaktiere Rawstuff Deals
            </div>
            
            {/* </button> */}
          </Dropdown.Toggle>
          <br />
          <br />
        </div>
        <Modal
          class="user-card"
          className={contactSellerModalOpen ? "modal-blur" : ""}
          id="shopping-address-bgs"
          show={contactSellerModalOpen}
          onHide={handleContactSellerModalClose}
        >
          <Modal.Body
            class="seller-contact"
            style={{ padding: "1rem 1.5rem 0rem 1.5rem " }}
          >
            <div>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h2>Rawstuff Contact Details</h2>
                </Modal.Title>
              </Modal.Header>
              <p>
                <strong>Name:</strong> Rawstuff Deal
              </p>
              <p>
                <strong>Email:</strong>info@rawstuff.deals
              </p>
              <button
                style={{
                  width: "300px",
                  height: "35px",
                  marginLeft: "1.7rem",
                  marginTop: "0.5rem",
                  borderColor: "black",
                  border: "2px",
                  borderRadius: "5px",
                }}
              >
                <a
                  style={{ color: "#18233E" }}
                  href="mailto:seller_email"
                  target="blank"
                >
                  <div style={{ marginLeft: "0.5rem" }}>
                    <Chat />
                    <strong>Message Rawstuff Deal</strong>
                  </div>
                </a>
              </button>
            </div>
          </Modal.Body>
        </Modal>
              
      </div>
    </div>
  );
};

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <ArrowsDiagramsArrow4
      color="#1577EA"
      style={{
        position: "relative",
      }}
    />
  </a>
));

export default ChangeDeliveryDate;
