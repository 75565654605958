import React, { useState, useEffect } from "react";
import PurchasedItem from "./Purchased";
import "./PurchasedItemStyle.css";
import axios from "axios";
import { useParams } from "react-router-dom";

function PurchasedItems() {
  const [totalorder, setTotalorder] = useState([]);
  const [pendingorder, setPendingorder] = useState([]);
  const [loading, setLoading] = useState(false);

  localStorage.setItem("orderedItem", totalorder.length);

  const { status } = useParams();

  useEffect(() => {
    let newtoken = JSON.parse(localStorage.getItem("token"));
    newtoken = newtoken.token;

    const fechorder = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          "https://api.rawstuff.deals/api/v1/orders/getUserOrders",
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${newtoken}`,
            },
          }
        );
        const data = response.data;
        setTotalorder(data.data);
        setLoading(false);
      } catch (error) {
        console.error("error");
        setLoading(false);
      }
    };

    const fetchPendingOrder = async () => {
      setLoading(true);
      try {
        const response2 = await axios.get(
          "https://api.rawstuff.deals/api/v1/orders/getUserOrders?status=pending",
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${newtoken}`,
            },
          }
        );
        setLoading(false);
        const data2 = response2.data;
        setPendingorder(data2.data);
      } catch (error) {
        console.error("error");
        setLoading(false);
      }
    };

    fechorder();
    fetchPendingOrder();
  }, []);

  return (
    <div className="dashboardd mt-1">
      <div class="row">
        <div class="col-sm">
          <div
            class="table-title d-none"
            style={{ background: "#eeeeee", padding: "2%" }}
          >
            <div class="row">
              <div class="col-sm-4">
                <h5 style={{ color: "#000000" }}>
                  <i class="fa fa-store"> </i>&nbsp;Purchased Items
                </h5>
              </div>
              <div class="col-sm-8">
                <div class="search-box">
                  <i class="fa fa-search"></i>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search&hellip;"
                  />
                </div>
              </div>
            </div>
          </div>

          <PurchasedItem
            purchasedItemsData={
              status === "pending" ? pendingorder : totalorder
            }
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
}

export default PurchasedItems;
