import React, { useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { europeanCountries, europeanCountriesCode } from "./ManageNewAdress";
import address from "../../Constants/Contents/Address";

function AddWarehouse({ modalOpen, handleModalClose, fetchwarehouse }) {
  const [newWarehouseDetails, setNewWarehouseDetails] = useState({
    name: "",
    company: "",
    // street1: "",
    city: "",
    zip: "",
    country: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    company: "",
    // street1: "",
    city: "",
    zip: "",
    country: "",
  });

  const handleFormInputChange = (event) => {
    const { name, value } = event.target;
    setNewWarehouseDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));

    if (newWarehouseDetails[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }

    if (name === "zip") {
      const isValidZipCode = /^[a-zA-Z0-9]{4,7}$/.test(value);

      if (!isValidZipCode) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Invalid zip code",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
      }
    }
  };

  const createWarehouse = async () => {
    try {
      var jsonString = localStorage.getItem("token");
      var jsonObject = JSON.parse(jsonString);
      var Token = jsonObject?.token;

      let formIsValid = true;
      const newErrors = { ...errors };

      Object.keys(newWarehouseDetails).forEach((key) => {
        if (newWarehouseDetails[key] === "" || !newWarehouseDetails[key]) {
          formIsValid = false;
          newErrors[key] = `${
            key.charAt(0).toUpperCase() + key.slice(1)
          } is required`;
        } else {
          newErrors[key] = "";
        }
      });

      if (!formIsValid) {
        setErrors(newErrors);
        return;
      }

      const isValidZip = /^[a-zA-Z0-9]{4,7}$/.test(newWarehouseDetails["zip"]);

      if (!isValidZip) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          zip: "Invalid zip code",
        }));
        return;
      }

      // Include country code in the warehouse details
      newWarehouseDetails["country"] =
        europeanCountriesCode[newWarehouseDetails["country"]];
      // Make a request to create a warehouse
      const response = await fetch(
        "https://api.rawstuff.deals/api/v1/warehouses",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${Token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newWarehouseDetails),
        }
      );

      if (response.ok) {
        // toast.success("Warehouse created successfully!");
        toast.success("Lager erfolgreich erstellt!");
        fetchwarehouse();
        handleModalClose();
      } else {
        // toast.error("Failed to create a warehouse?. Please try again.");
        toast.error(
          "Konnte kein Lager erstellt werden? Bitte versuche es erneut."
        );
      }
    } catch (error) {
      // toast.error("An error occurred. Please try again later.");
      toast.error(
        "Ein Fehler ist aufgetreten. Bitte versuchen Sie es später noch einmal."
      );
    }
  };

  return (
    <Modal id="shopping-address-bg" show={modalOpen} onHide={handleModalClose}>
      <Modal.Body>
        <Modal.Header closeButton>
          <Modal.Title className="add-new-address">
            {address.addnewaddressde}
          </Modal.Title>
        </Modal.Header>
        <Form>
          <Row>
            <Form.Group controlId="formGridName">
              <Form.Label className="">
                {address.deliveryaddressnamede}
              </Form.Label>
              <Form.Control
                type="text"
                name="name"
                required
                value={newWarehouseDetails.name}
                onChange={handleFormInputChange}
              />
              <span className="text-danger">{errors.name}</span>
            </Form.Group>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="formGridCompany">
                <Form.Label className="mt-3 mb-1">
                  {address.companyde}
                </Form.Label>
                <Form.Control
                  type="text"
                  required
                  name="company"
                  value={newWarehouseDetails.company}
                  onChange={handleFormInputChange}
                />
                <span className="text-danger">{errors.company}</span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Form.Group controlId="formGridCountry">
              <Form.Label className="mt-3 mb-1">{address.countryde}</Form.Label>
              <Form.Control
                as="select"
                name="country"
                required
                value={newWarehouseDetails.country}
                onChange={handleFormInputChange}
              >
                <option value="">{address.selectcountryde}</option>
                {europeanCountries.map((country) => (
                  <option key={country} value={country}>
                    {country}
                  </option>
                ))}
              </Form.Control>
              <span className="text-danger">{errors.country}</span>
            </Form.Group>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group controlId="formGridCity">
                <Form.Label className="mt-3  mb-1">{address.cityde}</Form.Label>
                <Form.Control
                  type="text"
                  name="city"
                  required
                  value={newWarehouseDetails.city}
                  onChange={handleFormInputChange}
                  style={{ borderRadius: "8px" }}
                />
                <span className="text-danger">{errors.city}</span>
              </Form.Group>
            </Col>
            {/* <Col>
              <Form.Group controlId="formGridStreet1">
                <Form.Label className="mt-3 mb-1">Street 1</Form.Label>
                <Form.Control
                  type="text"
                  name="street1"
                  required
                  value={newWarehouseDetails.street1}
                  onChange={handleFormInputChange}
                />
                <span className="text-danger">{errors.street1}</span>
              </Form.Group>
            </Col> */}
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group controlId="formGridZip">
                <Form.Label className="mt-3  mb-1">{address.zipde}</Form.Label>
                <Form.Control
                  type="text"
                  name="zip"
                  required
                  value={newWarehouseDetails.zip}
                  onChange={handleFormInputChange}
                />
                <span className="text-danger">{errors.zip}</span>
              </Form.Group>
            </Col>
          </Row>

          <Form.Group className="position-relative">
            <Form.Check
              className="Save-my-location"
              required
              name="terms"
              label={address.savemylocationde}
              // onChange={handleChange}
              feedbackType="invalid"
              id="validationFormik106"
              feedbackTooltip
              style={{
                height: "48px",
                width: "30rem",
                // margin: "0.5rem",
                paddingleft: "1.5rem",
                border: "#E1E1E1",
                display: "flex",
                alignItems: "center",
                fontSize: "1rem",
              }}
            />
          </Form.Group>
          <Button
            className="Save-Delivery"
            style={{ margin: "0.3rem" }}
            variant="secondary"
            onClick={() => createWarehouse()}
          >
            {address.savedeliveryaddressde}
          </Button>
          <Button
            variant="secondary"
            className="cancel-bt"
            // style={{
            //   margin: "0.3rem",
            //   background: "#fff",
            //   color: "#2C2C2C",
            //   border: "1px solid #2C2C2C",
            // }}
            onClick={handleModalClose}
          >
            {address.cancelde}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default AddWarehouse;
