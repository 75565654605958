import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "./ForgotPassword.css";

export default function ResetSuccess() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [token, setToken] = useState("");

  const passwordHandler = (event) => {
    const { name, value } = event.target;

    if (name === "password") {
      setPassword(value);
    } else if (name === "confirmPassword") {
      setConfirmPassword(value);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }
    try {
      await axios.post("https://api.rawstuff.deals/api/v1/resetPassword", {
        token: token,
        password: password,
        password_confirmation: confirmPassword,
      });
      toast.success("Password reset successful");
      window.location.href = "/login";
    } catch (error) {
      toast.error("Error resetting password");
    }
  };

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);

    const token = urlSearchParams.get("token");

    setToken(token);
  }, []);

  return (
    <div className="container-fluid d-flex align-items-center justify-content-center vh-100">
      <div
        className="card p-4"
        style={{ boxShadow: "0 4px 8px rgba(0,0,0,0.1)" }}
      >
        <form className="card__form" onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="password" className="form-label">
              {/* Password */}
              Passwort
            </label>
            <input
              type="password"
              value={password}
              onChange={(event) => passwordHandler(event)}
              name="password"
              className="form-control"
              // placeholder="Enter new password"
              placeholder="neues Passwort eingeben"
              required
            />
          </div>

          <div className="mb-3">
            <label htmlFor="confirmPassword" className="form-label">
              {/* Confirm Password */}
              Bestätige das Passwort
            </label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(event) => passwordHandler(event)}
              name="confirmPassword"
              className="form-control"
              // placeholder="Confirm new password"
              placeholder="Bestätige neues Passwort"
              required
            />
          </div>

          <button
            type="submit"
            className="btn btn-dark w-100"
            style={{
              transition: "background-color 0.3s",
              backgroundColor: "#000000",
              borderColor: "#000000",
            }}
          >
            {/* Reset Password */}
            Passwort zurücksetzen
          </button>
        </form>
      </div>
    </div>
  );
}
