import React, { useState } from "react";
import { CustomPagination } from "./Pagination2";
import { CustomHits } from "./ProductCard";
import Filter from "./Filter";
import { SearchBox } from "react-instantsearch-dom";
import { Configure } from "react-instantsearch-dom";

function Result({ ...props }) {
  const [nump, setNump] = useState(5);

  const takenum = (e) => {
    e.preventDefault();
    let pn = e.target.value;
    if (pn > 0) {
      setNump(pn);
    } else {
      setNump(1);
    }
  };

  return (
    <div className="container-fluid">
      <div className="">
        <div className="searchbar">
          <div className="searchbar-box my-5">
            <SearchBox
              translations={{ placeholder: "Search Raw Materials" }}
              showLoadingIndicator={false}
              className=""
              onChange={(e) =>
                localStorage.setItem("SearchQuery", e.target.value)
              }
              defaultRefinement={localStorage.getItem("SearchQuery")}
            />
          </div>
        </div>
        <Configure hitsPerPage={nump} />
        <Filter />
        <div className="row">
          <div className="pagenumberinputfield col-sm">
            <label>Display items per page</label>
            <input
              type="number"
              onChange={(e) => takenum(e)}
              defaultValue={nump}
            />
          </div>
          <div id="paginationn" className="col-sm">
            <div className="float-sm-end">
              <CustomPagination defaultRefinement={1} />
            </div>
          </div>
        </div>

        <div className="best-title">
          {/* Result */}
          Ergebnis
        </div>
        <div>
          <CustomHits />
        </div>
      </div>
    </div>
  );
}

export default Result;
