const marketplaceENDE = {
  section1en: "Trending Products",
  section1de: "Trendiges Produkt",
  section2en: "Best Sold Products",
  section2de: "Beliebtestes Produkt",
  section3en: "Top Rated",
  section3de: "Top bewertet",
  section4en: "Latest Uploaded Products",
  section4de: "Zuletzt hochgeladenes Produkt",
};

Object.freeze(marketplaceENDE);

export default marketplaceENDE;
