import React from 'react';
import "./ShippingMethod.css";
import Coupon from '../PriceDetails/Coupon';
import PriceDetails from '../PriceDetails/PriceDetails';
import ShippingOptions from './ShippingOptions';

const ShippingMethod = () => {
  return (
    <>
      <div className="container-fluid shipping-method-container ">
        <div className="row justify-content-around">
          <div className="col-md-7">
            <div className="personal-info">
              <p className="txt-style">Personal Information</p>
              ##billing details here
            </div>
            <div className="billing-details">
              <p className="txt-style">Billing details</p>
              ##billing details here
            </div>
            <div className="shipping-details">
              <p className="txt-style">Shipping Details</p>
              ##shipping details here
            </div>
            <div className="btn-continue mb-5">
              <button className="txt-style">Continue</button>
            </div>
          </div>
          <div className="col-md-4">
            <div className="shipping-method-details mb-2">
              <Coupon />
              <div className="border p-3">
              <PriceDetails />
              </div>
            </div>
            <div>
                <ShippingOptions />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ShippingMethod;