import React, { useState } from "react";
import axios from "axios";
import { images } from "../../Constants";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "./OrderTrackingPage.css";
import { Link } from "react-router-dom";
import OrderTrackingStatus from "./OrderTrackingStatus";
import SubHeader from "./SubHeader";
import { useSelector } from "react-redux";

import { useLocale, useTranslation } from "@psyycker/react-translation";

const OrderTrackingPage = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [orderId, setOrderId] = useState("");
  console.log("tracking code", orderId);
  const [errorPopUp, setErrorPopUp] = useState("");
  // const [confirmationBox, setConfirmationBox] = useState("")
  const [orderStatus, setOrderStatus] = useState("");
  console.log("order status", orderStatus);
  const [showCancelRefund, setShowCancelRefund] = useState(false);
  const order = {
    status: orderStatus,
  };
  let Token = useSelector((state) => state.userAuth.token);
  let newtoken = JSON.parse(Token);

  const handleTrackOrder = async () => {
    // Simulating an API call to fetch order status based on orderId
    // Replace this with your actual API call

    try {
      if (orderId === "") {
        setErrorPopUp("Bestellnummer erforderlich");
      } else {
        setErrorPopUp("");
        const response = await axios.get(
          `https://api.rawstuff.deals/api/v1/orders/getOrderByOrderNumber/${orderId}`,
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${newtoken.token}`,
            },
          }
        );
        const data = response.data;
        localStorage.setItem("orderId", data.data.id);
        setOrderStatus(data.data.status);
        setShowCancelRefund(data.data.status === "pending");

        // .then((response) => response.json())
        // .then((data) => {
        //   setOrderStatus(data.data.status);
        //   console.log(data);
        //   setShowCancelRefund(data.status === "Processing");
        // })
        // .catch((error) => console.log(error));
        return response;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlerAllTrackOrder = () => {
    handleTrackOrder();
    handleShow();
  };
  // const handleConfirmation = () => {
  //   setConfirmationBox("Are you sure you want to cancel your order?");
  // };

  const order_Id = localStorage.getItem("orderId");

  const handleCancelOrder = async () => {
    // Simulating an API call to cancel the order based on orderId
    // Replace this with your actual API call
    const confirmation = window.confirm(
      "Sind Sie sicher, dass Sie Ihre Bestellung stornieren möchten?"
    );
    if (confirmation) {
      fetch(
        `https://api.rawstuff.deals/api/v1/orders/cancelOrder/${order_Id}`,

        {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${newtoken.token}`,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setOrderStatus(data.data);
          setShowCancelRefund(false);
          console.log("Order canceled successfully!");
          localStorage.removeItem("orderId");
        })
        .catch((error) => console.log(error));
    }
  };

  // * fetch the endpoints for refund
  //   const handleRequestRefund = () => {
  //     // Simulating an API call to request a refund for the order based on orderId
  //     // Replace this with your actual API call
  //     fetch(`/api/orders/${orderId}/refund`, { method: "POST" })
  //       .then((response) => response.json())
  //       .then((data) => {
  //         setOrderStatus(data.status);
  //         setShowCancelRefund(false);
  //         console.log("Refund requested successfully!");
  //       })
  //       .catch((error) => console.log(error));
  //   };

  //Get Language Translation starts
  const { getTranslation } = useTranslation();
  const [locale, setLocale] = useLocale();
  const onButtonClick = () => {
    var lang = document.getElementById("lang").value;
    var selectedx = "You have selected";
    if (lang == "English") {
      setLocale("en-US");
    }

    if (lang == "Germany") {
      setLocale("german");
    }

    if (lang == "Dutch") {
      setLocale("de");
    }

    if (lang == "French") {
      setLocale("fr-FR");
    }
  };

  //Get Language Translation ends

  return (
    <div className="orderTracking-main-container my-5">
      {/* <SubHeader /> */}

      <div className=" container">
        <div className="row">
          <div class="col-sm-3 d-none" style={{ marginBottom: "2%" }}>
            <div class="list-group">
              <a
                style={{ background: "#000000" }}
                href="/dashboard"
                class="list-group-item list-group-item-action active"
              >
                <i class="fa fa-dashboard">&nbsp;&nbsp;</i>Dashboard
              </a>
              <a
                href="/purchased-items/total"
                class="list-group-item list-group-item-action"
              >
                <i class="fa fa-store">&nbsp;&nbsp;</i>Bestellte Artikel
                <span
                  style={{ background: "#000000" }}
                  class="badge badge-primary badge-pill"
                >
                  {localStorage.getItem("orderedItem")}
                </span>
              </a>
              <a
                href="/wishlist"
                class="list-group-item list-group-item-action"
              >
                <i class="fa fa-heart">&nbsp;&nbsp;</i>Mein Wunschzettel
              </a>
              <a
                href="/membership"
                class="list-group-item list-group-item-action"
              >
                <i class="fa fa-shield">&nbsp;&nbsp;</i>Mitgliedschaftsplan
              </a>
              <a
                href="/add-ticket"
                class="list-group-item list-group-item-action"
              >
                <i class="fa fa-message">&nbsp;&nbsp;</i>Anfrage zur
                Unterstützung
              </a>
              <a
                href="/EditProfile"
                class="list-group-item list-group-item-action"
              >
                <i class="fa fa-pencil">&nbsp;&nbsp;</i>Profil bearbeiten
              </a>
              <a href="/logout" class="list-group-item list-group-item-action">
                <i class="fa fa-home">&nbsp;&nbsp;</i>Ausloggen
              </a>
            </div>
          </div>
          <div className="col-md-8 ">
            <div className="border h-0">
              <div className="order-tracking-wrapper">
                <h1 className="pb-2 mb-5">
                  {/* {getTranslation({ translationKey: "OrderTracking" })} */}
                  Auftrag verfolgen
                </h1>
                <center>
                  Hier können Sie den Fortschrittsstatus Ihrer Produkte
                  verfolgen.
                  <br />
                  Geben Sie die laufende Nummer Ihrer Bestellung ein, um
                  fortzufahren.
                  <br />
                </center>
                <div className="order-tracking-input-wrapper  pt-5">
                  <p className="text-danger">{errorPopUp}</p>
                  {/* <label htmlFor="orderId">Order Tracking:</label> */}
                  <input
                    className="orderTracking-input mb-2 mb-sm-0"
                    type="text"
                    id="orderId"
                    placeholder="Bestellung verfolgen"
                    value={orderId}
                    onChange={(e) => setOrderId(e.target.value)}
                  />

                  <button
                    onClick={handlerAllTrackOrder}
                    className="btn-viewTracking  btn-primary text-white"
                    // onClick={handleShow}
                  >
                    {/* {getTranslation({ translationKey: "View" })}{" "}
                    {getTranslation({ translationKey: "Tracking" })} */}
                    {/* Anzeigeverfolgung */}
                    Bestellung verfolgen
                  </button>
                </div>
                {orderStatus && (
                  <Modal
                    show={show}
                    onHide={handleClose}
                    style={{
                      top: "22%",
                    }}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>
                        {/* {getTranslation({ translationKey: "OrderTracking" })} */}
                        Auftrag verfolgen
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <OrderTrackingStatus order={order} />
                    </Modal.Body>
                    <Modal.Footer>
                      {showCancelRefund && (
                        <>
                          {/* onClick={handleCancelOrder} */}
                          {/* onClick={handleRequestRefund} */}
                          {/* <Button variant="primary" onClick={handleCancelOrder}>
                            cancel order
                          </Button> */}
                          {/* <button>Request Refund</button> */}
                        </>
                      )}
                      {orderStatus === "canceled" ? (
                        <Button variant="primary" onClick={handleClose}>
                          Schließen Sie
                        </Button>
                      ) : (
                        <Button variant="primary" onClick={handleCancelOrder}>
                          Auftrag stornieren
                        </Button>
                      )}
                    </Modal.Footer>
                  </Modal>
                )}
                {orderStatus && (
                  <div>
                    {/* <h2>Order Status: {orderStatus}</h2> */}
                    {showCancelRefund && (
                      <div>
                        {/* onClick={handleCancelOrder} */}
                        {/* onClick={handleRequestRefund} */}
                        {/* <button>Cancel Order</button>
                        <button>Request Refund</button> */}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderTrackingPage;
