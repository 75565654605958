import React from "react";
import HomeHero from "../MainHero/HomeHero";

function Main() {
  return (
    <>
      {/* <Products /> */}
      {/*
      <section id="intro">
        <div className="intro-container">
          <div
            id="introCarousel"
            className="carousel slide carousel-fade pointer-event"
            data-ride="carousel"
          >
            <ol className="carousel-indicators">
              {" "}
              <li
                data-target="#introCarousel"
                data-slide-to="0"
                className="active"
              />
              <li data-target="#introCarousel" data-slide-to="1" className="" />
              <li data-target="#introCarousel" data-slide-to="2" className="" />
              <li data-target="#introCarousel" data-slide-to="3" className="" />
              <li data-target="#introCarousel" data-slide-to="4" className="" />
            </ol>

            <div className="carousel-inner" role="listbox">
              <div className="carousel-item active">
                <div className="carousel-background">
                  <img
                    src="https://api.rawstuff.deals/storage/app/public/photos/5YmVg5vlcsnap-error004.png1682340071"
                    alt=""
                  />
                </div>
                <div className="carousel-container">
                  <div className="carousel-content">
                    <h2 className="animate__animated animate__fadeInDown">
                      Welcome to Rawstuff
                    </h2>
                    <p className="animate__animated animate__fadeInUp">
                      Best B2B Market place. Put your investing ideas into
                      action with a full range of investments.Enjoy real
                      benefits and rewards on Rawstuff.
                    </p>
                    <a
                      href="login"
                      className="btn-get-started scrollto animate__animated animate__fadeInUp"
                    >
                      Get Started
                    </a>
                  </div>
                </div>
              </div>

              <div className="carousel-item">
                <div className="carousel-background">
                  <img
                    src="https://api.rawstuff.deals/storage/app/public/photos/GmLbUEvlcsnap-error004.png1682340170"
                    alt=""
                  />
                </div>
                <div className="carousel-container">
                  <div className="carousel-content">
                    <h2 className="animate__animated animate__fadeInDown">
                      Simple. Better. Sourcing.
                    </h2>
                    <p className="animate__animated animate__fadeInUp">
                      Put your investing ideas into action with a full range of
                      investments.Enjoy real benefits and rewards on Rawstuff
                    </p>
                    <a
                      href="login"
                      className="btn-get-started scrollto animate__animated animate__fadeInUp"
                    >
                      Get Started
                    </a>
                  </div>
                </div>
              </div>

              <div className="carousel-item">
                <div className="carousel-background">
                  <img
                    src="https://api.rawstuff.deals/storage/app/public/photos/LS7Hv9vlcsnap-error004.png1682340398"
                    alt=""
                  />
                  <div className="carousel-container">
                    <div className="carousel-content">
                      <h2 className="animate__animated animate__fadeInDown">
                        Invest in the future
                      </h2>
                      <p className="animate__animated animate__fadeInUp">
                        Put your investing ideas into action with a full range
                        of investments.Enjoy real benefits and rewards on Lorem
                        ipsum dolor sit ametLorem ipsum dolor sit amet
                      </p>
                      <a
                        href="login"
                        className="btn-get-started scrollto animate__animated animate__fadeInUp"
                      >
                        Get Started
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="carousel-item">
                <div className="carousel-background">
                  <img
                    src="https://api.rawstuff.deals/storage/app/public/photos/DgBE16bitcoinbg.jpg1637430018"
                    alt=""
                  />
                </div>
                <div className="carousel-container">
                  <div className="carousel-content">
                    <h2 className="animate__animated animate__fadeInDown">
                      Create your company profile on wlw
                    </h2>
                    <p className="animate__animated animate__fadeInUp">
                      Lorem ipsum dolor sit amet.Lorem ipsum dolor sit ametLorem
                      ipsum dolor sit ametLorem ipsum dolor sit amet
                    </p>
                    <a
                      href="login"
                      className="btn-get-started scrollto animate__animated animate__fadeInUp"
                    >
                      Get Started
                    </a>
                  </div>
                </div>
              </div>

              <div className="carousel-item">
                <div className="carousel-background">
                  <img
                    src="https://api.rawstuff.deals/storage/app/public/photos/ZrRMB8vlcsnap-error004.png1682340448"
                    alt=""
                  />
                </div>
                <div className="carousel-container">
                  <div className="carousel-content">
                    <h2 className="animate__animated animate__fadeInDown">
                      Best B2B on rawstuff
                    </h2>
                    <p className="animate__animated animate__fadeInUp">
                      Put your investing ideas into action with a full range of
                      investments.Enjoy real benefits and rewards on Rawstuff
                    </p>
                    <a
                      href="login"
                      className="btn-get-started scrollto animate__animated animate__fadeInUp"
                    >
                      Get Started
                    </a>
                  </div>
                </div>
              </div>
            </div>
           
          </div>
          <div
            className="content"
            style={{
              padding: "15px",
              backgroundColor: "blue",
              opacity: "0.8",
              background:
                "url(https://api.rawstuff.deals/temp/images/blogs/blog-article-2.jpg)",
            }}
          >
            <div className="container">
              <div className="row contact-info">
                <div className="col-md-2">
                  <div className="contact-address">
                    <font
                      style={{
                        color: "#ffffff",
                        fontWeight: "700",
                        fontSize: "40px",
                      }}
                    >
                      600.00
                    </font>
                    <br />
                    <font style={{ color: "#ffffff" }}>B2B Supplier</font>
                    <br />
                  </div>
                </div>

                <div className="col-md-2">
                  <div className="contact-address">
                    <font
                      style={{
                        color: "#ffffff",
                        fontWeight: "700",
                        fontSize: "40px",
                      }}
                    >
                      670.00
                    </font>
                    <br />
                    <font style={{ color: "#ffffff" }}>Industries</font>
                    <br />
                  </div>
                </div>

                <div className="col-md-2">
                  <div className="contact-address">
                    <font
                      style={{
                        color: "#ffffff",
                        fontWeight: "700",
                        fontSize: "40px",
                      }}
                    >
                      3500.00
                    </font>
                    <br />
                    <font style={{ color: "#ffffff" }}>Products</font>
                    <br />
                  </div>
                </div>

                <div className="col-md-2">
                  <div className="contact-address">
                    <font
                      style={{
                        color: "#ffffff",
                        fontWeight: "700",
                        fontSize: "40px",
                      }}
                    >
                      3500.00
                    </font>
                    <br />
                    <font style={{ color: "#ffffff" }}>Products</font>
                    <br />
                  </div>
                </div>

                <div className="col-md-2">
                  <div className="contact-address">
                    <font
                      style={{
                        color: "#ffffff",
                        fontWeight: "700",
                        fontSize: "40px",
                      }}
                    >
                      237.00
                    </font>
                    <br />
                    <font style={{ color: "#ffffff" }}>Buyers /month</font>
                    <br />
                  </div>
                </div>
              </div>
              <!------------ end of content bellow slider ----------------> 
            </div>
          </div>
        </div>
      // </section> */}
      {/* <About /> */}
      {/* <Service /> */}
      {/* <Market /> */}
      {/* <FQAs /> */}
      <HomeHero />
    </>
  );
}

export default Main;
