const membershipENDE = {
  monthlyen: "Monthly billing",
  monthlyde: "Monatiliche Abrechnung",
  yearlyen: "Annual billing",
  yearlyde: "Jaehrliche Abrechnung",

  seller: {
    monthly: {
      titleen: "Membership Plan for sellers - 79 EUR/month",
      titlede: "Membership Plan für Verkäufer – 79 EUR/Monat ",

      bodyen: [
        {
          "Access to a broad network of buyers":
            "Access to new customer groups and markets.",
        },
        {
          "Increased product visibility":
            "Increase product presence and brand awareness.",
        },
        {
          "Efficient sales processes":
            "Acceleration and simplification of the sales process.",
        },
        {
          "Targeted marketing":
            "Targeted approach of potential customers to increase sales opportunities.",
        },
        {
          "Cost reduction in sales and marketing":
            "savings through centralized platform resources.",
        },
      ],

      bodyde: [
        {
          "Zugang zu einem breiten Käufernetzwerk":
            "Erschließung neuer Kundengruppen und Märkte.",
        },
        {
          "Erhöhte Sichtbarkeit der Produkte":
            "Steigerung der Produktpräsenz und Markenbekanntheit.",
        },
        {
          "Effiziente Verkaufsprozesse":
            "Beschleunigung und Vereinfachung des Verkaufsvorgangs.",
        },
        {
          "Zielgerichtetes Marketing":
            "Gezielte Ansprache potenzieller Kunden zur Steigerung der Verkaufschancen.",
        },
        {
          "Kostenreduktion im Vertrieb und Marketing":
            "Einsparungen durch zentralisierte Plattform-Ressourcen.",
        },
      ],
    },
    yearly: {
      titleen: "Membership Plan for sellers - 948 EUR/year",
      titlede: "Membership Plan für Verkäufer – 948 EUR/Jaehrliche ",

      bodyen: [
        {
          "Access to a broad network of buyers":
            "Access to new customer groups and markets.",
        },
        {
          "Increased product visibility":
            "Increase product presence and brand awareness.",
        },
        {
          "Efficient sales processes":
            "Acceleration and simplification of the sales process.",
        },
        {
          "Targeted marketing":
            "Targeted approach of potential customers to increase sales opportunities.",
        },
        {
          "Cost reduction in sales and marketing":
            "savings through centralized platform resources.",
        },
      ],

      bodyde: [
        {
          "Zugang zu einem breiten Käufernetzwerk":
            "Erschließung neuer Kundengruppen und Märkte.",
        },
        {
          "Erhöhte Sichtbarkeit der Produkte":
            "Steigerung der Produktpräsenz und Markenbekanntheit.",
        },
        {
          "Effiziente Verkaufsprozesse":
            "Beschleunigung und Vereinfachung des Verkaufsvorgangs.",
        },
        {
          "Zielgerichtetes Marketing":
            "Gezielte Ansprache potenzieller Kunden zur Steigerung der Verkaufschancen.",
        },
        {
          "Kostenreduktion im Vertrieb und Marketing":
            "Einsparungen durch zentralisierte Plattform-Ressourcen.",
        },
      ],
    },
  },
  buyer: {
    monthly: {
      titleen: "Membership Plan for buyers - 29 EUR/month",
      titlede: "Membership Plan für Käufer – 29 EUR/Monat",

      bodyen: [
        {
          "Qualified sellers with verified raw materials":
            "high product standards and trustworthiness through seller verification",
        },
        {
          "Modern search and filter options":
            "Enable targeted and fast product discovery",
        },
        {
          "Possibility to compare vendors":
            "Better vendor overview and filter functions",
        },
        {
          "Secure and transparent transactions":
            "Building trust through clear and protected trading processes",
        },
        {
          "Efficient procurement processes":
            "Time savings thanks to quick and easy search and comparison options",
        },
      ],
      bodyde: [
        {
          "Qualifizierte Verkäufer mit geprüften Rohstoffen":
            "durch einen Verifizierung der Verkäufer hohe Produktstandards und Vertrauenswürdigkeit",
        },
        {
          "Fortschrittliche Such- und Filteroptionen":
            "Ermöglichen eine zielgerichtete und schnelle Produktfindung",
        },
        {
          "Möglichkeit zum Anbietervergleich":
            "Bessere Anbieterübersicht und Filterfunktionen.",
        },
        {
          "Sichere und transparente Transaktionen":
            "Vertrauensbildung durch klare und geschützte Handelsprozesse",
        },
        {
          "Effiziente Beschaffungsprozesse":
            "Zeitersparnis durch schnelle und einfache Suche und Vergleichsmöglichkeiten",
        },
      ],
    },
    yearly: {
      titleen: "Membership Plan for buyers - 348 EUR/year",
      titlede: "Membership Plan für Käufer – 348 EUR/Jaehrliche",

      bodyen: [
        {
          "Qualified sellers with verified raw materials":
            "high product standards and trustworthiness through seller verification",
        },
        {
          "Modern search and filter options":
            "Enable targeted and fast product discovery",
        },
        {
          "Possibility to compare vendors":
            "Better vendor overview and filter functions",
        },
        {
          "Secure and transparent transactions":
            "Building trust through clear and protected trading processes",
        },
        {
          "Efficient procurement processes":
            "Time savings thanks to quick and easy search and comparison options",
        },
      ],
      bodyde: [
        {
          "Qualifizierte Verkäufer mit geprüften Rohstoffen":
            "durch einen Verifizierung der Verkäufer hohe Produktstandards und Vertrauenswürdigkeit",
        },
        {
          "Fortschrittliche Such- und Filteroptionen":
            "Ermöglichen eine zielgerichtete und schnelle Produktfindung",
        },
        {
          "Möglichkeit zum Anbietervergleich":
            "Bessere Anbieterübersicht und Filterfunktionen.",
        },
        {
          "Sichere und transparente Transaktionen":
            "Vertrauensbildung durch klare und geschützte Handelsprozesse",
        },
        {
          "Effiziente Beschaffungsprozesse":
            "Zeitersparnis durch schnelle und einfache Suche und Vergleichsmöglichkeiten",
        },
      ],
    },
  },
};

Object.freeze(membershipENDE);

export default membershipENDE;
