const productcardENDE = {
  desten: "Product origin",
  destde: "Herkunftsland",
  minen: "MinOQ",
  minde: "Min Menge",
  maxen: "MaxOQ",
  maxde: "Max Menge",
  priceen: "Price per",
  pricede: "Preis pro",
  addtocarten: "Add to cart",
  addtocartde: "Warenkorb",
  buynowen: "Buy Now",
  buynowde: "Jetzt kaufen",
  certificationsen: "Certifications",
  certificationsde: "Zertifizierungen",
  preven: "Previous",
  prevde: "Vorherige",
  nexten: "Next",
  nextde: "Nächste",
  casnumberen: "Can Number",
  casnumberde: "CAS Nummer",
  sellersen: "Sellers",
  sellersde: "Verkäufer",
  viewproducten: "View Product",
  viewproductde: "Produkte anzeigen",
};

Object.freeze(productcardENDE);

export default productcardENDE;
