import React from "react";

export const ArrowRight7 = ({ style }) => {
  return (
    <svg
      className="arrow-right-7"
      style={style}
      fill="none"
      height="12"
      viewBox="0 0 12 12"
      width="12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path-3"
        d="M4.4548 10.335C4.3598 10.335 4.2648 10.3 4.1898 10.225C4.0448 10.08 4.0448 9.84001 4.1898 9.69501L7.4498 6.43501C7.6898 6.19501 7.6898 5.80501 7.4498 5.56501L4.1898 2.30501C4.0448 2.16001 4.0448 1.92001 4.1898 1.77501C4.3348 1.63001 4.5748 1.63001 4.7198 1.77501L7.9798 5.03501C8.2348 5.29001 8.3798 5.63501 8.3798 6.00001C8.3798 6.36501 8.2398 6.71001 7.9798 6.96501L4.7198 10.225C4.6448 10.295 4.5498 10.335 4.4548 10.335Z"
        fill="#758195"
        stroke="#808B9C"
      />
      <g className="g" opacity="0" />
    </svg>
  );
};
