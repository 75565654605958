import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { addToCarts } from "../../redux/Slice/ShoppingCartSlice";
import productcardENDE from "../../Constants/Contents/ProductcardENDE";

function BuyNOwButton({ product }) {
  const getItems = useSelector((state) => state.getCart);

  const [cartItems, setCartItems] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isProductExist = (id) => {
    return cartItems?.cart_items?.some((item) => item.product_id === id);
  };

  //click for details function
  const buynow = () => {
    if (isProductExist(product.id)) {
      navigate("/shoppingcart");
    } else {
      dispatch(addToCarts(product.id));
      navigate("/shoppingcart");
    }
  };

  useEffect(() => {
    setCartItems(getItems.cartItemsget);
  }, [getItems]);

  return (
    <div>
      <div
        className="buynow_button hovered px-sm-4 px-1"
        onClick={() => buynow()}
      >
        {productcardENDE.buynowde}
      </div>
    </div>
  );
}

export default BuyNOwButton;
