const wl = {
  wishen: "Wishlist",
  wishde: "Merkzettel",
  saveditemen: "Saved Items",
  saveditemde: "Gespeicherte Artikel",
  mycollen: "My Collections",
  mycolde: "Meine Merkzettel",
  newcolen: "New Collection",
  newcolde: "Neuer Merkzettel",
  svaetoen: "Save to",
  svaetode: "Speichern bei",
  doneen: "Done",
  donede: "Fertig",
  createen: "Create",
  createde: "Erstellen",
  namecolen: "Name Collection",
  namecolde: "Name Kollektion",
};

Object.freeze(wl);

export default wl;
