import React from "react";
import { connectPagination } from "react-instantsearch-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const Pagination = ({ currentRefinement, nbPages, refine, createURL }) => {
  const handlePrevious = () => {
    const previousPage = currentRefinement - 1;
    if (previousPage >= 1) {
      refine(previousPage);
    }
  };

  const handleNext = () => {
    const nextPage = currentRefinement + 1;
    if (nextPage <= nbPages) {
      refine(nextPage);
    }
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxDisplayedPages = 3;

    if (nbPages <= maxDisplayedPages) {
      for (let i = 1; i <= nbPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const firstPage = Math.max(
        1,
        currentRefinement - Math.floor(maxDisplayedPages / 2)
      );
      let lastPage = firstPage + maxDisplayedPages - 1;

      if (lastPage > nbPages) {
        lastPage = nbPages;
        pageNumbers.push(1);
        pageNumbers.push("...");
      }

      for (let i = firstPage; i <= lastPage; i++) {
        pageNumbers.push(i);
      }

      if (lastPage < nbPages) {
        pageNumbers.push("...");
        pageNumbers.push(nbPages);
      }
    }

    return pageNumbers;
  };

  return (
    <div className="d-flex">
      <button
        className="prev-btn px-0 px-sm-3"
        onClick={handlePrevious}
        disabled={currentRefinement === 1}
        style={{ fontWeight: "bold" }}
      >
        <ArrowBackIcon /> Vorherige
      </button>
      {getPageNumbers().map((page, index) => {
        const isCurrentPage = currentRefinement === page;
        const style = {
          fontWeight: isCurrentPage ? "bold" : "",
          cursor: isCurrentPage ? "active" : "pointer",
        };

        return (
          <div key={index}>
            {typeof page === "number" ? (
              <a
                className={`page-linkk ${isCurrentPage ? "activated" : ""}`}
                href={createURL(page)}
                style={style}
                onClick={(event) => {
                  event.preventDefault();
                  refine(page);
                }}
              >
                {page}
              </a>
            ) : (
              <span className="conti" style={style}>
                {page}
              </span>
            )}
          </div>
        );
      })}
      <button
        className="next-btn px-0 px-sm-3"
        onClick={handleNext}
        disabled={currentRefinement === nbPages}
        style={{ fontWeight: "bold" }}
      >
        Nächste <ArrowForwardIcon />
      </button>
    </div>
  );
};

export const CustomPagination = connectPagination(Pagination);
