const details = {
  orderdetailsen: "Order Details",
  orderdetailsde: "Bestelldetails",
  useren: "User",
  userde: "Käufer",
  emailen: "Email",
  emailde: "Email",
  statusen: "Status",
  statusde: "Status",
  dateen: "Date",
  datede: "Datum",
  ordernumen: "Order Number",
  ordernumde: "Bestellnummer",
  totalquantityen: "Total Quantity",
  totalquantityde: "Gesamtmenge",
  totaltaxen: "Total Tax",
  totaltaxde: "Mehrwertsteuer",
  totaldiscounten: "Total discount",
  totaldiscountde: "Rabatt",
  totalpricenettoen: "Total Price (netto)",
  totalpricenettode: "Zwischensumme",
  totalpricebrutoen: "Total Price (brutto)",
  totalpricebrutode: "Umsatzsteuerpflichtige Gesamtsumme",
  recreateorderen: "Recreate Order",
  recreateorderde: "Bestellung neu erstellen",
  itemsen: "Items",
  itemsde: "Artikel",
  pruducten: "Product",
  pruductde: "Produkt",
  quantityen: "Quantity",
  quantityde: "Menge",
  uniten : "Unit",
  unitde : "Einheit",
  unitpriceen: "Unit Price",
  unitpricede: "Preis pro Einheit",
  discounten: "Discount",
  discountde: "Rabatt",
  taxen: "Tax",
  taxde: "Mehrwertsteuer (%)",
  taxpriceen: "Tax Price",
  taxpricede: "Mehrwertsteuer (Euro)",
  shippingaddressen: "Shipping Address",
  shippingaddressde: "Lieferadresse",
};

Object.freeze(details);

export default details;
