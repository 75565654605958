import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import "./PriceDetails.css"

const PriceDetails = () => {
     let getItems = useSelector((state) => state.getCart);
     console.log("getCarts", getItems);
  return (
    <>
      <div>
        <div className="priceDetails text-center mb-3">Price Details</div>
        <div className="d-flex justify-content-between p-1">
          <div className="priceDetailsKey">TotalPrice:</div>
          <div className="priceDetailsValue">
            €{getItems.cartItemsget.total_price?.toFixed(2)}
          </div>
        </div>
        <div className="d-flex justify-content-between p-1">
          <div className="priceDetailsKey">Discount:</div>
          <div id="discountColor" className="priceDetailsValue ">
            -€60.00
          </div>
        </div>
        <div className="d-flex justify-content-between  border-bottom pl-1 pr-1 pb-2">
          <div className="priceDetailsKey">Tax:</div>
          <div id="TotalTaxColor" className="priceDetailsValue ">
           + €{getItems.cartItemsget.total_tax?.toFixed(2)}
          </div>
        </div>
        <div className="d-flex justify-content-between p-2">
          <div className="priceDetailsKey">Total:</div>
          <div id="totalColor" className="priceDetailsValue">
            €{getItems.cartItemsget.total_taxed_price?.toFixed(2)}
          </div>
        </div>
      </div>
    </>
  );
}

export default PriceDetails