import React, { useContext } from "react";
import Carousel from "react-bootstrap/Carousel";
import { BsGiftFill, BsHeart } from "react-icons/bs";
import { BiChevronDown, BiChevronRight } from "react-icons/bi";
import { ShoppingCart } from "@mui/icons-material";
import { StateContext } from "../Stateprovider";
import Stars from "../Stars";
import "./resultPage.css";

export default function ResultPage() {
  const { result } = useContext(StateContext);
  return (
    <>
      <div className="row RawstuffResult flex-md flex-lg">
        <div className="algoliasearch">
          <div className="catagory">
            <ul className="catagory_list">
              <li>
                <button>
                  <a href="#">
                    Hardware <BiChevronRight />
                  </a>
                </button>
              </li>
              <li>
                <button>
                  <a href="#">
                    sound headphones <BiChevronRight />
                  </a>
                </button>
              </li>
              <li>
                <button>
                  <a href="#">
                    Headphones <BiChevronRight />
                  </a>
                </button>
              </li>
              <li>
                <button>
                  <a href="#">
                    Headphones <BiChevronRight />
                  </a>
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className="ImageCarousels col-sm-12 col-md-5 text-center ">
          <Carousel style={{ width: "320px", height: "320px" }}>
            <Carousel.Item>
              <img
                style={{
                  width: "320px",
                  height: "320px",
                }}
                src="https://api.rawstuff.deals/productImg/4.jpg"
                alt={result.name}
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                style={{ width: "320px", height: "320px" }}
                src="https://api.rawstuff.deals/productImg/3.jpg"
                alt={result.name}
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                style={{ width: "320px", height: "320px" }}
                src="https://api.rawstuff.deals/productImg/6.jpg"
                alt={result.name}
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                style={{ width: "320px", height: "320px" }}
                src="https://api.rawstuff.deals/productImg/8.jpg"
                alt={result.name}
              />
            </Carousel.Item>
          </Carousel>
          <div class="ThumbnailFrame">
            <div class="ThumbnailContainer">
              <ul class="Thumbnails" data-ref-carousel="ImageCarousel">
                <Carousel>
                  <li class="active">
                    <a href="#" title="Apple AirPods Pro 2021" target="_blank">
                      <img
                        src="https://api.rawstuff.deals/productImg/4.jpg"
                        alt="Apple AirPods Pro 2021"
                        width="32"
                        height="32"
                        title="Apple AirPods Pro 2021"
                      />
                    </a>
                  </li>
                  <li class="">
                    <a href="#" title="Apple AirPods Pro 2021" target="_blank">
                      <img
                        src="https://api.rawstuff.deals/productImg/3.jpg"
                        alt="Apple AirPods Pro 2021"
                        width="32"
                        height="32"
                        title="Apple AirPods Pro 2021"
                      />
                    </a>
                  </li>
                  <li class="">
                    <a href="#" title="Apple AirPods Pro 2021" target="_blank">
                      <img
                        src="https://api.rawstuff.deals/productImg/6.jpg"
                        alt="Apple AirPods Pro 2021"
                        width="32"
                        height="32"
                        title="Apple AirPods Pro 2021"
                      />
                    </a>
                  </li>
                  <li class="">
                    <a href="#" title="Apple AirPods Pro 2021" target="_blank">
                      <img
                        src="https://api.rawstuff.deals/productImg/8.jpg"
                        alt="Apple AirPods Pro 2021"
                        width="32"
                        height="32"
                        title="Apple AirPods Pro 2021"
                      />
                    </a>
                  </li>
                </Carousel>
              </ul>
            </div>
          </div>

          <div>
            <strong>
              <font>
                <font>Article number </font>
              </font>
            </strong>
            <span>
              <font>
                <font>9037070</font>
              </font>
            </span>
            <span></span>
            <br />
            <br />
            <strong>
              <font>
                <font>EAN </font>
              </font>
            </strong>
            <span>
              <font>
                <font>0194252721384 </font>
              </font>
            </span>
            &nbsp;&nbsp;
            <strong>
              <font>
                <font>SKU </font>
              </font>
            </strong>
            <span>
              <font>
                <font>MLWK3ZM/A</font>
              </font>
            </span>
          </div>
        </div>

        <div className="rawtitle raw-list col-sm-6 col-md-4 hidden-xs">
          <div>
            <h1 className="name">{result.name}</h1>
            <h3>{result.main_category}</h3>
            <a className="Lagernd" href="#">
              Lagernd | &gt; 5 St.
              <br />
              <span class="unbold">(Max. Bestellmenge: 2)</span>
            </a>
            <Stars />
            <img
              src="https://cdn.macovi.de/templates/mindfactory_new/images/schutzklick.jpg?rev=20210408"
              alt="Der Schutz für Ihre Geräte - Umfangreich und überall geschützt mit dem Geräteschutz."
              title="Der Schutz für Ihre Geräte - Umfangreich und überall geschützt mit dem Geräteschutz."
              class="img-responsive"
            ></img>
          </div>
        </div>

        <div className="price-page col-sm-6  col-md-3 color-col-grey">
          <div className="prices">
            <span className="only price">
              <font style={{ verticalAlign: "inherit" }}>
                <font style={{ verticalAlign: "inherit" }}>only </font>
              </font>
            </span>
            <span className="specialPriceText">
              <span className="text-currency">
                <font style={{ verticalAlign: "inherit" }}>
                  <font style={{ verticalAlign: "inherit", fontsize: "20px" }}>
                    €
                  </font>
                </font>
              </span>
              <font style={{ verticalAlign: "inherit" }}>
                <font
                  className="unitprice"
                  style={{ verticalAlign: "inherit" }}
                >
                  &nbsp;106*
                </font>
              </font>
            </span>
            <div className="pfin">
              <a
                href="#"
                title="Finance yourself Apple AirPods Pro 2021"
                className="new_win"
              >
                <span className="or price">
                  <font style={{ verticalAlign: "inherit" }}>
                    <font style={{ verticalAlign: "inherit" }}>or from </font>
                  </font>
                </span>{" "}
                <span className="big_finance_text">
                  <strong>
                    <font style={{ verticalAlign: "inherit" }}>
                      <span style={{ verticalAlign: "inherit", color: "blue" }}>
                        €12.09*
                      </span>
                    </font>
                  </strong>
                  <font style={{ verticalAlign: "inherit" }}>
                    <font style={{ verticalAlign: "inherit" }}>
                      {" "}
                      per month{" "}
                    </font>
                  </font>
                </span>
                <br />
                <span className="price months">
                  <font style={{ verticalAlign: "inherit" }}>
                    <font style={{ verticalAlign: "inherit" }}>
                      18 months/7.90% eff.{" "}
                    </font>
                    <font style={{ verticalAlign: "inherit" }}>Jz.</font>
                  </font>
                </span>
              </a>
              <font style={{ verticalAlign: "inherit" }}>
                <font style={{ verticalAlign: "inherit" }}> ***</font>
              </font>
            </div>
          </div>
          <div className="price" style={{ position: "relative", zIndex: 1 }}>
            <font style={{ verticalAlign: "inherit" }}>
              <font style={{ verticalAlign: "inherit", color: "black" }}>
                * incl. 19% VAT plus
              </font>
            </font>
            <a
              href="https://www.mindfactory.de/info_center.php/icID/11/popup/true"
              className="line"
              title="Information about our shipping costs"
              target="_blank"
            >
              <font style={{ verticalAlign: "inherit" }}>
                <font style={{ verticalAlign: "inherit" }}>shipping costs</font>
              </font>
            </a>
            <font style={{ verticalAlign: "inherit" }}>
              <font style={{ verticalAlign: "inherit" }}> / </font>
            </font>
            <a
              href="https://www.mindfactory.de/info_center.php/icID/92/popup/true"
              className="line"
              title="Delivery Restrictions"
              target="_blank"
            >
              <font style={{ verticalAlign: "inherit" }}>
                <font style={{ verticalAlign: "inherit" }}>
                  delivery restrictions
                </font>
              </font>
            </a>
          </div>

          <div className="raw">
            <ul>
              <li className="rawstaf">
                <input type="checkbox" name="attributes[4]" value="2" />{" "}
                <span className="glyphicon glyphicons-basic-70-gift mat4"></span>{" "}
                <span className="text">
                  <font style={{ verticalAlign: "inherit" }}>
                    <font style={{ verticalAlign: "inherit" }}>+ </font>
                  </font>
                  <a href="#" title="Wrap as a present">
                    <BsGiftFill />
                    <font style={{ verticalAlign: "inherit" }}>
                      <font style={{ verticalAlign: "inherit" }}>
                        {" "}
                        € 3.90*{" "}
                      </font>
                    </font>
                    <span className="attr-title-text">
                      <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>
                          gift packaging
                        </font>
                      </font>
                    </span>
                  </a>
                </span>
              </li>
            </ul>
          </div>

          <div id="detail_list pspecial">
            <div className="">
              <div class=" cart">
                <div class=" col-xs-12">
                  <button
                    class="btn btn-primary btn-block"
                    type="submit"
                    id="btn-buy-productInfo"
                  >
                    <div class="cart-box">
                      <div class="cart-box-icon col-xs-2 text-center  ">
                        <ShoppingCart />
                        <input
                          type="tel"
                          value="1"
                          size="4"
                          class="form-control"
                        />
                      </div>
                      <div class="cart-boxs col-xs-7 mat5 text-left no-pal">
                        <span class="hidden-xs hidden-sm hidden-lg">
                          &nbsp;
                        </span>
                        <font>
                          <font>Add to Cart</font>
                        </font>
                      </div>
                    </div>
                  </button>
                </div>
              </div>
              <div class="col-xs-12">
                <a href="#" class="btn">
                  <span>
                    <BsHeart />
                  </span>
                  Auf den Wunschzettel
                </a>
              </div>
              <div class="row mat5 hidden-print">
                <div class="col-xs-12">
                  <a href="#" role="button" class="btn btn-default btn-block">
                    <font style={{ verticalAlign: "inherit" }}>
                      <font style={{ verticalAlign: "inherit" }}>
                        Add to Wishlist
                      </font>
                    </font>
                  </a>
                </div>
              </div>
            </div>

            <ul class="arrowMenu">
              <li class="no-br">
                <a href="#" target="_blank">
                  <font style={{ verticalAlign: "inherit" }}>
                    <font style={{ verticalAlign: "inherit" }}>
                      ParcelShop delivery possible
                    </font>
                  </font>
                </a>
              </li>
              <li class="no-br">
                <a href="#" target="_blank">
                  <font style={{ verticalAlign: "inherit" }}>
                    <font style={{ verticalAlign: "inherit" }}>
                      save shipping costs
                    </font>
                  </font>
                </a>
              </li>
              <li class="no-br">
                <a href="#" title="Feedback on the article" target="_blank">
                  <font style={{ verticalAlign: "inherit" }}>
                    <font style={{ verticalAlign: "inherit" }}>
                      Feedback on the article
                    </font>
                  </font>
                </a>
              </li>
              <li class="no-br hidden-xs hidden-sm">
                <a href="#">
                  <span class="glyphicon glyphicons-basic-16-print"></span>
                  <font style={{ verticalAlign: "inherit" }}>
                    <font style={{ verticalAlign: "inherit" }}>
                      Print item information
                    </font>
                  </font>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="Item-Information">
        <div className="infoBtns">
          <button className="InfoBtn">
            <h4>Item Information</h4>
            <h3>
              <BiChevronDown />
            </h3>
          </button>
          <button className="InfoBtn">
            <h4>Item Rating</h4>
            <h3>
              <BiChevronDown />
            </h3>
          </button>
          <button className="InfoBtn">
            <h4>About {result.name}</h4>
            <h3>
              <BiChevronDown />
            </h3>
          </button>
        </div>
        <div className="item-name">
          <button>{result.name}</button>
        </div>
      </div>
      <div className="Itmesdescriptions">
        <div className="ItemTitle">
          {" "}
          <h1 style={{ padding: "3rem" }}>ITEM DESCRIPTION</h1>
        </div>
        <p className="Itmedescription">
          {result.description} Lorem ipsum dolor sit amet, doleriumLorem ipsum
          dolor sit amet, doleriumLorem ipsum dolor sit amet, doleriumLorem
          ipsum dolor sit amet, doleriumLorem ipsum dolor sit amet, dolerium
          Lorem ipsum dolor sit amet, doleriumLorem ipsum dolor sit amet,
          doleriumLorem ipsum dolor sit amet, doleriumLorem ipsum dolor sit
          amet, doleriumLorem ipsum dolor sit amet, dolerium
        </p>
        <p className="Itmedescription">
          {result.description} Lorem ipsum dolor sit amet, doleriumLorem ipsum
          dolor sit amet, doleriumLorem ipsum dolor sit amet, doleriumLorem
          ipsum dolor sit amet, doleriumLorem ipsum dolor sit amet, dolerium
          Lorem ipsum dolor sit amet, doleriumLorem ipsum dolor sit amet,
          doleriumLorem ipsum dolor sit amet, doleriumLorem ipsum dolor sit
          amet, doleriumLorem ipsum dolor sit amet, dolerium
        </p>
        <p className="Itmedescription">
          {result.description} Lorem ipsum dolor sit amet, doleriumLorem ipsum
          dolor sit amet, doleriumLorem ipsum dolor sit amet, doleriumLorem
          ipsum dolor sit amet, doleriumLorem ipsum dolor sit amet, dolerium
          Lorem ipsum dolor sit amet, doleriumLorem ipsum dolor sit amet,
          doleriumLorem ipsum dolor sit amet, doleriumLorem ipsum dolor sit
          amet, doleriumLorem ipsum dolor sit amet, dolerium
        </p>
        <p className="Itmedescription">
          {result.description} Lorem ipsum dolor sit amet, doleriumLorem ipsum
          dolor sit amet, doleriumLorem ipsum dolor sit amet, doleriumLorem
          ipsum dolor sit amet, doleriumLorem ipsum dolor sit amet, dolerium
          Lorem ipsum dolor sit amet, doleriumLorem ipsum dolor sit amet,
          doleriumLorem ipsum dolor sit amet, doleriumLorem ipsum dolor sit
          amet, doleriumLorem ipsum dolor sit amet, dolerium.
        </p>
      </div>

      <div className="specification">
        <table>
          <thead>
            <tr>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <ul className="tables">{result.name}</ul>
              <ul className="tables">{result.main_category}</ul>
              <ul className="tables">{result.description}</ul>
              <ul className="tables">{result.name}</ul>
              <ul className="tables">{result.main_category}</ul>
            </tr>
          </tbody>
          <thead>
            <tr>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <ul className="tables">{result.name}</ul>
              <ul className="tables">{result.main_category}</ul>
              <ul className="tables">{result.description}</ul>
              <ul className="tables">{result.name}</ul>
              <ul className="tables">{result.main_category}</ul>
              <ul className="tables">{result.description}</ul>
            </tr>
          </tbody>
          <thead>
            <tr>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <ul className="tables">{result.name}</ul>
              <ul className="tables">{result.main_category}</ul>
              <ul className="tables">{result.description}</ul>
              <ul className="tables">{result.name}</ul>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}
