import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Define an initial state
const initialState = {
  onBoard: null,
  status: "onboard",
  error: null,
};

// Define the asynchronous thunk for handling the checkout process
export const handleStripeOnBoard = createAsyncThunk(
  "OnBoarding/handleStripeOnBoard",
  async (_, { getState }) => {
    try {
      const state = getState();
      console.log("tokentest", state);
      const newtoken = state.userAuth.token;
      const authToken = JSON.parse(newtoken);

      // Assuming you have auth slice with newtoken
      console.log("token", newtoken);
      console.log("tokenparse", authToken.token);
      const apiUrl = "https://api.rawstuff.deals/api/v1/stripe/onBoard";
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${authToken.token}`);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
      };
      const response = await fetch(apiUrl, requestOptions);
      const data = await response.json();

      return data;
    } catch (error) {
      throw error;
    }
  }
);

// Create a Redux slice
const stripeOnBoardingSlice = createSlice({
  name: "OnBoarding",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(handleStripeOnBoard.pending, (state) => {
        state.status = "loading";
      })
      .addCase(handleStripeOnBoard.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.order = action.payload;
      })
      .addCase(handleStripeOnBoard.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

// Export the asynchronous thunk for use in components
// export { handleCheckout };
// Export the reducer for use in the Redux store

export default stripeOnBoardingSlice.reducer;
