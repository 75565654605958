import React from 'react'
import "./Proceedtocheckout.css";
import { Link } from 'react-router-dom'

const ProceedToPayment = () => {
  const paymentLink=localStorage.getItem("payment")
  let paymentLinkData = JSON.parse(paymentLink);
  console.log("paymentLinkData", paymentLinkData);


  return (
    <section className="proceedtocheckout-wrapper py-2">
      <iframe
        title="checkoutUrl-Payment"
        src={paymentLinkData.paylink}
        width="100%"
        height="700"
        frameBorder="0"
        // scrolling="no"
      />
    </section>
  );
}

export default ProceedToPayment;