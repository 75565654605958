const SupportCard = ({ icon, title, descriprition }) => {
  return (
    <div className="m-0 p-0 w-100">
      <div className="support-card hoveroverlay">
        <div className="pic">
          <div className="">{icon}</div>
        </div>

        <div className="w-100 header-desc text-center">
          <div className="headerr1">{title}</div>
          <p className="detailp px-3">{descriprition}</p>
        </div>
      </div>
    </div>
  );
};

export default SupportCard;