import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import RepartitionIcon from "@mui/icons-material/Repartition";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import MoneyIcon from "@mui/icons-material/Money";
import "./style.css";
import SupportCard from "./SupportCard";
import Footer from "./Footer";
import Services from "./Services";
import SupportModal from "./SupportModal";

const Support = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [data] = useState([
    {
      id: "1",
      icon: <PersonOutlinedIcon fontSize="large" />,
      title: "Dein Account",
      descriprition: "Accounteinstellungen",
    },
    {
      id: "2",
      icon: <RepartitionIcon fontSize="large" />,
      title: "Rückgabe und Rückerstattung",
      descriprition: "Statusüberprüfung",
    },
    {
      id: "3",
      icon: <MiscellaneousServicesIcon fontSize="large" />,
      title: (
        <div>
          Hilfe zum Produkt <br></br>erhalten
        </div>
      ),
      descriprition:
        "Behebung von Problemen bei der Produktkonfiguration und -nutzung",
    },
    {
      id: "4",
      icon: <MoneyIcon fontSize="large" />,
      title: "Verdienen Sie Geld mit uns!",
      descriprition: "Häufig gestellte Fragen (FAQ)",
    },
  ]);

  const navigate = useNavigate();

  const handleShow2 = () => {
    try {
      let token = localStorage.getItem("token");
      token = JSON.parse(token).token;
      handleShow();
    } catch (error) {
      navigate(`/login`);
    }
  };

  const handleClick = (title, id) => {
    try {
      let token = localStorage.getItem("token");
      token = JSON.parse(token).token;

      if (title === "Dein Account") {
        navigate(`/dashboard/profile`);
      }

      if (title === "Rückgabe und Rückerstattung") {
        navigate(`/agbs`);
      }

      if (id === "3") {
        handleShow();
      }

      if (title === "Verdienen Sie Geld mit uns!") {
        navigate(`/faq`);
      }
    } catch (error) {
      navigate(`/login`);
    }
  };

  return (
    <div className="top-300 ">
      <div className="container">
        <div className="text-center mb-5">
          <p className="support">Support</p>
          <div className="hellos">
            Willkommen beim Support Tool: Wie dürfen wir Ihnen helfen?
          </div>
        </div>
        <div className="do-something d-none">Some things you can do here</div>
        <div className="row col col-lg-11 px-0 mx-auto mx-sm-0">
          {data.map((item, index) => {
            return (
              <div
                className="col-md-5 col-lg col-sm-12 p-0 mr-5 mb-2 mb-lg-0 mr-sm-4 mb-sm-4"
                key={index}
                onClick={() => handleClick(item.title, item.id)}
              >
                <SupportCard
                  icon={item.icon}
                  title={item.title}
                  descriprition={item.descriprition}
                />
              </div>
            );
          })}
        </div>
      </div>

      <Services />
      <div className="my-0  text-center d-none">
        <a href="/faq">FAQ's</a>
      </div>
      <Footer handleShow={handleShow2} />
      <SupportModal show={show} handleClose={handleClose} />
    </div>
  );
};

export default Support;
