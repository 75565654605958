import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';
// Define an initial state
const initialState = {
  wishlist: [],
  status: 'idle',
  error: null,
};

// Create an asynchronous thunk using createAsyncThunk
export const addToWishList = createAsyncThunk('wishlist/addToWishList', async (product_id) => {
  try {
    let jsonString = localStorage.getItem('token');
    let token = JSON.parse(jsonString).token;

    const response = await axios.post('https://api.rawstuff.deals/api/v1/wish_list',
      { "product_id": product_id },
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    toast.success('Successfully  Added to wishlist!');
    return response.data;
  } catch (error) {
    // Handle errors
    console.log('errore')
    // throw Error('Failed to add to wishlis.');
  }
});

// Create a slice of the Redux store
const wishlistSlice = createSlice({
  name: 'wishlist',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addToWishList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addToWishList.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.wishlist = action.payload;
      })
      .addCase(addToWishList.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});


// Export the reducer for use in the store
export default wishlistSlice.reducer;
