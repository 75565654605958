import { createSlice } from "@reduxjs/toolkit";

const orderdetailslice = createSlice({
  name: "order details",
  initialState: {
    orderID: localStorage.getItem("orderId")
      ? localStorage.getItem("orderId")
      : 7,
  },
  reducers: {
    orderDetailId: (state, action) => {
      state.orderID = action.payload;
    },
  },
});

export const { orderDetailId } = orderdetailslice.actions;

export default orderdetailslice.reducer;
