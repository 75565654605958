import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./PurchasedItemStyle.css";
import order from "../../Constants/Contents/ordereditem";

export const formatDate = (date) => {
  return new Date(date).toLocaleDateString();
};

const PurchasedItem = ({ purchasedItemsData, loading }) => {
  purchasedItemsData = purchasedItemsData.sort((a, b) => {
    if (a.created_at < b.created_at) {
      return 1;
    }
    if (a.created_at > b.created_at) {
      return -1;
    }
    return 0;
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [searchOrderNumber, setSearchOrderNumber] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    handleSearch();
  }, [searchOrderNumber]);

  const handleSearch = () => {
    const foundOrder = purchasedItemsData.filter((order) => {
      const matchOrderNumber = order.order_number.startsWith(searchOrderNumber);
      // const matchStatus = order.status.toLowerCase().includes(searchOrderNumber.toLowerCase());
      // return matchOrderNumber || matchStatus;
      return matchOrderNumber;
    });

    if (foundOrder.length === 0) {
      setSearchResults([]);
      return;
    }

    setSearchResults(foundOrder);
    setCurrentPage(1);
  };

  const itemsPerPage = 5;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const displayItems = searchOrderNumber
    ? searchResults.length > 0
      ? searchResults.slice(indexOfFirstItem, indexOfLastItem)
      : []
    : purchasedItemsData.slice(indexOfFirstItem, indexOfLastItem);

  const currentItems = searchOrderNumber
    ? searchResults.slice(indexOfFirstItem, indexOfLastItem)
    : purchasedItemsData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(
    (searchOrderNumber ? searchResults.length : purchasedItemsData.length) /
      itemsPerPage
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const goToFirstPage = () => setCurrentPage(1);
  const goToLastPage = () => setCurrentPage(totalPages);
  const goToNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };
  const goToPrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  return (
    <div
      className="dashboardd"
      style={{ display: "flex", flexDirection: "column", minHeight: "70vh" }}
    >
      <div className="row">
        {/* Main content */}
        <div className="col-sm">
          {/* Table header */}
          <div
            className="table-title"
            style={{ background: "#eeeeee", padding: "2%" }}
          >
            <div className="row">
              <div className="col-sm-4 d-flex p-0">
                <h5 className="float-left ml-0" style={{ color: "#000000" }}>
                  <i className="fa fa-store"> </i>&nbsp;{order.ordereditemsde}
                </h5>
              </div>
              <div className="col-sm-8">
                <div className="search-box">
                  <i className="fa fa-search"></i>
                  <input
                    type="text"
                    className="form-control"
                    // placeholder="Search Order Number&hellip;"
                    placeholder="Bestellnummer&hellip;"
                    value={searchOrderNumber}
                    onChange={(e) => setSearchOrderNumber(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Purchased Items Table */}
          <div className="table-responsive">
            {loading ? (
              <p>Loading...</p>
            ) : displayItems.length === 0 ? (
              <p>Keine Bestellung gefunden</p>
            ) : (
              <table
                className="table table-striped table-hover"
                style={{ textAlign: "left" }}
              >
                <thead>
                  <tr>
                    <th>{order.ordnumde}</th>
                    <th>{order.amntde}</th>
                    <th>{order.datede}</th>
                    <th className="w-10">{order.statde}</th>
                    <th className="" style={{ textAlign: "center" }}>
                      {order.actde}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {displayItems.map((item) => (
                    <tr key={item.id} style={{ cursor: "default" }}>
                      <td>{item.order_number}</td>
                      <td> €{item.total_price}</td>
                      <td>{formatDate(item.created_at)}</td>
                      <td>{item.status}</td>
                      <td
                        style={{ cursor: "pointer", textAlign: "center" }}
                        onClick={() =>
                          navigate(`/purchased-items-details/${item.id}`)
                        }
                      >
                        <a
                          className="btn btn-secondary btn-sm"
                          style={{ color: "#ffffff" }}
                        >
                          {/* View details */}
                          Details anzeigen
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}

            <div className="clearfix">
              <div className="hint-text">
                Zeigt <b>{currentItems.length}</b> von {" "}
                <b>
                  {searchOrderNumber
                    ? searchResults.length
                    : purchasedItemsData.length}
                </b>{" "}
                Einträgen
              </div>
              <ul className="pagination">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <a href="#" className="page-link" onClick={goToFirstPage}>
                    <i class="fa fa-angle-double-left"></i>
                  </a>
                </li>
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <a href="#" className="page-link" onClick={goToPrevPage}>
                    <i class="fa fa-angle-left"></i>
                  </a>
                </li>

                {Array.from({ length: totalPages }, (_, index) => (
                  <li
                    key={index}
                    className={`page-item ${
                      currentPage === index + 1 ? "active" : ""
                    }`}
                  >
                    <a
                      href="#"
                      className="page-link"
                      onClick={() => paginate(index + 1)}
                    >
                      {index + 1}
                    </a>
                  </li>
                ))}

                <li
                  className={`page-item ${
                    currentPage === totalPages ? "disabled" : ""
                  }`}
                >
                  <a href="#" className="page-link" onClick={goToNextPage}>
                    <i class="fa fa-angle-right"></i>
                  </a>
                </li>
                <li
                  className={`page-item ${
                    currentPage === totalPages ? "disabled" : ""
                  }`}
                >
                  <a href="#" className="page-link" onClick={goToLastPage}>
                    <i class="fa fa-angle-double-right"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-n3 pt-0">
        {/* <i>**Pending order will be cancelled after 15 minutes.**</i> */}
        <i>**Ausstehende Bestellungen werden nach 15 Minuten storniert**</i>
      </div>
    </div>
  );
};

export default PurchasedItem;
