/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import wl from "../../Constants/Contents/wishlist";

export const AddToWishList = ({ product_id }) => {
  const [show1, setShow1] = useState(false);
  const [modalPosition1, setModalPosition1] = useState({ top: 0, left: 0 });

  const [show3, setShow3] = useState(false);
  const [modalPosition3, setModalPosition3] = useState({ top: 0, left: 0 });

  let handleClick32 = (event, x) => {
    setShow3(true);
    setModalPosition3({ top: modalPosition1.top, left: modalPosition1.left });
  };

  const handleClick1 = (event) => {
    const { pageX, pageY } = event;
    setModalPosition1({ top: pageY, left: pageX });
    setShow1(true);
  };

  return (
    <div>
      <div onClick={(e) => handleClick1(e)}>
        <FavoriteBorderIcon />
      </div>

      <ModalSavetoWishList
        show={show1}
        setShow={setShow1}
        modalPosition={modalPosition1}
        handleClick3={handleClick32}
        product_id={product_id}
      />

      <ModalCreateSavetoWishList
        show={show3}
        setShow={setShow3}
        modalPosition={modalPosition3}
        product_id={product_id}
      />
    </div>
  );
};

export default AddToWishList;

export function ModalCreateSavetoWishList({ show, setShow, product_id }) {
  const [saveto, setSaveto] = useState("");
  const handleChange = (e) => {
    setSaveto(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (saveto === "") {
      toast.error("Erstellen des Merkzettel fehlgeschlagen.");
      return;
    }
    try {
      let token = localStorage.getItem("token");
      token = JSON.parse(token).token;

      await axios.post(
        "https://api.rawstuff.deals/api/v1/wish_list",
        {
          list_name: saveto,
          product_id: product_id,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // toast.success("Collection Created and product added successfully.");
      toast.success("Sammlung erstellt und Produkt erfolgreich hinzugefügt.");
    } catch (error) {
      toast.error("Erstellen des Merkzettel fehlgeschlagen.");
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="sm"
      style={
        {
          // position: "fixed",
          // top: "40%",
          // left: "40%",
          // backgroundColor: "red",
          // margin: "0",
          // width: "auto",
          // // height: "200px",
          // "border-radius": "8px",
          // border: "1px solid #999",
          // background: "#FAFAFA",
          // padding: "0 10px 0 10px",
        }
      }
    >
      <Modal.Header closeButton>
        <Modal.Title>{wl.namecolde}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form action="" onSubmit={handleSubmit}>
          <div className="">
            <input
              className="form-control"
              type="text"
              name="saveto"
              id="saveto2"
              placeholder="Eingabe Name"
              onChange={handleChange}
            />
          </div>

          <div className="mt-3">
            <button
              className="btnclrwishlist w-100"
              type="submit"
              onClick={() => setShow(false)}
            >
              <h5>{wl.createde}</h5>
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export function ModalSavetoWishList({
  show,
  setShow,
  modalPosition,
  handleClick3,
  product_id,
}) {
  let [lists, setLists] = useState([]);
  if (lists && lists.length > 0) {
    lists = lists.map((list) => list.list_name);
  }

  let token = localStorage.getItem("token");
  token = JSON.parse(token).token;
  const fetchLists = async () => {
    try {
      const url = "https://api.rawstuff.deals/api/v1/wishlist_collection";
      const response = await axios.get(url, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setLists(response.data.collections);
    } catch (error) {
      console.error("Error fetching:", error);
    }
  };

  useEffect(() => {
    fetchLists();
  }, []);

  const [selectedList, setSelectedList] = useState("");

  const handleSelect = (list) => {
    setSelectedList(list);
  };

  const sendToList = async () => {
    try {
      const url = "https://api.rawstuff.deals/api/v1/wish_list";
      await axios.post(
        url,
        {
          product_id: product_id,
          list_name: selectedList,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // toast.success(`Successfully added to ${selectedList}.`);
      toast.success(`Erfolgreich zu ${selectedList} hinzugefügt.`);
    } catch (error) {
      if (error.response.status === 409) {
        // toast.error("Product already added to this list.");
        toast.error("Das Produkt wurde dieser Liste bereits hinzugefügt.");
      } else {
        // toast.error("Sending To Collection Failed.");
        toast.error("Das Senden an die Sammlung ist fehlgeschlagen.");
      }
    }
    setShow(false);
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="sm"
      style={
        {
          // position: 'absolute',
          // top: modalPosition.top,
          // left: modalPosition.left,
          // backgroundColor: 'red',
          // margin: '0',
          // width: "auto",
          // maxHeight: '400px',
          // height: 'auto',
          // overflow: 'auto',
          // 'border-radius': '8px',
          // border: '1px solid #999',
          // background: '#FAFAFA',
          // padding: '0 10px 0 0',
        }
      }
    >
      <Modal.Header closeButton>
        <Modal.Title>{wl.svaetode}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {lists &&
          lists.length > 0 &&
          lists.map((list, index) => (
            <div
              className="d-flex justify-content-between"
              key={index}
              onClick={() => handleSelect(list)}
            >
              <label htmlFor="saveto2">{list}</label>
              <input type="radio" name="saveto" id="saveto2" />
            </div>
          ))}

        <div className="mt-3">
          <button
            className="w-100 btnclrwishlist py-1"
            onClick={(e) => setShow(false)}
          >
            <h5 onClick={(e) => handleClick3(e)}>+{wl.newcolde}</h5>
          </button>
        </div>
        <div className="mt-3 mb-0 w-100">
          <button
            className="w-100 btnclrwishlistdone py-1"
            // onClick={() => setShow(false)}
            onClick={() => sendToList()}
          >
            <h5 className="text-white">{wl.donede}</h5>
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
