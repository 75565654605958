import React from "react";

function Impressum() {
  return (
    <div className="container d-flex justify-content-center align-items-center vh-100 top-300">
      <div className="card p-4 mt-n5">
        <h2 className="text-primaryy">IMPRESSUM</h2>
        <p className="mb-0">Rawstuff Deals GmbH</p>
        <p>
          Zum Heidchen 6 <br />
          56377 Nassau <br />
          Deutschland <br />
          <br />
          Kontakt:
          <br />
          E-Mail: info@rawstuff.deals <br />
          Web: <a href="https://www.rawstuff.deals">www.rawstuff.deals</a>
          <br />
          Unternehmensinformationen:
          <br />
          Umsatzsteuer-Identifikationsnummer: DE359140193 <br />
          Handelsregister: HRB 28974 <br />
          Steuer-Nummer: 30/650/26091 <br />
          <br />
          Plattform der EU-Kommission zur Online-Streitbeilegung:
          <a href="https://ec.europa.eu/odr" target="_blank">
            https://ec.europa.eu/odr
          </a>
          <br />
          <br />
          Wir sind zur Teilnahme an einem Streitbeilegungsverfahren vor einer
          Verbraucherschlichtungsstelle weder verpflichtet noch bereit.
          <br />
        </p>
      </div>
    </div>
  );
}

export default Impressum;
