import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import { toast } from 'react-toastify';

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (credentials, thunkAPI) => {
    try {
      // localStorage.removeItem('stripOnboard');
      const response = await axios.post(
        "https://api.rawstuff.deals/api/v1/login",
        credentials
      );
      // console.log('error', response.data);

      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        // If the API returns a response with an error message
        return thunkAPI.rejectWithValue({ message: error.response.data });
      } else {
        // If there's no specific error message in the response
        return thunkAPI.rejectWithValue({
          message: "An unexpected error occurred. Please try again later.",
        });
      }
    }
  }
);

export const RegisterBuyerUser = createAsyncThunk(
  "auth/registerUser",
  async (formData) => {
    try {
      console.log(formData, "yemiform");
      const form = new FormData();
      form.append("name", formData.name);
      form.append("email", formData.email);
      form.append("phoneNum", formData.phoneNum);
      form.append("password", formData.password);
      form.append("passwordcon", formData.passwordcon);
      form.append("promocode", formData.promocode);
      form.append("street", formData.street);
      form.append("houseNumber", formData.houseNumber);
      form.append("vatNumber", formData.vatNumber);
      form.append("vatNumberVerification", formData.vatNumberVerification);
      form.append("postalCode", formData.postalCode);
      form.append("authorizedRepPosition", formData.authorizedRepPosition);
      form.append("authorizedRepFirstName", formData.authorizedRepFirstName);
      form.append("authorizedRepLastName", formData.authorizedRepLastName);
      form.append("authorizedRepEmail", formData.authorizedRepEmail);
      form.append("authorizedRepTelephone", formData.authorizedRepTelephone);
      form.append("authorizedRepID", formData.authorizedRepID);
      form.append("authorizedRepDob", formData.authorizedRepDob);
      form.append("authorizedRepAddress", formData.authorizedRepAddress);
      form.append("authorizedRepGender", formData.authorizedRepGender);
      form.append("howDidYouFindOut", formData.howDidYouFindOut ?? "null");
      // form.append("verificationDocument", formData.verificationDocument);
      // form.append("companyDocument", formData.companyDocument);
      form.append("selectedSocialMedia", formData.selectedSocialMedia);
      form.append("role", formData.role);
      form.append("country", formData.country);
      form.append("city", formData.city);
      form.append("ImnotRobot", formData.ImnotRobot);
      form.append("CompanyName", formData.CompanyName);
      form.append("companyInfo", formData.companyInfo);
      form.append("country_of_trade", formData.country_of_trade);
      const cdArr = Array.from(formData.companyDocument);
      Array.isArray(cdArr) &&
        cdArr?.length > 0 &&
        cdArr?.map((file, key) => {
          form.append(`companyDocument[${key}]`, file);
        });

      const vdArr = Array.from(formData.verificationDocument);
      Array.isArray(vdArr) &&
        vdArr?.length > 0 &&
        vdArr?.map((file, key) => {
          form.append(`verificationDocument[${key}]`, file);
        });

      const response = await axios.post(
        "https://api.rawstuff.deals/api/v1/signup",
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return response;
    } catch (error) {
      console.log("🚀 ~ error:", error);
      toast.error(error.response.data.message);
      // return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const ContactMeApi = createAsyncThunk(
  "auth/contactme",
  async (credentials, thunkAPI) => {
    try {
      const response = await axios.post(
        "https://api.rawstuff.deals/api/v1/contactUs",
        credentials
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  token: localStorage.getItem("token") || null,
  loading: false,
  error: null,
};

const userSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials(state, action) {
      const { token, authorities } = action.payload;
      state.authorities = token; // Update authorities with the provided token
      console.log("toke", token);
      state.authorities = authorities; // Update authorities with the provided authorities (should this be state.token instead?)
      localStorage.setItem("token", token); // Store token in local storage
      localStorage.setItem("authorities", authorities); // Store authorities in local storage
    },
    log_out(state, action) {
      state = action.payload; // Set token to null
      localStorage.removeItem("token"); // Remove token from local storage
      localStorage.removeItem("authorities"); // Remove authorities from local storage
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        localStorage.setItem("token", JSON.stringify(action.payload));
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const selectAuthorities = (state) => state.auth.authorities; // Select authorities from the auth state

export const { setCredentials, logOut } = userSlice.actions;

export default userSlice.reducer;
