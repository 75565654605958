import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

function PaymentModal(props) {
  // check the user they agreed on AGB law or not
  const [isChecked, setIsChecked] = useState(false);
  // handle check box
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const makePayment = async () => {
    let token = localStorage.getItem("token");
    token = JSON.parse(token);

    try {
      const response = await axios.post(
        "https://api.rawstuff.deals/api/v1/stripe/onBoard",
        { type: props.pricingtype },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token.token}`,
          },
        }
      );
      const data = response.data;
      if (data.data.url) {
        window.location.href = data.data.url;
      } else {
        toast.error("You have already subscribed!");
      }
    } catch (error) {
      // Handle error
      console.error("Payment error:", error);
    }
  };

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="custom-memebership-agb-modal custom-modal-membership"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4>Zur Zahlungsabwicklung</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <p>

            Sie haben sich für die Fortsetzung des {props.pricingtype}
            {props.role} Abonnements entschieden. <br />
            Sie müssen eine sichere Zahlung leisten, um diesen Plan zu
            aktivieren und zu genießen. Plan zu genießen.
            <br />
          </p> */}
          <p>
            Sie haben sich für den monatlichen Verkäufer-Abonnementplan
            entschieden. Sie müssen eine sichere Zahlung leisten, um dieses Abo
            zu aktivieren und zu nutzen.
          </p>
        </Modal.Body>
        <Modal.Footer>

          <p>
            <p>
              Bitte stimmen Sie den <Link to="/agbs">(AGB)</Link> von Rawstuff
              Deals zu?
            </p>
          </p>
          <Form.Check
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />

          <Button
            variant="primary"
            onClick={() => makePayment(props.role, props.pricingtype)}
            disabled={!isChecked}
          >
            <i class="fa-solid fa-check"></i> &nbsp; Bezahlen
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default PaymentModal;
