import React, { useState } from 'react';
import './style.css';
import Banner from './Banner';
import PricingPlan from './PricingPlan';

function MemberShipPage() {
  const [pricingtype, setPricingTYe] = useState(0);

  return (
    <div className='top100'>
      <div className=''>
        <div className=''>
         <Banner setPricingTYe={setPricingTYe}/>
         <PricingPlan pricingtype={pricingtype}/>
        </div>
      </div>
    </div>
  )
}

export default MemberShipPage;