import React, { useState, useEffect } from "react";
import transactionData from "./Transaction.json";
import { Link, useNavigate } from "react-router-dom";

const Transaction = () => {
  const [transaction, setTransaction] = useState(null);
  const [isTransactionPending, setTransactionPending] = useState(false);
  const [isContactingSupport, setContactingSupport] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchTransactionData();
  }, []);

  const fetchTransactionData = () => {
    setTimeout(() => {
      setTransaction(transactionData);
    }, 1000);
  };

  const handleRetryTransaction = () => {
    setTransactionPending(true);
    // Simulating an asynchronous transaction request
    setTimeout(() => {
      setTransaction({ ...transaction, status: "success", errorMessage: null });
      setTransactionPending(false);
    }, 2000);
  };

  const handleNavigateToEmail = () => {
    const emailAddress = "rawstuff@example.com";
    const emailSubject = transaction?.errorMessage;
    const emailBody = `Just wanted to say hi! I exprencing this error + ${transaction?.errorMessage} Please Help me`;

    const mailtoUrl = `mailto:${emailAddress}?subject=${encodeURIComponent(
      emailSubject
    )}&body=${encodeURIComponent(emailBody)}`;

    return <Link to="/contact">Contact Support</Link>;
  };

  const handleContactSupport = () => {
    setContactingSupport(true);
    // Simulating an asynchronous support request
    setTimeout(() => {
      setContactingSupport(false);
      handleNavigateToEmail();
    }, 1000);
  };

  return (
    <div className="transaction">
      {transaction && transaction.status === "failed" && (
        <div>
          <p>Error: {transaction.errorMessage}</p>
          <button
            onClick={handleRetryTransaction}
            disabled={isTransactionPending}
          >
            Retry Transaction
          </button>
          <button onClick={handleContactSupport} disabled={isContactingSupport}>
            <Link to="/contact">Contact Support</Link>
          </button>
        </div>
      )}
      {transaction && transaction.status === "success" && (
        <p>Successful transaction!</p>
      )}
      {!transaction && <p>Loading transaction data...</p>}
    </div>
  );
};

export default Transaction;
