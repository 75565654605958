import React from "react";
import "./AdvertHero.css";
import { images } from "../../../Constants";
// import adds from '../../../Asset/images/marketplace/ice.jpeg'

const AdvertHero = () => {
  return (
    <div className="AdvertHeroContainer mt-3 mb-4 d-none d-lg-block">
      <div className="AdvertHero-bg">
        <div className="row justify-content-between align-items-center ">
          <div className="col-md-6">
            <div className="advert-content">
              <div className="advert-Txt">
                <h4>Ihr Platz Für Werbung?</h4>
                <p>
                  Entdecken Sie unsere Werbemöglichkeiten und steigern Sie Ihre
                  Reichweite auf unserem lebhaften Marktplatz! Kontaktieren Sie
                  uns jetzt für maßgeschneiderte Lösungen.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="advert-banner-wrapper">
              <div className="advert-banner">
                <img
                  src={images.AdvertImg}
                  alt="ads_image"
                  width="100%"
                  height="100%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvertHero;
