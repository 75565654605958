import { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "./ForgotPassword.css";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");

  const emailHandler = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Send password reset link to email
      const res = await axios.post(
        "https://api.rawstuff.deals/api/v1/requestResetPassword",
        {
          email: email,
        }
      );
      // toast.success("Password reset link sent to your email");
      toast.success(
        "Ein Link zum Zurücksetzen des Passworts wird an Ihre  Emailadresse geschickt"
      );
    } catch (error) {
      // toast.error("Error sending password reset link");
      toast.error("Fehler beim Senden des Passwort Reset-Links");
    }
  };

  return (
    <div className="container-fluid d-flex align-items-center justify-content-center vh-100 mt-5">
      <div
        className="card p-4"
        style={{ boxShadow: "0 4px 8px rgba(0,0,0,0.1)" }}
      >
        <h4 className="text-center mb-4">
          {/* Forgot Password */}
          Passwort vergessen?
        </h4>
        <p className="text-center subtext mb-4">
          {/* No worries, we will send you reset instructions */}
          Wir schicken Ihnen eine entsprechende Anweisung
        </p>

        <form className="card__form" onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input
              type="email"
              value={email}
              onChange={(event) => emailHandler(event)}
              name="email"
              className="form-control"
              placeholder="Geben Sie Ihre Emailadresse ein"
              required
            />
          </div>

          <button
            type="submit"
            className="btn btn-dark w-100"
            style={{
              transition: "background-color 0.3s",
              backgroundColor: "#000000",
              borderColor: "#000000",
            }}
          >
            {/* Reset Password */}
            Passwort zurücksetzen
          </button>

          <div className="mt-3 text-center">
            <Link to="/login" style={{ color: "#FF561D" }}>
              {/* Back to Login */}
              Zurück zur Anmeldung
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
