import React, { useState, useEffect } from "react";
import ImageIcon from "@mui/icons-material/Image";
import Slider from "react-slick";
import "./style.css";

function Categories() {
  const Categories = [
    {
      categoryName: "Ads 1",
      image: "url",
    },
    {
      categoryName: "Ads 2",
      image: "url",
    },
    {
      categoryName: "Ads 3",
      image: "url",
    },
    {
      categoryName: "Ads 4",
      image: "url",
    },
  ];

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="container-fluid">
      <div className="text-center">
        {isMobile ? (
          <div className="row">
            <Slider {...settings}>
              {Categories.map((category, id) => (
                <div
                  key={id}
                  className="col-sm-8 catcardslider p-4 mb-1 clickwl catcardbg"
                >
                  <div className="category-bgg d-flex">
                    <div className="bg-white p-3">
                      <ImageIcon />
                    </div>
                    <div className="category-namee p-3">
                      {category.categoryName}
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        ) : (
          <div className="row catcard">
            {Categories.map((category, id) => (
              <div
                key={id}
                className="col-md-5 col-lg p-4 mb-1 clickwl catcardbg"
              >
                <div className="d-flex">
                  <div className="p-3 bg-white">
                    <ImageIcon />
                  </div>
                  <div className="p-3">{category.categoryName}</div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default Categories;
