import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

import { Provider } from "react-redux";
import store from "./redux/store";
import { fetchProducts } from "./redux/Slice/ProductSlice";
import { getTotals } from "./redux/Slice/ShoppingCartSlice";
import {TranslationProvider, registerTranslations} from "@psyycker/react-translation";

//Registration translation starts Here
registerTranslations({
  'en-US': {
    Home: 'Home',
    OrderTracking: "Order Tracking",
    TrackOrder: "Track Order",
    Order: "Order",
    AboutUs: "About Us",
    Cart: "Cart",
    Shop: "Shop",
    ShoppingCart: "Shopping Cart",
    MarketPlace: "Market Place",
    OurServices: "Our Services",
    Login: "Log In",
    SignIn: "Sign In",
    LogOut: "Log Out",
    SignOut: "Sign Out",
    Dashboard: "Dashboard",
    Wallet: "Wallet",
    Edit: "Edit",
    Delete: "Delete",
    Category: "Category",
    SubCategory: "Sub Category",
    Variation: "Variation",
    Tax: "Tax",
    Membership: "Membership",
    Faq: "FAQ",
    Contact: "Contact",
    Footer: "Footer",
    Links: "Links",
    Privacy: "Privacy",
    Policy: "Policy",
    PrivacyAndPolicy: "Privacy and Policy",
    TermsAndCondition: "Terms and Conditions",
    Products: "Products",
    Latest: "Latest",
    TrendingProducts: "Trending Products",
    TopRated: "Top Rated",
    BestSeller: "Best Seller",
    LatestUploadedProduct: "Latest Uploaded Products",
    Seller: "Seller",
    Buyer: "Buyer",
    Favorite: "Favorite",
    Upload: "Upload",
    Download: "Download",
    Profile: "Profile",
    Post: "Recent Post",
    Blog: "Blog",
    Notifications: "Notifications",
    Messages: "Messages",
    Register: "Register",
    Account: "Account",
    Welcome: "Welcome",
    Price: "Price",
    CostPrice: "Cost Price",
    MinPrice: "Min Price",
    MaxPrice: "Max Price",
    Quantity: "Quantity",
    Country: "Country",
    State: "State",
    WarehouseLocation: "Warehouse Location",
    leadingB2B: "Rawstuff is the leading B2B Platform",
    OptimalInternetPresence: "Optimal Internet Presence",
    QualifiedBusinessCustomer: "Qualified business customer",
    StartNow: "Start Now",
    RegisterYourCompany: "Register Your Company",
    WelcomeText: "Welcome to Rawstuff Deals",
    RegisterHere: "Register here",
    NotCustomer: "Not a Customer",
    Email: "Email",
    Password: "Password",
    AddToCart: "Add to Cart",
    BuyNow: "Buy Now",
    Previous: "Previous",
    Next: "Next",
    CopyRight: "Copyright",
    AllRightsReserved: "All Rights Reserved",
    WhatAreYouLookingFor: "What are tou looking for",
    RememberMe: "Remember me",

    CreateAccount: "Create an account",
    AccountInformation: "Account Information",
    UserName: "User Name",
    RepeatPassword: "Repeat Password",
    PromoCode: "Promo Code",
    NotRobot: "I'm not a Robot!",
    CompanyRegisteration: "Company Registration",
    CompanyName: "Company Name",
    VatNumber: "VAT Number",
    VatNumberVerification: "VAT Number Verification",
    City: "City",
    HouseNumber: "House Number",
    Street: "Street",
    PostalCode: "Postal Code",
    Telephone: "Telephone",
    CompanyInformation: "Company Information",
    UploadDocument: "Upload Document",
    AuthorizedRepresentative: "Authorized Representative",
    FirstName: "First Name",
    Position: "Position",
    UploadIDCard: "Upload ID-Card or Passport",
    Passport: "Passport",
    IDCard: "ID CARD",
    ChooseFile: "Choose File",
    HowYouFindUs: "How did you find out about us?",
    SocialMedia: "Social Media",
    Recommendation: "Recommendation",
    Others: "Others",
    SelectAnOption: "Select an option",
    ForgetPassword: "Forget Password",
    DontHaveAccount: "Don't have an Account yet?",
    PopularCollections: "Popular Collections",
    Highlights: "Highlights",
    TheB2Bplatform: "The B2B platform for the best purchasing decision",
    RegisterAsBuyer: "Register as buyer",
    RegisterAsSeller: "Register as Seller",
    SearchCompanies: "Search companies and save personal list of companies",
    ViewSales: "View sales nd financial data",
    ContactMultiple: "Contact multiple vendors",
    RegisterForFree: "RegisterForFree",
    ToInsideBusiness: "To Inside Business",
    FreeSpace: "Free space for Advertising?",
    Subscribe: "Subscribe",
    DontMiss: "Don't miss out on any Dutchie news!",
    FindOut: "Find out here what moves your industry today - from digital trends to new developments in purchasing. Find out here what moves your industry today - from digital trends to new developments in purchasing.",
    Address: "Address",
    Shipping: "Shipping",

    MembershipPlans: "Membership Plans",
    SimpleTransparent: "Simple, transparent pricing that grows with you. Try any plan free for 30 days.",
    MonthlyBilling: "Monthly billing",
    AnnualBilling: "Annual billing",
    BasicMembership: "Basic Membership",
    SilverMembership: "Silver Membership",
    PlatinumMembership : "Platinum Membership",
    GoldMembership: "Gold Membership",
    SelectPlan: "Select Plan",

    ProductRanking: "Product ranking",
    ProductRankingTextBasic: "Products from Basic members have standard visibility in search results and category listings.",
    ProductRankingTextSilver: "Products from Silver members receive a moderate boost in visibility in search results and category listings.",
    ProductRankingTextPlatinium: "Products from Platinum members receive the highest level of visibility in search results and category listings.",
    ProductRankingTextGold: "Products from Gold members receive a significant boost in visibility in search results and category listings.",
    Numberofproductlistings: "Number of product listings",
    NumberofproductlistingsText: "Limited to 10 active product listings.",
    CustomerSupport: "Customer Support",
    CustomerSupportText: "Access to basic customer support via email.",
    SellerDashboard: "Seller dashboard",
    SellerDashboardText: "Basic analytics and reporting features",

    SellerDashboardTextSilver: "Advanced analytics and reporting features, including sales data and customer insights.",
    SellerDashboardTextGold: " Comprehensive analytics and reporting features, including sales data, customer insights, and industry trends.",
    SellerDashboardTextPlatinium: "Comprehensive analytics and reporting features, including sales data, customer insights, industry trends, and customized recommendations for business growth",

    FeaturedProducts: "Featured products",
    FeaturedProductsTextSilver: "Ability to designate up to 5 products as Featured increasing visibility on category pages or promotional sections.",
    FeaturedProductsTextGold: "Ability to designate up to 15 products as Featured, increasing visibility on category pages or promotional sections.",
    FeaturedProductsTextPlatinium: "Ability to designate up to 30 products as Featured, increasing visibility on category pages or promotional sections.",

    BannerAdvertising: "Banner advertising",
    BannerAdvertisingText: "Premium banner advertising placements on the platform to maximize brand exposure.",
    SponsoredProducts: "Sponsored products",
    SponsoredProductsText : "Eligibility for sponsored product placements in search results or on category pages.",

    Upto50Text: "Up to 50 active product listings.",
    Upto200Text: "Up to 200 active product listings.",
    UnlimitedProductsText: "Unlimited active product listings.",

    CountryOfOrigin: "Country Of Origin",
    TotalPrice: "Total Price",
    CountryOfWarehouse: "Country Of Warehouse",
    ShippingCost: "Shipping Cost",
    Taxable: "Taxable",
    EstimatedTaxFor: "Estimated Tax For",
    DeliveryTime: "Delivery time",
    SoldBy: "Sold by",
    Calculation: "Calculation",
    Number: "Number",
    ShipTo: "Ship To"
    





  },
  'german': {
    Home: 'Heim',
    OrderTracking: "Sendungsverfolgung",
    TrackOrder: "Sendungsverfolgung",
    Order: "Befehl",
    AboutUs: "Über uns",
    Cart: "Wagen",
    Shop: "Geschäft",
    ShoppingCart: "Einkaufswagen",
    MarketPlace: "Marktplatz",
    OurServices: "Unsere Dienstleistungens",
    Login: "Anmeldung",
    SignIn: "anmelden",
    LogOut: "Ausloggen",
    SignOut: "Austragen",
    Dashboard: "Armaturenbrett",
    Wallet: "Geldbörse",
    Edit: "Bearbeiten",
    Delete: "Löschen",
    Category: "Kategorie",
    SubCategory: "Unterkategorie",
    Variation: "Variation",
    Tax: "Steuer",
    Membership: "Mitgliedschaft",
    Faq: "Fragen und Antworten",
    Contact: "kontaktiere uns",
    Footer: "fuBzeile",
    Links: "Links",
    Privacy: "Privatsphäre",
    Policy: "Politik",
    PrivacyAndPolicy: "Datenschutz-Bestimmungen",
    TermsAndCondition: "Allgemeine Geschäftsbedingungen",
    Products: "Produkte",
    Latest: "Latest",
    TrendingProducts: "Trendprodukte",
    TopRated: "Bestbewertet",
    BestSeller: "Bestseller, Verkaufsschlager, Spitzenreiter",
    LatestUploadedProduct: "Neuestes hochgeladenes Produkt",
    Seller: "Verkäufer",
    Buyer: "Käufer",
    Favorite: "Favorit",
    Upload: "Upload",
    Download: "Herunterladen",
    Profile: "Profil",
    Post: "Post",
    Blog: "Blog",
    Notifications: "Benachrichtigungen",
    Messages: "Mitteilungen",
    Register: "Registrieren",
    Account: "Account",
    Welcome: "Willkommen",
    Price: "Preis",
    CostPrice: "Selbstkostenpreis",
    MinPrice: "Minimaler Preis",
    MaxPrice: "Maximaler Preis",
    Quantity: "Menge",
    Country: "Land",
    State: "Zustand",
    WarehouseLocation: "Wrehouse-Standort",
    leadingB2B: "Rawstuff ist das führende B2B",
    OptimalInternetPresence: "Optimaler Internetauftritt für Ihr Unternehmen",
    QualifiedBusinessCustomer: "Nur qualifizierte Geschäftskundenkontakte",
    StartNow: "Jetzt kostenlos starten",
    RegisterYourCompany: "Registrieren Sie Ihr Unternehmen",
    WelcomeText: "Willkommen zu",
    RegisterHere: "Hier registrieren",
    NotCustomer: "Kein Kunde",
    Email: "Email",
    Password: "Passwort",
    AddToCart: "In den Warenkorb legen",
    BuyNow: "Kaufe jetzt",
    Previous: "Vorherige",
    Next: "Nächste",
    CopyRight: "Urheberrechte ©",
    AllRightsReserved: "Alle Rechte vorbehalten",
    WhatAreYouLookingFor: "wonach suchst du",
    RememberMe: "Mich erinnern",
    
    CreateAccount: "Ein Konto erstellen",
    AccountInformation: "Kontoinformationen",
    UserName: "Nutzername",
    RepeatPassword: "Passwort wiederholen",
    PromoCode: "Aktionscode",
    NotRobot: "Ich bin kein Roboter!",
    CompanyRegisteration: "Firmenregistrierung",
    CompanyName: "Name der Firma",
    VatNumber: "Umsatzsteuer-Identifikationsnummer",
    VatNumberVerification: "Überprüfung der Umsatzsteuer-Identifikationsnummer",
    City: "Stadt",
    HouseNumber: "Hausnummer",
    Street: "Straße",
    PostalCode: "Postleitzahl",
    Telephone: "Telefon",
    CompanyInformation: "Firmeninformation",
    UploadDocument: "Dokument hochladen",
    AuthorizedRepresentative: "Bevollmächtigter Vertreter",
    FirstName: "Vorname",
    Position: "Position",
    UploadIDCard: "Personalausweis oder Reisepass hochladen",
    Passport: "Reisepass",
    IDCard: "AUSWEIS",
    ChooseFile: "Datei wählen",
    HowYouFindUs: "Wie haben Sie von uns erfahren?",
    SocialMedia: "Sozialen Medien",
    Recommendation: "Empfehlung",
    Others: "Andere",
    SelectAnOption: "Wähle eine Option",
    ForgetPassword: "Passwort vergessen",
    DontHaveAccount: "Sie haben noch kein Konto?",
    PopularCollections: "Beliebte Sammlungen",
    Highlights: "Höhepunkte",
    TheB2Bplatform: "Die B2B-Plattform für die beste Kaufentscheidung",
    RegisterAsBuyer: "Als Käufer registrieren",
    RegisterAsSeller: "Registrieren Sie sich als Verkäufer",
    SearchCompanies: "Suchen Sie nach Unternehmen und speichern Sie Ihre persönliche Firmenliste",
    ViewSales: "Verkaufs- und Finanzdaten anzeigen",
    ContactMultiple: "Kontaktieren Sie mehrere Anbieter",
    RegisterForFree: "Kostenlos registrieren",
    ToInsideBusiness: "Zum Inside Business",
    FreeSpace: "Freier Platz für Werbung?",
    Subscribe: "Abonnieren",
    DontMiss: "Verpassen Sie keine Neuigkeiten von Dutchie!",
    FindOut: "Erfahren Sie hier, was Ihre Branche heute bewegt – von digitalen Trends bis hin zu neuen Entwicklungen im Einkauf. Erfahren Sie hier, was Ihre Branche heute bewegt – von digitalen Trends bis hin zu neuen Entwicklungen im Einkauf.",
    Address: "Adresse",
    Shipping: "Versand",

    MembershipPlans: "Mitgliedschaftspläne",
    SimpleTransparent: "Einfache, transparente Preisgestaltung, die mit Ihnen wächst. Testen Sie jeden Plan 30 Tage lang kostenlos.",
    MonthlyBilling: "Monatliche Abrechnung",
    AnnualBilling: "Jährliche Abrechnung",
    BasicMembership: "Basismitgliedschaft",
    SilverMembership: "Silber-Mitgliedschaft",
    PlatinumMembership : "Platin-Mitgliedschaft",
    GoldMembership: "Gold Mitgliedschaft",
    SelectPlan: "Plan auswählen",

    ProductRanking: "Produktranking",
    ProductRankingTextBasic: "Products from Basic members have standard visibility in search results and category listings.",
    ProductRankingTextSilver: "Products from Silver members receive a moderate boost in visibility in search results and category listings.",
    ProductRankingTextPlatinium: "Produkte von Platinum-Mitgliedern erhalten die höchste Sichtbarkeit in Suchergebnissen und Kategorielisten.",
    ProductRankingTextGold: "Produkte von Gold-Mitgliedern erhalten eine deutliche Steigerung der Sichtbarkeit in Suchergebnissen und Kategorielisten.",
    Numberofproductlistings: "Anzahl der Produkteinträge",
    NumberofproductlistingsText: "Begrenzt auf 10 aktive Produkteinträge.",
    CustomerSupport: "Kundendienst",
    CustomerSupportText: "Zugang zum grundlegenden Kundensupport per E-Mail.",
    SellerDashboard: "Verkäufer-Dashboard",
    SellerDashboardText: "Grundlegende Analyse- und Berichtsfunktionen",

    SellerDashboardTextSilver: "Advanced analytics and reporting features, including sales data and customer insights.",
    SellerDashboardTextGold: " Comprehensive analytics and reporting features, including sales data, customer insights, and industry trends.",
    SellerDashboardTextPlatinium: "Comprehensive analytics and reporting features, including sales data, customer insights, industry trends, and customized recommendations for business growth",

    FeaturedProducts: "Ausgewählte Produkte",
    FeaturedProductsTextSilver: "Möglichkeit, bis zu 5 Produkte als „Hervorgehoben“ zu kennzeichnen, um die Sichtbarkeit auf Kategorieseiten oder Werbeabschnitten zu erhöhen.",
    FeaturedProductsTextGold: "Möglichkeit, bis zu 15 Produkte als „Empfohlen“ zu kennzeichnen, um die Sichtbarkeit auf Kategorieseiten oder Werbeabschnitten zu erhöhen.",
    FeaturedProductsTextPlatinium: "Möglichkeit, bis zu 30 Produkte als „Empfohlen“ zu kennzeichnen, um die Sichtbarkeit auf Kategorieseiten oder Werbeabschnitten zu erhöhen.",
    
    BannerAdvertising: "Bannerwerbung",
    BannerAdvertisingText: "Platzierung von Premium-Bannerwerbung auf der Plattform zur Maximierung der Markenpräsenz.",
    SponsoredProducts: "Gesponserte Produkte",
    SponsoredProductsText : "Eligibility for sponsored product placements in search results or on category pages.",

    Upto50Text: "Bis zu 50 aktive Produkteinträge.s",
    Upto200Text: "Bis zu 200 aktive Produkteinträge.",
    UnlimitedProductsText: "Unbegrenzte aktive Produkteinträge.",

    CountryOfOrigin: "Herkunftsland",
    TotalPrice: "Gesamtpreis",
    CountryOfWarehouse: "Land des Lagers",
    ShippingCost: "Versandkosten",
    Taxable: "Steuerpflichtig",
    EstimatedTaxFor: "Geschätzte Steuer für",
    DeliveryTime: "Lieferzeit",
    SoldBy: "Verkauft durch",
    Calculation: "Berechnung",
    Number: "Nummer",
    ShipTo: "Ausliefern"


    
  },
  'de': {
    Home: 'Thuis',
    OrderTracking: "Bestelling volgen",
    TrackOrder: "Bestelling volgen",
    Order: "Volgorde",
    AboutUs: "Over ons",
    Cart: "Winkelwagen",
    Shop: "Winkel",
    ShoppingCart: "Winkelmand",
    MarketPlace: "Marktplaats",
    OurServices: "Onze diensten",
    Login: "Aanmelden",
    SignIn: "Aanmelden",
    LogOut: "Uitloggen",
    SignOut: "Afmelden",
    Dashboard: "Armaturenbrett",
    Wallet: "Portemonnee",
    Edit: "Bewerking",
    Delete: "Verwijderen",
    Category: "Categorie",
    SubCategory: "Subcategorie",
    Variation: "Variatie",
    Tax: "Belasting",
    Membership: "Lidmaatschap",
    Faq: "Veel Gestelde Vragen",
    Contact: "Neem contact met ons op",
    Footer: "Voettekst",
    Links: "Koppelingen",
    Privacy: "Privacy",
    Policy: "Beleid",
    PrivacyAndPolicy: "Privacy en beleid",
    TermsAndCondition: "Algemene voorwaarden",
    Products: "Producten",
    Latest: "Laatste",
    TrendingProducts: "Populaire producten",
    TopRated: "Hoogst gewaardeerd",
    BestSeller: "Bestseller",
    LatestUploadedProduct: "Laatst geüploade product",
    Seller: "Verkoper",
    Buyer: "Koper",
    Favorite: "Favoriet",
    Upload: "Uploaden",
    Download: "Downloaden",
    Profile: "Profiel",
    Post: "Na",
    Blog: "bloggen",
    Notifications: "Meldingen",
    Messages: "Berichten",
    Register: "Register",
    Account: "Rekening",
    Welcome: "Welkom",
    Price: "Prijs",
    CostPrice: "Kostprijs",
    MinPrice: "Minimum prijs",
    MaxPrice: "Maximale prijs",
    Quantity: "Hoeveelheid",
    Country: "Land",
    State: "Staat",
    WarehouseLocation: "Magazijn Locatie",
    leadingB2B: "Rawstuff is het toonaangevende B2B-platform",
    OptimalInternetPresence: "Optimale internetaanwezigheid",
    QualifiedBusinessCustomer: "Gekwalificeerde zakelijke klant",
    StartNow: "Begin nu",
    RegisterYourCompany: "Registreer uw bedrijf",
    WelcomeText: "Willkommen zu",
    RegisterHere: "Registreer hier",
    NotCustomer: "Geen klant",
    Email: "E-mail",
    Password: "Wachtwoord",
    AddToCart: "Voeg toe aan winkelkar",
    BuyNow: "Koop nu",
    Previous: "Vorig",
    Next: "Volgende",
    CopyRight: "auteursrechten",
    AllRightsReserved: "Alle rechten voorbehouden",
    WhatAreYouLookingFor: "Waar bent u naar op zoek",
    RememberMe: "Onthoud me",
    
    CreateAccount: "Create an account",
    AccountInformation: "Account Information",
    UserName: "User Name",
    RepeatPassword: "Repeat Password",
    PromoCode: "Promo Code",
    NotRobot: "I'm not a Robot!",
    CompanyRegisteration: "Company Registration",
    CompanyName: "Company Name",
    VatNumber: "VAT Number",
    VatNumberVerification: "VAT Number Verification",
    City: "City",
    HouseNumber: "House Number",
    Street: "Street",
    PostalCode: "Postal Code",
    Telephone: "Telephone",
    CompanyInformation: "Company Information",
    UploadDocument: "Upload Document",
    AuthorizedRepresentative: "Authorized Representative",
    FirstName: "First Name",
    Position: "Position",
    UploadIDCard: "Upload ID-Card or Passport",
    Passport: "Passport",
    IDCard: "ID CARD",
    ChooseFile: "Choose File",
    HowYouFindUs: "How did you find out about us?",
    SocialMedia: "Social Media",
    Recommendation: "Recommendation",
    Others: "Others",
    SelectAnOption: "Select an option",
    ForgetPassword: "Forget Password",
    DontHaveAccount: "Don't have an Account yet?",
    PopularCollections: "Popular Collections",
    Highlights: "Highlights",
    TheB2Bplatform: "The B2B platform for the best purchasing decision",
    RegisterAsBuyer: "Register as buyer",
    RegisterAsSeller: "Register as Seller",
    SearchCompanies: "Search companies and save personal list of companies",
    ViewSales: "View sales nd financial data",
    ContactMultiple: "Contact multiple vendors",
    RegisterForFree: "RegisterForFree",
    ToInsideBusiness: "To Inside Business",
    FreeSpace: "Free space for Advertising?",
    Subscribe: "Subscribe",
    DontMiss: "Don't miss out on any Dutchie news!",
    FindOut: "Find out here what moves your industry today - from digital trends to new developments in purchasing. Find out here what moves your industry today - from digital trends to new developments in purchasing.",
    Address: "Adres",
    Shipping: "Verzenden",

    MembershipPlans: "Lidmaatschapsplannen",
    SimpleTransparent: "Eenvoudige, transparante prijzen die met u meegroeien. Probeer elk abonnement 30 dagen gratis uit.",
    MonthlyBilling: "Maandelijkse facturering",
    AnnualBilling: "Jaarlijkse facturering",
    BasicMembership: "Basis lidmaatschap",
    SilverMembership: "Zilveren lidmaatschap",
    PlatinumMembership : "Platinum-lidmaatschap",
    GoldMembership: "Gouden Lidmaatschap",
    SelectPlan: "Selecteer Abonnement",

    ProductRanking: "Productrangschikking",
    ProductRankingTextBasic: "Products from Basic members have standard visibility in search results and category listings.",
    ProductRankingTextSilver: "Products from Silver members receive a moderate boost in visibility in search results and category listings.",
    ProductRankingTextPlatinium: "Products from Platinum members receive the highest level of visibility in search results and category listings.",
    ProductRankingTextGold: "Products from Gold members receive a significant boost in visibility in search results and category listings.",
    Numberofproductlistings: "Aantal productvermeldingen",
    NumberofproductlistingsText: "Beperkt tot 10 actieve productvermeldingen.",
    CustomerSupport: "Klantenservice",
    CustomerSupportText: "Toegang tot basisklantenondersteuning via e-mail.",
    SellerDashboard: "Verkoper dashboard",
    SellerDashboardText: "Basisanalyse- en rapportagefuncties",

    SellerDashboardTextSilver: "Geavanceerde analyse- en rapportagefuncties, waaronder verkoopgegevens en klantinzichten.",
    SellerDashboardTextGold: "Uitgebreide analyse- en rapportagefuncties, waaronder verkoopgegevens, klantinzichten en branchetrends.",
    SellerDashboardTextPlatinium: "Uitgebreide analyse- en rapportagefuncties, waaronder verkoopgegevens, klantinzichten, branchetrends en aangepaste aanbevelingen voor bedrijfsgroei",

    FeaturedProducts: "Uitgelichte producten",
    FeaturedProductsTextSilver: "Mogelijkheid om tot 5 producten aan te wijzen als Uitgelicht, waardoor de zichtbaarheid op categoriepagina's of promotiesecties toeneemt.",
    BannerAdvertising: "Bannerreclame",
    BannerAdvertisingText: "Plaatsing van premium banneradvertenties op het platform om de bekendheid van het merk te maximaliseren.",
    SponsoredProducts: "Gesponsorde producten",
    SponsoredProductsText : "Geschiktheid voor gesponsorde productplaatsingen in zoekresultaten of op categoriepagina's.",

    Upto50Text: "Tot 50 actieve productvermeldingen.",
    Upto200Text: "Tot 200 actieve productvermeldingen.",
    UnlimitedProductsText: "Onbeperkte actieve productvermeldingen.",

    CountryOfOrigin: "Land van herkomst",
    TotalPrice: "Totale prijs",
    CountryOfWarehouse: "Land van magazijn",
    ShippingCost: "Transportkosten",
    Taxable: "Belastbaar",
    EstimatedTaxFor: "Geschatte belasting voor",
    DeliveryTime: "Aflevertijd",
    SoldBy: "Verkocht door",
    Calculation: "Berekening",
    Number: "Nummer",
    ShipTo: "Verzend naar"


  },
  'fr-FR': {
    Home: 'Maison',
    OrderTracking: "Suivi de commande",
    TrackOrder: "Suivi de commande",
    Order: "Commande",
    AboutUs: "À propos de nous",
    Cart: "Chariot",
    Shop: "Boutique",
    ShoppingCart: "Panier",
    MarketPlace: "Place du marché",
    OurServices: "Nos services",
    Login: "Connexion",
    SignIn: "S'identifier",
    LogOut: "Se déconnecter",
    SignOut: "Se déconnecter",
    Dashboard: "Tableau de bord",
    Wallet: "Portefeuille",
    Edit: "Modifier",
    Delete: "Supprimer",
    Category: "Catégorie",
    SubCategory: "Sous-catégorie",
    Variation: "Variation",
    Tax: "Impôt",
    Membership: "Adhésion",
    Faq: "Questions fréquemment posées",
    Contact: "Contactez-nous",
    Footer: "Bas de page",
    Links: "Liens",
    Privacy: "Confidentialité",
    Policy: "Politique",
    PrivacyAndPolicy: "Politique de confidentialité",
    TermsAndCondition: "termes et conditions",
    Products: "Des produits",
    Latest: "Dernière",
    TrendingProducts: "Produits tendance",
    TopRated: "Les mieux notés",
    BestSeller: "Best-seller",
    LatestUploadedProduct: "Derniers produits téléchargés",
    Seller: "Vendeuse",
    Buyer: "Acheteuse",
    Favorite: "Préférée",
    Upload: "Télécharger",
    Download: "Télécharger",
    Profile: "Profil",
    Post: "Poste",
    Blog: "Blog",
    Notifications: "Avis",
    Messages: "messages",
    Register: "Enregistrer",
    Account: "Compte",
    Welcome: "Bienvenue",
    Price: "Prix",
    CostPrice: "Prix ​​de revient",
    MinPrice: "Prix ​​minimum",
    MaxPrice: "Prix ​​maximal",
    Quantity: "Quantité",
    Country: "Pays",
    State: "État",
    WarehouseLocation: "Emplacement de l'entrepôt",
    leadingB2B: "Rawstuff est la principale plateforme B2B",
    OptimalInternetPresence: "Présence Internet optimale",
    QualifiedBusinessCustomer: "Client professionnel qualifié",
    StartNow: "Commencez maintenant",
    RegisterYourCompany: "Enregistrez votre entreprise",
    WelcomeText: "Bienvenue sur Rawstuff Deals",
    RegisterHere: "Inscrivez-vous ici",
    NotCustomer: "Pas un client",
    Email: "E-mail",
    Password: "Mot de passe",
    AddToCart: "Ajouter au panier",
    BuyNow: "Acheter maintenant",
    Previous: "Précédente",
    Next: "Suivante",
    CopyRight: "droits d'auteur",
    AllRightsReserved: "Tous les droits sont réservés",
    WhatAreYouLookingFor: "Que cherches-tu",
    RememberMe: "Souviens-toi de moi",
    
    CreateAccount: "Create an account",
    AccountInformation: "Account Information",
    UserName: "User Name",
    RepeatPassword: "Repeat Password",
    PromoCode: "Promo Code",
    NotRobot: "I'm not a Robot!",
    CompanyRegisteration: "Company Registration",
    CompanyName: "Company Name",
    VatNumber: "VAT Number",
    VatNumberVerification: "VAT Number Verification",
    City: "City",
    HouseNumber: "House Number",
    Street: "Street",
    PostalCode: "Postal Code",
    Telephone: "Telephone",
    CompanyInformation: "Company Information",
    UploadDocument: "Upload Document",
    AuthorizedRepresentative: "Authorized Representative",
    FirstName: "First Name",
    Position: "Position",
    UploadIDCard: "Upload ID-Card or Passport",
    Passport: "Passport",
    IDCard: "ID CARD",
    ChooseFile: "Choose File",
    HowYouFindUs: "How did you find out about us?",
    SocialMedia: "Social Media",
    Recommendation: "Recommendation",
    Others: "Others",
    SelectAnOption: "Select an option",
    ForgetPassword: "Forget Password",
    DontHaveAccount: "Don't have an Account yet?",
    PopularCollections: "Popular Collections",
    Highlights: "Highlights",
    TheB2Bplatform: "The B2B platform for the best purchasing decision",
    RegisterAsBuyer: "Register as buyer",
    RegisterAsSeller: "Register as Seller",
    SearchCompanies: "Search companies and save personal list of companies",
    ViewSales: "View sales nd financial data",
    ContactMultiple: "Contact multiple vendors",
    RegisterForFree: "RegisterForFree",
    ToInsideBusiness: "To Inside Business",
    FreeSpace: "Free space for Advertising?",
    Subscribe: "Subscribe",
    DontMiss: "Don't miss out on any Dutchie news!",
    FindOut: "Find out here what moves your industry today - from digital trends to new developments in purchasing. Find out here what moves your industry today - from digital trends to new developments in purchasing.",
    Address: "Adresse",
    Shipping: "VerExpéditionzenden",

    MembershipPlans: "Plans d'adhésion",
    SimpleTransparent: "Une tarification simple et transparente qui évolue avec vous. Essayez n'importe quel plan gratuitement pendant 30 jours.",
    MonthlyBilling: "Facturation mensuelle",
    AnnualBilling: "Facturation annuelle",
    BasicMembership: "Adhésion de base",
    SilverMembership: "Adhésion Argent",
    PlatinumMembership : "Adhésion Platine",
    GoldMembership: "Abonnement Or",
    SelectPlan: "Sélectionnez le plan",

    ProductRanking: "Classement des produits",
    ProductRankingTextBasic: "Products from Basic members have standard visibility in search results and category listings.",
    ProductRankingTextSilver: "Products from Silver members receive a moderate boost in visibility in search results and category listings.",
    ProductRankingTextPlatinium: "Products from Platinum members receive the highest level of visibility in search results and category listings.",
    ProductRankingTextGold: "Products from Gold members receive a significant boost in visibility in search results and category listings.",
    Numberofproductlistings: "Nombre de listes de produits",
    NumberofproductlistingsText: "Limité à 10 listes de produits actifs.",
    CustomerSupport: "Service client",
    CustomerSupportText: "Accès au support client de base par e-mail.",
    SellerDashboard: "Tableau de bord vendeur",
    SellerDashboardText: "Fonctionnalités d'analyse et de création de rapports de base",

    SellerDashboardTextSilver: "Fonctionnalités avancées d'analyse et de création de rapports, y compris les données de vente et les informations sur les clients.",
    SellerDashboardTextGold: "Fonctionnalités complètes d'analyse et de création de rapports, y compris les données de vente, les informations sur les clients et les tendances du secteur.",
    SellerDashboardTextPlatinium: "Fonctionnalités complètes d'analyse et de création de rapports, y compris les données sur les ventes, les informations sur les clients, les tendances du secteur et des recommandations personnalisées pour la croissance de l'entreprise",

    FeaturedProducts: "Produits populaires",
    FeaturedProductsTextSilver: "Possibilité de désigner jusqu'à 5 produits en vedette augmentant la visibilité sur les pages de catégories ou les sections promotionnelles.",
    FeaturedProductsTextGold: "Possibilité de désigner jusqu'à 15 produits en vedette, augmentant la visibilité sur les pages de catégories ou les sections promotionnelles.",
    FeaturedProductsTextPlatinium: "Possibilité de désigner jusqu'à 30 produits en vedette, augmentant la visibilité sur les pages de catégories ou les sections promotionnelles.",
    
    BannerAdvertising: "Bannière publicitaire",
    BannerAdvertisingText: "Placements publicitaires de bannières premium sur la plateforme pour maximiser l'exposition de la marque.",
    SponsoredProducts: "Produits sponsorisés",
    SponsoredProductsText : "Admissibilité aux placements de produits sponsorisés dans les résultats de recherche ou sur les pages de catégorie.",

    Upto50Text: "Jusqu'à 50 listes de produits actifs.",
    Upto200Text: "Jusqu'à 200 listes de produits actifs.",
    UnlimitedProductsText: "Listes de produits actives illimitées."


  },
});

// Registration translation ends Here

store.dispatch(fetchProducts());
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
        <TranslationProvider defaultLocale="en-US">
    <Provider store={store}>
      <App />
    </Provider>
    </TranslationProvider>
  </React.StrictMode> 
);
