import { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import SideBar from "./SideBar";
import DashBoardContent from "./DashBoardContent";
import EditProfilePage from "../EditProfile/EditProfilePage";
import PurchasedItems from "./PurchasedItem";
import ManageWarehouse from "../ManageWarehouse/ManageWarehouse";
import AddTicket from "./AddTickets";
import ProfilePage from "../Profile/ProfilePage";
import PartnerOverview from "./PartnerOverview";
import OrderTrackingPage from "../OrderTracking/OrderTrackingPage";

function DashboardPage() {
  const navigate = useNavigate();
  let token = localStorage?.getItem("token");
  token = JSON.parse(token)?.token;

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [navigate, token]);

  if (!token) {
    navigate("/login");
    return;
  }

  return (
    <div className="mx-3">
      <div className="container-fluid top100">
        <div className="row my-5">
          <div className="col-md-3">
            <SideBar />
          </div>

          {/* Nested routes within Dashboard */}
          <div className="col-md">
            <Routes>
              <Route path={`/`} element={<DashBoardContent />} exact />
              <Route path={`/edit-profile`} element={<EditProfilePage />} />
              <Route
                path={`/purchased-items/:status`}
                element={<PurchasedItems />}
              />
              <Route path={`/managewarehouse`} element={<ManageWarehouse />} />
              <Route path={`/add-ticket`} element={<AddTicket />} />
              <Route path={`/profile`} element={<ProfilePage />} />
              <Route path={`/partner-overview`} element={<PartnerOverview />} />
              <Route path={`/ordertracking`} element={<OrderTrackingPage />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardPage;
