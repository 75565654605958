import React from "react";
import { useNavigate } from "react-router-dom";
function PaymentSuccess() {
  const navigate = useNavigate();

  return (
    <div className="top-300 bbody d-flex justify-content-center align-items-center text-center">
      <div className="ccard text-center d-flex justify-content-center align-items-center text-center flex-column">
        <div
          className="bg-success rounded-circle text-center"
          style={{
            backgroundColor: "green",
            padding: "5px",
            width: "50px",
            height: "50px",
          }}
        >
          <i
            className="checkmarkk iii"
            style={{ color: "white", borderRadius: "50%", fontSize: "24px" }}
          >
            ✓
          </i>
        </div>
        <br />
        <h1 className="hh1">Payment Done!</h1>
        <p className="pp">
          Thank you for completing secure online payment.
          <br /> Have a great day!
        </p>
        <br />
        <br />
        <div className="buynow_button hovered px-sm-4 px-1" onClick={()=> navigate("/")}>Go Back</div>
      </div>
    </div>
  );
}

export default PaymentSuccess;