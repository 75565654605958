export const cityOptionsByCountry = {
  GB: [
    { code: "London", name: "London" },
    { code: "Manchester", name: "Manchester" },
    { code: "Liverpool", name: "Liverpool" },
    { code: "Birmingham", name: "Birmingham" },
    { code: "Glasgow", name: "Glasgow" },
    { code: "Edinburgh", name: "Edinburgh" },
    { code: "Bristol", name: "Bristol" },
    { code: "Leeds", name: "Leeds" },
    { code: "Sheffield", name: "Sheffield" },
    { code: "Newcastle", name: "Newcastle" },
    { code: "Cardiff", name: "Cardiff" },
    { code: "Belfast", name: "Belfast" },
    { code: "Aberdeen", name: "Aberdeen" },
    { code: "Inverness", name: "Inverness" },
  ],
  DE: [
    { code: "Berlin", name: "Berlin" },
    { code: "Hamburg", name: "Hamburg" },
    { code: "Munich", name: "Munich" },
    { code: "Cologne", name: "Cologne" },
    { code: "Frankfurt", name: "Frankfurt" },
    { code: "Stuttgart", name: "Stuttgart" },
    { code: "Dusseldorf", name: "Dusseldorf" },
    { code: "Dortmund", name: "Dortmund" },
    { code: "Essen", name: "Essen" },
    { code: "Leipzig", name: "Leipzig" },
    { code: "Bremen", name: "Bremen" },
    { code: "Dresden", name: "Dresden" },
    { code: "Hanover", name: "Hanover" },
    { code: "Nuremberg", name: "Nuremberg" },
    { code: "Hannover", name: "Hannover" },
    { code: "Cottbus", name: "Cottbus" },
    { code: "Kiel", name: "Kiel" },
    { code: "Munster", name: "Munster" },
    { code: "Augsburg", name: "Augsburg" },
    { code: "Gelsenkirchen", name: "Gelsenkirchen" },
    { code: "Bonn", name: "Bonn" },
    { code: "Darmstadt", name: "Darmstadt" },
    { code: "Freiburg", name: "Freiburg" },
    { code: "Mannheim", name: "Mannheim" },
    { code: "Karlsruhe", name: "Karlsruhe" },
    { code: "Wiesbaden", name: "Wiesbaden" },
    // Add more unique cities as needed
  ],

  IT: [
    { code: "Rome", name: "Rome" },
    { code: "Milan", name: "Milan" },
    { code: "Florence", name: "Florence" },
    { code: "Venice", name: "Venice" },
    { code: "Naples", name: "Naples" },
    { code: "Turin", name: "Turin" },
    { code: "Bologna", name: "Bologna" },
    { code: "Genoa", name: "Genoa" },
    { code: "Palermo", name: "Palermo" },
    { code: "Bari", name: "Bari" },
    { code: "Catania", name: "Catania" },
    { code: "Verona", name: "Verona" },
    { code: "Trieste", name: "Trieste" },
    { code: "Padua", name: "Padua" },
  ],
  US: [
    { code: "New York", name: "New York" },
    { code: "Los Angeles", name: "Los Angeles" },
    { code: "Chicago", name: "Chicago" },
    { code: "Miami", name: "Miami" },
    { code: "San Francisco", name: "San Francisco" },
    // ... Other cities for the United States
  ],
  AL: [
    { code: "Tirana", name: "Tirana" },
    { code: "Durres", name: "Durres" },
    { code: "Vlore", name: "Vlore" },
    { code: "Elbasan", name: "Elbasan" },
    { code: "Shkoder", name: "Shkoder" },
    // ... Other cities for Albania
  ],
  AD: [
    { code: "Andorra la Vella", name: "Andorra la Vella" },
    { code: "Encamp", name: "Encamp" },
    { code: "La Massana", name: "La Massana" },
    { code: "Ordino", name: "Ordino" },
    { code: "Canillo", name: "Canillo" },
    // ... Other cities for Andorra
  ],
  AM: [
    { code: "Yerevan", name: "Yerevan" },
    { code: "Gyumri", name: "Gyumri" },
    { code: "Vanadzor", name: "Vanadzor" },
    { code: "Ejmiatsin", name: "Ejmiatsin" },
    { code: "Hrazdan", name: "Hrazdan" },
    // ... Other cities for Armenia
  ],
  AT: [
    { code: "Vienna", name: "Vienna" },
    { code: "Graz", name: "Graz" },
    { code: "Linz", name: "Linz" },
    { code: "Salzburg", name: "Salzburg" },
    { code: "Innsbruck", name: "Innsbruck" },
    // ... Other cities for Austria
  ],
  AZ: [
    { code: "Baku", name: "Baku" },
    { code: "Ganja", name: "Ganja" },
    { code: "Sumqayit", name: "Sumqayit" },
    { code: "Mingachevir", name: "Mingachevir" },
    { code: "Lankaran", name: "Lankaran" },
    // ... Other cities for Azerbaijan
  ],
  BY: [
    { code: "Minsk", name: "Minsk" },
    { code: "Gomel", name: "Gomel" },
    { code: "Mogilev", name: "Mogilev" },
    { code: "Vitebsk", name: "Vitebsk" },
    { code: "Hrodna", name: "Hrodna" },
    // ... Other cities for Belarus
  ],
  BE: [
    { code: "Brussels", name: "Brussels" },
    { code: "Antwerp", name: "Antwerp" },
    { code: "Ghent", name: "Ghent" },
    { code: "Charleroi", name: "Charleroi" },
    { code: "Liege", name: "Liege" },
    // ... Other cities for Belgium
  ],
  BA: [
    { code: "Sarajevo", name: "Sarajevo" },
    { code: "Banja Luka", name: "Banja Luka" },
    { code: "Tuzla", name: "Tuzla" },
    { code: "Zenica", name: "Zenica" },
    { code: "Mostar", name: "Mostar" },
    // ... Other cities for Bosnia and Herzegovina
  ],
  BG: [
    { code: "Sofia", name: "Sofia" },
    { code: "Plovdiv", name: "Plovdiv" },
    { code: "Varna", name: "Varna" },
    { code: "Burgas", name: "Burgas" },
    { code: "Ruse", name: "Ruse" },
    // ... Other cities for Bulgaria
  ],
  HR: [
    { code: "Zagreb", name: "Zagreb" },
    { code: "Split", name: "Split" },
    { code: "Rijeka", name: "Rijeka" },
    { code: "Osijek", name: "Osijek" },
    { code: "Zadar", name: "Zadar" },
    // ... Other cities for Croatia
  ],
  CY: [
    { code: "Nicosia", name: "Nicosia" },
    { code: "Limassol", name: "Limassol" },
    { code: "Larnaca", name: "Larnaca" },
    { code: "Famagusta", name: "Famagusta" },
    { code: "Paphos", name: "Paphos" },
    // ... Other cities for Cyprus
  ],
  CZ: [
    { code: "Prague", name: "Prague" },
    { code: "Brno", name: "Brno" },
    { code: "Ostrava", name: "Ostrava" },
    { code: "Pilsen", name: "Pilsen" },
    { code: "Liberec", name: "Liberec" },
    // ... Other cities for Czech Republic
  ],
  DK: [
    { code: "Copenhagen", name: "Copenhagen" },
    { code: "Aarhus", name: "Aarhus" },
    { code: "Odense", name: "Odense" },
    { code: "Aalborg", name: "Aalborg" },
    { code: "Esbjerg", name: "Esbjerg" },
    // ... Other cities for Denmark
  ],
  EE: [
    { code: "Tallinn", name: "Tallinn" },
    { code: "Tartu", name: "Tartu" },
    { code: "Narva", name: "Narva" },
    { code: "Parnu", name: "Parnu" },
    { code: "Viljandi", name: "Viljandi" },
    // ... Other cities for Estonia
  ],
  FI: [
    { code: "Helsinki", name: "Helsinki" },
    { code: "Espoo", name: "Espoo" },
    { code: "Tampere", name: "Tampere" },
    { code: "Vantaa", name: "Vantaa" },
    { code: "Turku", name: "Turku" },
    // ... Other cities for Finland
  ],
  FR: [
    { code: "Paris", name: "Paris" },
    { code: "Marseille", name: "Marseille" },
    { code: "Lyon", name: "Lyon" },
    { code: "Toulouse", name: "Toulouse" },
    { code: "Nice", name: "Nice" },
    // ... Other cities for France
  ],
  GE: [
    { code: "Tbilisi", name: "Tbilisi" },
    { code: "Kutaisi", name: "Kutaisi" },
    { code: "Batumi", name: "Batumi" },
    { code: "Rustavi", name: "Rustavi" },
    { code: "Gori", name: "Gori" },
    // ... Other cities for Georgia
  ],
  GR: [
    { code: "Athens", name: "Athens" },
    { code: "Thessaloniki", name: "Thessaloniki" },
    { code: "Patras", name: "Patras" },
    { code: "Heraklion", name: "Heraklion" },
    { code: "Larissa", name: "Larissa" },
    // ... Other cities for Greece
  ],
  HU: [
    { code: "Budapest", name: "Budapest" },
    { code: "Debrecen", name: "Debrecen" },
    { code: "Szeged", name: "Szeged" },
    { code: "Miskolc", name: "Miskolc" },
    { code: "Pecs", name: "Pecs" },
    // ... Other cities for Hungary
  ],
  IS: [
    { code: "Reykjavik", name: "Reykjavik" },
    { code: "Akureyri", name: "Akureyri" },
    { code: "Kopavogur", name: "Kopavogur" },
    { code: "Hafnarfjordur", name: "Hafnarfjordur" },
    { code: "Keflavik", name: "Keflavik" },
    // ... Other cities for Iceland
  ],
  IE: [
    { code: "Dublin", name: "Dublin" },
    { code: "Cork", name: "Cork" },
    { code: "Limerick", name: "Limerick" },
    { code: "Galway", name: "Galway" },
    { code: "Waterford", name: "Waterford" },
    // ... Other cities for Ireland
  ],
  IT: [
    { code: "Rome", name: "Rome" },
    { code: "Milan", name: "Milan" },
    { code: "Florence", name: "Florence" },
    { code: "Venice", name: "Venice" },
    { code: "Naples", name: "Naples" },
    // ... Other cities for Italy
  ],
};

export const countryOptions = [
  { value: "All EU countries", label: "All EU countries" },
  { value: "Germany", label: "Germany" },
  { value: "Austria", label: "Austria" },
  { value: "Switzerland", label: "Switzerland" },
  { value: "Albania", label: "Albania" },
  { value: "Belgium", label: "Belgium" },
  { value: "Bulgaria", label: "Bulgaria" },
  { value: "Belarus", label: "Belarus" },
  { value: "Czech Republic", label: "Czech Republic" },
  { value: "Croatia", label: "Croatia" },
  { value: "Denmark", label: "Denmark" },
  { value: "Estonia", label: "Estonia" },
  { value: "Finland", label: "Finland" },
  { value: "France", label: "France" },
  { value: "Greece", label: "Greece" },
  { value: "Hungary", label: "Hungary" },
  { value: "Ireland", label: "Ireland" },
  { value: "Iceland", label: "Iceland" },
  { value: "Italy", label: "Italy" },
  { value: "Liechtenstein", label: "Liechtenstein" },
  { value: "Lithuania", label: "Lithuania" },
  { value: "Luxembourg", label: "Luxembourg" },
  { value: "Latvia", label: "Latvia" },
  { value: "Netherlands", label: "Netherlands" },
  { value: "Norway", label: "Norway" },
  { value: "Poland", label: "Poland" },
  { value: "Portugal", label: "Portugal" },
  { value: "Romania", label: "Romania" },
  { value: "Spain", label: "Spain" },
  { value: "Sweden", label: "Sweden" },
  { value: "Slovenia", label: "Slovenia" },
  { value: "Slovakia", label: "Slovakia" },
  { value: "United Kingdom", label: "United Kingdom" },
];




export const countryOptionss = [
  { code: "AL", name: "🇦🇱 Albania", value: "Albania" },
  { code: "AT", name: "🇦🇹 Austria", value: "Austria" },
  { code: "BE", name: "🇧🇪 Belgium", value: "Belgium" },
  { code: "BG", name: "🇧🇬 Bulgaria", value: "Bulgaria" },
  { code: "BY", name: "🇧🇾 Belarus", value: "Belarus" },
  { code: "CZ", name: "🇨🇿 Czech Republic", value: "Czech Republic" },
  { code: "HR", name: "🇭🇷 Croatia", value: "Croatia" },
  { code: "DK", name: "🇩🇰 Denmark", value: "Denmark" },
  { code: "EE", name: "🇪🇪 Estonia", value: "Estonia" },
  { code: "FI", name: "🇫🇮 Finland", value: "Finland" },
  { code: "FR", name: "🇫🇷 France", value: "France" },
  { code: "DE", name: "🇩🇪 Germany", value: "Germany" },
  { code: "GR", name: "🇬🇷 Greece", value: "Greece" },
  { code: "HU", name: "🇭🇺 Hungary", value: "Hungary" },
  { code: "IE", name: "🇮🇪 Ireland", value: "Ireland" },
  { code: "IC", name: "🇮🇸 Iceland", value: "Iceland" },
  { code: "IT", name: "🇮🇹 Italy", value: "Italy" },
  { code: "LI", name: "🇱🇮 Liechtenstein", value: "Liechtenstein" },
  { code: "LT", name: "🇱🇹 Lithuania", value: "Lithuania" },
  { code: "LU", name: "🇱🇺 Luxembourg", value: "Luxembourg" },
  { code: "LV", name: "🇱🇻 Latvia", value: "Latvia" },
  { code: "NL", name: "🇳🇱 Netherlands", value: "Netherlands" },
  { code: "NO", name: "🇳🇴 Norway", value: "Norway" },
  { code: "PL", name: "🇵🇱 Poland", value: "Poland" },
  { code: "PT", name: "🇵🇹 Portugal", value: "Portugal" },
  { code: "RO", name: "🇷🇴 Romania", value: "Romania" },
  { code: "ES", name: "🇪🇸 Spain", value: "Spain" },
  { code: "SE", name: "🇸🇪 Sweden", value: "Sweden" },
  { code: "CH", name: "🇨🇭 Switzerland", value: "Switzerland" },
  { code: "SI", name: "🇸🇮 Slovenia", value: "Slovenia" },
  { code: "SK", name: "🇸🇰 Slovakia", value: "Slovakia" },
  { code: "UK", name: "🇬🇧 United Kingdom", value: "United Kingdom" },
  // ... Add more countries here ...
];


