import logorawstuff from "../Asset/NavBar/logo-rawstuff.jpg"
import logo_rs from "../Asset/NavBar/Logo_rawstuff/logo_rs.png"
import logo_rawstuff from "../Asset/NavBar/Logo_rawstuff/logo_rawstuff.png"
import userProfile from "../Asset/NavBar/NavBar-icons/User-Profile.svg"
import userIcon from "../Asset/NavBar/NavBar-icons/userIcon.svg"
import essentialarrows from "../Asset/NavBar/NavBar-icons/essential-Arrows.svg"
import basket from "../Asset/NavBar/NavBar-icons/basket.svg"
import romanceHeart from "../Asset/NavBar/NavBar-icons/romance-heart.svg" 
import translateLanguage from "../Asset/NavBar/NavBar-icons/translate-language-square.svg" 
import languageTranslation from "../Asset/NavBar/NavBar-icons/languageTranslation.svg"; 
import ProfileSvg from "../Asset/NavBar/NavBar-icons/profile_svg.svg" 
import sales from "../Asset/NavBar/NavBar-icons/sales_svg.svg" 
import supports from "../Asset/NavBar/NavBar-icons/supports_svg.svg" 
import invoice from "../Asset/NavBar/NavBar-icons/invoice_svg.svg" 
import companySetting from "../Asset/NavBar/NavBar-icons/companysetting_svg.svg"  
import moreOption from "../Asset/NavBar/NavBar-icons/moreoption_svg.svg" 
import login from "../Asset/NavBar/NavBar-icons/log-in.svg" 
import logOut from "../Asset/NavBar/NavBar-icons/logOut_svg.svg" 
import ProfilePicture from "../Asset/images/member-lucia.jpg" 
import ProfileAvatar from "../Asset/profileAvatar/profileAvatar.jpg" 
import SuccessPicture from "../Asset/images/success.png" 

import Eruo from "../Asset/NavBar/NavBar-icons/Euro.svg" 
import customerSupports from "../Asset/NavBar/NavBar-icons/HeadphonesCustomer-support.svg" 
import messageChat from "../Asset/NavBar/NavBar-icons/MessagesChat.svg" 
import bellNotifications from "../Asset/NavBar/NavBar-icons/BellNotifications.svg" 
import notificationsBell from "../Asset/NavBar/NavBar-icons/notification_bell.svg"; 
import pinLocation from "../Asset/NavBar/NavBar-icons/PinLocation.svg" 
import downArrow from "../Asset/NavBar/NavBar-icons/downArrow.svg" 
import menu from "../Asset/NavBar/NavBar-icons/menu.svg" 
import AdvertImg from "../Asset/NavBar/NavBar-icons/AdvertImg.PNG" 
import flag from "../Asset/flags/flag.png" 

// about us image here
import aboutusdownload from "../Asset/aboutUs/AboutdownloadBtn.svg" 
import markTickIcon from "../Asset/aboutUs/markTickIcon.svg" 


import dollar from "../Asset/img/SVG/dollar.svg";
import payment from "../Asset/img/SVG/payment.svg";
import referral from "../Asset/img/SVG/referral.svg";
import shield from "../Asset/img/SVG/shield.svg";
import stable from "../Asset/img/SVG/stable.svg";
import support from "../Asset/img/SVG/support.svg";
// contact me
import  Headphones  from "../Asset/contactme/Headphones, Customer, support.png"
import  mailApp  from "../Asset/contactme/mail-app.png"
import  userProfiles  from "../Asset/contactme/User,Profile.png"
import  phonenum  from "../Asset/contactme/Group.png"
import backArrow from "../Asset/contactme/ArrowLeft.png"
import righticon from "../Asset/contactme/Vector.png" 
// payment card image
import visacard from "../Asset/paymentCard/visacard.png"; 
import mastercard from "../Asset/paymentCard/mastercard.png"; 
import maestrocard from "../Asset/paymentCard/maestrocard.png"; 
import humburger_menu from "../Asset/NavBar/res-navbar-icon/rawstuff-humburger-menu.svg"; 
import dashboard_Icon from "../Asset/NavBar/res-navbar-icon/dashbord-icon.svg"; 
import close_Icon from "../Asset/NavBar/res-navbar-icon/close-icon.svg"; 
import rawstuffProductCertificate from "../Asset/product-certificate/rawstuff-product-certificate.jpg"; 
import rsProductCertificate from "../Asset/product-certificate/rs-product-c-bage.png"; 
import recent_post1 from "../Asset/Blogs/PR_start_RSD.png"; 
import recent_post2 from "../Asset/Blogs/solar_energy_Flyer.png"; 
import recent_post_sm1 from "../Asset/Blogs/PR_start_RSD_sm.png"; 
import recent_post_sm2 from "../Asset/Blogs/solar_energy_flyer_sm.png"; 


import MAESK from "../Asset/shipping/image 6.png"
import CMA from "../Asset/shipping/image 7.png"
import UPS from "../Asset/shipping/image 8.png"
import FedEx from "../Asset/shipping/image 9.png"
import Mediterranean from "../Asset/shipping/image 11.png"

import Apple from "../Asset/SingleSignOn/apple.png"
import Microsoft from "../Asset/SingleSignOn/Microsoft.png"
import Google from "../Asset/SingleSignOn/GSocial Icon.png"

const rawStuffImages = {
  Apple,
  Microsoft,
  Google,
  MAESK,
  CMA,
  UPS,
  FedEx,
  Mediterranean,
  logorawstuff,
  logo_rs,
  logo_rawstuff,
  userProfile,
  userIcon,
  essentialarrows,
  basket,
  romanceHeart,
  translateLanguage,
  languageTranslation,
  ProfileSvg,
  sales,
  supports,
  invoice,
  companySetting,
  moreOption,
  login,
  logOut,
  Eruo,
  customerSupports,
  messageChat,
  bellNotifications,
  notificationsBell,
  pinLocation,
  downArrow,
  menu,
  AdvertImg,
  flag,

  aboutusdownload,
  markTickIcon,
  dollar,
  payment,
  referral,
  shield,
  stable,
  support,
  Headphones,
  mailApp,
  userProfiles,
  phonenum,
  backArrow,
  righticon,
  ProfilePicture,
  ProfileAvatar,
  SuccessPicture,
  maestrocard,
  mastercard,
  visacard,
  dashboard_Icon,
  humburger_menu,
  close_Icon,
  rawstuffProductCertificate,
  rsProductCertificate,
  recent_post1,
  recent_post2,
  recent_post_sm1,
  recent_post_sm2,
};
export default rawStuffImages;
